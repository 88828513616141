import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { saveAs } from '@progress/kendo-file-saver';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-StoricoReport',
  templateUrl: './StoricoReport.component.html',
  styleUrls: ['./StoricoReport.component.scss']
})
export class StoricoReportComponent implements OnInit {

  reportList: any
  reportListPaged: any
  displayedColumnsCantieri: any = ['Nome', 'Data creazione', 'Azioni']

  length = 0
  pageIndex: number = 0;
  pageSize: number = 10;

  isLoadingResults: any = true

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.GetAllReport()
  }

  handlePageEvent(event: PageEvent) {
    console.log(event.length)
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getReportPaged();
  }

  getReportPaged()
  {
    this.reportListPaged = this.batchReduce(this.reportList,this.pageSize)[this.pageIndex]
  }

  batchReduce<T>(arr: T[], batchSize: number): T[][] {
    return arr.reduce((batches, curr, i) => {
        if (i % batchSize === 0) batches.push([]);
        batches[batches.length - 1].push(arr[i]);
        return batches;
    }, [] as T[][]);
  }

  GetAllReport(){
    this.apiService.GetAllReport().subscribe(
      (data: any) => {
        this.reportList = data
        this.length = data.length
        this.getReportPaged()
        this.isLoadingResults = false
      },
      (error: any) => {
        this.openSnackBarError('Errore nel caricamento dei report')
      }
    );
  }

  readFile(data: any, name: any){
    const bytes = atob(data);
    const byteLength = bytes.length;
    const byteArray = new Uint8Array(byteLength);
    for (let i = 0; i < byteLength; i++) {
        byteArray[i] = bytes.charCodeAt(i);
    }

    // Use SheetJS to create a new workbook from the byte array
    const workbook = XLSX.read(byteArray, { type: 'array' });

    // Use SheetJS to convert the workbook to a new file blob
    const fileBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', bookSST: true });

    // Convert the file blob to a Blob object
    const blob = new Blob([fileBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    name = name + '.xlsx'
    // Save the blob as a file using FileSaver.js
    saveAs(blob, name);

  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<mat-card>
  <mat-card-title>Parametri Modelli</mat-card-title>
  <mat-card-content>
    <div class="edit-container" *ngIf="!isEdit">
      <div fxFlex></div>
      <div>
        <button
          mat-stroked-button
          color="primary"
          (click)="Add()"
          *ngIf="!isEdit"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<table
  *ngIf="!isEdit"
  mat-table
  matSort
  matSortActive="chiave"
  matSortDirection="asc"
  matSortDisableClear
  [dataSource]="dataSource"
  class="mat-elevation-z8"
>
  <!-- Id Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
  </ng-container>

  <!-- AmpiezzaHiLo Column -->
  <ng-container matColumnDef="ampiezzahilo">
    <th mat-header-cell *matHeaderCellDef>ampiezzahilo</th>
    <td mat-cell *matCellDef="let element">{{ element.AmpiezzaHiLo }}</td>
  </ng-container>

  <!-- Banco Column -->
  <ng-container matColumnDef="banco">
    <th mat-header-cell *matHeaderCellDef>banco</th>
    <td mat-cell *matCellDef="let element">{{ element.Banco }}</td>
  </ng-container>

  <!-- Forzacentrifuga Column -->
  <ng-container matColumnDef="forzacentrifugahilo">
    <th mat-header-cell *matHeaderCellDef>forzacentrifugahilo</th>
    <td mat-cell *matCellDef="let element">
      {{ element.ForzaCentrifugaHiLo }}
    </td>
  </ng-container>

  <!-- Frequenza Column -->
  <ng-container matColumnDef="frequenzahilo">
    <th mat-header-cell *matHeaderCellDef>frequenzahilo</th>
    <td mat-cell *matCellDef="let element">{{ element.FrequenzaHiLo }}</td>
  </ng-container>

  <!-- dynalink Column -->
  <ng-container matColumnDef="dynalink">
    <th mat-header-cell *matHeaderCellDef>dyn@link</th>
    <td mat-cell *matCellDef="let element">{{ element.Dynalink }}</td>
  </ng-container>

  <!-- larghezzastesa Column -->
  <ng-container matColumnDef="larghezzastesa">
    <th mat-header-cell *matHeaderCellDef>larghezzastesa</th>
    <td mat-cell *matCellDef="let element">{{ element.LarghezzaStesa }}</td>
  </ng-container>

  <!-- spessorestesamax Column -->
  <ng-container matColumnDef="spessorestesamax">
    <th mat-header-cell *matHeaderCellDef>spessorestesamax</th>
    <td mat-cell *matCellDef="let element">{{ element.SpessoreStesaMax }}</td>
  </ng-container>

  <!-- velocitastesa Column -->
  <ng-container matColumnDef="velocitastesa">
    <th mat-header-cell *matHeaderCellDef>velocitastesa</th>
    <td mat-cell *matCellDef="let element">{{ element.VelocitaStesa }}</td>
  </ng-container>

   <!-- tampering Column -->
   <ng-container matColumnDef="tampering">
    <th mat-header-cell *matHeaderCellDef>tampering</th>
    <td mat-cell *matCellDef="let element">{{ element.TamperingBanco }}</td>
  </ng-container>

  <!-- Vibrazione Column -->
  <ng-container matColumnDef="vibrazione">
    <th mat-header-cell *matHeaderCellDef>Vibrazione</th>
    <td mat-cell *matCellDef="let element">{{ element.Vibrazione }}</td>
  </ng-container>

  <!-- Chiave Column -->
  <ng-container matColumnDef="chiave">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chiave</th>
    <td mat-cell *matCellDef="let element">{{ element.Chiave }}</td>
  </ng-container>

  <!-- Model Column -->
  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Modello</th>
    <td mat-cell *matCellDef="let element">{{ element.Model }}</td>
  </ng-container>

  <!-- FuelTank Column -->
  <ng-container matColumnDef="fueltank">
    <th mat-header-cell *matHeaderCellDef>Serbatoio Lt</th>
    <td mat-cell *matCellDef="let element">{{ element.FuelTank }}</td>
  </ng-container>

  <!-- Gruppo Column -->
  <ng-container matColumnDef="gruppo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gruppo</th>
    <td mat-cell *matCellDef="let element">{{ element.Gruppo }}</td>
  </ng-container>

  <!-- FrequenzaTampering Column -->
  <!-- <ng-container matColumnDef="frequenzatampering">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">{{ element.FrequenzaTampering }}</td>
  </ng-container> -->

  <!-- FrequenzaTamperingHiLoBanco Column -->
  <!-- <ng-container matColumnDef="frequenzatamperinghilo">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">{{ element.FrequenzaTamperingHiLo }}</td>
  </ng-container> -->

  <!-- Actions -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Azioni</th>
    <ng-container *matCellDef="let element">
      <td mat-cell>
        <button
          (click)="Edit(element)"
          mat-stroked-button
          color="primary"
          class="margin"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          (click)="Elimina(element)"
          mat-stroked-button
          color="primary"
          class="margin"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  *ngIf="!isEdit"
  [pageSizeOptions]="[50]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
></mat-paginator>

<mat-card class="parameter-box" *ngIf="isEdit">
  <mat-card-header>
    <mat-card-title>Parametri funzionamento</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="parameterForm">
      <div class="parameter-container">
        <mat-form-field appearance="fill">
          <mat-label>Chiave</mat-label>
          <input matInput placeholder="Chiave" formControlName="chiave" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Modello</mat-label>
          <input matInput placeholder="Modello" formControlName="model" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Gruppo</mat-label>
          <mat-select formControlName="gruppo" required (selectionChange)="onChangeGruppo()">
            <mat-option *ngFor="let ws of gruppoSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Dyn@link</mat-label>
          <mat-select formControlName="dynalink">
            <mat-option *ngFor="let ws of dynalinkDataSource" [value]="ws">
              {{ ws }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="parameter-container" *ngIf="showCompattatore">

        <mat-form-field appearance="fill" >
          <mat-label>Vibrazione (On/Off)</mat-label>
          <input matInput placeholder="" formControlName="vibrazione" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>FrequenzaHiLo</mat-label>
          <input matInput formControlName="frequenzahilo" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Ampiezza HiLo</mat-label>
          <input matInput formControlName="ampiezzahilo" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>ForzaCentrifugaHiLo</mat-label>
          <input matInput formControlName="forzacentrifugahilo" />
        </mat-form-field>

        </div>

        <div class="parameter-container" *ngIf="showFinitrice">

        <mat-form-field appearance="fill" >
          <mat-label>Velocita Stesa</mat-label>
          <input matInput formControlName="velocitastesa" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Spessore Stesa Max</mat-label>
          <input matInput formControlName="spessorestesamax" />
        </mat-form-field>

        <!-- <mat-form-field appearance="fill" >
          <mat-label>Larghezza Stesa</mat-label>
          <input matInput formControlName="larghezzastesa" />
        </mat-form-field> -->

        </div>

        <div class="parameter-container" *ngIf="showBanco">

        <mat-form-field appearance="fill" >
          <mat-label>Tampering (On/Off)</mat-label>
          <input matInput formControlName="tampering" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Tampering HILo</mat-label>
          <input matInput formControlName="tamperinghilo" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Larghezza Stesa</mat-label>
          <input matInput formControlName="larghezzastesa" />
        </mat-form-field>

      </div>

      <div class="parameter-container">
        <mat-form-field appearance="fill" >
          <mat-label>Serbatoio Lt</mat-label>
          <input matInput formControlName="fueltank" type="number" />
        </mat-form-field>
      </div>

    </form>
  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions>
    <div fxFlex></div>
    <button
      [disabled]="!parameterForm.valid"
      mat-stroked-button
      color="primary"
      class="btn-block margin"
      (click)="Salva()"
    >
      <mat-icon>check</mat-icon>
    </button>
    <button
      mat-stroked-button
      color="warn"
      class="btn-block margin"
      (click)="Annulla()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>

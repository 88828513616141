<h2 mat-dialog-title>Avviso</h2>
<mat-dialog-content class="mat-typography">
  {{ text }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
    Chiudi
  </button>
</mat-dialog-actions>

<div #riferimento>
<mat-card class="card-title-start">
  <mat-card-title *ngIf="!isEdit" class="mat-card-title-first">Mezzi</mat-card-title>
  <mat-card-title *ngIf="isEdit" class="mat-card-title-first">Scheda dei parametri</mat-card-title>
  <mat-card-content>
    <div class="edit-container" *ngIf="!isEdit">
      <mat-form-field appearance="fill">
        <mat-label>Filtra Validi</mat-label>
        <mat-select
          [(ngModel)]="activeFilterId"
          (selectionChange)="GetMachine()"
        >
          <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex></div>
    <div *ngIf="!isEdit">
      <button
        mat-flat-button
        color="primary"
        (click)="Read()"
        class="margin"
      >
        Aggiorna Mezzi da Dyn@link
      </button>
    </div>
  </mat-card-content>
</mat-card>
</div>


<!-- TABELLA MEZZI -->

<div class="table-container mat-elevation-z8">
  <table
    *ngIf="!isEdit"
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="model"
    matSortDirection="asc"
    matSortDisableClear
  >
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
    </ng-container>

    <!-- Image Column -->
    <ng-container matColumnDef="image" sticky>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">

        <img [src]= 'element.LinkImage' width="100" height="80">
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">Nome</th>
      <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="oemname">
      <th mat-header-cell *matHeaderCellDef>Oem</th>
      <td mat-cell *matCellDef="let element">{{ element.OEMName }}</td>
    </ng-container>

    <!-- Model Column -->
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Modello</th>
      <td mat-cell *matCellDef="let element">{{ element.Model }}</td>
    </ng-container>

    <!-- Password Column -->
    <ng-container matColumnDef="serialnumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero Seriale</th>
      <td mat-cell *matCellDef="let element">{{ element.SerialNumber }}</td>
    </ng-container>

    <!-- Date End -->
    <ng-container matColumnDef="dateend">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Scadenza Licenza</th>
      <td mat-cell *matCellDef="let element">{{ element.DateEnd | date: "dd/MM/yyyy" }}</td>
    </ng-container>

    <!-- CumulativeHours Column -->
    <ng-container matColumnDef="cumulativeHours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ore operative</th>
      <td mat-cell *matCellDef="let element">
        <tr *ngIf="element.CumulativeHours != null">{{ element.CumulativeHours }}</tr>
        <mat-icon *ngIf="element.CumulativeHours == null" class="worksiteNull-icon" matTooltip="Il dato verrà mostrato non appena sarà disponibile...">history</mat-icon>
      </td>
    </ng-container>

    <!-- CumulativeHours SyncDate -->
    <ng-container matColumnDef="SyncDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data sincronizzazione</th>
      <td mat-cell *matCellDef="let element">
        <tr *ngIf="element.SyncDate != null">{{ element.SyncDate | date: "dd/MM/yyyy HH:mm" }}</tr>
        <tr *ngIf="element.SyncDate == null">-</tr>
      </td>
    </ng-container>

    <!-- Cantiere_associato Column -->
    <ng-container matColumnDef="cantiere">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantiere</th>
      <td mat-cell *matCellDef="let element">
        <tr *ngIf="element.Cantiere != null">{{ element.Cantiere }}</tr>
        <mat-icon *ngIf="element.Cantiere == null" class="worksiteNull-icon">close</mat-icon>
      </td>
    </ng-container>

    <!-- IsValid Column -->
    <ng-container matColumnDef="isvalid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Censito</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
        <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
      </td>
    </ng-container>

    <!-- Dinakit Column -->
    <ng-container matColumnDef="dynakit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dyn@kit</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.Dynakit" class="ok-icon">done</mat-icon>
        <mat-icon *ngIf="!element.Dynakit" class="error-icon">close</mat-icon>
      </td>
    </ng-container>

    <!-- IsAssociate Column -->
    <ng-container matColumnDef="isassociate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Associato</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.IsAssociate" class="ok-icon">done</mat-icon>
        <mat-icon *ngIf="!element.IsAssociate" class="error-icon">close</mat-icon>
      </td>
    </ng-container>

    <!-- Banco Column -->
    <ng-container matColumnDef="banco">
      <th mat-header-cell *matHeaderCellDef>Banco</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Banco }}
      </td>
    </ng-container>

    <!-- NomeDynaKit Column -->
    <ng-container matColumnDef="nomeDynakit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; padding-right: 20px; width: 50px;">Nome Dyn@Kit</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
          {{ element.NomeDynakit }}
        </div>
      </td>
    </ng-container>

    <!-- Disponibile Column -->
    <ng-container matColumnDef="disponibile" stickyEnd>
      <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">Disponibile</th>
      <ng-container *matCellDef="let element">
        <td mat-cell >
          <mat-slide-toggle [disabled]="!element.IsValid || element.IdCantiere != null" [checked]="element.IsActive"
            (change)="changeToggle(element)">
          </mat-slide-toggle>
        </td>
      </ng-container>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions"  >
      <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">Parametri</th>
      <ng-container *matCellDef="let element">
        <td mat-cell>
          <button
            *ngIf="element.IsValid"
            (click)="Edit(element)"
            mat-stroked-button
            matTooltip="Parametri"
            [matTooltipShowDelay]="1000"
            color="primary"
            class="check-margin"
          >
            <mat-icon>subject</mat-icon>
          </button>
          <button (click)="Test(element)"
                mat-stroked-button color="primary"
                class="check-margin"
                [disabled]="!btnEnabled"
                matTooltip="Test Dyn@link"
                [matTooltipShowDelay]="1000">
                <mat-icon>network_check</mat-icon>
              </button>

              <button (click)="TestFuel(element)"
                mat-stroked-button color="primary"
                class="check-margin"
                [disabled]="!btnEnabled"
                matTooltip="Test Fuel Dyn@link"
                [matTooltipShowDelay]="1000">
                <mat-icon>local_gas_station</mat-icon>
              </button>

              <button (click)="TestEngine(element)"
              mat-stroked-button color="primary"
              class="check-margin"
              style="margin-bottom: 5px;"
              [disabled]="!btnEnabled"
              matTooltip="Test Engine Dyn@link"
              [matTooltipShowDelay]="1000">
              <mat-icon>settings</mat-icon>
            </button>

        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<mat-paginator
  *ngIf="!isEdit"
  [pageSizeOptions]="[10]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
  style="min-width: 615px !important;"
></mat-paginator>


<!-- CONTEINER MEZZO -->

<div class="container" style="display: flex; justify-content: space-around;"  [ngClass]="{'row-layout': riferimento.offsetWidth >= 950, 'column-layout': riferimento.offsetWidth < 950}">

  <div style="width: 30%; margin-right: 20px;" [ngClass]="{'box_fullSize': riferimento.offsetWidth < 950 }">
    <mat-card class="box_mezzo" *ngIf="isEdit">

      <mat-card-title class="title-box"><mat-icon>image_search</mat-icon><span>Mezzo</span></mat-card-title>
  
      <mat-divider></mat-divider>
  
      <div #riferimento2>
        <mat-card-content>
  
          <div [style.width.px]="riferimento2.offsetWidth">
            <img id="machine-img" mat-card-image src={{Image}}>
          </div>
  
          <mat-divider id="divider-label-anagrafica"></mat-divider>
  

          <div class="container" style="display: flex; flex-direction: row; justify-content: space-between;">

            <mat-form-field appearance="fill" class="input-label-anagrafica-top">
              <mat-label>Modello</mat-label>
              <input matInput value={{Model}} readonly>
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="input-label-anagrafica-top" style="margin-left: 10px;">
              <mat-label>Numero Seriale</mat-label>
              <input matInput value={{SerialNumber}} readonly>
            </mat-form-field>
          
          </div>



          <form [formGroup]="machineNameForm">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Nome visualizzato</mat-label>
              <input formControlName="machineName" matInput>
            </mat-form-field>
          </form>
        </mat-card-content>
      </div>
  
    </mat-card>
  </div>



  <div style="width: 60%;" [ngClass]="{'box_fullSize': riferimento.offsetWidth < 950 }">

    <mat-card class="box_posizione" *ngIf="isEdit">
      <mat-card-title class="title-box">
        <mat-icon>image_search</mat-icon><span>Posizione</span>
      </mat-card-title>
  
      <mat-divider></mat-divider>
  
      <div #riferimento2>
        <mat-card-content>
          <!-- Condizione per mostrare la mappa o il messaggio -->
          <div *ngIf="markers && markers.length > 0; else noMarkers">
            <google-map class="google-map" height="220px" width="100%" [options]="options" style="margin-top: 25px;"> 
              <map-marker *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label"
                [title]="marker.title" [options]="marker.options">
              </map-marker>
            </google-map>
            <p class="no-markers-message" style="margin: 10px;">{{ timestamp }}</p>
          </div>
          <!-- Template per il messaggio quando non ci sono marker -->
          <ng-template #noMarkers>
            <div class="no-markers-container">
              <h3 class="no-markers-title">Mappa non disponibile.</h3>
              <p class="no-markers-message">Non è mai stata registrata una posizione del veicolo.</p>
            </div>
          </ng-template>
        </mat-card-content>
      </div>
  
    </mat-card>


    <mat-card *ngIf="isEdit" class="box_parametri">

      <mat-card-title class="title-box"><mat-icon>list</mat-icon><span>Parametri {{Model}}</span></mat-card-title>
  
      <mat-divider></mat-divider>
  
      <mat-card-content>
        <form [formGroup]="bancoForm">
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-top" style="margin-right: 18px;">
            <mat-label>Dyn@link</mat-label>
            <input matInput value={{Dynalink}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-top" *ngIf="ShowFrequenzaHiLo">
            <mat-label>FrequenzaHiLo</mat-label>
            <input matInput value={{FrequenzaHiLo}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" style="margin-right: 18px;" *ngIf="ShowAmpiezzaHiLo">
            <mat-label>AmpiezzaHiLo</mat-label>
            <input matInput value={{AmpiezzaHiLo}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" *ngIf="ShowForzaCentrifugaHiLo">
            <mat-label>ForzaCentrifugaHiLo</mat-label>
            <input matInput value={{ForzaCentrifugaHiLo}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-top" *ngIf="ShowVelocitaStesa">
            <mat-label>VelocitaStesa</mat-label>
            <input matInput value={{VelocitaStesa}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" style="margin-right: 18px;" *ngIf="ShowSpessoreStesaMax">
            <mat-label>SpessoreStesaMax</mat-label>
            <input matInput value={{SpessoreStesaMax}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" *ngIf="ShowLarghezzaStesa">
            <mat-label>LarghezzaStesa</mat-label>
            <input matInput value={{LarghezzaStesa}} readonly>
          </mat-form-field>
  
      </form>
      </mat-card-content>
  
    </mat-card>



    <mat-card *ngIf="isEdit && ShowBanco" class="box_parametri">

      <mat-card-title class="title-box"><mat-icon>list</mat-icon><span>Parametri banco</span></mat-card-title>
  
      <mat-divider></mat-divider>
  
      <mat-card-content>
        <form [formGroup]="bancoForm">
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-top" style="margin-right: 18px; ">
            <mat-label>Banco</mat-label>
            <mat-select formControlName="banco" required (selectionChange)="onChangeBanco()">
              <mat-option *ngFor="let ws of bancoSource" [value]="ws.Id">
                {{ ws.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-top" *ngIf="ShowVibrazioneBanco">
            <mat-label>Frequenza vibrazione</mat-label>
            <input matInput value={{FrequenzaHiLoBanco}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" style="margin-right: 18px;" *ngIf="ShowTamperingBanco">
            <mat-label>Frequenza tampering</mat-label>
            <input matInput value={{TamperingHiLo}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" *ngIf="ShowAmpiezzaHiLoBanco">
            <mat-label>AmpiezzaHiLo</mat-label>
            <input matInput value={{AmpiezzaHiLoBanco}} readonly>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="input-label-anagrafica-end" *ngIf="ShowForzaCentrifugaHiLoBanco">
            <mat-label>ForzaCentrifugaHiLo</mat-label>
            <input matInput value={{ForzaCentrifugaHiLoBanco}} readonly>
          </mat-form-field>
  
      </form>
      </mat-card-content>
  
    </mat-card>
  </div>

</div>




<!-- CONTEINER BOTTONI -->

<div #riferimento1 class="conteiner-button" *ngIf="isEdit">

  <mat-divider [style.width.px]="riferimento1.offsetWidth"></mat-divider>

  <button
        mat-stroked-button
        color="primary"
        (click)="Salva()">

        <mat-icon>check</mat-icon>

  </button>

  <button mat-stroked-button color="warn" class="button-check" (click)="Annulla()"><mat-icon>close</mat-icon></button>

</div>

export class ConfigurationModel {

  constructor(
    public type: string,
    public user: string,
    public password: string
  ) {  }

}


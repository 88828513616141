<div class="loginContainer">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box" style="max-width: 400px;">
      <mat-card-header>
        <mat-card-title>
          <img mat-card-image style="padding-top: 1rem;" src="/assets/img/Dynapac_Logo.png" alt="">
          <p style="display: flex; justify-content:center; align-items:center;">Dyn@Production</p>
        </mat-card-title>

      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="loginForm">
          <mat-form-field class="edit-full-width">
            <input matInput placeholder="Email" formControlName="email">
          </mat-form-field>

          <mat-form-field class="edit-full-width">
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password">
            <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
              'visibility'}}</mat-icon>
          </mat-form-field>


          <label class="edit-container" (click)="forgotPassword()">
            <span style="cursor: pointer;">
              <u>{{'forgotPassword' | translate}}</u>
            </span>
          </label>

        </form>
      </mat-card-content>
      <mat-divider inset></mat-divider>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button (click)="Login()" mat-stroked-button color="primary" [disabled]="!loginForm.valid" class="btn-block"
            style="width: 100%; max-width: 200px;">{{'login' | translate}}
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
    <br>
  </div>

  <div style="text-align: center; font-size: 16px; margin-top: 25px;">
    {{'newUser' | translate}}
    <span [routerLink]="['/register']" routerLinkActive="router-link-active" style="cursor: pointer;">
      <u><b>{{'register' | translate}}</b></u>
    </span>
  </div>

  <br>
  <div style="display: flex; justify-content:center; align-items:center;">Powered By Ulisse</div>

  <div class="container-banner-samoter" *ngIf="showBannerSamoter">
    <a [href]="linkBannerSamoter" target="_blank">
      <img src="/assets/img/samoter.png">
    </a>
  </div>

  <div class="overlay" *ngIf="popup">
    <div class="externalDiv">
      <div class="popup">
        <form [formGroup]="userResetForm" class="container container-popup">
          <a class="close" (click)="popup=false">&times;</a><br>
          <label style="font-size: 20px;">Reset Password</label><br>
          <mat-form-field class="d-block fit">
            <label class="uppercase">Email</label>
            <input matInput type="text" formControlName="email" required />
            <mat-error *ngIf="!userResetForm.valid">{{'validEmail' | translate}}</mat-error>
          </mat-form-field><br>
          <button mat-raised-button color="primary" style="margin-top: 10px;" (click)="startReset()"
            [disabled]="!userResetForm.valid">
            {{'send' | translate}}
          </button>
        </form>
      </div>
    </div>
  </div>

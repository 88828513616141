export class JobModel {

  constructor(
    public id: number,
    // public code: string,
    public description: string,
    //public isactive: boolean,
    public datestart: Date,
    public dateend: Date,
    public idworksite: string,
    public idmachine: number,
    public idoperator: number,
    public idtypework: number,
    public vibrazione: boolean,
    public frequenza: string,
    public ampiezza: string,
    public forzacentrifuga : string,
    public velocitastesa?: number,
    public spessorestesa?: number,
    public larghezzastesa?: number,
    public note?: string,
    public azione?: string,
    public tempostimato?: number,
    public materiale?: string,
    public numeropassate?: number,
    public banco?: string,
    // public vibrazionebanco?: boolean,
    // public frequenzabanco?: string,
    // public ampiezzabanco?: string,
    // public forzacentrifugabanco?: string,
    public tamperingbanco?: boolean,
    public frequenzatamperingbanco?: string,
  ) {  }

}


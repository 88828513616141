export class ChangePasswordModel {

  constructor(
    public oldpassword: string,
    public password: string,
    public confirmpassword: string
  ) {  }

}


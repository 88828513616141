<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<mat-card>
  <mat-card-title>Lavori</mat-card-title>
  <mat-card-content>
    <div class="edit-container" *ngIf="!isEdit">
      <mat-form-field appearance="fill">
        <mat-label>Filtra Cantiere</mat-label>
        <mat-select [(ngModel)]="worksiteFilterId" (selectionChange)="GetJob()">
          <mat-option *ngFor="let ws of workSiteFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Filtra Mezzo</mat-label>
        <mat-select [(ngModel)]="machineFilterId" (selectionChange)="GetJob()">
          <mat-option *ngFor="let ws of machineFilterSource" [value]="ws.Id">
            {{ ws.MachineDescription }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill">
        <mat-label>Filtra Attivi</mat-label>
        <mat-select [(ngModel)]="activeFilterId" (selectionChange)="GetJob()">
          <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field appearance="fill">
        <mat-label>Filtra Conclusi</mat-label>
        <mat-select
          [(ngModel)]="completedFilterId"
          (selectionChange)="GetJob()"
        >
          <mat-option *ngFor="let ws of completedFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill">
        <mat-label>Filtra Lavoro</mat-label>
        <mat-select [(ngModel)]="typeworkFilterId" (selectionChange)="GetJob()">
          <mat-option *ngFor="let ws of typeworkFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>

    <div fxFlex></div>
    <div>
      <button
        mat-stroked-button
        color="primary"
        (click)="Add()"
        *ngIf="!isEdit"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<table
  *ngIf="!isEdit"
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="worksite"
  matSortDirection="desc"
  matSortDisableClear
>
  class="mat-elevation-z8">
  <!-- Id Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
  </ng-container>

  <!-- IdWorkSite Column -->
  <ng-container matColumnDef="idworksite">
    <th mat-header-cell *matHeaderCellDef>Id Cantiere</th>
    <td mat-cell *matCellDef="let element">{{ element.IdWorkSite }}</td>
  </ng-container>

  <!-- IdMachine Column -->
  <ng-container matColumnDef="idmachine">
    <th mat-header-cell *matHeaderCellDef>Id Macchina</th>
    <td mat-cell *matCellDef="let element">{{ element.IdMachine }}</td>
  </ng-container>

  <!-- IdOperator Column -->
  <ng-container matColumnDef="idoperator">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Operatore</th>
    <td mat-cell *matCellDef="let element">{{ element.IdOperator }}</td>
  </ng-container>

  <!-- DateStart Column -->
  <!-- <ng-container matColumnDef="datestart">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inizio</th>
    <td mat-cell *matCellDef="let element">
      {{ element.DateStart | date: "dd/MM/yyyy HH:mm" }}
    </td>
  </ng-container> -->

  <!-- DateEnd Column -->
  <!-- <ng-container matColumnDef="dateend">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fine</th>
    <td mat-cell *matCellDef="let element">
      {{ element.DateEnd | date: "dd/MM/yyyy HH:mm" }}
    </td>
  </ng-container> -->

  <!-- Code Column -->
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Codice</th>
    <td mat-cell *matCellDef="let element">{{ element.Code }}</td>
  </ng-container>

  <!-- Descrizione Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrizione</th>
    <td mat-cell *matCellDef="let element">
      <span class="truncate-text">{{ element.Description }}</span>
    </td>
  </ng-container>

  <!-- WorkSite Column -->
  <ng-container matColumnDef="worksite">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantiere</th>
    <td mat-cell *matCellDef="let element">
      {{ element.WorkSiteDescription }}
    </td>
  </ng-container>

  <!-- Machine Column -->
  <ng-container matColumnDef="machine">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mezzo</th>
    <td mat-cell *matCellDef="let element">{{ element.MachineDescription }}</td>
  </ng-container>

  <!-- operator Column -->
  <ng-container matColumnDef="operator">
    <th mat-header-cell *matHeaderCellDef >Operatore</th>
    <td mat-cell *matCellDef="let element">{{ element.OperatorName }}</td>
  </ng-container>

  <!-- Stato Column -->
  <ng-container matColumnDef="stato">
    <th mat-header-cell *matHeaderCellDef >Stato</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Stato }}
    </td>
  </ng-container>

  <!-- Tipo Lavoro -->
  <ng-container matColumnDef="tipolavoro">
    <th mat-header-cell *matHeaderCellDef>Tipo Lavoro</th>
    <td mat-cell *matCellDef="let element">
      {{ element.TipoLavoro }}
    </td>
  </ng-container>

  <!-- azione -->
  <ng-container matColumnDef="azione">
    <th mat-header-cell *matHeaderCellDef>Attività</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Azione }}
    </td>
  </ng-container>

  <!-- materiale -->
  <ng-container matColumnDef="materiale">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Materiale</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Materiale }}
    </td>
  </ng-container>

  <!-- numeropassate -->
  <ng-container matColumnDef="numeropassate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>N. Passate</th>
    <td mat-cell *matCellDef="let element">
      {{ element.NumeroPassate }}
    </td>
  </ng-container>

  <!-- IsActive Column -->
  <!-- <ng-container matColumnDef="isactive">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Attivo</th>
    <td mat-cell *matCellDef="let element">
      {{ element.IsActive ? "Si" : "No" }}
    </td>
  </ng-container> -->

  <!-- Vibrazione Column -->
  <ng-container matColumnDef="vibrazione">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vibrazione</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Vibrazione ? "Si" : "No" }}
    </td>
  </ng-container>

  <!-- Frequenza Column -->
  <ng-container matColumnDef="frequenza">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequenza</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Frequenza }}
    </td>
  </ng-container>

  <!-- Ampiezza Column -->
  <ng-container matColumnDef="ampiezza">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ampiezza</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Ampiezza }}
    </td>
  </ng-container>

  <!-- ForzaCentrifuga Column -->
  <ng-container matColumnDef="forzacentrifuga">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Forza Centrifuga</th>
    <td mat-cell *matCellDef="let element">
      {{ element.ForzaCentrifuga }}
    </td>
  </ng-container>

  <!-- VelocitaStesa Column -->
  <ng-container matColumnDef="velocitastesa">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>VelocitaStesa</th>
    <td mat-cell *matCellDef="let element">
      {{ element.VelocitaStesa }}
    </td>
  </ng-container>

  <!-- SpessoreStesa Column -->
  <ng-container matColumnDef="spessorestesa">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>SpessoreStesa</th>
    <td mat-cell *matCellDef="let element">
      {{ element.SpessoreStesa }}
    </td>
  </ng-container>

  <!-- larghezzaStesa Column -->
  <ng-container matColumnDef="larghezzastesa">
    <th mat-header-cell *matHeaderCellDef >LarghezzaStesa</th>
    <td mat-cell *matCellDef="let element">
      {{ element.LarghezzaStesa }}
    </td>
  </ng-container>

  <!-- TempoStimato Column -->
  <ng-container matColumnDef="TempoStimato">
    <th mat-header-cell *matHeaderCellDef >TempoStimato</th>
    <td mat-cell *matCellDef="let element">
      {{ element.TempoStimatoOre }}
    </td>
  </ng-container>

  <!-- Note Column -->
  <ng-container matColumnDef="note">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Note }}
    </td>
  </ng-container>

  <!-- IsCompleted -->
  <ng-container matColumnDef="iscompleted">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Concluso</th>
    <td mat-cell *matCellDef="let element">
      {{ element.IsCompleted }}
    </td>
  </ng-container>

  <!-- IsStarted -->
  <ng-container matColumnDef="isstarted">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Avviato</th>
    <td mat-cell *matCellDef="let element">
      {{ element.IsStarted }}
    </td>
  </ng-container>

  <!-- Tampering Banco Column -->
  <ng-container matColumnDef="tamperingbanco">
    <th mat-header-cell *matHeaderCellDef>Tampering Banco</th>
    <td mat-cell *matCellDef="let element">
      {{ element.TamperingBanco }}
    </td>
  </ng-container>

  <!-- Tampering Frequenze Banco Column -->
  <ng-container matColumnDef="tamperingfrequenzabanco">
    <th mat-header-cell *matHeaderCellDef>Frequanza tampering banco</th>
    <td mat-cell *matCellDef="let element">
      {{ element.FrequenzaTamperingBanco }}
    </td>
  </ng-container>

  <!-- Actions -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Azioni</th>
    <ng-container *matCellDef="let element">
      <td mat-cell>
        <button
          (click)="Edit(element)"
          mat-stroked-button
          color="primary"
          class="margin-action"
          *ngIf="!element.IsStarted"
          matTooltip="Modifica"
          [matTooltipShowDelay]="1000"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          (click)="Info(element)"
          mat-stroked-button
          *ngIf="element.IsStarted"
          matTooltip="Storico1"
          [matTooltipShowDelay]="1000"
          color="primary"
          class="margin-action"
        >
          <mat-icon>info</mat-icon>
        </button>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  *ngIf="!isEdit"
  [pageSizeOptions]="[10]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
></mat-paginator>

<mat-card class="edit-box" *ngIf="isEdit">
  <mat-card-header>
    <mat-card-title>Lavoro</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="jobForm">

      <!-- <div class="edit-container">

       <mat-form-field appearance="fill">
          <mat-label>Data Inizio</mat-label>
          <input matInput type="datetime-local" formControlName="datestart" required/>
        </mat-form-field> -->

        <!-- <mat-form-field appearance="fill">
          <mat-label>Data Fine</mat-label>
          <input matInput type="datetime-local" formControlName="dateend" required />
        </mat-form-field>


      </div> -->

      <div class="edit-container">
        <mat-form-field appearance="fill">
          <mat-label>Cantiere</mat-label>
          <mat-select formControlName="idworksite" required>
            <mat-option *ngFor="let ws of workSiteSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Mezzo</mat-label>
          <mat-select
            formControlName="idmachine"
            required
            (selectionChange)="onChangeMachine()"
          >
            <mat-option *ngFor="let m of machineSource" [value]="m.Id">
              {{ m.MachineDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Tempo Stimato</mat-label>
          <input matInput formControlName="tempostimato" type="number" required/>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Operatore</mat-label>
          <mat-select formControlName="idoperator" required>
            <mat-option *ngFor="let m of operatorSource" [value]="m.Id">
              {{ m.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Tipo Attivita</mat-label>
          <mat-select formControlName="idtypework" required>
            <mat-option *ngFor="let m of typeworkSource" [value]="m.Id">
              {{ m.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field appearance="fill">
          <mat-label>Codice di lavoro</mat-label>
          <input matInput formControlName="code" required/>
        </mat-form-field>

        <!-- <mat-checkbox class="margin" formControlName="isactive"
          >Attivo</mat-checkbox
        > -->


      </div>

      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Attività</mat-label>
          <mat-select
            formControlName="azione" required
          >
            <mat-option *ngFor="let m of azioniSource" [value]="m.Id">
              {{ m.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="edit-half-width" appearance="fill">
          <mat-label>Descrizione</mat-label>
          <input
            matInput
            maxlength="500"
            placeholder="Descrizione"
            formControlName="description"
          />
        </mat-form-field>



      </div>

      <!-- <div class="edit-container"> -->


        <!-- <mat-form-field appearance="fill">
          <mat-label>Materiale</mat-label>
          <mat-select
            formControlName="materiale"
          >
            <mat-option *ngFor="let m of materialiSource" [value]="m.Id">
              {{ m.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

      <!-- </div> -->

      <div class="">

      <div class="edit-container">

          <mat-checkbox class="check-margin" formControlName="vibrazione" *ngIf="showVibrazione"
            >Vibrazione</mat-checkbox
          >
          <mat-form-field appearance="fill" *ngIf="showVibrazione">
            <mat-label>Frequenza Vibrazione</mat-label>
            <mat-select formControlName="frequenza">
              <mat-option *ngFor="let m of frequenzaSource" [value]="m">
                {{ m }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="showAmpiezza">
            <mat-label>Ampiezza</mat-label>
            <mat-select formControlName="ampiezza">
              <mat-option *ngFor="let m of ampiezzaSource" [value]="m">
                {{ m }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="showForzaCentrifuga">
            <mat-label>Forza Centrifuga</mat-label>
            <mat-select formControlName="forzacentrifuga">
              <mat-option *ngFor="let m of centrifugheSource" [value]="m">
                {{ m }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="showNumeroPassate">
            <mat-label>Numero Passate</mat-label>
            <input type="number" matInput formControlName="numeropassate" />
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="showVelocitaStesa">
            <mat-label
              >Vel. Stesa min ({{ velocitaStesaMin }}) max ({{
                velocitaStesaMax
              }})</mat-label
            >
            <input matInput formControlName="velocitastesa" type="number" />
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="showSpessoreStesa">
            <mat-label>Spes. Stesa max ({{ spessoreStesaMax }})</mat-label>
            <input matInput formControlName="spessorestesa" type="number" />
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="showLarghezzaStesa">
            <mat-label>Lar. Stesa min ({{ larghezzaStesaMin }}) max ({{ larghezzaStesaMax }} </mat-label>
            <input matInput formControlName="larghezzastesa" type="number" />
          </mat-form-field>

          </div>

        <div class="edit-container" *ngIf="showBanco">

          <!-- <mat-form-field appearance="fill">
            <mat-label>Banco</mat-label>
            <input matInput formControlName="banco" readonly />
          </mat-form-field> -->

              <mat-checkbox class="check-margin" formControlName="tamperingbanco" *ngIf="showTampering"
                onchec
                >Tampering</mat-checkbox
              >

              <mat-form-field appearance="fill" *ngIf="showTampering">
                <mat-label>Frequenza Tampering</mat-label>
                <mat-select formControlName="frequenzatamperingbanco" >
                  <mat-option *ngFor="let m of frequenzaTamperingBancoSource" [value]="m">
                    {{ m }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

        </div>

        <p>
          <mat-form-field class="edit-full-width" appearance="fill">
            <mat-label>Note</mat-label>
            <textarea
              matInput
              rows="3"
              maxlength="500"
              placeholder="Note"
              formControlName="note"
            ></textarea>
          </mat-form-field>
        </p>
      </div>
    </form>
  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions>



    <div fxFlex></div>
    <button
      [disabled]="!jobForm.valid"
      mat-stroked-button
      color="primary"
      class="btn-block margin"
      (click)="Salva()"
    >
      <mat-icon>check</mat-icon>
    </button>
    <button
      mat-stroked-button
      color="warn"
      class="btn-block margin"
      (click)="Annulla()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>

<div class="container-title">
  <mat-icon class="icon-title">
    <span>donut_large</span>
  </mat-icon>
  <span class="title">Overview</span>
</div>


<!-- ELENCO DATI REPORT -->

<div class="container-card">

  <mat-card class="card">
    <mat-card-title>Aziende</mat-card-title>
    <mat-card-subtitle>Panoramica generale delle aziende</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content-card" *ngIf="AZIENDE != null">
        <div class="container-icon"><mat-icon class="material-icon">business_center</mat-icon> <span class="title-content-info">Totali: </span>{{AZIENDE.Totali}}</div>
        <div class="container-icon"><mat-icon class="material-icon">verified_user</mat-icon> <span class="title-content-info">Attive: </span>{{AZIENDE.Attive}}</div>
        <div class="container-icon"><mat-icon class="material-icon">report</mat-icon> <span class="title-content-info">Da attivare: </span>{{AZIENDE.Totali - AZIENDE.Attive}}</div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="query" *ngIf="AZIENDE == null" style="margin-top: auto;"></mat-progress-bar>
  </mat-card>

  <mat-card class="card">
    <mat-card-title>Macchine</mat-card-title>
    <mat-card-subtitle>Panoramica generale delle macchine</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content-card" *ngIf="MACCHINE != null">
        <div class="container-icon"><mat-icon class="material-icon">directions_car</mat-icon> <span class="title-content-info">Totali: </span>{{MACCHINE.Totali}}</div>
        <div class="container-icon"><mat-icon class="material-icon">mobile_friendly</mat-icon> <span class="title-content-info">Associate: </span>{{MACCHINE.Attive}}</div>
        <div class="container-icon"><mat-icon class="material-icon">device_unknown</mat-icon> <span class="title-content-info">Da associare: </span>{{MACCHINE.Totali - MACCHINE.Attive}}</div>
        <div class="container-icon"><mat-icon class="material-icon">tune</mat-icon> <span class="title-content-info">Da censire: </span>{{MACCHINE.NotCensite}}</div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="query" *ngIf="MACCHINE == null" style="margin-top: auto;"></mat-progress-bar>
  </mat-card>

  <mat-card class="card">
    <mat-card-title>Dyn@kit</mat-card-title>
    <mat-card-subtitle>Panoramica generale dei device</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content-card" *ngIf="KIT != null">
        <div class="container-icon"><mat-icon class="material-icon">directions_car</mat-icon> <span class="title-content-info">Totali: </span>{{KIT.Totali}}</div>
        <div class="container-icon"><mat-icon class="material-icon">mobile_friendly</mat-icon> <span class="title-content-info">Attivi: </span>{{KIT.Attivi}}</div>
        <div class="container-icon"><mat-icon class="material-icon">device_unknown</mat-icon> <span class="title-content-info">Da associare: </span>{{KIT.DaAssociare}}</div>
        <div class="container-icon"><mat-icon class="material-icon">add_to_home_screen</mat-icon> <span class="title-content-info">In scadenza: </span>{{KIT.InScadenza}}</div>
        <div class="container-icon"><mat-icon class="material-icon">phonelink_erase</mat-icon> <span class="title-content-info">Scaduti: </span>{{KIT.Scaduti}}</div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="query" *ngIf="KIT == null" style="margin-top: auto;"></mat-progress-bar>
  </mat-card>

</div>


<!-- ELENCO ANOMALIE -->

<div class="container-listAnomalie-Anteprima" *ngIf="ANOMALIE != null && ANOMALIE.length != 0">

  <div class="container-listAnomalie">

    <div class="container-title-anomalie">
      <mat-icon class="icon-title">
        <span>storage</span>
      </mat-icon>
      <span class="title">Elenco anomalie</span>
    </div>

    <mat-divider class="divider-anomalie"></mat-divider>

    <div class="container-anomalie">
      <div class="row-anomalia" *ngFor="let anomalia of ANOMALIE">

        <div class="container-info-ritardo">
          <mat-icon class="icon-anomalia">
            <span>insert_comment</span>
          </mat-icon>
          <mat-icon class="icon-anomalia" *ngIf="checkCreateDate(anomalia.created_at)" style="color: red;">
            <span>warning</span>
          </mat-icon>

        </div>


        <div class="container-info-anomalia">
          <div class="container-text-anomalia">
            <span>{{ anomalia.summary.split('-')[1].trim() }}</span>

            <div style="display: flex;">
              <mat-icon class="icon-info-anomalia"><span>local_offer</span></mat-icon> <span class="subtitle-anomalia">{{ anomalia.summary.split('-')[0].trim() }}</span>
            </div>
            <div style="display: flex;">
              <mat-icon class="icon-info-anomalia"><span>date_range</span></mat-icon> <span class="subtitle-anomalia">{{ anomalia.created_at | date:'dd/MM/yyyy HH:mm' }}</span>
            </div>

          </div>
          <div class="container-button-row-anomalia">
            <button mat-raised-button matTooltip="Visualizza i dettagli" (click)="showAnteprima(anomalia)">Visualizza</button>
          </div>
        </div>

      </div>
    </div>

  </div>

  <mat-divider [vertical]="true"></mat-divider>


  <!-- ANTEPRIMA -->

  <div class="container-anteprima">

    <div class="container-title-anomalie">
      <mat-icon class="icon-title">
        <span>library_books</span>
      </mat-icon>
      <span class="title">Dettaglio anomalia</span>
    </div>

    <mat-divider class="divider-anomalie"></mat-divider>

    <div class="container-message-empty-anteprima" *ngIf="selectedAnomalia == null">
      <span>Nessuna anomalia selezionata</span>
    </div>

    <div class="container-dettaglio-anomalia" *ngIf="selectedAnomalia != null">

      <span><b>{{ selectedAnomalia.summary.split('-')[1].trim() }}</b></span>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>memory</span>
        </mat-icon>
        <span>{{ selectedAnomalia.id }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>turned_in</span>
        </mat-icon>
        <span>{{ selectedAnomalia.category.name }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>local_offer</span>
        </mat-icon>
        <span>{{ selectedAnomalia.summary.split('-')[0].trim() }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>date_range</span>
        </mat-icon>
        <span>{{ selectedAnomalia.created_at | date:'dd/MM/yyyy HH:mm' }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>border_color</span>
        </mat-icon>
        <span>{{ selectedAnomalia.updated_at | date:'dd/MM/yyyy HH:mm' }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>notes</span>
        </mat-icon>
        <span>{{ selectedAnomalia.priority.label }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia" style="margin-bottom: 25px;">
        <mat-icon class="icon-info-anomalia">
          <span>linear_scale</span>
        </mat-icon>
        <span>{{ selectedAnomalia.status.label }}</span>
      </div>

      <div class="container-icon-info-label-dettaglio-anomalia">
        <mat-icon class="icon-info-anomalia">
          <span>assignment</span>
        </mat-icon>
        <span><b>Messaggio</b></span>
      </div>

      <span>{{ selectedAnomalia.description }}</span>

      <div class="container-icon-info-label-dettaglio-anomalia" style="margin-top: 25px;">
        <mat-icon class="icon-info-anomalia">
          <span>link</span>
        </mat-icon>
        <a [href]="linkBugTrucker" target="_blank"><b>Link BugTrucker</b></a>
      </div>


    </div>

  </div>

</div>


import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import * as fileSaver from 'file-saver';

interface selectWorkSite {
  Id: string;
  Description: string;
}

interface selectMachine {
  Id: string;
  MachineDescription: string;
}

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
})

export class ReportPageComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<any>,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  )
  { }

  chartTotal : any[] = [];
  chartPie : any[] = [];

  isLoadingResults = true;
  workSiteSource: selectWorkSite[] = [];
  dataSource = [];
  machineFilterSource: selectMachine[] = [];
  //machineFilterId = '0';

  displayedColumns: string[] = [
    'descrizione',
    'macchina',
    'tempostimato',
    'tempodynalink',
    'tempodynakit',
  ];

  cantiereForm = this.fb.group({
    datestart: [''],
    dateend: [''],
    description: [''],
    client: [''],
    boss: [''],
  });

  showChart: boolean = false;

  viewchart: [number, number] = [600, 400];
  viewpie: [number, number] = [500, 500];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Tempi';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Ore';

  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';

  colorScheme: Color =
  {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };

  reportForm = this.fb.group({
    //datestart: ['', Validators.required],
    //dateend: ['', Validators.required],
    idworksite: ['', Validators.required],
    idmachine: ['', Validators.required],
  });

  ngOnInit(): void {
    this.dateAdapter.setLocale('it-IT');

    var datestart = new Date;
    var dateend = new Date;

    this.reportForm.setValue({
      //datestart: datestart,
      //dateend: dateend,
      idworksite: '',
      idmachine: '0'
    });

    this.GetWorkSiteActive();
    this.GetMachineActive();
  }

  GetWorkSiteActive(): void {
    this.apiService.GetWorkSiteActive().subscribe(
      (data) => {
        this.workSiteSource = [];

        data.forEach((w: selectWorkSite) => {
          this.workSiteSource.push(w);
        });

        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetMachineActive(): void {
    this.apiService.GetMachineActive().subscribe(
      (data) => {
        this.machineFilterSource = [];

        var item: selectMachine = { Id: '0', MachineDescription: 'Tutti' };
        this.machineFilterSource.push(item);

        data.forEach((item: selectMachine) => {
          this.machineFilterSource.push(item);
        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  Report(): void {
    this.isLoadingResults = true;

    let idworksite = this.reportForm.get('idworksite')?.value;
    let datestart = this.reportForm.get('datestart')?.value;
    let dateend = this.reportForm.get('dateend')?.value;

     this.apiService.ReportCantiere(idworksite, datestart, dateend)
       .subscribe((response: any) => {
         let blob:any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     	  fileSaver.saveAs(blob, 'report.xlsx');
         this.isLoadingResults = false;
       }),
       (error: any) => {
         ////console.log('Error downloading the file');
         this.isLoadingResults = false;
       };
  }

  GetJobs(): void {
    this.isLoadingResults = true;

    this.dataSource = [];

    let idworksite = this.reportForm.get('idworksite')?.value;
    let idmachine = this.reportForm.get('idmachine')?.value;

    // this.apiService.GetJobs(idworksite).subscribe(
    //   (data) => {
    //     this.dataSource = data;

    //     this.isLoadingResults = false;
    //   },
    //   (error) => {
    //     ////console.log(error);
    //     this.isLoadingResults = false;
    //   }
    // );

    this.apiService.GetWorkSite(idworksite).subscribe(
      (data) => {

          //let start = data.DateStart;

          let date = new Date(data.DateStart);

          var day = ('0' + date.getDate()).slice(-2);
          var month = ('0' + (date.getMonth() + 1)).slice(-2);
          var year = date.getFullYear();
          let start = day + "/" + month + "/" + year;

          let dateend = new Date(data.DateEnd);
          let dayend = ('0' + dateend.getDate()).slice(-2);
          let monthend = ('0' + (dateend.getMonth() + 1)).slice(-2);
          let yearend = dateend.getFullYear();
          let end = dayend + "/" + monthend + "/" + yearend;

          this.cantiereForm.setValue({
            datestart: start,
            dateend: end,
            description: data.Description,
            client: data.Client,
            boss: data.BossName,
          });

        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);
    });

    this.apiService.GetChartTotal(idworksite, idmachine).subscribe(
      (data) => {

        this.showChart = true;

        this.chartTotal = [];

        for (const i of data.Chart)
        {
          this.chartTotal.push(i);
        }

        // reassign for refresh
        this.chartTotal = [...this.chartTotal];

        this.chartPie = [];

        for (const i of data.Pie)
        {
          this.chartPie.push(i);
        }

        // reassign for refresh
        this.chartPie = [...this.chartPie];

        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);

        this.isLoadingResults = false;
      }
    );


  }


  // format(date: string) {
  //   date = new Date(date);

  //   var day = ('0' + date.getDate()).slice(-2);
  //   var month = ('0' + (date.getMonth() + 1)).slice(-2);
  //   var year = date.getFullYear();

  //   return year + '-' + month + '-' + day;
  // }




}

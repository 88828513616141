import { StoricoReportComponent } from './components/Report-Components/StoricoReport/StoricoReport.component';
import { GeneraReportComponent } from './components/Report-Components/GeneraReport/GeneraReport.component';
import { DashboardMezzoComponent } from './components/Dashboard-Mezzo/Dashboard-Mezzo.component';
import { DashboardCantiereComponent } from './components/Dashboard-Cantiere/Dashboard-Cantiere.component';
import { FirstPageComponent } from './components/first-page/first-page.component';
import { RegistrationPageComponent } from './components/registration-page/registration-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { MachinePageComponent } from './components/machine-page/machine-page.component';
import { WorksitePageComponent } from './components/worksite-page/worksite-page.component';
import { OperatorPageComponent } from './components/operator-page/operator-page.component';
import { UsersAdminPageComponent } from './components/users-admin-page/users-admin-page.component';
import { UsersPageComponent } from './components/capocantiere-page/users-page.component';
import { ConfigurationPageComponent } from './components/configuration-page/configuration-page.component';
import { JobPageComponent } from './components/job-page/job-page.component';
import { ParameterPageComponent } from './components/parameter-page/parameter-page.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { DynakitPageComponent } from './components/dynakit-page/dynakit-page.component';
import { ReportPageComponent } from './components/report-page/report-page.component';
import { AssistenzaPageComponent } from './components/assistenza-page/assistenza-page.component';
import { AuthGuard } from "./shared/auth.guard";
import { ManagementPageComponent } from './components/management-page/management-page.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeAdminComponent } from './components/home-admin/home-admin.component';
import { HomeSupervisorComponent } from './components/home-supervisor/home-supervisor.component';
import { AnagraficaDynaKitComponent } from './components/AnagraficaDynaKit/AnagraficaDynaKit.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'login' },
  {path: 'login', component: LoginPageComponent },
  {path: 'register', component: RegistrationPageComponent },
  {path: 'resetpassword', component: ResetPasswordComponent },
  {path: 'resetpassword/:UserId', component: ResetPasswordComponent },
  {path: 'resetpassword/:UserId/:Token', component: ResetPasswordComponent },
  {path: 'firstpage', component: FirstPageComponent, canActivate: [AuthGuard] },
  {path: 'dashboard', component: DashboardPageComponent, canActivate: [AuthGuard] },
  {path: 'dashboardCantiere', component: DashboardCantiereComponent, canActivate: [AuthGuard] },
  {path: 'dashboardCantiere/:IdWorkSite', component: DashboardCantiereComponent, canActivate: [AuthGuard] },
  {path: 'dashboardMezzo', component: DashboardMezzoComponent, canActivate: [AuthGuard] },
  {path: 'users-admin', component: UsersAdminPageComponent , canActivate: [AuthGuard]},
  {path: 'users', component: UsersPageComponent , canActivate: [AuthGuard]},
  {path: 'configuration', component: ConfigurationPageComponent , canActivate: [AuthGuard]},
  {path: 'machine', component: MachinePageComponent , canActivate: [AuthGuard]},
  {path: 'worksite', component: WorksitePageComponent , canActivate: [AuthGuard]},
  {path: 'job', component: JobPageComponent , canActivate: [AuthGuard]},
  {path: 'parameter', component: ParameterPageComponent , canActivate: [AuthGuard]},
  {path: 'home-admin', component: HomeAdminComponent , canActivate: [AuthGuard]},
  {path: 'home-supervisor', component: HomeSupervisorComponent , canActivate: [AuthGuard]},
  {path: 'profile', component: ProfilePageComponent , canActivate: [AuthGuard]},
  {path: 'operator', component: OperatorPageComponent , canActivate: [AuthGuard]},
  {path: 'dynakit', component: DynakitPageComponent, canActivate: [AuthGuard] },
  {path: 'report', component: ReportPageComponent, canActivate: [AuthGuard] },
  {path: 'anagraficaDynaKit', component: AnagraficaDynaKitComponent, canActivate: [AuthGuard] },
  {path: 'GeneraReport', component: GeneraReportComponent, canActivate: [AuthGuard] },
  {path: 'StoricoReport', component: StoricoReportComponent, canActivate: [AuthGuard] },
  {path: 'help',  component: AssistenzaPageComponent, canActivate: [AuthGuard]},
  {path: 'management',  component: ManagementPageComponent, canActivate: [AuthGuard]}

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

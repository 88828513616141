<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>


<!-- FILTRI & NEW CAPOCANTIERE-->

<div #riferimento
style="min-width: 620px;">
<mat-card *ngIf="!isEdit && !isNew && !showActions">
  <mat-card-title>Capocantieri</mat-card-title>
  <mat-card-content>
    <div class="edit-container" *ngIf="!isEdit">
      <mat-form-field appearance="fill">
        <mat-label>Filtra Attivi</mat-label>
        <mat-select [(ngModel)]="activeFilterId" (selectionChange)="GetUsers()">
          <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex></div>
    <div>
      <button
        mat-stroked-button
        color="primary"
        (click)="Add()"
        *ngIf="!isEdit"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
</div>


<!-- TABELLA CAPOCANTIERI -->

<div class="table-container mat-elevation-z8"
  style="min-width: 620px;"
  *ngIf="!isEdit && !isNew && !showActions"
>
<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="email"
  matSortDirection="asc"
  matSortDisableClear
>

  <!-- Id Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element.UserId }}</td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Email</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Email }}
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Cognome</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Name }}
    </td>
  </ng-container>

  <!-- Confirm Email Column -->
  <ng-container matColumnDef="confirmemail">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Email Confermata</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.EmailConfirmed" class="ok-icon">done</mat-icon>
      <mat-icon *ngIf="!element.EmailConfirmed" class="error-icon">close</mat-icon>
    </td>
  </ng-container>

  <!-- Banned Column -->
  <ng-container matColumnDef="banned">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Bloccato</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.Banned" class="error-icon">lock</mat-icon>
      <mat-icon *ngIf="!element.Banned" class="ok-icon">lock_open</mat-icon>
    </td>
  </ng-container>

  <!-- Stato Column -->
  <ng-container matColumnDef="stato">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Stato</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Stato }}
    </td>
  </ng-container>

  <!-- Azioni -->
  <ng-container matColumnDef="action" stickyEnd>
    <th mat-header-cell *matHeaderCellDef style="padding-left: 10px;">Azioni</th>
    <ng-container *matCellDef="let element">
      <td mat-cell>
        <button
          [disabled]="element.EmailConfirmed"
          (click)="ResendMail(element)"
          matTooltip="Invia email conferma"
          mat-stroked-button
          color="primary"
          style="margin-right: 5px; margin-top: 8px; margin-bottom: 8px;"
        >
          <mat-icon>send</mat-icon>
        </button>
        <button
          *ngIf="!element.Banned && element.EmailConfirmed"
          (click)="Banned(element)"
          matTooltip="Blocca utente"
          mat-stroked-button
          color="primary"
          class="margin-action"
        >
          <mat-icon>lock</mat-icon>
        </button>
        <button
          *ngIf="element.Banned && element.EmailConfirmed"
          (click)="Activate(element)"
          mat-stroked-button
          matTooltip="Sblocca utente"
          color="primary"
          class="margin-action"
        >
          <mat-icon>lock_open</mat-icon>
        </button>

        <button
          (click)="Edit(element)"
          mat-stroked-button
          color="primary"
          class="margin-action"
          *ngIf="!element.IsStarted"
          matTooltip="Modifica"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          (click)="Delete(element)"
          mat-stroked-button
          matTooltip="Elimina"
          color="primary"
          class="margin-action"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <button
          *ngIf="element.EmailConfirmed"
          (click)="Info(element)"
          mat-stroked-button
          color="primary"
          class="margin-action"
          matTooltip="Informazioni"
          style="margin-bottom: 8px;"
        >
          <mat-icon>info</mat-icon>
        </button>

      </td>
    </ng-container>
  </ng-container>

  <!-- ban -->
  <!-- <ng-container matColumnDef="ban">
    <th mat-header-cell *matHeaderCellDef>Bloccare</th>
    <ng-container *matCellDef="let element">
      <td mat-cell>
        <button
          *ngIf="!element.Banned && element.EmailConfirmed"
          (click)="Banned(element)"
          mat-stroked-button
          color="primary"
          class="margin"
        >
          <mat-icon>lock</mat-icon>
        </button>
        <button
          *ngIf="element.Banned && element.EmailConfirmed"
          (click)="Activate(element)"
          mat-stroked-button
          color="primary"
          class="margin"
        >
          <mat-icon>lock_open</mat-icon>
        </button>
      </td>
    </ng-container>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
</div>

<mat-paginator
  *ngIf="!isEdit && !isNew && !showActions"
  [pageSizeOptions]="[10]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
  style="min-width: 620px;"
></mat-paginator>


<!-- BOX ANAGRAFICA -->

<mat-card *ngIf="isNew || isEdit || showActions" class="box_anagrafica" [ngClass]="{'box_fullSize':  riferimento.offsetWidth < 1100, 'box_anagInfo': showActions }">

  <mat-card-title class="title-box"><mat-icon>library_books</mat-icon><span>Anagrafica</span></mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <form [formGroup]="userForm">

      <mat-form-field appearance="fill" class="label-full" style="margin-top: 15px;" *ngIf="isNew || isEdit || showActions">
        <mat-label>Nome e Cognome</mat-label>
        <input [readonly]="showActions" matInput formControlName="name" maxlength="100"/>
      </mat-form-field>

      <mat-form-field appearance="fill" class="label-full" *ngIf="isNew || showActions">
        <mat-label>Email</mat-label>
        <input [readonly]="showActions" matInput type="email" formControlName="email" maxlength="100"/>
      </mat-form-field>

      <mat-form-field appearance="fill" class="label-half" style="margin-right: 10px;" *ngIf="isNew">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" maxlength="100" [type]="hide ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide = !hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="fill" class="label-half" *ngIf="isNew">
        <mat-label>Conferma Password</mat-label>
        <input matInput formControlName="confirmpassword" maxlength="100" [type]="hide1 ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide1 = !hide1">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <!-- INFO PASSWORD -->

      <div *ngIf="isNew"
        style="width: 170px; margin-top: -15px; margin-bottom: 8px; margin-left: -10px;"
        matTooltip="{{'registration_passwordmustcontain' | translate}}
                    {{'registration_passwordlength' | translate}}
                    {{'registration_passwordonelower' | translate}}
                    {{'registration_passwordonenumber' | translate}}
                    {{'registration_passwordonespecial' | translate}}
                    {{'registration_passwordoneupper' | translate}}"
        matTooltipPosition="below">
        <span>
          <mat-icon style="cursor: pointer;transform: scale(0.9) translateY(7px)">info</mat-icon>
          {{'registration_rulesforpasswordcrea' | translate}}
        </span>
      </div>

    </form>

    <mat-divider [inset]="true" *ngIf="isEdit || isNew"></mat-divider>

    <div style="text-align: right; margin-top: 10px;" *ngIf="isEdit || isNew">
      <button
        [disabled]="!userForm.valid"
        mat-stroked-button
        color="primary"
        class="btn"
        (click)="Salva()">
        <mat-icon>check</mat-icon>
      </button>
      <button
        style="margin-left: 8px;"
        mat-stroked-button
        color="warn"
        class="btn"
        (click)="Annulla()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

  </mat-card-content>
</mat-card>


<!-- BOX CANTIERI -->

<mat-card *ngIf="showActions" class="box_cantieri" [ngClass]="{'box_fullSize1':  riferimento.offsetWidth < 1405 }">

  <mat-card-title class="title-box"><mat-icon>assignment</mat-icon><span>Cantieri assegnati</span></mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <!-- ALERT NESSUN CANTIERE TROVATO -->

    <mat-card id="alert-card" *ngIf="lengthUser == 0">
      <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
      <p id="alert-text">Nessun cantiere trovato</p>
    </mat-card>


    <!-- TABELLA -->

    <div class="table-container mat-elevation-z8" *ngIf="lengthUser != 0">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="datestart"
        matSortDirection="asc"
        matSortDisableClear
      >

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
        </ng-container>

        <!-- DateStart Column -->
        <ng-container matColumnDef="datestart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Inizio</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 8px;">
            {{ element.DateStart | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- DateEnd Column -->
        <ng-container matColumnDef="dateend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Fine</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 8px;">
            {{ element.DateEnd | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Descrizione</th>
          <td mat-cell *matCellDef="let element">
            <span class="truncate-text">{{ element.Description }}</span>
          </td>
        </ng-container>

        <!-- Client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Committente</th>
          <td mat-cell *matCellDef="let element">{{ element.Client }}</td>
        </ng-container>

        <!-- Indirizzo -->
        <ng-container matColumnDef="Indirizzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Indirizzo</th>
          <td mat-cell *matCellDef="let element">{{ element.Indirizzo }}</td>
        </ng-container>

        <!-- Boss id -->
        <ng-container matColumnDef="bossid">
          <th mat-header-cell *matHeaderCellDef>Capocantiere Id</th>
          <td mat-cell *matCellDef="let element">{{ element.BossId }}</td>
        </ng-container>

        <!-- Boss Name Column -->
        <ng-container matColumnDef="bossname">
          <th mat-header-cell *matHeaderCellDef class="columns-title">Capocantiere</th>
          <td mat-cell *matCellDef="let element">{{ element.BossName }}</td>
        </ng-container>

        <!-- IsActive Column -->
        <ng-container matColumnDef="isactive" stickyEnd>
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Attivo</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsActive" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.IsActive" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- IsActive Column -->
        <ng-container matColumnDef="commessa" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Commessa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.CodiceCommessa }}
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="report">
          <th mat-header-cell *matHeaderCellDef>Report</th>
          <ng-container *matCellDef="let element">
            <td mat-cell>

            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsInfo"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsInfo"></tr>
      </table>
    </div>

    <mat-paginator *ngIf="lengthUser != 0"
      [pageSizeOptions]="[10]"
      (page)="handlePageEventUser($event)"
      [length]="lengthUser"
      [pageIndex]="pageIndexUser"
      [pageSize]="pageSizeUser"
      showFirstLastButtons>
    </mat-paginator>

  </mat-card-content>
</mat-card>


<!-- BOTTONO EXIT -->

<div class="conteiner-button" *ngIf="showActions">

  <mat-divider [style.width.px]="riferimento.offsetWidth - 18" style="min-width: 566px;"></mat-divider>

  <button mat-stroked-button color="warn" class="button-check" (click)="Annulla()"><mat-icon>close</mat-icon></button>

</div>

<!-- TITOLO E FILTRO -->

<div #riferimento>
  <mat-card id="card-filtri">

    <mat-card-title id="title_dashboard">
      Dashboard mezzo
    </mat-card-title>

    <mat-card-content>

      <mat-form-field appearance="fill" id="filtro_mezzo">
        <mat-label>Filtra mezzo</mat-label>
        <mat-select [(ngModel)]="idMachine" (selectionChange)="ngOnInit()">
          <mat-option *ngFor="let machine of machinelist" [value]="machine.Id">
            {{ machine.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card-content>

  </mat-card>
</div>


<!-- BOX AVVISO INSERIMENTO CANTIERE -->

<mat-card id="alert-card-filterMezzo" *ngIf="idMachine == 0">
  <mat-icon style="font-size: 35px; margin-top: 7px; margin-left: 10px;">warning</mat-icon><p id="alert-text-start">Per generare la dashboard occorre selezionare un mezzo</p>
</mat-card>


<!-- BOX ANAGRAFICA -->

<mat-card class="box_anagrafica" *ngIf="idMachine != 0" [ngClass]="{'box_anagrafica_1':  riferimento.offsetWidth < 1400 }">

  <mat-card-title class="title-box"><mat-icon>library_books</mat-icon><span>Anagrafica</span></mat-card-title>

  <mat-divider></mat-divider>

  <div #riferimento2>
    <mat-card-content>

      <div [style.width.px]="riferimento2.offsetWidth">
        <img id="machine-img" mat-card-image src={{LinkImage}}>
      </div>


      <mat-divider id="divider-label-anagrafica"></mat-divider>

      <mat-form-field appearance="fill" class="input-label-anagrafica-top">
        <mat-label>Modello</mat-label>
        <input matInput value={{modello}} readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" class="input-label-anagrafica-top" style="margin-left: 17px;">
        <mat-label>Numero seriale</mat-label>
        <input matInput value={{serialNumber}} readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" class="input-label-anagrafica-end">
        <mat-label>Ore operative</mat-label>
        <input matInput value={{motore}} readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" class="input-label-anagrafica-end" style="margin-left: 17px;">
        <mat-label>Nome</mat-label>
        <input matInput value={{name}} readonly>
      </mat-form-field>

    </mat-card-content>
  </div>


</mat-card>


<!-- BOX PANORAMICA -->

<mat-card class="box_panoramica" *ngIf="idMachine != 0" [ngClass]="{'box_panoramica_1':  riferimento.offsetWidth < 1400, 'box_panoramica_2': ODL_conclu_cantiere == 0 }">
  <mat-card-title class="title-box"><mat-icon>data_usage</mat-icon><span>Panoramica</span></mat-card-title>

  <mat-divider></mat-divider>

  <div #riferimento1>
      <mat-card-content>

      <!-- LABEL DATI PIECHART -->

      <div style="float: left; width: 298px;" #piechart>
        <mat-form-field appearance="fill" class="input_label_panoramica">
          <mat-label>N° ODL totali</mat-label>
          <input matInput value={{ODL_tot_cantiere}} readonly>
        </mat-form-field>

        <mat-form-field appearance="fill" class="input_label_panoramica">
          <mat-label>N° ODL conclusi</mat-label>
          <input matInput value={{ODL_conclu_cantiere}} readonly>
        </mat-form-field>

        <!-- <mat-divider [inset]="true" style="width: 71.5%;"></mat-divider> -->

        <mat-card id="alert-card-piechart" *ngIf="ODL_tot_cantiere == 0">
          <mat-icon style="font-size: 43px; margin-top: 5px; margin-left: -25px;">warning</mat-icon><p id="alert-text-piechart">Dati insufficienti per la generazione del grafico</p>
        </mat-card>

        <!-- PIE CHART -->

        <div class="grafico">
          <ngx-charts-pie-chart *ngIf="ODL_tot_cantiere != 0"
          [view]="view"
          [results]="ODL_panoramica"
          [arcWidth]="arcWidth"
          [gradient]="gradient"
          [legendTitle]="title"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [labels]="showLabels"
          [doughnut]="isDoughnut"
          [scheme]="colorScheme"
          >
          </ngx-charts-pie-chart>

        </div>
      </div>

      <mat-divider id="vertical_divider"></mat-divider>


      <!-- BOX AVVISO INSERIMENTO MEZZO BARCHART -->

      <mat-card id="alert-card-barchart" *ngIf="ODL_conclu_cantiere == 0">
        <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
        <p id="alert-text-barchart">Dati insufficienti per la generazione del grafico</p>
      </mat-card>


      <!-- BARCHART -->

      <div id="grafico_MachineHours" >
        <ngx-charts-bar-horizontal *ngIf="ODL_conclu_cantiere != 0"
          [view]="[(riferimento1.offsetWidth - piechart.offsetWidth - 10), 302]"
          [scheme]="colorSchemeBarChart"
          [results]="MachineHours"
          [legendTitle]="title"
          [legendPosition]="legendPositionBarChart"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [showGridLines]="showGridLines">
        </ngx-charts-bar-horizontal>
      </div>

      <!-- BOTTONE MOUSEHOVER INFO DATI BARCHART -->

      <!-- <button *ngIf="ODL_conclu_cantiere != 0"
        id="button-info-BarChart"
        mat-icon-button
        matTooltip="Ore motore: Tempo di Utilizzo effettivo del motore
                    Ore dyn@kit: Tempo impiegato per gli ordini di lavoro
                    Ore previste: Tempo stimato per gli ordini di lavoro"
        [matTooltipPosition]="positionOptions"
      >
        <mat-icon>info</mat-icon>
      </button> -->

      <mat-divider [inset]="true" id="divider_after_grafico" *ngIf="ODL_conclu_cantiere != 0"></mat-divider>


      <!-- BARCHART A GRUPPI PER TIPO DI ATTIVITA' -->

      <div id="grafico_MachineHours_Activity">
        <ngx-charts-bar-horizontal-2d *ngIf="ODL_conclu_cantiere != 0"
          [view]="[(riferimento1.offsetWidth), 300]"
          [scheme]="colorSchemeBarChart"
          [legendTitle]="title"
          [results]="MachineHours_Activity"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [groupPadding]="groupPadding"
          [xAxisLabel]="xAxisLabel"
        >
        </ngx-charts-bar-horizontal-2d>
      </div>

    </mat-card-content>
  </div>


</mat-card>

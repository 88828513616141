import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  isAuthorized = false;
  email = '';
  role = '';
  name = '';
  token = '';

  showUsersAdmin = false;
  showUsersPowerUser = false;
  showMachinePowerUser = false;
  showAempConfiguration = false;
  showDashBoard = false;
  showWorkSite = false;
  showWorkSiteUser = false;
  showJob = false;
  showTestJob = false;
  showParameter = false;
  showProfile = false;
  showOperator = false;
  showDynakit = false;
  showReport = false;
  showHelp = false;
  showDashboardMenu = false;
  showAnagraficaMenu = false;
  showFirstPage = false;
  showAnagrafica = false;
  showDashBoardCantiere = false;
  showDashBoardMezzo = false;
  showCustomReport = false;
  showReportMenu = false;
  showAdminHome = false;
  showSupervisorHome = false;
  showAnagraficaDynakit = false;
}

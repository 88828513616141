import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from './globals';
import { AuthService } from './auth/auth.service';
import { MatSpinner } from '@angular/material/progress-spinner';
import { HostListener } from "@angular/core";
import { BooleanLiteral } from 'typescript';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent {
  title = 'Dyn@Production';

  screenHeight!: number;
  screenWidth!: number;
  isTablet!: Boolean;
  public isMenuOpen: boolean = true;
  globals: Globals;
  @ViewChild('overlay') overlay!: ElementRef;
  constructor(
    public authService: AuthService,
    globals: Globals,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.onResize();
    authService.getUser();
    this.globals = globals;
    translate.addLangs(['en', 'it']);
    translate.setDefaultLang('en');




    const browserLang = translate.getBrowserLang();
    switch (browserLang) {
      case 'it':
        translate.use('it');
        break;
      default:
        translate.use('en');
        break;
    }

    //this.dateAdapter.setLocale('it');
  }

  public onSidenavClick(): void {
    this.isMenuOpen = true;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {

    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1800) {
      this.isMenuOpen = false;
      this.isTablet = true;
    }
    else {
      this.isTablet = false;
    }
  }

  logOff(): void {
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('roleName');
    sessionStorage.removeItem('token');

    this.globals.isAuthorized = false;

    this.router.navigateByUrl('/login');
  }

  routeTo(routing: string) {
    this.router.navigateByUrl(routing);
    if (this.isTablet)
      this.isMenuOpen = false;
  }

  sidenavToggle() {

    this.isMenuOpen = !this.isMenuOpen;
  }

  showDashboardMenu() {
    this.globals.showDashboardMenu = !this.globals.showDashboardMenu
  }

  showAnagraficaMenu() {
    this.globals.showAnagraficaMenu = !this.globals.showAnagraficaMenu
  }

  showReportMenu() {
    this.globals.showReportMenu = !this.globals.showReportMenu
  }

  showSpinner() {
    ////console.log(this.overlay);
  }
}

import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  globals: Globals;

  constructor(globals: Globals, private router: Router) {
    this.globals = globals;
  }

  saveUser(user: string, role: string, token: string, name: string) {
    // memorizzo token
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('userName', user);
    sessionStorage.setItem('roleName', role);
    sessionStorage.setItem('name', name);

    this.globals.email = user;
    this.globals.name = name;
    this.globals.role = role;
    this.globals.isAuthorized = true;

    this.getMenu(role);

    // amministratore
    if (role == 'admin') {
      this.router.navigateByUrl('/home-admin');
    }

    // supervisore
    if (role == 'supervisor') {
      this.router.navigateByUrl('/home-supervisor');
    }

    // Cliente
    if (role == 'poweruser') {
      this.router.navigateByUrl('/firstpage');
    }

    // capo cantiere
    if (role == 'user') {
      this.router.navigateByUrl('/management');
    }
  }

  logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('roleName');
    sessionStorage.removeItem('name');

    this.globals.isAuthorized = false;
  }

  getUser(): any {
    var token = sessionStorage.getItem('token');
    var user = sessionStorage.getItem('userName');
    var role = sessionStorage.getItem('roleName');
    var name = sessionStorage.getItem('name');

    this.globals.email = user ? user : '';
    this.globals.name = name ? name : '';
    this.globals.role = role ? role : '';
    this.globals.token = token ? token : '';

    if (user == null) {
      this.globals.isAuthorized = false;
    } else {
      this.globals.isAuthorized = true;
    }

    this.getMenu(this.globals.role);

    return user;
  }

  public getToken(): string {
    var x = sessionStorage.getItem('token');
    if (x) {
      return x;
    } //do something
    else return '';
  }

  getRole(): any {
    return sessionStorage.getItem('roleName');
  }

  isLoggedIn(): boolean {
    return sessionStorage.getItem('userName') !== null;
  }

  getMenu(role: string) {

    this.globals.showUsersAdmin = false;
    this.globals.showParameter = false;
    this.globals.showUsersPowerUser = false;
    this.globals.showMachinePowerUser = false;
    this.globals.showAempConfiguration = false;
    this.globals.showWorkSite = false;
    this.globals.showProfile = false;
    this.globals.showDashBoard = false;
    this.globals.showWorkSiteUser = false;
    this.globals.showTestJob = false;
    this.globals.showOperator = false;
    this.globals.showDynakit = false;
    this.globals.showReport = false;
    this.globals.showHelp = false;
    this.globals.showFirstPage = false;
    this.globals.showAnagrafica = false;
    this.globals.showJob = false;
    this.globals.showDashBoardCantiere = false;
    this.globals.showDashBoardMezzo = false;
    this.globals.showAdminHome = false
    this.globals.showAnagraficaDynakit = false;


    // amministratore
    if (role == 'admin') {
      this.globals.showUsersAdmin = true;
      this.globals.showDynakit = true;
      this.globals.showParameter = true;
      this.globals.showAdminHome = true
    }

    // supervisore
    if (role == 'supervisor') {
      this.globals.showUsersAdmin = true;
      this.globals.showDynakit = true;
      this.globals.showSupervisorHome = true
    }

    // Cliente
    if (role == 'poweruser') {
      this.globals.showUsersPowerUser = true;
      this.globals.showMachinePowerUser = true;
      this.globals.showAempConfiguration = true;
      this.globals.showWorkSite = true;
      this.globals.showProfile = true;
      this.globals.showDashBoard = true;
      this.globals.showOperator = true;
      this.globals.showReport = true;
      this.globals.showHelp = true;
      this.globals.showFirstPage = true;
      this.globals.showAnagrafica = true;
      this.globals.showJob = true;
      this.globals.showDashBoardCantiere = true;
      this.globals.showDashBoardMezzo = true;
      this.globals.showCustomReport = true;
      this.globals.showAnagraficaDynakit = true;
    }

    // capo cantiere
    if (role == 'user') {
      this.globals.showWorkSiteUser = true;
      this.globals.showJob = true;
      this.globals.showTestJob = false;
      this.globals.showProfile = true;
      this.globals.showHelp = true;
      this.globals.showDashBoardCantiere = true;
      this.globals.showDashBoardMezzo = true;
      this.globals.showDashBoard = true;
      this.globals.showAnagraficaDynakit = true;
    }
  }
}

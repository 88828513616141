import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoginModel } from 'src/app/model/LoginModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private snackBar: MatSnackBar
  ) { }

  popup: boolean = false;
  showBannerSamoter: any
  linkBannerSamoter: any
  hide = true;
  errorLoginText: any;

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  userResetForm = this.fb.group({
    email: [
      '',
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ],
  });

  ngOnInit() {
    this.GetInfoBannerSamoter()
   }

   GetInfoBannerSamoter(){
    this.apiService.GetInfoBannerSamoter().subscribe(
      (data) => {
        this.showBannerSamoter = data.visibility
        this.linkBannerSamoter = data.link
      },
      (error) => {

      }
    );
   }

  Login(): void {
    const user = this.loginForm.get('email')?.value;
    const pass = this.loginForm.get('password')?.value;

    let dataLogin = new LoginModel(user, pass);

    this.apiService.userLogin(dataLogin).subscribe(
      (data) => {

        // ok login

        this.authService.saveUser(
          data.userName,
          data.roleName,
          data.access_token,
          data.name
        );

      },
      (error) => {
        this.authService.logout();

        ////console.log(error);
        this.openSnackBarError('Errore Login');
      }
    );
  }
  forgotPassword(): void {
    this.userResetForm.reset();
    this.popup = true;
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }
  startReset() {
    var email = this.userResetForm.get('email')?.value;
    ////console.log(email)
    if (email)
      this.apiService.userResetPassword(email).subscribe(
        (data) => {
          ////console.log(data);
          this.openSnackBarOk(data);
          this.popup = false;
        },
        (error) => {
          this.openSnackBarError(error.error.Message);
        }
      );
    //userResetPassword
  }
}

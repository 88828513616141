import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-Dashboard-Mezzo',
  templateUrl: './Dashboard-Mezzo.component.html',
  styleUrls: ['./Dashboard-Mezzo.component.scss']
})
export class DashboardMezzoComponent implements OnInit {

  idMachine = 0;
  modello = "";
  serialNumber = "";
  motore = "0";
  name = "";
  LinkImage = "";

  machinelist: any[] = [];

  dataListBarChart: any[] = []

  MachineHours: any[] = []

  MachineHours_Activity: any[] = []

  ODL_panoramica: any[] = [];
  ODL_tot_cantiere = 0
  ODL_conclu_cantiere = 0
  ODL_attesa = 0
  view!: [number, number];

  // options
  tooltipDisabled : boolean = false;
  labels : boolean = false;
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  arcWidth: number = 0.45;
  legendPosition: any = 'below';
  title: string = ""

  colorScheme: Color =
  {
    domain: ['#004162', '#ff8000', '#3CB371', '#61899D'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };



  // GRAFICI A BARRE ORIZZONTALI

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegendBarChart: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  legendPositionBarChart: any = "below";
  showGridLines: boolean = true

  colorSchemeBarChart: Color =
  {
    domain: ['#3CB371','#004162', '#EB861B'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };

  groupPadding: number = 35


  // BOTTONE MOUSEHOVER INFO

  positionOptions: TooltipPosition = "above"



  constructor(private apiService: ApiService) {
    Object.assign(this, { this:this.ODL_panoramica });
  }

  ngOnInit() {
    this.GetListMachine()

    if (this.idMachine != 0){
      this.GetSingleMachine()
      this.GetPieChart()
      this.GetBarChart()
      this.GetGroupBarChart()
    }
  }

  GetListMachine(): void{
    this.apiService.GetListMachineHours().subscribe(
      (data) => {
        this.machinelist = data
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetSingleMachine(){
    var found = false;
      var i = 0;
      while (!found && i < this.machinelist.length) {
        if (this.machinelist[i].Id == this.idMachine)
          found = true;
        else
          i++;
      }

      this.LinkImage = this.machinelist[i].LinkImage;
      this.modello = this.machinelist[i].Model;
      this.serialNumber = this.machinelist[i].SerialNumber;
      this.motore = this.machinelist[i].CumulativeHours;
      this.name = this.machinelist[i].Name;
  }

  GetPieChart(): void{
    this.apiService.GetDataPieChartMezzi(this.idMachine).subscribe(
      (data) => {
        this.ODL_panoramica = data.ODL

        this.ODL_tot_cantiere = data.ODL[0].value + data.ODL[1].value + data.ODL[2].value;
        this.ODL_conclu_cantiere = data.ODL[0].value

      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetBarChart(){
    this.apiService.GetDataBarChartMezzi(this.idMachine).subscribe(
      (data) => {
        this.MachineHours = data;
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetGroupBarChart(): void{
    this.apiService.GetDataBarChartGroupMezzi(this.serialNumber, this.idMachine).subscribe(
      (data) => {
        this.MachineHours_Activity = data.Dati
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

}

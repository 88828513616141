import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterUserModel } from 'src/app/model/FilterUserModel';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PasswordValidator } from 'src/app/services/PasswordValidator';
import { RegisterUserModel } from 'src/app/model/RegisterUserModel';
import { DialogConfermaComponent } from '../Dialog-conferma/Dialog-conferma.component';
import { MatDialog } from '@angular/material/dialog';
import { FilterWorkSiteModel } from 'src/app/model/FilterWorkSiteModel';
import { isThisTypeNode } from 'typescript';
import { tree } from 'd3';


interface selectItems {
  Id: string;
  Description: string;
}

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})

export class UsersPageComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

   pageEvent!: PageEvent;
   @ViewChild(MatSort) sort!: MatSort;

   pageIndexUser: number = 0;
   sortFieldUser: string = 'datestart'
   pageSizeUser: number = 10;
   sortOrderUser: string = 'asc';
   lengthUser = 0;

   isLoadingResults = true;
   pageIndex: number = 0;
   pageSize: number = 10;
   length = 0;
   displayedColumns: string[] = [
     'name',
     'email',
     'confirmemail',
     'banned',
     'action',
   ];

   displayedColumnsInfo: string[] = [
    'commessa',
    'datestart',
    'dateend',
    'description',
    'client',
    'Indirizzo',
    // 'bossname',
    'isactive',
    // 'actions'

  ];

   dataSource = [];

  activeFilterSource: selectItems[] = [];
  activeFilterId = '0';

  isEdit = false;
  isNew = false;
  showActions = false;

  hide = true;
  hide1 = true;
  sortField: string = 'email';
  sortOrder: string = 'asc';
  idUser = '';

   userForm = this.fb.group({
     email: ['', Validators.required] ,
     password: ['', Validators.compose([
                    Validators.required,
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#.$^+=!*()@%&]).{8,}$')
    ])],
     confirmpassword: [''],
     name: ['', Validators.required ],
   }, { validators: PasswordValidator.confirmed("password", "confirmpassword") });


  ngOnInit(): void {

    ////console.log(this.userForm)
    this.activeFilterSource = [];

    var item : selectItems = {Id : '0', Description : 'Tutti'};
    this.activeFilterSource.push(item);

    var itemAttivi : selectItems = {Id : '1', Description : 'Attivi'};
    this.activeFilterSource.push(itemAttivi);

    var itemNonAttivi : selectItems = {Id : '2', Description : 'Conferma Email'};
    this.activeFilterSource.push(itemNonAttivi);

    var itemNonAttivi : selectItems = {Id : '3', Description : 'Non attivi'};
    this.activeFilterSource.push(itemNonAttivi);

    this.GetUsers();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;
      this.sortField = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.GetUsers();
    });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.GetUsers();
  }

  handlePageEventUser(event: PageEvent) {
    this.lengthUser = event.length;
    this.pageSizeUser = event.pageSize;
    this.pageIndexUser = event.pageIndex;
    this.GetWorkSite();
  }

  GetUsers(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterUserModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.activeFilterId
    );

    this.apiService.GetUsers(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  ResendMail(element: any): void {

    this.apiService.ResendMailUser(element.UserId).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.openSnackBarOk('Mail inviata');
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
        this.openSnackBarError(error.error.message);
      }
    );

  }


  Banned(element: any): void {

    let risp = this.dialog.open(DialogConfermaComponent, {
      data: {
        title: 'Disabilita capocantiere',
        text: 'Vuoi davvero disabilitare questo capocantiere?',
        optionalDescription: "Disabilitando questo utente, esso non avrà più la possibilità di accedere al portale"
      }}
    );
    risp.afterClosed().subscribe(result => {
      if(result == 'true'){
        this.apiService.BanUser(element.UserId).subscribe(
          (data) => {
            this.isLoadingResults = false;
            this.openSnackBarOk('Utente Disattivato');
            this.GetUsers();
          },
          (error) => {
            ////console.log(error);
            this.openSnackBarError(error);
            this.isLoadingResults = false;
            this.openSnackBarError(error.error.message);
          }
        );
      }
    })
  }

  Activate(element: any): void {

    let risp = this.dialog.open(DialogConfermaComponent, {
      data: {
        title: 'Riattivazione capocantiere',
        text: 'Vuoi davvero riattivare questo capocantiere?'
      }}
    );
    risp.afterClosed().subscribe(result => {
      if(result == 'true'){
        this.apiService.ActivateUser(element.UserId).subscribe(
          (data) => {
            this.isLoadingResults = false;
            this.openSnackBarOk('Utente Attivato');
            this.GetUsers();
          },
          (error) => {
            ////console.log(error);
            this.openSnackBarError(error);
            this.isLoadingResults = false;
            this.openSnackBarError(error.error.message);
          }
        );
      }
    })
  }

  Delete(element: any): void {

    let risp = this.dialog.open(DialogConfermaComponent, {
      data: {
        title: 'Elimina capocantiere',
        text: 'Vuoi davvero eliminare questo capocantiere?'
      }}
    );
    risp.afterClosed().subscribe(result => {
      if(result == 'true'){
        this.apiService.DeleteUser(element.UserId).subscribe(
          (data) => {
            this.isLoadingResults = false;
            this.openSnackBarOk('Utente Eliminato');
            this.GetUsers();
          },
          (error) => {
            ////console.log(error);
            this.openSnackBarError(error);
            this.isLoadingResults = false;
            this.openSnackBarError(error.error.Message);
          }
        );
      }
    })
  }

  Add(): void {
    this.isNew = true;

  }

  Annulla(): void {
    this.isEdit = false;
    this.isNew = false;
    this.showActions = false;

     this.userForm.setValue({
         email: '',
         password: '',
         confirmpassword: '',
         name: '',
       });

    this.GetUsers()
  }

  Salva(): void {
    let data = new RegisterUserModel(
      this.idUser,
      this.userForm.get('email')?.value,
      this.userForm.get('password')?.value,
      this.userForm.get('confirmpassword')?.value,
      this.userForm.get('name')?.value,
    );

    if(this.isNew)
      this.apiService.RegisterUser(data).subscribe(
        (data) => {
          this.isNew = false;
          this.GetUsers();
          this.openSnackBarOk('Utente aggiunto');

          this.userForm.setValue({
            email: '',
            password: '',
            confirmpassword: '',
            name: '',
          });
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error.error.Message);
        }
      );
    else
    {
      this.apiService.EditUser(data).subscribe(
        (data) => {
          this.isEdit = false;
          this.GetUsers();
          this.openSnackBarOk('Utente modificato');

          this.userForm.setValue({
            email: '',
            password: '',
            confirmpassword: '',
            name: '',
          });
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error.error.Message);
        }
      );

    }
  }


  Info(element: any){
    this.isEdit = false;
    this.isNew = false;
    this.showActions = true;
    this.idUser = element.UserId
    this.userForm.setValue({
      email: element.Email,
      password: '',
      confirmpassword: '',
      name: element.Name,
    });
    this.GetWorkSite();
  }

  Edit(element: any): void{
    this.isEdit = true;
    this.isNew = false;
    this.idUser = element.UserId
    this.userForm.setValue({
      email: element.Email,
      name: element.Name,
      password: 'P@ssword0',
      confirmpassword: 'P@ssword0',
    });
  }

  GetWorkSite(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterWorkSiteModel(
      this.pageIndexUser,
      this.pageSizeUser,
      this.sortFieldUser,
      this.sortOrderUser,
      undefined,
      undefined,
      undefined,
      this.idUser,
    );

    this.apiService.GetCantieri(dataFilter).subscribe(
      (data) => {
        console.log("Data: ", data.Data);
        this.dataSource = data.Data;
        this.lengthUser = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        //console.log(error);
        this.openSnackBarError(error.error);
        this.isLoadingResults = false;
      }
    );
  }


  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 10000,
    });
  }

}


import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/model/UserModel';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfigurationModel } from 'src/app/model/ConfigurationModel';
import { iif } from 'rxjs';

@Component({
    selector: 'app-users-admin-page',
    templateUrl: './users-admin-page.component.html',
    styleUrls: ['./users-admin-page.component.scss']
})

export class UsersAdminPageComponent implements OnInit {

    constructor(private apiService: ApiService,
        public translate: TranslateService,
        private snackBar: MatSnackBar,
        private fb: FormBuilder) {

    }

    displayedColumns: string[] =
        [
            'azienda',
            'HasCredential',
            'email',
            'stato',
            'emailConfirmed',
            'banned',
            'resend',
            'credenziali'
        ];
    value: string = ""
    isEdit = false;
    isNew = false;
    isBasic = true;
    isAdmin = false;
    hide = true;
    dataSource = [];
    pageIndex: number = 0;
    pageSize: number = 5;
    sort: string = ""
    sortOrder: string = ""
    length = 0;

    configForm = this.fb.group({
        user: ['', Validators.required],
        password: ['', Validators.required],
    });

    newForm = this.fb.group({
      user: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      type: ['', Validators.required],
    });


    ngOnInit() {
      if(sessionStorage.getItem('roleName') == "admin")
        this.isAdmin = true;
      this.GetUsers();

    }

    GetUsers(): void {

        this.apiService.GetPowerUsers(this.pageIndex, this.pageSize, this.sort, this.sortOrder, this.value)
            .subscribe(
                data => {
                    ////console.log(data);
                    this.dataSource = data.Data;
                    this.length = data.Count;
                },
                error => {
                    ////console.log(error);
                    this.openSnackBarError("Errore !");
                });
    }

    Attiva(userid: string): void {

        let dataUser = new UserModel(userid);

        this.apiService.AttivaUtente(dataUser)
            .subscribe(
                data => {
                    this.openSnackBarOk("Utente Attivato");
                    this.GetUsers();
                },
                error => {
                    ////console.log(error);
                    this.openSnackBarError("Errore !");
                });

    }

    Disattiva(userid: string): void {

        let dataUser = new UserModel(userid);

        this.apiService.DisattivaUtente(dataUser)
            .subscribe(
                data => {
                    this.openSnackBarOk("Utente Disattivato");
                    this.GetUsers();
                },
                error => {
                    ////console.log(error);
                    this.openSnackBarError("Errore");
                });

    }

    SendConfirmMail(userid: string) {

        let dataUser = new UserModel(userid);

        this.apiService.SendConfirmEmail(dataUser)
            .subscribe(
                data => {
                    this.openSnackBarOk("Mail Inviata");
                },
                error => {
                    ////console.log(error);
                    this.openSnackBarError("Errore !");
                });

    }


    openSnackBarOk(message: string) {
        this.snackBar.open(message, '', {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['snackClassSuccess'],
            duration: 5000,
        });
    }

    openSnackBarError(message: string) {
        this.snackBar.open(message, '', {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['snackClassError'],
            duration: 5000,
        });
    }
    handleSearch() {
        this.pageIndex = 0;
        this.GetUsers();
    }
    handlePageEvent(event: PageEvent) {
        this.length = event.length;
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.GetUsers();
    }
    userIdEdit = "";
    openEdit(element: any) {
        console.log(element);

        this.apiService.GetSingleConfiguration(element).subscribe(
            (data) => {
                console.log(data);
                this.configForm.setValue({
                    user: data.UserName,
                    password: data.Password
                });
                this.userIdEdit = element;
                this.isEdit = !this.isEdit;
                this.isBasic = !this.isBasic;
            },
            (error) => {
                //console.log(error);
                this.openSnackBarError(error);
            }
        );
    }

    openNew() {
      this.isNew = !this.isEdit;
      this.isBasic = !this.isBasic;
    }

    Salva() {
        // UpdateConfigurationUser
        let user = this.configForm.get('user')?.value;
        let pass = this.configForm.get('password')?.value;
        let type = "dyn@link";

        if (this.userIdEdit != "") {
            let dataConfig = new ConfigurationModel(type, user, pass);
            this.apiService.UpdateConfigurationUser(dataConfig, this.userIdEdit).subscribe(
                (data) => {
                    this.isEdit = false;
                    this.isBasic = true;
                    this.GetUsers();
                    this.openSnackBarOk('Configurazione modificata');
                },
                (error) => {
                    //console.log(error);
                    this.openSnackBarError(error);
                }
            );
        }
    }

    SalvaNew() {
      // UpdateConfigurationUser
      let user = this.newForm.get('user')?.value;
      let email = this.newForm.get('email')?.value;
      let pass = this.newForm.get('password')?.value;
      let type = this.newForm.get('type')?.value;;

      this.apiService.NewConfigurationUser(user, type, email, pass).subscribe(
          (data) => {
              this.isNew = false;
              this.isBasic = true;
              this.GetUsers();
              this.openSnackBarOk('Aggiunta utenza');
          },
          (error) => {
              //console.log(error);
              this.openSnackBarError(error);
          }
      );

  }

}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-job-engaged-dialog',
  templateUrl: './job-engaged-dialog.component.html',
  styleUrls: ['./job-engaged-dialog.component.scss']
})
export class JobEngagedDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JobEngagedDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any
  ) {

  }

  text = '';

  ngOnInit(): void {
      this.text = this.mydata;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' }); }
}

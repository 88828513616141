export class RegisterUserModel {

    constructor(
      public UserId: string,
      public email: string,
      public password: string,
      public confirmpassword: string,
      public name: string,
    ) {  }

  }


export class OperatorModel {

  constructor(
    public id: string,
    public name: string,
    public date: Date,
  ) {  }


}


import { DialogConfermaComponent } from './../Dialog-conferma/Dialog-conferma.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterOperatorModel } from 'src/app/model/FilterOperatorModel';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateAdapter } from '@angular/material/core';
import { OperatorModel } from 'src/app/model/OperatorModel';
import { FilterWorkSiteOperatorModel } from 'src/app/model/FilterWorkSiteOperatorModel';
import { FilterWorkSiteModel } from 'src/app/model/FilterWorkSiteModel';
import { Router } from '@angular/router';

interface selectItems {
  Id: string;
  Description: string;
}

interface selectUser {
  UserId: string;
  Name: string;
}

@Component({
  selector: 'app-operator-page',
  templateUrl: './operator-page.component.html',
  styleUrls: ['./operator-page.component.scss']
})
export class OperatorPageComponent implements OnInit {

  constructor(
    private dateAdapter: DateAdapter<any>,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  pageEvent!: PageEvent;
  @ViewChild(MatSort) sort!: MatSort;

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 15;
  length = 0;
  displayedColumns: string[] = [
    'name',
    'isvalid',
    'date',
    'pin',
    'actions',
  ];

  displayedColumnsInfo: string[] = [
    'commessa',
    'datestart',
    'dateend',
    'description',
    'client',
    'Indirizzo',
    'bossname',
    'isactive',
    'actions'

  ];

  dataSource = [];

  isEdit = false;
  isNew = false;
  showActions = false;

  idOperator = '';
  sortField: string = 'name';
  sortOrder: string = 'asc';
  filtername = '';
  activeFilterSource: selectItems[] = [];
  activeFilterId = '0';
  dataSourceCantieri = []
  bossFilterSource: selectUser[] = [];
  bossFilterId = '0'
  isTableOperatori = true

  operatorForm = this.fb.group({
    date: ['', Validators.required],
    name: ['', Validators.required],
    pin : ['']
  });

  ngOnInit(): void {

    this.dateAdapter.setLocale('it-IT');

    var item : selectItems = {Id : '0', Description : 'Tutti'};
    this.activeFilterSource.push(item);

    var itemAttivi : selectItems = {Id : '1', Description : 'Attivi'};
    this.activeFilterSource.push(itemAttivi);

    var itemNonAttivi : selectItems = {Id : '2', Description : 'Non Attivi'};
    this.activeFilterSource.push(itemNonAttivi);

    this.GetOperator();

  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;
      this.sortField = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.GetOperator();
    });
  }

  handlePageEvent(event: PageEvent) {
    console.log('index: ', this.pageIndex)

    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.GetOperator()
    this.GetWorkSite();
  }

  GetOperator(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterOperatorModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.activeFilterId,
      this.filtername,
    );

    this.apiService.GetOperator(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
        console.log('oper: ', this.dataSource)
      },
      (error) => {
        //////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  GetBossUsersStorico(): void {
    this.bossFilterSource = [];


    this.apiService.BossUsersStorico().subscribe(
      (data) => {
        var data = data;

        const itemTutti: selectUser = { UserId: '0', Name: 'Tutti' };

        this.bossFilterSource.push(itemTutti);

        data.forEach((w: selectUser) => {
          this.bossFilterSource.push(w);

        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  Add() {
    this.isEdit = true;
    this.isNew = true;
    this.showActions = false;
    this.idOperator = '';

    this.operatorForm.setValue({
      date: new Date(''),
      name: '',
      pin:''
    });
  }

  Edit(element: any): void {
    this.isEdit = true;
    this.isNew = false;
    this.showActions = false;

    this.idOperator = element.Id;

    this.operatorForm.setValue({
      date: element.ValidityDate,
      name: element.Name,
      pin: element.Pin
    });
  }

  Delete(IdOperator: any){
    let risp = this.dialog.open(DialogConfermaComponent, {
      data: {
        title: 'Elimina operatore',
        text: 'Vuoi davvero eliminare questo operatore?'
      }}
    );
    risp.afterClosed().subscribe(result => {
      if(result == 'true'){
        console.log('idOper: ', IdOperator)
        this.apiService.DeleteOperator(IdOperator).subscribe(
          (data) => {
            this.GetOperator();
            this.openSnackBarOk('Operatore eliminato');
          },
          (error) => {
            ////console.log(error);
            this.openSnackBarError(error.error.Message);
          }
        );
      }
    })
  }

  Info(element: any){
    this.isEdit = false;
    this.isNew = false;
    this.showActions = true;

    this.idOperator = element.Id;

    this.operatorForm.setValue({
      date: element.ValidityDate,
      name: element.Name,
      pin: element.Pin
    });

    this.GetWorkSite()
    this.GetBossUsersStorico()
  }

  Salva(): void {

    let dataJob = new OperatorModel(
      this.idOperator,
      this.operatorForm.get('name')?.value,
      this.operatorForm.get('date')?.value,
    );

    console.log('Data: ', this.operatorForm.get('date')?.value)

    if(this.operatorForm.get('date')?.value < new Date()){
      this.openSnackBarError('La data di fine contratto deve essere maggiore di oggi');
      return
    }

    if (this.isNew) {
      this.apiService.AddOperator(dataJob).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idOperator = '';
          this.GetOperator();
          this.openSnackBarOk('Operatore aggiunto');
        },
        (error) => {
          this.openSnackBarError("Impossibile creare l'operatore");
        }
      );
    } else {
      this.apiService.UpdateOperator(dataJob).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idOperator = '';
          this.GetOperator();
          this.openSnackBarOk('Operatore modificato');
        },
        (error) => {
          //////console.log(error);
          this.openSnackBarError(error.error.message);
        }
      );
    }

  }

  Annulla(): void {
    this.isEdit = false;
    this.isNew = false;
    this.showActions = false;
    this.GetOperator()
  }

  GetWorkSite(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterWorkSiteModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.activeFilterId,
      this.bossFilterId,
      this.idOperator,
    );

    console.log('sono dataFilter: ', dataFilter)

    this.apiService.GetCantieri(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        //console.log(error);
        this.openSnackBarError(error.error);
        this.isLoadingResults = false;
      }
    );
  }

  Dashboard(idWorkSite: number){
    this.router.navigate(['dashboardCantiere', {IdWorkSite: idWorkSite}]);
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

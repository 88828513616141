export class FilterDynakitModel {

  constructor(
    public page: number,
    public itempage: number,
    public sort: string,
    public sortorder: string,
    public filtercustomer?: string,
  ) {  }

}

export class FilterUserModel {

  constructor(
    public page: number,
    public itempage: number,
    public sort: string,
    public sortorder: string,
    public filteractiveid?: string,
  ) {  }

}

export class RegistrationModel {

    constructor(
      public email: string,
      public password: string,
      public confirmPassword: string,
      public name : string,
    ) {  }

  }

<mat-card class="box_elenco" *ngIf="isBasic">
    <mat-card-title class="title-box">
        <mat-icon>supervised_user_circle</mat-icon><span>Clienti</span>


    </mat-card-title>
    <div style="display: flex;">
      <div style="width: 100%;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>
            <mat-icon>search</mat-icon><span>Search</span>
          </mat-label>
          <input matInput type="text" [(ngModel)]="value" (keyup)="handleSearch()">
          <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value='';handleSearch()">
              <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div *ngIf="isAdmin">
        <button  mat-icon-button style="background-color: #b11116; margin-top: 8px; margin-left: 8px; margin-right: 8px;" (click)="openNew()" >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <div class="table-container mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSortActive="email" matSortDirection="desc">


            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
            </ng-container>

            <!-- Azienda -->
            <ng-container matColumnDef="azienda" sticky>
                <th mat-header-cell *matHeaderCellDef> Azienda </th>
                <td mat-cell *matCellDef="let element" style="padding-right: 10px;"> {{element.Name}} </td>
            </ng-container>
            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.UserId}} </td>
            </ng-container>

            <!-- Stato Column -->
            <ng-container matColumnDef="stato">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Stato </th>
                <td mat-cell *matCellDef="let element"> {{element.Stato}} </td>
            </ng-container>

            <!-- Email Confirmed Column -->
            <ng-container matColumnDef="emailConfirmed">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Email Confermata </th>
                <td mat-cell *matCellDef="let element"> {{element.EmailConfirmed? 'Si':'No'}} </td>
            </ng-container>

            <!-- Banned -->
            <ng-container matColumnDef="banned">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Attivazione </th>
                <ng-container *matCellDef="let element">
                    <td mat-cell>
                        <button style="margin-right: 15px;" *ngIf="element.Stato == 'Attivo'" (click)="Disattiva(element.UserId)" mat-raised-button
                            color="warn"> Disattiva </button>
                        <button style="margin-right: 15px;" *ngIf="element.Stato == 'Bannato'" (click)="Attiva(element.UserId)" mat-raised-button
                            color="primary"> Attiva </button>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Resend -->
            <ng-container matColumnDef="resend">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Reinvio email </th>
                <ng-container *matCellDef="let element">
                    <td mat-cell>
                        <button style="margin-right: 15px;" (click)="SendConfirmMail(element.UserId)" *ngIf="element.EmailConfirmed == false"
                            mat-raised-button color="accent"> Invia </button>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="HasCredential">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Credenziali Inserite </th>
                <ng-container *matCellDef="let element">
                    <td mat-cell>
                        <mat-icon *ngIf="element.HasCredential" class="ok-icon">done</mat-icon>
                        <mat-icon *ngIf="!element.HasCredential" class="error-icon">close</mat-icon>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="credenziali" stickyEnd>
                <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Credenziali Dynalink </th>
                <ng-container *matCellDef="let element">
                    <td mat-cell>
                        <button mat-raised-button color="accent" (click)="openEdit(element.UserId)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>

    <mat-paginator [pageSizeOptions]="[5,10]" (page)="handlePageEvent($event)" [length]="length" [pageIndex]="pageIndex"
        [pageSize]="pageSize" showFirstLastButtons></mat-paginator>

</mat-card>

<mat-card class="box_elenco" *ngIf="isEdit">
    <mat-card-title class="title-box">
        <mat-icon>edit</mat-icon><span>Modifica cliente</span>
    </mat-card-title>
    <mat-card style="margin-bottom: 15px;">

        <div #cardedit>
            <mat-card-content>
                <form [formGroup]="configForm">
                    <div class="edit-container">

                        <mat-form-field appearance="fill" class="input_label">
                            <mat-label>User</mat-label>
                            <input matInput formControlName="user" type="text" required />
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="input_label_end">
                            <mat-label>Password</mat-label>
                            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required />
                            <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">
                                {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>

                    </div>
                </form>
            </mat-card-content>
        </div>

        <mat-divider inset></mat-divider>
        <mat-card-actions>



            <div fxFlex></div>
            <button [disabled]="!configForm.valid" mat-stroked-button color="primary" class="btn-block margin"
                (click)="Salva()">
                <mat-icon>check</mat-icon>
            </button>
            <button mat-stroked-button color="warn" class="btn-block margin" (click)="isEdit = !isEdit; isBasic = !isBasic">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
</mat-card>

<mat-card class="box_elenco" *ngIf="isNew">
  <mat-card-title class="title-box">
      <mat-icon>edit</mat-icon><span>Nuovo cliente</span>
  </mat-card-title>
  <mat-card style="margin-bottom: 15px;">

      <div #cardedit>
          <mat-card-content>
              <form [formGroup]="newForm">
                  <div class="edit-container">

                      <mat-form-field appearance="fill" class="input_label">
                          <mat-label>User</mat-label>
                          <input matInput formControlName="user" type="text" required />
                      </mat-form-field>

                      <mat-form-field appearance="fill" class="input_label_end">
                          <mat-label>Email</mat-label>
                          <input matInput formControlName="email" type="email" required />
                      </mat-form-field>

                      <mat-form-field appearance="fill" class="input_label">
                          <mat-label>Password</mat-label>
                          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required />
                          <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">
                              {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </mat-form-field>

                      <mat-form-field appearance="fill" class="input_label_end">
                        <mat-label>Tipologia</mat-label>
                        <mat-select formControlName="type">
                          <mat-option value="supervisor">
                            Supervisore
                          </mat-option>
                          <mat-option value="poweruser">
                            Poweruser
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                  </div>
              </form>
          </mat-card-content>
      </div>

      <mat-divider inset></mat-divider>
      <mat-card-actions>



          <div fxFlex></div>
          <button [disabled]="!newForm.valid" mat-stroked-button color="primary" class="btn-block margin"
              (click)="SalvaNew()">
              <mat-icon>check</mat-icon>
          </button>
          <button mat-stroked-button color="warn" class="btn-block margin" (click)="isNew = !isNew; isBasic = !isBasic">
              <mat-icon>close</mat-icon>
          </button>
      </mat-card-actions>
  </mat-card>
</mat-card>

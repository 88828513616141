<div style="text-align: center;">
  <h3><mat-dialog-title><b>{{data.title}}</b></mat-dialog-title></h3>
  <mat-dialog-content>
    <div>
      {{data.text}}
    </div>

    <!-- TESTO OPZIONALE PER DESCRIZIONE AGGIUNTIVA -->
    <div class="container-optionalDescription">
      <br>
      <span *ngIf="data.optionalDescription != null" class="optionalDescription">
        {{data.optionalDescription}}
      </span>
    </div>


  </mat-dialog-content>

  <mat-divider style="margin-top: 25px;"></mat-divider>

  <div style="align-items: center; margin-top: 15px;">
    <button
      mat-stroked-button
      mat-dialog-close
      mat-dialog-close="true"
      style="margin-right: 10px;">
      Conferma
    </button>

    <button
      mat-raised-button
      color="warn"
      mat-dialog-close
      mat-dialog-close="false">
      Annulla
    </button>
  </div>

</div>



import { Component, Inject, OnInit, Optional, ElementRef  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DatePipe } from '@angular/common';

// import { PDF_STYLE } from '../../services/PDF_SERVICE/PDF_STYLE.service';
// import { PDF_SERVICE } from 'src/app/services/PDF_SERVICE/Generate-PDF.service'


@Component({
  selector: 'app-my-dialog',
  templateUrl: './job-dialog-page.component.html',
  styleUrls: ['./job-dialog-page.component.scss']
})
export class JobDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JobDialogComponent>,
    private apiService: ApiService,
    private datePipe: DatePipe,
    // @Inject(PDF_SERVICE) public PDF_SERVICE: any,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any,
    // public PDF_STYLE: PDF_STYLE,
    private elementRef: ElementRef
  ) {

  }

  headerMaps: any
  mapsScreen: any
  formatDataMap: any
  dividerMaps: any

  map: any;
  coordinates: { lat: number, lng: number }[] = [];
  loadMachineHours: boolean = false
  showMap: any
  hasCoordinates: any
  path: any
  cssProperty: any
  cssPropertyHeight: any
  cssPropertyHeightCard: any

  dataSource = [];
  dataODL : any = [];
  MachineHours: any;
  displayedColumnsStatus: string[] = [
    'type',
    'date',
    'odometer',
    'operatinghour',
    'idlehour',
    'fuelremaining',
    'defremaining',
    'running',
    'latitude',
    'longitude'
  ];

  ngOnInit(): void {

    this.dataSource = this.mydata[0];
    this.dataODL = this.mydata[1]

    console.log('dataSource: ', this.dataSource)
    console.log('dataODL: ', this.dataODL)
    console.log('dataSourceTotale: ', this.mydata)

    if (this.mydata != null && this.mydata.length > 0) {
      this.GetDataBarchart(this.mydata[1].Id);
    }

    this.hasCoordinates = this.dataSource.some((item: any) => item.Latitude && item.Longitude && item.Latitude !== '-' && item.Longitude !== '-');
    console.log('hasCoordinates: ', this.hasCoordinates)

    if (this.hasCoordinates) {
      this.cssProperty = 'visible'
      this.cssPropertyHeight = '100%'
      this.cssPropertyHeightCard = '400px'

      this.initializeMap();
    }else{
      this.cssProperty = 'hidden'
      this.cssPropertyHeightCard = 'auto'
      this.cssPropertyHeight = '0px'
    }

  }

  initializeMap(): void {
    const mapOptions = {
      center: new google.maps.LatLng(0, 0), // Centro iniziale della mappa
      zoom: 10, // Zoom iniziale della mappa
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    const mapElement = document.getElementById('map');
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, mapOptions);
    }

    this.drawPolyline()

    const bounds = new google.maps.LatLngBounds();
    this.mydata[0].forEach((item: any) => {
      const latitude = parseFloat(item.Latitude);
      const longitude = parseFloat(item.Longitude);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        const point = new google.maps.LatLng(latitude, longitude);
        bounds.extend(point);
      }
    });

    // Applica i limiti dei punti alla mappa per impostare lo zoom corretto

    this.map.fitBounds(bounds);
  }

  drawPolyline(): void {
    this.path = this.mydata[0].map((item: any) => {
      if (item && item.Latitude && item.Longitude) {
        const latitude = parseFloat(item.Latitude);
        const longitude = parseFloat(item.Longitude);

        if (isNaN(latitude) || isNaN(longitude)) {
          return null; // Ignore invalid coordinates
        }

        const date = this.datePipe.transform(item.Date, 'dd/MM/yyyy HH:mm')

        return {
          lat: latitude,
          lng: longitude,
          type: item.Type,
          date: date,
          text: item.PauseReason != null ? date + ' - ' + item.PauseReason : date,
        };
      }
      return null;
    }).filter((coord: any) => coord !== null);

    console.log('path: ', this.path)

    setTimeout(() => {
      const dashedPolyline = new google.maps.Polyline({
        path: this.path,
        strokeColor: '#FF0000',
        strokeOpacity: 0.0,
        strokeWeight: 2,
        icons: [{
          icon: {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 4
          },
          offset: '0',
          repeat: '20px'
        }]
      });

      dashedPolyline.setMap(this.map);

      const markerPolyline = new google.maps.Polyline({
        path: this.path,
        strokeColor: '#FF0000',
        strokeOpacity: 0.0,
        strokeWeight: 2
      });

      markerPolyline.setMap(this.map);

      // Add a marker for each point along the polyline
      this.path.forEach((point: any) => {
        let iconUrl = '';
        let iconFillColor = '#B11116';
        if (point.type === 'Avviato') {
          iconUrl = '../assets/img/mapStart.png';
        } else if (point.type === 'In Pausa') {
          iconUrl = '../assets/img/mapPause.png';
        } else {
          iconUrl = '../assets/img/mapStop.png';
        }

        const markerOptions: google.maps.MarkerOptions = {
          position: point,
          map: this.map,

          icon: {
            url: iconUrl,
            scaledSize: new google.maps.Size(32, 32),
            fillColor: iconFillColor
          },

          label: {
            text: point.text,
            color: 'white',
            fontSize: '12px',
            fontWeight: 'bold',
            fontFamily: 'Arial',
            className: 'labelStyle'
          }
        };

        const marker = new google.maps.Marker(markerOptions);

      });
    }, 1);

  }

  checkIfIsNull(data: any){
    if (typeof data === 'string') {
      data = data.trim();
    }
    if (data[0] == "-" || data < 0) {
      return true
    }else{
      return false
    }
  }

  getMinutes(num:any)
  {
    var minutiToConvert = num % 1;
    var minutiConverted = 60 * minutiToConvert;
    var txtReturn = (minutiConverted.toString().split(".")[0] );
    return txtReturn;
  }

  GetDataBarchart(idJob: number): void {
    console.log("CIAOOOO");
    this.loadMachineHours = false

    this.apiService.jobstats(idJob).subscribe(
      (data) => {

        this.MachineHours = data;

        this.loadMachineHours = true

        console.log('MachineHours1: ', this.MachineHours)
      },
      (error) => {

      }
    );
  }

  GetIntero(input: any) {
    if (!isNaN(input)) {
      return Math.floor(input);
    }
    return input;
  };


  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

  async createPDF() {

    //DATA PDF

    let column = ['Stato', 'Data', 'Conta KM', 'Ore lavorate', 'Ore IDLE', 'Carburante (%)', 'DEF (%)', 'Motore', 'Latitudine', 'Longitudine'];
    const fieldNames = ['Type', 'Date', 'OdometerStat', 'CumulativeOperatingHoursStat', 'CumulativeIdleHours', 'FuelRemaining', 'DEFRemaining', 'Running', 'Latitude', 'Longitude'];
    let PDF_Title = "Consuntivo dell'attività";

    let dataDoc = {
      author: 'Ulisse Srl',
      subject: 'Consuntivo attività',
      creator: 'Dyn@Production.it',
      //logoUrl: 'https://dynapacbe.azurewebsites.net//content//img//dynaProdLogo.jpeg',
    }

    let dataHeader = {
      title: "Consuntivo dell'attività",
      subtitle: "Riepilogo dei dati prodotti dal mezzo e dall'attività",
      style: {
        headerStyle: "header",
        titleStyle: "titleHeader",
        subTitleStyle: "subTitleHeader"
      }
    }

    let dataDividerHeader = {
      type: 'line',
      x1: 0,
      y1: 10,
      x2: 555,
      y2: 10,
      lineWidth: 0.5,
    }

    let dataDividerODL = {
      type: 'line',
      x1: 0,
      y1: 20,
      x2: 555,
      y2: 20,
      lineWidth: 0.5,
    }

    let dataDividerMaps = {
      type: 'line',
      x1: 0,
      y1: 20,
      x2: 555,
      y2: 20,
      lineWidth: 0.5,
    }

    let dataODL = {
      titleODL: "Riepilogo attività",
      subtitleODL: "Elenco dei dati anagrafici dell'attività",
      titleMachine: "Mezzo impiegato",
      subtitleMachine: "Elenco dei dati del mezzo utilizzato",
      fieldNamesODL: ['Code', 'Description', 'Note', 'DateStart', 'DateEnd', 'Stato', 'TipoLavoro', 'OperatorName', 'WorkSiteDescription'],
      fieldNamesToViewODL: ['Codice', 'Descrizione', 'Note', 'Data inizio', 'Data fine', 'Stato', 'Tipologia', 'Operatore', 'Cantiere'],
      fieldNamesMachine: ['Model', 'SerialNumber'],
      fieldNamesToViewMachine: ['Modello', 'Numero seriale'],
      ODL: this.dataODL,
      Machine: {
        Model: this.dataODL.Model,
        SerialNumber: this.dataODL.SerialNumber
      },
      machineImagewidth: 150,
      style: {
        containerInfoODLStyle: "containerTitle",
        titleStyle: "title",
        subTitleStyle: "subtitle",
        labelNameStyle: "DescriptionLabelODL",
        labelValueStyle: "dataLabelODL"
      }
    }

    console.log('data for info ODL - Machine: ', dataODL.Machine)

    let dataMaps = {
      title: "Storico posizione",
      subtitle: "Riepilogo degli spostamenti dal mezzo durante l'attività",
      dataGoogleMaps: {
        apiKey: 'AIzaSyDoHEEVdzy5lC8E7Hf6XlN6NEpHoXZuklE',
        center: '0,0',
        zoom: 10,
        size: '600x400',
        markers: null,
        path: null
      },
      style: {
        headerStyle: "containerTitle",
        titleStyle: "title",
        subTitleStyle: "subtitle"
      }
    }

    let dataHeaderTable = {
      title: "Stati dell'attività",
      subtitle: "Elenco degli stati e dei dati prodotti durante l'attività",
      style: {
        headerStyle: "containerTitle",
        titleStyle: "title",
        subTitleStyle: "subtitle"
      }
    }


    //STYLE

    // let style = this.PDF_STYLE.styles()


    //INTESTAZIONE

    // let header = this.PDF_SERVICE.Create_Header_For_PDF(dataHeader)

    // let dividerHeader = this.PDF_SERVICE.Create_Divider_For_PDF(dataDividerHeader)


    //INFO ODL

    // let infoODL = await this.PDF_SERVICE.Create_Info_ODL_For_PDF(dataODL)

    // let dividerODL = this.PDF_SERVICE.Create_Divider_For_PDF(dataDividerODL)


    //MAPS

    if (this.hasCoordinates) {

      // this.formatDataMap = this.PDF_SERVICE.Format_Data_Map_For_PDF(dataMaps, this.path)

      // this.headerMaps = this.PDF_SERVICE.Create_Header_For_PDF(this.formatDataMap)

      // this.mapsScreen = await this.PDF_SERVICE.Create_Maps_Image_For_PDF(this.formatDataMap)

      // this.dividerMaps = this.PDF_SERVICE.Create_Divider_For_PDF(dataDividerMaps)

    }


    //TABLE STORICO

    // let headerTable = this.PDF_SERVICE.Create_Header_For_PDF(dataHeaderTable)

    // let dataTable = this.PDF_SERVICE.Create_Body_For_Table(this.dataSource, fieldNames, column)

    // let table = this.PDF_SERVICE.Create_Table_For_PDF(dataTable);


    //COMPOSE AND CREATE PDF

    // let PDF = this.PDF_SERVICE.Compose_Content_For_PDF(PDF_Title, [header, dividerHeader, infoODL, dividerODL, this.headerMaps, this.mapsScreen, this.dividerMaps, headerTable, table])

    // this.PDF_SERVICE.Create_PDF(PDF, style, dataDoc);

  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterDynakitModel } from 'src/app/model/FilterDynakitModel';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DynakitModel } from 'src/app/model/DynakitModel';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { Console } from 'console';

interface selectCustomer {
  Id: string;
  Description: string;
}

@Component({
  selector: 'app-dynakit-page',
  templateUrl: './dynakit-page.component.html',
  styleUrls: ['./dynakit-page.component.scss']
})
export class DynakitPageComponent implements OnInit {

  constructor(
    private dateAdapter: DateAdapter<any>,
    public dialog: MatDialog,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }


  pageEvent!: PageEvent;
  @ViewChild(MatSort) sort!: MatSort;

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 10;
  length = 0;
  displayedColumns: string[] = [
    'customer',
    'nomeDynakit',
    'dynakit',
    'machine',
    'dateassociate',
    'dateend',
    'licenzaScaleFusion',
    'dateEndScaleFusion',
    'isvalid',
    'actions',
  ];

  dataSource = [];
  customerSource: selectCustomer[] = [];

  isEdit = false;
  isNew = false;
  idDynakit = 0;
  sortField: string = 'customer';
  sortOrder: string = 'asc';
  filtercustomer: string = '';

  dynakitForm = this.fb.group({
    dateend: ['', Validators.required],
    machinename: [''],
    nomeDynakit: ['', Validators.maxLength(4)],
    licenzaScaleFusion: [''],
    dateEndScaleFusion: [null]
  });

  ngOnInit(): void {

    this.dateAdapter.setLocale('it-IT');
    this.GetDynakit();

  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;
      this.sortField = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.GetDynakit();
    });
  }

   handlePageEvent(event: PageEvent) {
     this.length = event.length;
     this.pageSize = event.pageSize;
     this.pageIndex = event.pageIndex;
     this.GetDynakit();
   }

   GetCustomer(): void {
    this.apiService.GetCustomer().subscribe(
      (data) => {
        this.customerSource = [];

        data.forEach((item: selectCustomer) => {
          this.customerSource.push(item);
        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetDynakit(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterDynakitModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.filtercustomer,
    );

    this.apiService.GetDynakit(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        //console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  UpdatePage()
  {
    this.CheckCensimento();
    this.GetDynakit();
  }

  CheckCensimento(): void {
    this.isLoadingResults = true;

    this.apiService.CheckCensimento().subscribe(
      (data: any) => {

        this.isLoadingResults = false;
      },
      (error: any) => {
        ////console.log(error);
        this.openSnackBarError("error");
        this.isLoadingResults = false;
      }
    );
  }

  
  Add(): void {
    this.isEdit = true;
    this.isNew = true;

    this.dynakitForm.setValue({
      dateend: new Date(),
      nomeDynakit: '',
      licenzaScaleFusion: '',
      dateEndScaleFusion: new Date(),
    });
  }

  Edit(element: any): void {
    this.isEdit = true;
    this.isNew = false;

    this.idDynakit = element.Id;

    this.dynakitForm.setValue({
      dateend: element.DateEnd,
      machinename: element.MachineName,
      nomeDynakit: element.NomeDynakit == "" ? null : element.NomeDynakit,
      licenzaScaleFusion: element.LicenzaScaleFusion == "" ? null : element.LicenzaScaleFusion,
      dateEndScaleFusion: element.DateEndScaleFusion
    });

  }

  Annulla(): void {
    this.isEdit = false;
    this.isNew = false;
  }

  Salva(): void {

     let data = new DynakitModel(
       this.idDynakit,
       this.dynakitForm.get('dateend')?.value,
       this.dynakitForm.get('nomeDynakit')?.value,
       this.dynakitForm.get('licenzaScaleFusion')?.value,
       this.dynakitForm.get('dateEndScaleFusion')?.value
    );

     if (this.isNew) {
       this.apiService.AddDynakit(data).subscribe(
         (data) => {
           //console.log("All kit: " + data)
           this.isEdit = false;
           this.isNew = false;
           this.idDynakit = 0;
           this.GetDynakit();
           this.openSnackBarOk('Dynakit aggiunto');
         },
         (error) => {
           ////console.log(error);
           this.openSnackBarError(error);
         }
       );
     } else {
       this.apiService.UpdateDynakit(data).subscribe(
         (data) => {
           this.isEdit = false;
           this.isNew = false;
           this.idDynakit = 0;
           this.GetDynakit();
           this.openSnackBarOk('Dynakit modificato');
         },
         (error) => {
           ////console.log(error);
           this.openSnackBarError(error.error.message);
         }
       );
     }
  }

  Disassociate(element: any): void {

    if(confirm("Confermi disassociazione ?")) {

      this.apiService.DisassociateDynakit(element.Id).subscribe(
        (data) => {
          this.GetDynakit();
          this.openSnackBarOk('Dynakit disassociato');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error.error.Message);
        }
      );

    }





  }
  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

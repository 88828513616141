<mat-card>
  <mat-card-title>Configurazione</mat-card-title>
</mat-card>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef> Tipo </th>
        <td mat-cell *matCellDef="let element"> {{element.Type}} </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> User </th>
        <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
    </ng-container>

    <!-- Password Column -->
    <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef> Password </th>
        <td mat-cell *matCellDef="let element"> {{element.Password}} </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <ng-container *matCellDef="let element">
          <td mat-cell>
            <button (click)="Test(element)"
              mat-stroked-button color="primary"
              class="margin"
              [disabled]="!btnEnabled"
              matTooltip="Test Dyn@link"
              [matTooltipShowDelay]="1000">
              <mat-icon>network_check</mat-icon>
            </button>
          </td>
            <td mat-cell>
                <button (click)="Edit(element)" mat-raised-button color="primary" class="margin">
                  <mat-icon>edit</mat-icon> </button>
                <button (click)="Delete(element)" mat-raised-button color="warn" class="margin">
                  <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>

<!-- <br> -->

<button mat-raised-button color="primary" (click)="Add()" class="margin" *ngIf="!isEdit">Aggiungi</button>

<form [formGroup]="configForm" class="box" *ngIf="isEdit">

    <p>
        <mat-form-field appearance="fill">
            <mat-label>Tipo</mat-label>
            <mat-select >
                <mat-option value="dyn@link">dyn@link</mat-option>
            </mat-select>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill">
            <mat-label>User</mat-label>
            <input matInput placeholder="User" formControlName="user">
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input matInput placeholder="Password" formControlName="password">
        </mat-form-field>
    </p>

    <div>
        <button [disabled]="!configForm.valid" mat-raised-button color="primary" class="btn-block margin" (click)="Salva()"  >Salva</button>
        <button mat-raised-button color="warn" class="btn-block margin" (click)="Annulla()" >Annulla</button>
    </div>
</form>

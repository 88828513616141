<mat-dialog-content class="mat-typography">

  <h3>Storico Lavoro</h3>

  <div *ngIf="dataSource.length == 0">
    <b>Nessun Dato</b>
  </div>

  <mat-card>
    <mat-card-title>Storico posizioni</mat-card-title>
    <mat-card-subtitle>Elenco dei diversi spostamenti del veicolo durante l'attività</mat-card-subtitle>

    <mat-card-content id="map-container" style="--heightCard: {{cssPropertyHeightCard}}">

      <div id="map" style="--show: {{cssProperty}}; --height: {{cssPropertyHeight}}"></div>

      <div *ngIf="!hasCoordinates" class="container-NoResultMaps">
        <div class="image-noResulMap">
          <img src="../../../assets/img/NoResultMaps.png" alt="NoResultMaps" style="width: 20%;">
          <span style="text-align: center; color: rgba(0, 0, 0, 0.54);">Nessuno spostamento registrato per <br> questa attività</span>
        </div>
      </div>

    </mat-card-content>

  </mat-card>



  <div class="container-custom-divider">
    <mat-divider class="dividerStoricoODL"></mat-divider>
    <span class="labelDividerStorico">Elenco degli stati</span>
    <mat-divider class="dividerStoricoODL"></mat-divider>
  </div>

  <table *ngIf="dataSource.length != 0" mat-table [dataSource]="dataSource">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Stato</th>
      <td mat-cell *matCellDef="let element">

        <div *ngIf="element.Stato == 0" class="start">
          {{ element.Type }}
        </div>

        <div *ngIf="element.Stato == 1" class="pause">
          <div style="display: flex; align-items: center;">
            <span>{{ element.Type }}</span>
            <button mat-icon-button [matTooltip]="element.PauseReason" matTooltipPosition="right" *ngIf="element.PauseReason != null">
              <mat-icon>info</mat-icon>
            </button>
          </div>

        </div>

        <div *ngIf="element.Stato == 2" class="stop">
          {{ element.Type }}
        </div>


      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let element">

        <div *ngIf="element.Stato == 0" class="start">
          {{ element.Date | date: "dd/MM/yyyy HH:mm:ss" }}
        </div>

        <div *ngIf="element.Stato == 1" class="pause">
          {{ element.Date | date: "dd/MM/yyyy HH:mm:ss" }}
        </div>

        <div *ngIf="element.Stato == 2" class="stop">
          {{ element.Date | date: "dd/MM/yyyy HH:mm:ss" }}
        </div>


      </td>
    </ng-container>

    <!-- Odometer Column -->
    <ng-container matColumnDef="odometer">
      <th mat-header-cell *matHeaderCellDef>ContaKm</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.OdometerStat)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.OdometerStat)">{{ element.OdometerStat }}</span>

        </div>

      </td>
    </ng-container>

    <!-- CumulativeOperatingHours Column -->
    <ng-container matColumnDef="operatinghour">
      <th mat-header-cell *matHeaderCellDef>Ore Lavorate</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.CumulativeOperatingHoursStat)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.CumulativeOperatingHoursStat)">{{ element.CumulativeOperatingHoursStat }}</span>

        </div>

      </td>
    </ng-container>

    <!-- CumulativeIdleHours Column -->
    <ng-container matColumnDef="idlehour">
      <th mat-header-cell *matHeaderCellDef>Ore Idle</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.CumulativeIdleHours)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.CumulativeIdleHours)">{{ element.CumulativeIdleHours }}</span>

        </div>

      </td>
    </ng-container>

    <!-- FuelRemaing Column -->
    <ng-container matColumnDef="fuelremaining">
      <th mat-header-cell *matHeaderCellDef>Carburante %</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.FuelRemaining)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.FuelRemaining)">{{ element.FuelRemaining }}</span>

        </div>

      </td>
    </ng-container>

    <!-- DEFRemaing Column -->
    <ng-container matColumnDef="defremaining">
      <th mat-header-cell *matHeaderCellDef>DEF %</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.DEFRemaining)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.DEFRemaining)">{{ element.DEFRemaining }}</span>

        </div>

      </td>
    </ng-container>

    <!-- Running Column -->
    <ng-container matColumnDef="running">
      <th mat-header-cell *matHeaderCellDef>Motore</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.Running)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.Running)">{{ element.Running }}</span>

        </div>

      </td>
    </ng-container>

    <!-- Latitude Column -->
    <ng-container matColumnDef="latitude">
      <th mat-header-cell *matHeaderCellDef>Latitudine</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.Latitude)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.Latitude)">{{ element.Latitude }}</span>

        </div>

      </td>
    </ng-container>

    <!-- Longitude Column -->
    <ng-container matColumnDef="longitude">
      <th mat-header-cell *matHeaderCellDef>Longitudine</th>
      <td mat-cell *matCellDef="let element">

        <div [ngClass]="{'start': element.Stato == 0, 'pause': element.Stato == 1, 'stop': element.Stato == 2}">

          <button *ngIf="checkIfIsNull(element.Longitude)" mat-icon-button matTooltip="Dato in elaborazione..." matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>

          <span *ngIf="!checkIfIsNull(element.Longitude)">{{ element.Longitude }}</span>

        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsStatus"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsStatus"></tr>
  </table>

  <div class="container-custom-divider" style="margin-top: 8px; margin-bottom: 8px;">
    <mat-divider class="dividerStoricoODL"></mat-divider>
    <span class="labelDividerStoricoConsunstivo">Dati della consuntivazione</span>
    <mat-divider class="dividerStoricoODL"></mat-divider>
  </div>

  <div
    *ngIf="
      dataSource.length != 0 &&
      loadMachineHours &&
      MachineHours != null &&
      MachineHours.OreDynakit != -1 &&
      MachineHours.OreMacchina != -1"
      class="container-data-consuntivo"
    >

    <div class="container-all-MachineHours">

      <!-- ORE STIMATE -->
      <div *ngIf="MachineHours.OreStimate != -1">
        <!-- VALORE NUMERICO -->
        <span class="value-MachineHours">{{MachineHours.OreStimate | number : '1.0-0'}}</span><span class="symbol-value-MachineHours">H</span>
        <span class="value-MachineHours" *ngIf="getMinutes(MachineHours.OreStimate) != '0'">{{getMinutes(MachineHours.OreStimate) | number : '1.0-0'}}<span class="symbol-value-MachineHours">m</span></span>

        <!-- ICONA E LABEL DI INFO VALUE -->
        <div class="container-label-info-valueMachineHours">
          <div class="square">
            <mat-icon>query_builder</mat-icon>
          </div>
          <span class="text-info-MachineHours">Ore<br>Stimate</span>
        </div>
      </div>


      <!-- ORE DYNAKIT -->
      <div *ngIf="MachineHours.OreDynakit != -1">
        <!-- VALORE NUMERICO -->
        <span class="value-MachineHours">{{GetIntero(MachineHours.OreDynakit)}}</span><span class="symbol-value-MachineHours">H</span>
        <span class="value-MachineHours" *ngIf="getMinutes(MachineHours.OreDynakit) != '0'">{{getMinutes(MachineHours.OreDynakit) | number : '1.0-0'}}<span class="symbol-value-MachineHours">m</span></span>

        <!-- ICONA E LABEL DI INFO VALUE -->
        <div class="container-label-info-valueMachineHours">
          <div class="square">
            <mat-icon>phone_iphone</mat-icon>
          </div>
          <span class="text-info-MachineHours">Ore<br>Dyn@kit</span>
        </div>
      </div>


      <!-- ORE MOTORE -->
      <div *ngIf="MachineHours.OreMacchina != -1">
        <!-- VALORE NUMERICO -->
        <span class="value-MachineHours">{{MachineHours.OreMacchina | number : '1.0-0'}}</span><span class="symbol-value-MachineHours">H</span>
        <span class="value-MachineHours" *ngIf="getMinutes(MachineHours.OreMacchina) != '0'">{{getMinutes(MachineHours.OreMacchina ) | number : '1.0-0'}}<span class="symbol-value-MachineHours">m</span></span>

        <!-- ICONA E LABEL DI INFO VALUE -->
        <div class="container-label-info-valueMachineHours">
          <div class="square">
            <mat-icon>departure_board</mat-icon>
          </div>
          <span class="text-info-MachineHours">Ore<br>Motore</span>
        </div>
      </div>

    </div>

  </div>


  <div id="alert-aggiornamento" *ngIf="MachineHours == null || MachineHours.OreMacchina == -1 || MachineHours.OreDynakit == -1">
    <div class="container-content-alert-aggiornamento">
      <mat-icon style="margin-top: -7px;">warning</mat-icon>
      <span>I dati riepilogativi delle ore calcolate verranno mostrati non appena saranno disponibili</span>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">

  <!-- <button mat-button color="primary" (click)="createPDF()">
    <span class="span-button-pdf">
      <mat-icon>save_alt</mat-icon>
      <span>Esporta</span>
    </span>
  </button> -->

  <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Chiudi</button>

</mat-dialog-actions>

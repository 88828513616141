<div #riferimento>
  <mat-card>
    <mat-card-title class="header">Storico report</mat-card-title>
  </mat-card>
</div>

<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div *ngIf="!isLoadingResults">
  <table mat-table [dataSource]="reportListPaged" class="mat-elevation-z8" style="width: 100%; margin-top: 15px;">

    <ng-container matColumnDef="Nome">
      <th mat-header-cell *matHeaderCellDef> Nome report </th>
      <td mat-cell *matCellDef="let element"> {{element.FileName}} </td>
    </ng-container>

    <ng-container matColumnDef="Data creazione">
      <th mat-header-cell *matHeaderCellDef> Data di creazione </th>
      <td mat-cell *matCellDef="let element"> {{element.DataCreazione | date: "dd/MM/yyyy"}}</td>
    </ng-container>

    <ng-container matColumnDef="Azioni">
      <th mat-header-cell *matHeaderCellDef> Azioni </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button mat-raised-button color="warn" (click)="readFile(element.FileContent, element.FileName)">
          <div class="format-button-icon-and-label">
            <mat-icon>save_alt</mat-icon>
            <span style="padding-top: 2px;">Scarica</span>
          </div>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsCantieri"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsCantieri;"></tr>

  </table>
  <mat-paginator [length]="length"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                (page)="handlePageEvent($event)"
                [pageSizeOptions]="[5, 10, 25, 100]"
                aria-label="Select page">
  </mat-paginator>
</div>



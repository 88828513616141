import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { GoogleMapsModule } from '@angular/google-maps';
import { FilterMachineModel } from 'src/app/model/FilterMachineModel';
import { DynalinkDialogComponent } from 'src/app/components/dynalink-dialog/dynalink-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface selectActive {
  Id: string;
  Description: string;
}

interface selectBanco {
  Id: string;
  Name: string;
}

@Component({
  selector: 'app-machine-page',
  templateUrl: './machine-page.component.html',
  styleUrls: ['./machine-page.component.scss'],
})
export class MachinePageComponent implements OnInit {
  @ViewChild('googleMap') map!: google.maps.Map;
  //@ViewChild(GoogleMap) map!: GoogleMap;
  markers: any[] = [];
  center: any;
  zoom = 0;

  options: google.maps.MapOptions = {
    mapId: "DEMO_MAP_ID",
    zoom: 10,
  };

  constructor(public dialog: MatDialog,
              private apiService: ApiService,
              private snackBar: MatSnackBar,
              private fb: FormBuilder) {}

  pageEvent!: PageEvent;
  @ViewChild(MatSort) sort!: MatSort;

  bancoSource: selectBanco[] = [];

  bancoForm = this.fb.group({
    banco: [''],
  });

  machineNameForm = this.fb.group({
    machineName: '',
  });

  displayedColumns: string[] = [
    'image',
    'Name',
    'model',
    // 'banco',
    'serialnumber',
    'nomeDynakit',
    'dateend',
    'cumulativeHours',
    'SyncDate',
    'cantiere',
    'isvalid',
    'dynakit',
    'isassociate',
    'actions',
    'disponibile',
  ];
  dataSource = [];
  isEdit = false;

  btnEnabled = true;

  Model = '';
  SerialNumber = '';
  Gruppo = '';
  Image = '';
  Dynalink = '';
  ShowVibrazione = false;
  Vibrazione = '';
  ShowFrequenzaHiLo = false;
  FrequenzaHiLo = '';
  ShowAmpiezzaHiLo = false;
  AmpiezzaHiLo = '';
  ShowForzaCentrifugaHiLo = false;
  ForzaCentrifugaHiLo = '';
  ShowVelocitaStesa = false;
  VelocitaStesa = '';
  ShowSpessoreStesaMax = false;
  SpessoreStesaMax = '';
  ShowLarghezzaStesa = false;
  LarghezzaStesa = '';
  ShowBanco = false;
  ShowVibrazioneBanco = false;
  VibrazioneBanco = '';
  ShowFrequenzaHiLoBanco = false;
  FrequenzaHiLoBanco = '';
  ShowAmpiezzaHiLoBanco = false;
  AmpiezzaHiLoBanco = '';
  ShowForzaCentrifugaHiLoBanco = false;
  ForzaCentrifugaHiLoBanco = '';
  ShowTamperingBanco = false;
  Tampering = '';
  TamperingHiLo = '';

  IdMachine = 0;
  timestamp = "";

  isLoadingResults = true;
  pageIndex: number = 0 ;
  length:number = 0;
  pageSize : number = 10;
  sortField : string = "model";
  sortOrder : string = "asc";

  activeFilterId = '0';

  activeFilterSource: selectActive[] = [];

  ngOnInit(): void {
    this.activeFilterSource = [];

    var item: selectActive = { Id: '0', Description: 'Tutti' };
    this.activeFilterSource.push(item);

    var itemAttivi: selectActive = { Id: '1', Description: 'Valido' };
    this.activeFilterSource.push(itemAttivi);

    var itemNonAttivi: selectActive = { Id: '2', Description: 'Non Valido' };
    this.activeFilterSource.push(itemNonAttivi);

    this.GetBanco();
    this.GetMachine();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe((data : any) => {

      this.pageIndex = 0;

      this.sortField = this.sort.active;

      this.sortOrder = this.sort.direction;

      this.GetMachine();

    });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.GetMachine();
  }

  GetMachine(): void {

    this.isLoadingResults = true;

    let dataFilter = new FilterMachineModel(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.activeFilterId);

    this.apiService.GetMachine(dataFilter).subscribe(
      (data) => {
        console.log(data.Data)
        this.dataSource = data.Data;
        this.length = data.Count;

        this.isLoadingResults = false;
      },
      (error: any) => {
        console.log(error);
        this.openSnackBarError(error.message.message);
      }
    );
  }

  GetBanco(): void {

    this.apiService.GetMachineBanco().subscribe(
      (data) => {
        this.bancoSource = [];

        const itemBlank: selectBanco = { Id: "", Name: "" };
        this.bancoSource.push(itemBlank);

        data.forEach((w: selectBanco) => {
          this.bancoSource.push(w);
        });

      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  Edit(element: any): void {
    ////console.log(element);
    this.Image = element.LinkImage;
    this.Model = element.Model;
    this.SerialNumber = element.SerialNumber;

    this.machineNameForm.setValue({
      machineName: element.Name,
    })

    this.IdMachine = element.Id;

    this.bancoForm.setValue({
      banco: element.Banco,
    });

    this.apiService.GetMachineParameterFromModel(element.Model).subscribe(
      (data) => {
        this.Dynalink = data.Dynalink;
        this.Gruppo = data.Gruppo;
        this.ShowVibrazione = data.ShowVibrazione;
        this.Vibrazione = data.Vibrazione;
        this.ShowFrequenzaHiLo = data.ShowFrequenzaHiLo;
        this.FrequenzaHiLo = data.FrequenzaHiLo;
        this.ShowAmpiezzaHiLo = data.ShowAmpiezzaHiLo;
        this.AmpiezzaHiLo = data.AmpiezzaHiLo;
        this.ShowForzaCentrifugaHiLo = data.ShowForzaCentrifugaHiLo;
        this.ForzaCentrifugaHiLo = data.ForzaCentrifugaHiLo;
        this.ShowVelocitaStesa = data.ShowVelocitaStesa;
        this.VelocitaStesa = data.VelocitaStesa;
        this.ShowSpessoreStesaMax = data.ShowSpessoreStesaMax;
        this.SpessoreStesaMax = data.SpessoreStesaMax;
        this.ShowLarghezzaStesa = data.ShowLarghezzaStesa;
        this.LarghezzaStesa = data.LarghezzaStesa;

        this.ShowBanco = false;

        if (data.Gruppo == "FINITRICE") {
          this.ShowBanco = true;
        }

        // Banco
        this.ShowVibrazioneBanco = data.ShowVibrazioneBanco;
        this.VibrazioneBanco = data.VibrazioneBanco;
        this.ShowFrequenzaHiLoBanco = data.ShowFrequenzaHiLoBanco;
        this.FrequenzaHiLoBanco = data.FrequenzaHiLoBanco;
        this.ShowAmpiezzaHiLoBanco = data.ShowAmpiezzaHiLoBanco;
        this.AmpiezzaHiLoBanco = data.AmpiezzaHiLoBanco;
        this.ShowForzaCentrifugaHiLoBanco = data.ShowForzaCentrifugaHiLoBanco;
        this.ForzaCentrifugaHiLoBanco = data.ForzaCentrifugaHiLoBanco;
        this.ShowTamperingBanco = data.ShowTampering;
        this.Tampering = data.Tampering;
        this.TamperingHiLo = data.TamperingHiLo;

        this.isEdit = true;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError("Parametri non disponibili");
      }
    );

    this.GetCoordinate(this.IdMachine);
    //this.GetCoordinate(1);
  }

  Annulla(): void {
    this.isEdit = false;

    this.IdMachine = 0;

    this.GetMachine();
  }

  Read(): void {
    this.apiService.ReadMachine().subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else
        {
          this.openSnackBarOk('Lettura Effettuata');
          this.GetMachine();
        }
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError('Error');
      }
    );
  }

  Salva(): void {

    let banco = this.bancoForm.get('banco')?.value;

    let machineName = this.machineNameForm.get('machineName')?.value;

    this.apiService.UpdateMachineBanco(this.IdMachine, banco, machineName).subscribe(
      (data) => {
        this.isEdit = false;
        this.IdMachine = 0;
        this.GetMachine();
        this.openSnackBarOk('Mezzo modificato');
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error.error.Message);
      }
    );

  }

  onChangeBanco() {
    let banco = this.bancoForm.get('banco')?.value;

    ////console.log(banco);

    this.apiService.GetMachineParameterFromModelBanco(banco).subscribe(
      (data) => {

        this.ShowVibrazioneBanco = data.ShowVibrazione;
        this.VibrazioneBanco = data.Vibrazione;
        this.FrequenzaHiLoBanco = data.FrequenzaHiLo;

        this.ShowAmpiezzaHiLoBanco = data.ShowAmpiezzaHiLoBanco;
        this.AmpiezzaHiLoBanco = data.AmpiezzaHiLoBanco;

        this.ShowForzaCentrifugaHiLoBanco = data.ShowForzaCentrifugaHiLoBanco;
        this.ForzaCentrifugaHiLoBanco = data.ForzaCentrifugaHiLoBanco;

        this.ShowTamperingBanco = data.ShowTampering;
        this.Tampering = data.Tampering;
        this.TamperingHiLo = data.TamperingHiLo;

      },
      (error) => {
        ////console.log(error);

        this.ShowVibrazioneBanco =false;
        this.VibrazioneBanco = '';
        this.FrequenzaHiLoBanco = '';
        this.AmpiezzaHiLoBanco = '';
        this.ForzaCentrifugaHiLoBanco = '';
        this.ShowTamperingBanco = false;
        this.Tampering = '';
        this.TamperingHiLo = '';

      }
    );


  }

  changeToggle(element: any){
    this.apiService.UpdateMachineToggle(element.Id).subscribe(
      () => {},
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error.error.Message);
        this.isLoadingResults = false;
      }
    );
  }


  Test(element: any): void {
    ////console.log(element);

    this.btnEnabled = false;

    this.apiService.ReadSingleMachine(element.Id).subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else
        {
          ////console.log(data.Xml);
           this.dialog.open(DynalinkDialogComponent, { panelClass:'full-width-dialog',  data: data.Xml });
        }

        this.btnEnabled = true;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError('Error');

        this.btnEnabled = true;
      }
    );
  }

  TestFuel(element: any): void {
      ////console.log(element);

      this.btnEnabled = false;

      this.apiService.ReadSingleMachineFuel(element.Id).subscribe(
        (data) => {

          if (data.Errore) {
            this.openSnackBarError(data.Errore);
          }
          else
          {
            ////console.log(data.Xml);
             this.dialog.open(DynalinkDialogComponent, { panelClass:'full-width-dialog',  data: data.Xml });
          }

          this.btnEnabled = true;
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError('Error');

          this.btnEnabled = true;
        }
      );



  }

  TestEngine(element: any): void {
    ////console.log(element);

    this.btnEnabled = false;

    this.apiService.ReadSingleMachineEngine(element.Id).subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else
        {
          ////console.log(data.Xml);
           this.dialog.open(DynalinkDialogComponent, { panelClass:'full-width-dialog',  data: data.Xml });
        }

        this.btnEnabled = true;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError('Error');

        this.btnEnabled = true;
      }
    );



}

GetCoordinate(idMachine: number): void {
  console.log("getPosizioni");

  this.apiService.GetCoordinate(idMachine).subscribe(
    (data) => {
      console.log(data)
      console.log(Number(data.lat))
      console.log(Number(data.lng))
      console.log(Number(data.timestamp))
      if (data.Errore) {
        this.openSnackBarError(data.Errore);
      }
      else
      {
        this.center = {
          lat: Number(data.lat),
          lng: Number(data.lng),
        };

        const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

        this.markers = [{
          position: {
            lat: Number(data.lat),
            lng: Number(data.lng),
          },
          options: {
            animation: google.maps.Animation.DROP,
            icon: image
          }
        }];

        this.options = {
          center: {
            lat: Number(data.lat),
            lng: Number(data.lng),
          },
          zoom: 8
        } 

        // Impostare i confini della mappa
        const southWest = {
          lat: this.center.lat - 0.01,
          lng: this.center.lng - 0.01,
        };
        const northEast = {
          lat: this.center.lat + 0.01,
          lng: this.center.lng + 0.01,
        };

        // Se la mappa è disponibile, impostare i confini
        if (this.map) {
          console.log(southWest);
          console.log(northEast);
          const bounds = new google.maps.LatLngBounds(southWest, northEast);
          this.map.fitBounds(bounds, 3);
        }

        //Formattaqre il timestamp
        const date = new Date(data.timestamp);
        const formattedTimestamp = date.toLocaleString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });

        this.timestamp = "Ultima rilevazione: " + formattedTimestamp;
      }

      this.btnEnabled = true;
    },
    (error) => {
      ////console.log(error);
      this.openSnackBarError('Error');

      this.btnEnabled = true;
    }
  );


    /*

  
    // Impostare una posizione statica
    this.center = {
      lat: 45.466290,
      lng: 9.187252,
    };

    // Impostare marker statici
    const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

    this.markers = [{
      position: {
        lat: 45.466290,
        lng: 9.187252,
      },
      label: {
        color: 'black',
        text: 'Posizione Statica',
      },
      title: 'Posizione Statica',
      options: {
        animation: google.maps.Animation.DROP,
        icon: image
      }
    }];

    // Impostare i confini della mappa
    const southWest = {
      lat: this.center.lat - 0.01,
      lng: this.center.lng - 0.01,
    };
    const northEast = {
      lat: this.center.lat + 0.01,
      lng: this.center.lng + 0.01,
    };

    // Se la mappa è disponibile, impostare i confini
    if (this.map) {
      console.log(southWest);
      console.log(northEast);
      const bounds = new google.maps.LatLngBounds(southWest, northEast);
      this.map.fitBounds(bounds, 3);
    }


    */
}


  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }
}

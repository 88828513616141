import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { PasswordValidator } from 'src/app/services/PasswordValidator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private apiService: ApiService,
    private fb: FormBuilder) { }


  UserId!: string | null
  Token!: string | null
  hide: boolean = true

  resetForm = this.fb.group({
    password: ['', Validators.compose([
      Validators.required,
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#.$^+=!*()@%&]).{8,}$')])],
    confirmPassword: ['']
  }, { validators: PasswordValidator.confirmed("password", "confirmPassword") });

  ngOnInit(): void {
    this.UserId = this.route.snapshot.paramMap.get('UserId');
    this.Token = this.route.snapshot.paramMap.get('Token');
    if (this.UserId == null || this.Token == null) {
      this.openSnackBar("Reset password non dispobile", true)
      this.router.navigate(['/login']);
    }

  }
  resetPassword() {
    var password = this.resetForm.get('password')?.value;
    if (password && this.UserId && this.Token)
      this.apiService.userConfirmResetPassword(this.UserId,password,this.Token).subscribe(
        (data) => {
          this.openSnackBar("Password resettata con successo", false);
          this.router.navigate(['/login']);
        },
        (error) => {
          this.openSnackBar(error.error.Message, true);
          this.router.navigate(['/login']);
        }
      );
  }

  openSnackBar(message: string, isError: boolean) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: [isError ? 'snackClassError' : 'snackClassSuccess'],
      duration: 7000,
    });
  }

}

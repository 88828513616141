<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<!-- FILTRI & NEW OPERATORE-->

<div #riferimento>
  <mat-card *ngIf="!isEdit && !showActions">
    <mat-card-title>Operatori</mat-card-title>
    <mat-card-content>
      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Filtra Attivi</mat-label>
          <mat-select [(ngModel)]="activeFilterId" (selectionChange)="GetOperator()">
            <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Filtra Operatore</mat-label>
          <input
              matInput
              maxlength="100"
              [(ngModel)]="filtername"
            >
        </mat-form-field>

        <button
        mat-stroked-button
        color="primary"
        (click)="GetOperator()"
      >
      <mat-icon>search</mat-icon>
      </button>

      </div>


        <div fxFlex></div>
        <div>
        <button
        mat-stroked-button
        color="primary"
        (click)="Add()"
        *ngIf="!isEdit"
      >
      <mat-icon>add</mat-icon>
      </button>
      </div>

    </mat-card-content>
  </mat-card>
</div>

<!-- TABELLA OPERATORI -->

<div class="table-container mat-elevation-z8" *ngIf="!isEdit && !showActions">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="desc"
    matSortDisableClear
  >
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Data Fine Validità</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ValidityDate | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Operatore</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Name }}
      </td>
    </ng-container>

    <!-- IsValid -->
    <ng-container matColumnDef="isvalid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Valido</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
        <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
      </td>
    </ng-container>

    <!-- Pin -->
    <ng-container matColumnDef="pin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px">Pin</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Pin}}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">Azioni</th>
      <ng-container *matCellDef="let element">
        <td mat-cell>
          <button
            (click)="Edit(element)"
            mat-stroked-button
            color="primary"
            class="margin-action"
            *ngIf="!element.IsStarted"
            matTooltip="Modifica"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            (click)="Delete(element.Id)"
            mat-stroked-button
            color="primary"
            class="margin-action"
            *ngIf="!element.IsStarted"
            matTooltip="Elimina"
          >
            <mat-icon>delete</mat-icon>
          </button>

          <button
            (click)="Info(element)"
            mat-stroked-button
            color="primary"
            class="margin-action"
            *ngIf="!element.IsStarted"
            matTooltip="Informazioni"
          >
            <mat-icon>info</mat-icon>
          </button>

        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<mat-paginator
*ngIf="!isEdit && !showActions"
  [pageSizeOptions]="[5, 10, 15, 50]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
></mat-paginator>


<!-- BOX ANAGRAFICA -->

<mat-card *ngIf="isEdit || isNew || showActions" class="box_anagrafica" [ngClass]="{'box_fullSize':  riferimento.offsetWidth < 1251 }">

  <mat-card-title class="title-box"><mat-icon>library_books</mat-icon><span>Anagrafica</span></mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <form [formGroup]="operatorForm">
      <div class="edit-container">

        <mat-form-field appearance="fill" class="edit-full-width">
          <mat-label>Cognome e Nome</mat-label>
          <input
            matInput
            maxlength="100"
            [readonly]="showActions"
            placeholder="Cognome/Nome"
            formControlName="name"
          >
        </mat-form-field>
        <p>

        <mat-form-field appearance="fill" style="width: 44%;">
          <mat-label>Data Fine Validità</mat-label>
          <input
            matInput
            [readonly]="showActions"
            [matDatepicker]="picker"
            formControlName="date"
          />
          <mat-datepicker-toggle *ngIf="isEdit"
            matSuffix
            [readonly]="showActions"
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="!isNew" style="width: 45.5%;">
          <mat-label>Pin</mat-label>
          <input
            matInput
            readonly
            formControlName="pin"
          />

        </mat-form-field>
        </p>

       </div>

    </form>

    <mat-divider [inset]="true" *ngIf="isEdit || isNew"></mat-divider>

    <div style="text-align: right;" *ngIf="isEdit || isNew">
      <button
        [disabled]="!operatorForm.valid"
        mat-stroked-button
        color="primary"
        class="btn"
        (click)="Salva()">
        <mat-icon>check</mat-icon>
      </button>
      <button
        mat-stroked-button
        color="warn"
        class="btn"
        (click)="Annulla()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

  </mat-card-content>
</mat-card>


<!-- BOX CANTIERI  -->

<mat-card *ngIf="showActions" class="box_cantieri" [ngClass]="{'box_fullSize':  riferimento.offsetWidth < 1485, 'box_alert': length == 0}">

  <mat-card-title class="title-box"><mat-icon>assignment</mat-icon><span>Cantieri assegnati</span></mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <!-- FILTRI  -->

    <div class="edit-container" style="margin-top: 25px;">
      <mat-form-field appearance="fill">
        <mat-label>Filtra Attivi</mat-label>
        <mat-select
          [(ngModel)]="activeFilterId"
          (selectionChange)="GetWorkSite()"
        >
          <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Filtra Capo Cantieri</mat-label>
        <mat-select
          [(ngModel)]="bossFilterId"
          (selectionChange)="GetWorkSite()"
        >
          <mat-option *ngFor="let ws of bossFilterSource" [value]="ws.UserId">
            {{ ws.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>


    <!-- ALERT NESSUN CANTIERE TROVATO -->

    <mat-card id="alert-card" *ngIf="length == 0">
      <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
      <p id="alert-text">Nessun cantiere trovato</p>
    </mat-card>


    <!-- TABELLA -->

    <div class="table-container mat-elevation-z8" *ngIf="length != 0">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="datestart"
        matSortDirection="asc"
        matSortDisableClear
      >

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
        </ng-container>

        <!-- DateStart Column -->
        <ng-container matColumnDef="datestart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Inizio</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 8px;">
            {{ element.DateStart | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- DateEnd Column -->
        <ng-container matColumnDef="dateend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Fine</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 8px;">
            {{ element.DateEnd | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Descrizione</th>
          <td mat-cell *matCellDef="let element">
            <span class="truncate-text">{{ element.Description }}</span>
          </td>
        </ng-container>

        <!-- Client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Committente</th>
          <td mat-cell *matCellDef="let element">{{ element.Client }}</td>
        </ng-container>

        <!-- Indirizzo -->
        <ng-container matColumnDef="Indirizzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Indirizzo</th>
          <td mat-cell *matCellDef="let element">{{ element.Indirizzo }}</td>
        </ng-container>

        <!-- Boss id -->
        <ng-container matColumnDef="bossid">
          <th mat-header-cell *matHeaderCellDef>Capocantiere Id</th>
          <td mat-cell *matCellDef="let element">{{ element.BossId }}</td>
        </ng-container>

        <!-- Boss Name Column -->
        <ng-container matColumnDef="bossname">
          <th mat-header-cell *matHeaderCellDef class="columns-title">Capocantiere</th>
          <td mat-cell *matCellDef="let element">{{ element.BossName }}</td>
        </ng-container>

        <!-- IsActive Column -->
        <ng-container matColumnDef="isactive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Attivo</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsActive" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.IsActive" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- IsActive Column -->
        <ng-container matColumnDef="commessa" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Commessa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.CodiceCommessa }}
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions"  stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="columns-title">Azioni</th>
          <ng-container *matCellDef="let element">
            <td mat-cell>
              <button
                  (click)="Dashboard(element.Id)"
                  mat-stroked-button
                  color="primary"
                  matTooltip="Visualizza dashboard">
                <mat-icon>timeline</mat-icon>
              </button>
            </td>
          </ng-container>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="report">
          <th mat-header-cell *matHeaderCellDef>Report</th>
          <ng-container *matCellDef="let element">
            <td mat-cell>

            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsInfo"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsInfo"></tr>
      </table>
    </div>

    <mat-paginator *ngIf="length != 0"
      [pageSizeOptions]="[10]"
      (page)="handlePageEvent($event)"
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      showFirstLastButtons>
    </mat-paginator>

  </mat-card-content>
</mat-card>


<!-- BOX BOTTONI -->

<div class="conteiner-button" *ngIf="showActions">

  <mat-divider [style.width.px]="riferimento.offsetWidth - 18" style="min-width: 566px;"></mat-divider>

  <button mat-stroked-button color="warn" class="button-check" (click)="Annulla()"><mat-icon>close</mat-icon></button>

</div>

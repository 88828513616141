<div #riferimento class="page-container">

  <div *ngIf="globals.isAuthorized">
    <header>
      <div>
        <button mat-button class="menu-button" (click)="sidenavToggle()" style="display: inline-block;">
          <mat-icon>{{ isMenuOpen ? "menu_open" : "menu" }}</mat-icon>
        </button>

        <!-- TITOLO CLICCABILE -->

        <a *ngIf="globals.role == 'admin'" [routerLink]="'/home-admin'" style="display: inline-block;">
          <span>{{ title }}</span>
        </a>

        <a *ngIf="globals.showFirstPage" [routerLink]="'/firstpage'" style="display: inline-block;">
          <span>{{ title }}</span>
        </a>

        <a *ngIf="globals.role == 'user'" [routerLink]="'/management'" style="display: inline-block;">
          <span>{{ title }}</span>
        </a>
        <!-- ----------------- -->

      </div>


      <!-- MENU A TENDINA UTENTE -->

      <div>
        {{ globals.name }}
        <button mat-icon-button [matMenuTriggerFor]="menu" class="button-user">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item [routerLink]="'/profile'">
            <mat-icon>account_circle</mat-icon> Account
          </button>
          <button mat-menu-item (click)="logOff()">
            <mat-icon>exit_to_app</mat-icon> Esci
          </button>
        </mat-menu>
      </div>

    </header>

  </div>
</div>

<!-- VOCI DEL MENU -->

<mat-sidenav-container class="sidenav-container" >
  <mat-sidenav *ngIf="globals.isAuthorized" #sidenav [mode]="isTablet ? 'over' : 'side'" [(opened)]="isMenuOpen"
    class="sidenav" fixedInViewport="true" [ngStyle]="{'margin-top.px': riferimento.offsetHeight}">

    <a *ngIf="globals.showAdminHome" mat-button (click)="routeTo('/home-admin')">
      <mat-icon><span class="material-icons">home</span></mat-icon> Home
    </a>

    <a *ngIf="globals.showSupervisorHome" mat-button (click)="routeTo('/home-supervisor')">
      <mat-icon><span class="material-icons">home</span></mat-icon> Home
    </a>

    <a *ngIf="globals.showDashBoard" mat-button (click)="showDashboardMenu()">
      <mat-icon>dashboard</mat-icon> Dashboard
    </a>
    <a *ngIf="globals.showDashBoardCantiere && globals.showDashboardMenu" mat-button class="DropdownItem"
      (click)="routeTo('/dashboardCantiere')">
      <mat-icon>timeline</mat-icon> Cantiere
    </a>
    <a *ngIf="globals.showDashBoardMezzo && globals.showDashboardMenu" mat-button class="DropdownItem"
      (click)="routeTo('/dashboardMezzo')">
      <mat-icon>timeline</mat-icon> Mezzo
    </a>

    <a *ngIf="globals.showJob" mat-button (click)="routeTo('/management')">
      <mat-icon>work</mat-icon> Gestione
    </a>

    <a *ngIf="globals.showAnagrafica" mat-button (click)="showAnagraficaMenu()">
      <mat-icon>library_books</mat-icon> Anagrafica
    </a>
    <a *ngIf="globals.showMachinePowerUser && globals.showAnagraficaMenu" mat-button class="DropdownItem"
      (click)="routeTo('/worksite')">
      <mat-icon>construction</mat-icon> Cantieri
    </a>
    <a *ngIf="globals.showMachinePowerUser && globals.showAnagraficaMenu" mat-button class="DropdownItem"
      (click)="routeTo('/machine')">
      <mat-icon>directions_car</mat-icon> Mezzi
    </a>
    <a *ngIf="globals.showUsersPowerUser && globals.showAnagraficaMenu" mat-button class="DropdownItem"
      (click)="routeTo('/users')">
      <mat-icon>supervised_user_circle</mat-icon> Capocantieri
    </a>
    <a *ngIf="globals.showOperator && globals.showAnagraficaMenu" mat-button class="DropdownItem"
      (click)="routeTo('/operator')">
      <mat-icon>people</mat-icon> Operatori
    </a>
    <a *ngIf="globals.showAnagraficaDynakit && globals.showAnagraficaMenu" mat-button class="DropdownItem"
      (click)="routeTo('/anagraficaDynaKit')">
      <mat-icon> phonelink_ring</mat-icon> Dyn@kit
    </a>

    <a *ngIf="globals.showUsersAdmin" mat-button (click)="routeTo('/users-admin')">
      <mat-icon>supervised_user_circle</mat-icon> Clienti
    </a>

    <a *ngIf="globals.showDynakit" mat-button (click)="routeTo('/dynakit')">
      <mat-icon><span class="material-icons">smartphone</span></mat-icon> Dyn@kit
    </a>

    <a *ngIf="globals.showParameter" mat-button (click)="routeTo('/parameter')">
      <mat-icon>tune</mat-icon> Parametri Modelli
    </a>

    <a *ngIf="globals.showCustomReport" mat-button (click)="showReportMenu()">
      <mat-icon>description</mat-icon> Report
    </a>
    <a *ngIf="globals.showCustomReport && globals.showReportMenu" mat-button class="DropdownItem"
      (click)="routeTo('/GeneraReport')">
      <mat-icon>add_box</mat-icon> Genera
    </a>
    <a *ngIf="globals.showCustomReport && globals.showReportMenu" mat-button class="DropdownItem"
      (click)="routeTo('/StoricoReport')">
      <mat-icon>archive</mat-icon> Storico
    </a>


    <a *ngIf="globals.showHelp" mat-button (click)="routeTo('/help')">
      <mat-icon>help</mat-icon> Assistenza
    </a>
    <div *ngIf="globals.isAuthorized" class="footer">
      <img src="../assets/img/splash.png" style="width: 100%;">
    </div>

    <!-- VECCHIE VOCI DI MENU -->
    <!-- <a *ngIf="globals.showMachinePowerUser" mat-button [routerLink]="'/worksite'">
    <mat-icon>construction</mat-icon> Cantieri
  </a>
  <a *ngIf="globals.showAempConfiguration" mat-button [routerLink]="'/job'">
    <mat-icon>work</mat-icon> Lavori
  </a>
  <a *ngIf="globals.showMachinePowerUser" mat-button [routerLink]="'/machine'">
    <mat-icon>directions_car</mat-icon> Mezzi
  </a>
  <a *ngIf="globals.showAempConfiguration" mat-button [routerLink]="'/configuration'">
    <mat-icon>settings</mat-icon> Configurazione
  </a>
  <a *ngIf="globals.showDynakit" mat-button [routerLink]="'/dynakit'"> <mat-icon><span class="material-icons">smartphone</span></mat-icon> Dyn@kit </a>
  <a *ngIf="globals.showJob" mat-button [routerLink]="'/job'"> <mat-icon>work</mat-icon> Lavori </a>
  <a *ngIf="globals.showTestJob" mat-button [routerLink]="'/jobtest'"> Lavori Test</a>
  <a *ngIf="globals.showUsersPowerUser" mat-button [routerLink]="'/users'">
    <mat-icon>supervised_user_circle</mat-icon>Capo cantieri
  </a>
  <a *ngIf="globals.showReport" mat-button [routerLink]="'/report'">
    <mat-icon>receipt</mat-icon>Report
  </a>
  <a *ngIf="globals.showUsersAdmin" mat-button [routerLink]="'/users-admin'">
    <mat-icon>supervised_user_circle</mat-icon> Clienti
  </a>

  <a *ngIf="globals.showOperator" mat-button [routerLink]="'/operator'">
    <mat-icon>people</mat-icon> Operatori
  </a>
  <a *ngIf="globals.showProfile" mat-button [routerLink]="'/profile'">
    <mat-icon>account_circle</mat-icon> Profilo
  </a>
  <a *ngIf="globals.showHelp" mat-button [routerLink]="'/help'">
    <mat-icon>help</mat-icon>Assistenza
  </a> -->
    <!-- -------------------- -->

  </mat-sidenav>


  <mat-sidenav-content class="fooDiv">
    <main class="main-content">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

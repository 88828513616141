import { GeneraReportComponent } from './components/Report-Components/GeneraReport/GeneraReport.component';
import { StoricoReportComponent } from './components/Report-Components/StoricoReport/StoricoReport.component';

import { DialogConfermaComponent } from './components/Dialog-conferma/Dialog-conferma.component';
import { DashboardMezzoComponent } from './components/Dashboard-Mezzo/Dashboard-Mezzo.component';
import { DashboardCantiereComponent } from './components/Dashboard-Cantiere/Dashboard-Cantiere.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Globals } from './globals';

import { LoginPageComponent } from './components/login-page/login-page.component';
import { RegistrationPageComponent } from './components/registration-page/registration-page.component';
import { FirstPageComponent } from './components/first-page/first-page.component';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { UsersAdminPageComponent } from './components/users-admin-page/users-admin-page.component';
import { UsersPageComponent } from './components/capocantiere-page/users-page.component';
import { MachinePageComponent } from './components/machine-page/machine-page.component';
import { WorksitePageComponent } from './components/worksite-page/worksite-page.component';
import { OperatorPageComponent } from './components/operator-page/operator-page.component';
import { JobPageComponent } from './components/job-page/job-page.component';
import { DynakitPageComponent } from './components/dynakit-page/dynakit-page.component';
import { JobDialogComponent } from './components/job-dialog-page/job-dialog-page.component';
import { JobEngagedDialogComponent } from './components/job-engaged-dialog/job-engaged-dialog.component';
import { DynalinkDialogComponent } from './components/dynalink-dialog/dynalink-dialog.component';
import { ReportPageComponent } from './components/report-page/report-page.component';
import { AssistenzaPageComponent } from './components/assistenza-page/assistenza-page.component';
import { ConfigurationPageComponent } from './components/configuration-page/configuration-page.component';
import { ParameterPageComponent } from './components/parameter-page/parameter-page.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.intercerptor';
import { AngularMaterialModule } from './angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgxEchartsModule } from 'ngx-echarts';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ManagementPageComponent } from './components/management-page/management-page.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component'
import { ButtonModule } from 'primeng/button';
import { HomeAdminComponent } from './components/home-admin/home-admin.component';
import { HomeSupervisorComponent } from './components/home-supervisor/home-supervisor.component';

import { DatePipe } from '@angular/common';
import { AnagraficaDynaKitComponent } from './components/AnagraficaDynaKit/AnagraficaDynaKit.component';
// import { GeneratePDFService } from './services/PDF_SERVICE/Generate-PDF.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomeAdminComponent,
    HomeSupervisorComponent,
    RegistrationPageComponent,
    FirstPageComponent,
    DashboardPageComponent,
    DashboardCantiereComponent,
    DashboardMezzoComponent,
    UsersAdminPageComponent,
    UsersPageComponent,
    MachinePageComponent,
    WorksitePageComponent,
    OperatorPageComponent,
    JobPageComponent,
    DynakitPageComponent,
    JobDialogComponent,
    JobEngagedDialogComponent,
    DynalinkDialogComponent,
    ReportPageComponent,
    AssistenzaPageComponent,
    ConfigurationPageComponent,
    ParameterPageComponent,
    ProfilePageComponent,
    ManagementPageComponent,
    ResetPasswordComponent,
    DialogConfermaComponent,
    StoricoReportComponent,
    GeneraReportComponent,
    AnagraficaDynaKitComponent,
 ],

  entryComponents: [DialogConfermaComponent],

  imports: [
    ButtonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
      }
    ),
    AngularSvgIconModule.forRoot(),
    MatNativeDateModule,
    NgbModule,
    //  NgxEchartsModule.forRoot({
    //    echarts: () => import('echarts')
    //  })
  ],
  providers: [

    DatePipe,
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
     useValue: {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center'},
     },
     Globals,
     { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },

  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

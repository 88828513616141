export class FilterWorkSiteModel {

  constructor(
    public page: number,
    public itempage: number,
    public sort: string,
    public sortorder: string,
    public filteractiveid?: string,
    public filterbossid? : string,
    public idOperator?: string,
    public idUser?: string,
  ) {  }
}

<!-- CARD BENVENUTO -->

<mat-card id="card-benvenuto">
  <mat-card-title>
    Benvenuto {{globals.name}}
  </mat-card-title>
  <img mat-card-image id="arrow" src="assets\img\ArrowFirstPage.png">
  <img mat-card-image id="logo" src="assets\img\Dynapac_Logo.png">
</mat-card>

<!-- BOX PANORAMICHE -->

<div *ngIf="chartload">

  <mat-card class="box">
    <mat-card-title class="title-box"><mat-icon >collections_bookmark</mat-icon><span>Panoramica generale ODL</span></mat-card-title>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div class="paragrafo-info">
        <p>Ordini assegnati: {{ODL_assegnati}}</p>
        <p>Ordini conclusi: {{ODL[0].value}}</p>
        <p>Ordini non avviati: {{ODL[1].value}}</p>
        <p>Ordini in svolgimento: {{ODL[2].value}}</p>
      </div>

      <mat-divider id="vertical-divider"></mat-divider>

      <div class="grafico" *ngIf="ODL_assegnati != 0">
        <ngx-charts-pie-chart
        [view]="view"
        [results]="ODL"
        [arcWidth]="arcWidth"
        [gradient]="gradient"
        [legendTitle]="title"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut"
        [tooltipDisabled]="tooltipDisabled"
        [labels]="labels"
        [scheme]="colorScheme1"
        >
        </ngx-charts-pie-chart>
      </div>

      <mat-card id="alert-card-piechart" *ngIf="ODL_assegnati == 0">
        <mat-icon style="font-size: 43px; margin-top: 5px; margin-left: -25px;">warning</mat-icon>
        <p id="alert-text-piechart">Dati insufficienti per la generazione del grafico</p>
      </mat-card>

    </mat-card-content>

  </mat-card>

  <mat-card class="box">
    <mat-card-title class="title-box"><mat-icon>collections_bookmark</mat-icon><span>Panoramica generale dei cantieri</span></mat-card-title>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div class="paragrafo-info">
        <p>Cantieri registrati: {{Cantieri_registrati}}</p>
        <p>Cantieri chiusi: {{Cantieri[0].value}}</p>
        <p>Cantieri aperti: {{Cantieri[1].value}}</p>

      </div>

      <mat-divider id="vertical-divider"></mat-divider>

      <!-- PROVVISORIO - SOSTITUIRE CON DATI VERI -->
      <div class="grafico" style="width: 250px; margin-left: 230px;" *ngIf="Cantieri_registrati != 0">
        <ngx-charts-pie-chart
        [view]="view"
        [results]="Cantieri"
        [arcWidth]="arcWidth"
        [gradient]="gradient"
        [legendTitle]="title"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut"
        [scheme]="colorScheme1"
        >
      </ngx-charts-pie-chart>

      </div>

      <mat-card id="alert-card-piechart" *ngIf="Cantieri_registrati == 0">
        <mat-icon style="font-size: 43px; margin-top: 5px; margin-left: -25px;">warning</mat-icon>
        <p id="alert-text-piechart">Dati insufficienti per la generazione del grafico</p>
      </mat-card>

    </mat-card-content>
  </mat-card>

  <mat-card class="box">
    <mat-card-title class="title-box"><mat-icon >collections_bookmark</mat-icon><span>Panoramica generale dei mezzi</span></mat-card-title>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div class="paragrafo-info">
        <p>Mezzi registrati: {{Mezzi_registrati}}</p>
        <p>Mezzi impiegati: {{Mezzi[0].value}}</p>
        <p>Mezzi fermi: {{Mezzi[1].value}}</p>
      </div>

      <mat-divider id="vertical-divider"></mat-divider>

      <!-- PROVVISORIO - SOSTITUIRE CON DATI VERI -->
      <div class="grafico" style="width: 250px; margin-left: 230px;" *ngIf="Mezzi_registrati != 0">
        <ngx-charts-pie-chart
        [view]="view"
        [results]="Mezzi"
        [arcWidth]="arcWidth"
        [gradient]="gradient"
        [legendTitle]="title"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut"
        [scheme]="colorScheme1"
        >
      </ngx-charts-pie-chart>

      </div>

      <mat-card id="alert-card-piechart" *ngIf="Mezzi_registrati == 0">
        <mat-icon style="font-size: 43px; margin-top: 5px; margin-left: -25px;">warning</mat-icon>
        <p id="alert-text-piechart">Dati insufficienti per la generazione del grafico</p>
      </mat-card>

    </mat-card-content>
  </mat-card>

</div>








import { Component, OnInit } from '@angular/core';
import { Globals} from '../../globals';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { MachineHours_Activity } from '../../data';
import { MachineHours } from '../../data';
import { ApiService } from 'src/app/services/api.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { FilterMachineAssociateModel } from 'src/app/model/FilterMachineAssociateModel';
import { PageEvent } from '@angular/material/paginator';
import { number } from 'echarts';

interface selectMachine {
  Name: string;
  SerialNumber: string;
}

@Component({
  selector: 'app-Dashboard-Cantiere',
  templateUrl: './Dashboard-Cantiere.component.html',
  styleUrls: ['./Dashboard-Cantiere.component.scss']
})
export class DashboardCantiereComponent implements OnInit {

  temp : boolean = true;

   worksitelist: any[] = [];
   idWorksite = 0;
   singleworksite: any[] = [];
   dataListBarChart: any[] = [];
   motore = 0
   dynakit = 0
   previste = 0

  // BOX ANAGRAFICA

  checked = true
  positionOptions: TooltipPosition = "above"

  name = ''
  data_inizio = ''
  data_fine = ''
  commessa = ''
  committente = ''
  capocantiere = ''
  indirizzo = ''
  note = ''


  // GRAFICO A TORTA - BOX PANORAMICA

  ODL_tot_cantiere = 0
  ODL_conclu_cantiere = 0
  ODL_attesa = 0


  globals: Globals;
  ODL_panoramica: any[] = [];
  MachineHours: any[] = [];
  MachineHours_Activity: any[] = []
  view!: [number, number];

  macchine: any[] = [];
  machineName = ""

  // options
  tooltipDisabled : boolean = false;
  labels : boolean = false;
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  arcWidth: number = 0.45;
  legendPosition: any = 'below';
  title: string = ""

  colorScheme: Color =
  {
    domain: ['#004162', '#ff8000', '#3CB371', '#61899D'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };


  // GRAFICI A BARRE ORIZZONTALI

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegendBarChart: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  legendPositionBarChart: any = "below";
  showGridLines: boolean = true

  colorSchemeBarChart: Color =
  {
    domain: ['#3CB371','#004162', '#EB861B'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };

  // GRAFICI A BARRE ORIZZONTALI A GRUPPI

  groupPadding: number = 35

  constructor(globals: Globals, private apiService: ApiService, private router: Router, private route: ActivatedRoute) {
    this.globals = globals;
    Object.assign(this, { this:this.ODL_panoramica });
    Object.assign(this, { MachineHours });
    Object.assign(this, { MachineHours_Activity });
  }

  //BOX MEZZI
  isLoadingResults = true

  dataSourceMezzi = [];
  pageIndex: number = 0;
  pageSize: number = 10;

  pageSizeMezzi: number = 5;
  pageIndexMezzi: number = 0;
  length = 0;
  lengthMezzi = 0;

  sortFieldMezzi: string = 'model';
  sortOrderMezzi: string = 'asc';

  activeFilterId = '0';

  displayedColumns: string[] = [
    'code',
    'datestart',
    'dateend',
    'worksite',
    'machine',
    'operator',
    'description',
    'stato',
    'tipolavoro',
    'actions',
    'dynakit'
  ];

  displayedColumnsMezzi: string[] = [
    'image',
    //'IsAcceso',
    'name',
    'model',
    'serialnumber',
    'cumulativeHours',
    'ODLAssegnati',
    'ODLCompletati',
  ];

  ngOnInit(): void{
    if (this.route.snapshot.paramMap.get('IdWorkSite') != null && this.idWorksite == 0){
      this.idWorksite = Number(this.route.snapshot.paramMap.get('IdWorkSite'));
    }

    this.GetAllWorkSite()
    this.macchine = []
    this.machineName = ""

    if (this.idWorksite != 0) {

      if(this.worksitelist != []){
        this.GetSingleworksite()
        this.ODL_tot_cantiere = 1
        this.GetDataChart(this.idWorksite)
        this.GetDataBarchart(this.idWorksite)
        this.GetMachineStorico()
      }
    }

  }

  GetAllWorkSite(): void {
    this.apiService.GetAllWorkSite().subscribe(
      (data) => {
        this.worksitelist = data
        if(this.idWorksite != 0){
          this.GetSingleworksite()
          this.ODL_tot_cantiere = 1
          this.GetDataChart(this.idWorksite)
          this.GetDataBarchart(this.idWorksite)
          this.GetMachineStorico()
        }
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetSingleworksite(): void {
    ////console.log(this.worksitelist)
    var found = false;
      var i = 0;
      while (!found && i < this.worksitelist.length) {
        if (this.worksitelist[i].Id == this.idWorksite)
          found = true;
        else
          i++;
      }
      this.name = this.worksitelist[i].Description;

      var veraDataInizio = new Date(this.worksitelist[i].DateStart);

      var veraDataFine = new Date(this.worksitelist[i].DateEnd);

      this.data_inizio = veraDataInizio.getDate() + "/" + (veraDataInizio.getMonth() + 1) + "/" + veraDataInizio.getFullYear();

      this.data_fine = veraDataFine.getDate() + "/" + (veraDataFine.getMonth() + 1) + "/" + veraDataFine.getFullYear();

      this.commessa = this.worksitelist[i].CodiceCommessa;
      this.committente = this.worksitelist[i].Client;
      this.capocantiere = this.worksitelist[i].BossName;
      this.indirizzo = this.worksitelist[i].Indirizzo;
      this.note = this.worksitelist[i].DescriptionExtended;
  }

  GetDataChart(idWorksite: number): void{
    this.apiService.GetDataPieChart(idWorksite).subscribe(
      (data) => {
        this.ODL_panoramica = data.ODL

        this.ODL_tot_cantiere = data.ODL[0].value + data.ODL[1].value + data.ODL[2].value
        this.ODL_conclu_cantiere = data.ODL[0].value

      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetDataBarchart(idWorksite: number): void{
    this.apiService.GetJobs(idWorksite).subscribe(
      (data) => {
        this.dataListBarChart = data
        //CON RELATIVE ORE DYN@KIT, MOTORE E STIMATE
        for (let i = 0; i < data.length; i++) {
          var macchina: selectMachine = { Name: data[i].Name, SerialNumber: data[i].SerialNumber };
          var find = false
          var y = 0

          while(!find && y < this.macchine.length){
            if(this.macchine[y].SerialNumber == macchina.SerialNumber){
              find = true
            }
            y++
          }
          if(!find){
            this.macchine.push(macchina)
          }
        }
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  DataBarChart(){
    var SerialNumber = this.machineName.slice((this.machineName.length - 17), this.machineName.length)
    this.apiService.GetBarGroup(SerialNumber, this.idWorksite).subscribe(
      (data) => {
        this.MachineHours = data
      },
      (error) => {
        ////console.log(error);
      }
    );

    this.GetDataBarGroupChart(SerialNumber)
  }

  GetDataBarGroupChart(SerialNumber: string): void{
    this.apiService.GetDataBarGroup(SerialNumber, this.idWorksite).subscribe(
      (data) => {
        this.MachineHours_Activity = data.Dati
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetMachineStorico(): void {

    this.isLoadingResults = true;

    ////////console.log(dataFilter);
    this.apiService.GetMachineStorico(this.idWorksite.toString()).subscribe(
      (data) => {

        this.dataSourceMezzi = data;

      },
      (error) => {
        ////////console.log(error);
        // this.openSnackBarError('Error');
      }
    );
  }

}


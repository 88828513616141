import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as fileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-assistenza',
  templateUrl: './assistenza-page.component.html',
  styleUrls: ['./assistenza-page.component.scss']
})
export class AssistenzaPageComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
  ) { }

  isLoadingResults = true;

  aggiornamentoKit: any = false
  aggiornamentoMan: any = true

  tipologieSegnalazioni: any = [
    {
      name: "Problemi di accesso",
      code: 1
    },
    {
      name: "Anomalia al palmare",
      code: 2
    },
    {
      name: "Difficoltà nell'utilizzo",
      code: 3
    },
    {
      name: "Richiesta di informazioni",
      code: 4
    },
    {
      name: "Richiesta di supporto",
      code: 5
    },
    {
      name: "Richiesta di documentazione",
      code: 5
    },
    {
      name: "Problemi di ricezione delle email",
      code: 6
    },
    {
      name: "Dichiarazione codici Dyn@Kit",
      code: 7
    },
    {
      name: "Suggerimenti",
      code: 8
    },
  ]

  tipologia: any
  body: any = ''

  ngOnInit(): void {
  }

  DownloadManuale(): void {
    this.isLoadingResults = true;

     this.apiService.DownloadManuale()
       .subscribe((response: any) => {
         let blob:any = new Blob([response], { type: 'application/pdf' });
     	  fileSaver.saveAs(blob, 'Manuale web Dyn@production.pdf');
         this.isLoadingResults = false;
       }),
       (error: any) => {
         ////console.log('Error downloading the file');
         this.isLoadingResults = false;
       };
  }

  DownloadManualeTelefono(): void {
    this.isLoadingResults = true;

     this.apiService.DownloadManualeTelefono()
       .subscribe((response: any) => {
         let blob:any = new Blob([response], { type: 'application/pdf' });
     	  fileSaver.saveAs(blob, 'Guida alla configurazione di Athesi AP5702.pdf');
         this.isLoadingResults = false;
       }),
       (error: any) => {
         ////console.log('Error downloading the file');
         this.isLoadingResults = false;
       };
  }

  DownloadManualeDynakit(): void {
    this.isLoadingResults = true;

     this.apiService.DownloadManualeDynakit()
       .subscribe((response: any) => {
         let blob:any = new Blob([response], { type: 'application/pdf' });
     	  fileSaver.saveAs(blob, 'Manuale Dyn@kit.pdf');
         this.isLoadingResults = false;
       }),
       (error: any) => {
         ////console.log('Error downloading the file');
         this.isLoadingResults = false;
       };
  }

  textChange(body: any){
    this.body = body
  }

  sendSegnalazione(body: any, tipologia: any){
    this.apiService.sendSegnalazione(body, tipologia).subscribe(
      (data: any) => {
        this.openSnackBarOk('Segnalazione inviata correttamente');
        this.body = ''
        this.tipologia = undefined
      },
      (error: any) => {
        this.openSnackBarError('Impossibile inviare la segnalazione, contatta +39 02 92108836');
      }
    );
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

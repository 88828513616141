import { DateAdapter } from '@angular/material/core';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Globals} from '../../globals';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { dataTool } from 'echarts';

@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.scss']
})
export class FirstPageComponent {

  globals: Globals;
  ODL: any[] = [];
  Cantieri: any[] = [];
  Mezzi: any[] = [];
  view!: [number, number];

  ODL_assegnati = 0;
  Cantieri_registrati = 0;
  Mezzi_registrati = 0;

  chartload: boolean = false;

  // options
  tooltipDisabled : boolean = false;
  labels : boolean = false;
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  arcWidth: number = 0.45;
  legendPosition: any = 'below';
  title: string = ""

  colorScheme: Color =
  {
    domain: ['#004162', '#ff8000', '#d5cfcf', '#61899D'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };

  colorScheme1: Color =
  {
    domain: ['#3CB371','#004162', '#EB861B'],
    group: ScaleType.Ordinal,
    selectable: false,
    name: 'Customer Usage',
  };

  constructor(private apiService: ApiService, globals: Globals) {
    this.globals = globals;
    Object.assign(this, { this:this.ODL });
    Object.assign(this, { this:this.Cantieri });
    Object.assign(this, { this:this.Mezzi });
  }

  GetDataChart(): void{

    //start loading overlay

    this.apiService.Chartfirstpage().subscribe(
      (data) => {

        this.ODL = data.ODL
        this.Cantieri = data.Cantieri
        this.Mezzi = data.Mezzi

        this.ODL_assegnati = data.ODL[0].value + data.ODL[1].value + data.ODL[2].value
        this.Cantieri_registrati = data.Cantieri[0].value + data.Cantieri[1].value
        this.Mezzi_registrati = data.Mezzi[0].value + data.Mezzi[1].value

        this.chartload = true;
      },
      (error) => {
        //stop loading overlay
        ////console.log(error);
      });
  }

  ngOnInit(): void{
    this.GetDataChart()
  }

  onSelect(data: any): void {
    ////console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    ////console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    ////console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}

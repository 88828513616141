<div #riferimento>
  <mat-card>
    <mat-card-title class="header">Genera report</mat-card-title>
  </mat-card>
</div>

<mat-card class="stepper-container">
  <mat-stepper [linear]="isLinear" #stepper>

    <!-- PRIMO STEP -->

    <mat-step label="Scegli la tipologia">

      <div class="container-title-step">
        <mat-icon style="margin-top: 2px;">description</mat-icon>
        <span class="titolo-tab">Scegli la tipologia del report</span>
      </div>

      <mat-radio-group class="container-checkBox" [(ngModel)]="tipologia">

        <mat-radio-button [value]="tipologia" *ngFor="let tipologia of tipologie">{{tipologia}}</mat-radio-button>

      </mat-radio-group>

      <div class="container-button-action-stepper">
        <button mat-button matStepperNext mat-raised-button color="warn" [disabled]="tipologia == ''">Avanti</button>
      </div>

    </mat-step>


    <!-- SECONDO STEP -->

    <mat-step label="Scegli i filtri">

      <div *ngIf="tipologia == 'Cantiere'">

        <div class="container-title-step">
          <mat-icon>done_all</mat-icon>
          <span class="titolo-tab">Scegli i cantieri per la generazione del report</span>
        </div>


        <!-- SELEZIONI CANTIERI -->

        <mat-form-field appearance="fill">
          <mat-label>Cantieri</mat-label>
          <mat-select [formControl]="cantieriSelezionati" multiple>
            <mat-option *ngFor="let cantieriSelezionati of cantieri" [value]="cantieriSelezionati">{{cantieriSelezionati.Description}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-card *ngIf="cantieriSelezionati.value != ''">
          <mat-card-title>Cantieri selezionati</mat-card-title>
          <mat-card-content class="table-container">

            <!-- TABELLA RIEPILOGATIVA DEI CANTIERI SELEZIONATI -->

            <table mat-table [dataSource]="cantieriSelezionati.value" class="mat-elevation-z8" style="width: 100%;">

              <ng-container matColumnDef="CodiceCommessa">
                <th mat-header-cell *matHeaderCellDef> Codice commessa </th>
                <td mat-cell *matCellDef="let element"> {{element.CodiceCommessa}} </td>
              </ng-container>

              <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
              </ng-container>

              <ng-container matColumnDef="DateStart">
                <th mat-header-cell *matHeaderCellDef> Data inizio </th>
                <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy"}} </td>
              </ng-container>

              <ng-container matColumnDef="DateEnd">
                <th mat-header-cell *matHeaderCellDef> Data fine </th>
                <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy"}} </td>
              </ng-container>

              <ng-container matColumnDef="Client">
                <th mat-header-cell *matHeaderCellDef> Committente </th>
                <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
              </ng-container>

              <ng-container matColumnDef="Indirizzo">
                <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
                <td mat-cell *matCellDef="let element"> {{element.Indirizzo}} </td>
              </ng-container>

              <ng-container matColumnDef="BossName">
                <th mat-header-cell *matHeaderCellDef> Capocantiere </th>
                <td mat-cell *matCellDef="let element"> {{element.BossName}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsCantieri"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsCantieri;"></tr>
            </table>

          </mat-card-content>
        </mat-card>

      </div>

      <div *ngIf="tipologia == 'Operatore'">

        <div class="container-title-step">
          <mat-icon>done_all</mat-icon>
          <span class="titolo-tab">Scegli gli operatori per la generazione del report</span>
        </div>

        <div class="container-select-step2-tab-operatori">

          <!-- SELEZIONE OPERATORE -->
          <mat-form-field appearance="fill">
            <mat-label>Operatori</mat-label>
            <mat-select [formControl]="operatoriSelezionati" multiple>
              <mat-option *ngFor="let operatoriSelezionati of operatori" [value]="operatoriSelezionati">{{operatoriSelezionati.Name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- SELEZIONE CANTIERE -->
          <mat-form-field appearance="fill" *ngIf="operatoriSelezionati.value != ''">
            <mat-label>Cantieri</mat-label>
            <mat-select [formControl]="cantieriSelezionati" multiple>
              <mat-option *ngFor="let cantieriSelezionati of cantieri" [value]="cantieriSelezionati">{{cantieriSelezionati.Description}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <mat-card *ngIf="operatoriSelezionati.value != ''">
          <mat-card-title>Riepilogo dati selezionati</mat-card-title>
          <mat-card-content class="table-container">

            <!-- TABELLA RIEPILOGATIVA DEGLI OPERATORI E CANTIERI SELEZIONATI -->

            <div class="container-table-riepilogo-step2">

              <div class="displayFlex" style="width: 100%;">
                <span class="title-info-list">Dati operatore</span>
                <table mat-table [dataSource]="operatoriSelezionati.value" class="mat-elevation-z8" >

                  <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> Nome </th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Pin">
                    <th mat-header-cell *matHeaderCellDef> Pin </th>
                    <td mat-cell *matCellDef="let element"> {{element.Pin}} </td>
                  </ng-container>

                  <ng-container matColumnDef="ValidityDate">
                    <th mat-header-cell *matHeaderCellDef> Data fine validità </th>
                    <td mat-cell *matCellDef="let element"> {{element.ValidityDate | date: "dd/MM/yyyy"}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsOperatori"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsOperatori;"></tr>
                </table>
              </div>

              <div class="displayFlex" *ngIf="cantieriSelezionati.value != ''" style="width: 100%;">
                <span class="title-info-list">Dati cantiere</span>
                <table mat-table [dataSource]="cantieriSelezionati.value" class="mat-elevation-z8" style="width: 100%;">

                  <ng-container matColumnDef="CodiceCommessa">
                    <th mat-header-cell *matHeaderCellDef> Codice commessa </th>
                    <td mat-cell *matCellDef="let element"> {{element.CodiceCommessa}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                    <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="DateStart">
                    <th mat-header-cell *matHeaderCellDef> Data inizio </th>
                    <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy"}} </td>
                  </ng-container>

                  <ng-container matColumnDef="DateEnd">
                    <th mat-header-cell *matHeaderCellDef> Data fine </th>
                    <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy"}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Client">
                    <th mat-header-cell *matHeaderCellDef> Committente </th>
                    <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Indirizzo">
                    <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
                    <td mat-cell *matCellDef="let element"> {{element.Indirizzo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="BossName">
                    <th mat-header-cell *matHeaderCellDef> Capocantiere </th>
                    <td mat-cell *matCellDef="let element"> {{element.BossName}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCantieri"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsCantieri;"></tr>

                </table>
              </div>

            </div>


          </mat-card-content>
        </mat-card>

      </div>

      <div *ngIf="tipologia == 'Committente'">

        <div class="container-title-step">
          <mat-icon>done_all</mat-icon>
          <span class="titolo-tab">Scegli i committenti per la generazione del report</span>
        </div>

        <div class="container-select-step2-tab-operatori">

          <!-- SELEZIONE COMMITTENTI -->
          <mat-form-field appearance="fill">
            <mat-label>Committenti</mat-label>
            <mat-select [formControl]="committentiSelezionati" multiple (ngModelChange)="onChangeCommittente(committentiSelezionati)">
              <mat-option *ngFor="let committentiSelezionati of committenti" [value]="committentiSelezionati">{{committentiSelezionati}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- SELEZIONE CANTIERI -->
          <mat-form-field appearance="fill" *ngIf="committentiSelezionati.value != ''">
            <mat-label>Cantieri</mat-label>
            <mat-select [formControl]="cantieriSelezionati" multiple>
              <mat-option *ngFor="let cantieriSelezionati of cantieri" [value]="cantieriSelezionati">{{cantieriSelezionati.Description}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-card *ngIf="committentiSelezionati.value != ''">
          <mat-card-title>Riepilogo dati selezionati</mat-card-title>
          <mat-card-content class="table-container">

            <!-- TABELLA RIEPILOGATIVA DEI COMMITTENTI E CANTIERI SELEZIONATI -->

            <div class="container-table-riepilogo-step2">

              <!-- COMMITTENTI -->
              <div class="displayFlex" style="width: 100%;">
                <span class="title-info-list">Dati committenti</span>
                <table mat-table [dataSource]="committentiSelezionati.value" class="mat-elevation-z8">

                  <ng-container matColumnDef="RagioneSociale">
                    <th mat-header-cell *matHeaderCellDef> Committente </th>
                    <td mat-cell *matCellDef="let element"> {{element}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCommittenti"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsCommittenti"></tr>
                </table>
              </div>

              <!-- CANTIERI -->
              <div class="displayFlex" *ngIf="cantieriSelezionati.value != ''" style="width: 100%;">
                <span class="title-info-list">Dati cantiere</span>
                <table mat-table [dataSource]="cantieriSelezionati.value" class="mat-elevation-z8" style="width: 100%;">

                  <ng-container matColumnDef="CodiceCommessa">
                    <th mat-header-cell *matHeaderCellDef> Codice commessa </th>
                    <td mat-cell *matCellDef="let element"> {{element.CodiceCommessa}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                    <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="DateStart">
                    <th mat-header-cell *matHeaderCellDef> Data inizio </th>
                    <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy"}} </td>
                  </ng-container>

                  <ng-container matColumnDef="DateEnd">
                    <th mat-header-cell *matHeaderCellDef> Data fine </th>
                    <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy"}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Client">
                    <th mat-header-cell *matHeaderCellDef> Committente </th>
                    <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Indirizzo">
                    <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
                    <td mat-cell *matCellDef="let element"> {{element.Indirizzo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="BossName">
                    <th mat-header-cell *matHeaderCellDef> Capocantiere </th>
                    <td mat-cell *matCellDef="let element"> {{element.BossName}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCantieri"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsCantieri;"></tr>

                </table>
              </div>

            </div>

          </mat-card-content>
        </mat-card>
      </div>

      <div class="container-button-action-stepper">
        <button mat-button matStepperPrevious mat-raised-button color="warn" style="margin-right: 10px;" (click)="resetAll('step2')">Indietro</button>
        <button mat-button matStepperNext mat-raised-button color="warn"
                [disabled]="(operatoriSelezionati.value == '' && cantieriSelezionati.value == '' && committentiSelezionati.value == '') || cantieriSelezionati.value == ''"
                (click)="GetDataForDownload(this.cantieriSelezionati.value, this.operatoriSelezionati.value, this.committentiSelezionati.value)">
          Avanti
        </button>
      </div>

    </mat-step>


    <!-- TERZO STEP -->

    <mat-step label="Scegli i dati">

      <div class="container-title-step">
        <mat-icon style="margin-top: 2px;">list</mat-icon>
        <span class="titolo-tab">Scegli le informazioni da inserire nel report</span>
      </div>

      <div class="container-all-info-list">

        <!-- FORM OPERATORE -->
        <div [id]="tipologia == 'Operatore' ? 'primo' : (tipologia == 'Committente' ? 'quarto' : 'terzo')">
          <span class="title-info-list">Dati operatore</span>
          <section class="container-section-list">
            <mat-checkbox
                          [checked]="allComplete"
                          [color]="OPERATORE_FORM_DATA.color"
                          [indeterminate]="someComplete(OPERATORE_FORM_DATA)"
                          (change)="setAll($event.checked, OPERATORE_FORM_DATA)">
              {{OPERATORE_FORM_DATA.name}}
            </mat-checkbox>
            <div class="container-info-list">
              <span *ngFor="let subtask of OPERATORE_FORM_DATA.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed"
                              [color]="subtask.color"
                              (ngModelChange)="updateAllComplete(OPERATORE_FORM_DATA)">
                  {{subtask.name}}
                </mat-checkbox>
              </span>
            </div>
          </section>
        </div>

        <!-- FORM CANTIERE -->
        <div [id]="tipologia == 'Cantiere' ? 'primo' : 'secondo'">
          <span class="title-info-list">Dati cantiere</span>
          <section class="container-section-list">
            <mat-checkbox
                          [checked]="allComplete"
                          [color]="CANTIERE_FORM_DATA.color"
                          [indeterminate]="someComplete(CANTIERE_FORM_DATA)"
                          (change)="setAll($event.checked, CANTIERE_FORM_DATA)">
              {{CANTIERE_FORM_DATA.name}}
            </mat-checkbox>
            <div class="container-info-list">
              <span *ngFor="let subtask of CANTIERE_FORM_DATA.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed"
                              [color]="subtask.color"
                              (ngModelChange)="updateAllComplete(CANTIERE_FORM_DATA)">
                  {{subtask.name}}
                </mat-checkbox>
              </span>
            </div>
          </section>
        </div>

        <!-- FORM ODL -->
        <div [id]="tipologia == 'Committente' ? 'quinto' : 'quarto'">
          <span class="title-info-list">Dati ordine di lavoro</span>
          <section class="container-section-list">
            <mat-checkbox
                          [checked]="allComplete"
                          [color]="ODL_FORM_DATA.color"
                          [indeterminate]="someComplete(ODL_FORM_DATA)"
                          (change)="setAll($event.checked, ODL_FORM_DATA)">
              {{ODL_FORM_DATA.name}}
            </mat-checkbox>
            <div class="container-info-list">
              <span *ngFor="let subtask of ODL_FORM_DATA.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed"
                              [color]="subtask.color"
                              (ngModelChange)="updateAllComplete(ODL_FORM_DATA)">
                  {{subtask.name}}
                </mat-checkbox>
              </span>
            </div>
          </section>
        </div>

        <!-- FORM MEZZO -->
        <div [id]="tipologia == 'Cantiere' ? 'secondo' : 'terzo'">
          <span class="title-info-list">Dati del mezzo</span>
          <section class="container-section-list">
            <mat-checkbox
                          [checked]="allComplete"
                          [color]="MEZZO_FORM_DATA.color"
                          [indeterminate]="someComplete(MEZZO_FORM_DATA)"
                          (change)="setAll($event.checked, MEZZO_FORM_DATA)">
              {{MEZZO_FORM_DATA.name}}
            </mat-checkbox>
            <div class="container-info-list">
              <span *ngFor="let subtask of MEZZO_FORM_DATA.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed"
                              [color]="subtask.color"
                              (ngModelChange)="updateAllComplete(MEZZO_FORM_DATA)">
                  {{subtask.name}}
                </mat-checkbox>
              </span>
            </div>
          </section>
        </div>

        <!-- FORM COMMITTENTE -->
        <div *ngIf="tipologia == 'Committente'" id="primo">
          <span class="title-info-list">Dati committente</span>
          <section class="container-section-list">
            <mat-checkbox
                          [checked]="allComplete"
                          [color]="COMMITTENTE_FORM_DATA.color"
                          [indeterminate]="someComplete(COMMITTENTE_FORM_DATA)"
                          (change)="setAll($event.checked, COMMITTENTE_FORM_DATA)">
              {{COMMITTENTE_FORM_DATA.name}}
            </mat-checkbox>
            <div class="container-info-list">
              <span *ngFor="let subtask of COMMITTENTE_FORM_DATA.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed"
                              [color]="subtask.color"
                              (ngModelChange)="updateAllComplete(COMMITTENTE_FORM_DATA)">
                  {{subtask.name}}
                </mat-checkbox>
              </span>
            </div>
          </section>
        </div>

      </div>

      <div class="container-button-action-stepper">
        <button mat-button matStepperPrevious mat-raised-button color="warn" style="margin-right: 10px;" (click)="resetAll('step3')">Indietro</button>
        <button mat-button matStepperNext mat-raised-button color="warn" [disabled]="" (click)="buildDisplayColumnForStep4()">Avanti</button>
      </div>
    </mat-step>


    <!-- QUARTO STEP -->

    <mat-step label="Anteprima & Download">

      <div class="container-title-step">
        <mat-icon style="margin-top: 2px;">find_in_page</mat-icon>
        <span *ngIf="dataLength > 1" class="titolo-tab">Anteprima dei report</span>
        <span *ngIf="dataLength == 1" class="titolo-tab">Anteprima del report</span>
      </div>

      <!-- ANTEPRIMA CON PIU ELEMENTI SELEZIONATI -->
      <span *ngIf="dataLength > 1">

        <mat-accordion *ngFor="let element of getList(tipologia); let e=index">

          <mat-expansion-panel class="expansion-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="container-title-report-expansion-pannel">
                  <mat-icon>assessment</mat-icon>
                  <span *ngIf="tipologia == 'Operatore'">Report_{{ element.Name }}</span>
                  <span *ngIf="tipologia == 'Cantiere'">Report_{{ element.Description }}</span>
                  <span *ngIf="tipologia == 'Committente'">Report_{{ element }}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="container-expansion-palen-content">
              <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="mat-tab-group-container">

                <mat-tab *ngFor="let item of tabsLabels; let i=index" [label]="item">

                  <div *ngIf="item == 'Cantieri'" class="container-table-anteprima-oneElement">

                    <table mat-table [dataSource]="reportDataForDownload[e].Cantieri" class="mat-elevation-z8" style="width: 100%;">

                      <ng-container matColumnDef="CodiceCommessa" *ngIf="displayedColumnsCantieri_edit.includes('CodiceCommessa')">
                        <th mat-header-cell *matHeaderCellDef> Codice commessa </th>
                        <td mat-cell *matCellDef="let element"> {{element.CodiceCommessa}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Description" *ngIf="displayedColumnsCantieri_edit.includes('Description')">
                        <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DescriptionExtended" *ngIf="displayedColumnsCantieri_edit.includes('DescriptionExtended')">
                        <th mat-header-cell *matHeaderCellDef> Descrizione estesa</th>
                        <td mat-cell *matCellDef="let element"> {{element.DescriptionExtended}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DateStart" *ngIf="displayedColumnsCantieri_edit.includes('DateStart')">
                        <th mat-header-cell *matHeaderCellDef> Data inizio </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy HH:mm"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DateEnd" *ngIf="displayedColumnsCantieri_edit.includes('DateEnd')">
                        <th mat-header-cell *matHeaderCellDef> Data fine </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy HH:mm"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Client" *ngIf="displayedColumnsCantieri_edit.includes('Client')">
                        <th mat-header-cell *matHeaderCellDef> Committente </th>
                        <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Indirizzo" *ngIf="displayedColumnsCantieri_edit.includes('Indirizzo')">
                        <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
                        <td mat-cell *matCellDef="let element"> {{element.Indirizzo}} </td>
                      </ng-container>

                      <ng-container matColumnDef="BossName" *ngIf="displayedColumnsCantieri_edit.includes('BossName')">
                        <th mat-header-cell *matHeaderCellDef> Capocantiere </th>
                        <td mat-cell *matCellDef="let element"> {{element.BossName}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsCantieri_edit"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsCantieri_edit;"></tr>

                    </table>

                  </div>

                  <div *ngIf="item == 'Mezzi'" class="container-table-anteprima-oneElement">

                    <table mat-table *ngIf="reportDataForDownload[e].Mezzi.length != 0" [dataSource]="reportDataForDownload[e].Mezzi" class="mat-elevation-z8" style="width: 100%;">

                      <ng-container matColumnDef="Name" *ngIf="displayedColumnsMezzi_edit.includes('Name')">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Model" *ngIf="displayedColumnsMezzi_edit.includes('Model')">
                        <th mat-header-cell *matHeaderCellDef> Modello </th>
                        <td mat-cell *matCellDef="let element"> {{element.Model}} </td>
                      </ng-container>

                      <ng-container matColumnDef="SerialNumber" *ngIf="displayedColumnsMezzi_edit.includes('SerialNumber')">
                        <th mat-header-cell *matHeaderCellDef> Numero Seriale </th>
                        <td mat-cell *matCellDef="let element"> {{element.SerialNumber}} </td>
                      </ng-container>

                      <ng-container matColumnDef="OEMName" *ngIf="displayedColumnsMezzi_edit.includes('OEMName')">
                        <th mat-header-cell *matHeaderCellDef> OEM </th>
                        <td mat-cell *matCellDef="let element"> {{element.OEMName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Banco" *ngIf="displayedColumnsMezzi_edit.includes('Banco')">
                        <th mat-header-cell *matHeaderCellDef> Banco </th>
                        <td mat-cell *matCellDef="let element"> {{element.Banco}} </td>
                      </ng-container>

                      <ng-container matColumnDef="IsValid" *ngIf="displayedColumnsMezzi_edit.includes('IsValid')">
                        <th mat-header-cell *matHeaderCellDef> Censito </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
                          <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="Dynakit" *ngIf="displayedColumnsMezzi_edit.includes('Dynakit')">
                        <th mat-header-cell *matHeaderCellDef> Dyn@Kit </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-icon *ngIf="element.Dynakit" class="ok-icon">done</mat-icon>
                          <mat-icon *ngIf="!element.Dynakit" class="error-icon">close</mat-icon>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="IsAssociate" *ngIf="displayedColumnsMezzi_edit.includes('IsAssociate')">
                        <th mat-header-cell *matHeaderCellDef> Associato </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-icon *ngIf="element.IsAssociate" class="ok-icon">done</mat-icon>
                          <mat-icon *ngIf="!element.IsAssociate" class="error-icon">close</mat-icon>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="IsActive" *ngIf="displayedColumnsMezzi_edit.includes('IsActive')">
                        <th mat-header-cell *matHeaderCellDef> Disponibilità </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-icon *ngIf="element.IsActive" class="ok-icon">done</mat-icon>
                          <mat-icon *ngIf="!element.IsActive" class="error-icon">close</mat-icon>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="Cantiere" *ngIf="displayedColumnsMezzi_edit.includes('Cantiere')">
                        <th mat-header-cell *matHeaderCellDef> Cantiere attuale </th>
                        <td mat-cell *matCellDef="let element">
                          <span *ngIf="element.Cantiere != null">{{element.Cantiere}}</span>
                          <mat-icon *ngIf="element.Cantiere == null" class="worksiteNull-icon">close</mat-icon>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="DateEnd" *ngIf="displayedColumnsMezzi_edit.includes('DateEnd')">
                        <th mat-header-cell *matHeaderCellDef> Scadenza licenza </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="CumulativeHours" *ngIf="displayedColumnsMezzi_edit.includes('CumulativeHours')">
                        <th mat-header-cell *matHeaderCellDef> Ore cumulative </th>
                        <td mat-cell *matCellDef="let element"> {{element.CumulativeHours}} </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumnsMezzi_edit"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsMezzi_edit;"></tr>

                    </table>

                    <span *ngIf="reportDataForDownload[e].Mezzi.length == 0" class="TableEmptyMessage">Nessun mezzo trovato per questo report</span>

                  </div>

                  <div *ngIf="item == 'ODL'" class="container-table-anteprima-oneElement">

                    <table mat-table *ngIf="reportDataForDownload[e].ODL.length != 0" [dataSource]="reportDataForDownload[e].ODL" class="mat-elevation-z8" style="width: 100%;">

                      <ng-container matColumnDef="Code" *ngIf="displayedColumnsODL_edit.includes('Code')">
                        <th mat-header-cell *matHeaderCellDef> Codice </th>
                        <td mat-cell *matCellDef="let element"> {{element.Code}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Description" *ngIf="displayedColumnsODL_edit.includes('Description')">
                        <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Note" *ngIf="displayedColumnsODL_edit.includes('Note')">
                        <th mat-header-cell *matHeaderCellDef> Note </th>
                        <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Model" *ngIf="displayedColumnsODL_edit.includes('Model')">
                        <th mat-header-cell *matHeaderCellDef> Modello </th>
                        <td mat-cell *matCellDef="let element"> {{element.Model}}</td>
                      </ng-container>

                      <ng-container matColumnDef="SerialNumber" *ngIf="displayedColumnsODL_edit.includes('SerialNumber')">
                        <th mat-header-cell *matHeaderCellDef> Numero seriale </th>
                        <td mat-cell *matCellDef="let element">{{element.SerialNumber}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DateStart" *ngIf="displayedColumnsODL_edit.includes('DateStart')">
                        <th mat-header-cell *matHeaderCellDef> Data inizio (Prevista) </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy HH:mm"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DateEnd" *ngIf="displayedColumnsODL_edit.includes('DateEnd')">
                        <th mat-header-cell *matHeaderCellDef> Data fine (Prevista) </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy HH:mm"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DateStartEffettivo" *ngIf="displayedColumnsODL_edit.includes('DateStartEffettivo')">
                        <th mat-header-cell *matHeaderCellDef> Data inizio (Effettiva) </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateStartEffettivo | date: "dd/MM/yyyy HH:mm"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="DateEndEffettivo" *ngIf="displayedColumnsODL_edit.includes('DateEndEffettivo')">
                        <th mat-header-cell *matHeaderCellDef> Data fine (Effettiva) </th>
                        <td mat-cell *matCellDef="let element"> {{element.DateEndEffettivo | date: "dd/MM/yyyy HH:mm"}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Azione" *ngIf="displayedColumnsODL_edit.includes('Azione')">
                        <th mat-header-cell *matHeaderCellDef> Attività </th>
                        <td mat-cell *matCellDef="let element"> {{element.Azione}} </td>
                      </ng-container>

                      <ng-container matColumnDef="OperatorName" *ngIf="displayedColumnsODL_edit.includes('OperatorName')">
                        <th mat-header-cell *matHeaderCellDef> Operatore </th>
                        <td mat-cell *matCellDef="let element"> {{element.OperatorName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="TipoLavoro" *ngIf="displayedColumnsODL_edit.includes('TipoLavoro')">
                        <th mat-header-cell *matHeaderCellDef> Tipologia </th>
                        <td mat-cell *matCellDef="let element"> {{element.TipoLavoro}} </td>
                      </ng-container>

                      <ng-container matColumnDef="TempoStimatoOre" *ngIf="displayedColumnsODL_edit.includes('TempoStimatoOre')">
                        <th mat-header-cell *matHeaderCellDef> Ore previste </th>
                        <td mat-cell *matCellDef="let element">
                          <span>{{element.TempoStimatoOre | number : '1.0-0'}}h </span>
                          <span *ngIf="getMinutes(element.TempoStimatoOre) != 0">{{getMinutes(element.TempoStimatoOre) | number : '1.0-0'}}m</span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="TempoOreDynakit" *ngIf="displayedColumnsODL_edit.includes('TempoOreDynakit')">
                        <th mat-header-cell *matHeaderCellDef> Ore Dyn@Kit </th>
                        <td mat-cell *matCellDef="let element">
                          <span>{{element.TempoOreDynakit | number : '1.0-0'}}h </span>
                          <span *ngIf="getMinutes(element.TempoOreDynakit) != 0">{{getMinutes(element.TempoOreDynakit) | number : '1.0-0'}}m</span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="TempoOreMotore" *ngIf="displayedColumnsODL_edit.includes('TempoOreMotore')">
                        <th mat-header-cell *matHeaderCellDef> Ore motore </th>
                        <td mat-cell *matCellDef="let element">
                          <span>{{element.TempoOreMotore | number : '1.0-0'}}h </span>
                          <span *ngIf="getMinutes(element.TempoOreMotore) != 0">{{getMinutes(element.TempoOreMotore) | number : '1.0-0'}}m</span>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsODL_edit"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsODL_edit;"></tr>

                    </table>

                    <span *ngIf="reportDataForDownload[e].ODL.length == 0" class="TableEmptyMessage">Nessun ODL trovato per questo report</span>

                  </div>

                  <div *ngIf="item == 'Operatori'" class="container-table-anteprima-oneElement">

                    <table mat-table *ngIf="reportDataForDownload[e].Operatori.length != 0" [dataSource]="reportDataForDownload[e].Operatori" class="mat-elevation-z8" style="width: 100%;">

                      <ng-container matColumnDef="Name" *ngIf="displayedColumnsOperatori_edit.includes('Name')">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Pin" *ngIf="displayedColumnsOperatori_edit.includes('Pin')">
                        <th mat-header-cell *matHeaderCellDef> PIN </th>
                        <td mat-cell *matCellDef="let element"> {{element.Pin}} </td>
                      </ng-container>

                      <ng-container matColumnDef="ValidityDate" *ngIf="displayedColumnsOperatori_edit.includes('ValidityDate')">
                        <th mat-header-cell *matHeaderCellDef> Data fine validità </th>
                        <td mat-cell *matCellDef="let element"> {{element.ValidityDate | date: "dd/MM/yyyy"}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsOperatori_edit"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsOperatori_edit;"></tr>

                    </table>

                    <span *ngIf="reportDataForDownload[e].Operatori.length == 0" class="TableEmptyMessage">Nessun operatore trovato per questo report</span>

                  </div>

                  <div *ngIf="item == 'Committenti'" class="container-table-anteprima-oneElement">
                    <table mat-table *ngIf="reportDataForDownload[e].Committenti.length != 0" [dataSource]="reportDataForDownload[e].Committenti" class="mat-elevation-z8" style="width: 100%;">

                      <ng-container matColumnDef="RagioneSociale" *ngIf="displayedColumnsCommittenti_edit.includes('RagioneSociale')">
                        <th mat-header-cell *matHeaderCellDef> Committente </th>
                        <td mat-cell *matCellDef="let element"> {{element.RagioneSociale}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsCommittenti_edit"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsCommittenti_edit"></tr>

                    </table>

                    <span *ngIf="reportDataForDownload[e].Committenti.length == 0" class="TableEmptyMessage">Nessun Committente trovato per questo report</span>
                  </div>

                </mat-tab>

              </mat-tab-group>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

      </span>


      <!-- ANTEPRIMA CON UN SOLO ELEMENTO SELEZIONATO -->
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="dataLength == 1" class="mat-tab-group-container">

        <mat-tab *ngFor="let item of tabsLabels; let i=index" [label]="item">

          <div *ngIf="item == 'Cantieri'" class="container-table-anteprima-oneElement">

            <table mat-table [dataSource]="reportDataForDownload[0].Cantieri" class="mat-elevation-z8" style="width: 100%;">

              <ng-container matColumnDef="CodiceCommessa" *ngIf="displayedColumnsCantieri_edit.includes('CodiceCommessa')">
                <th mat-header-cell *matHeaderCellDef> Codice commessa </th>
                <td mat-cell *matCellDef="let element"> {{element.CodiceCommessa}} </td>
              </ng-container>

              <ng-container matColumnDef="Description" *ngIf="displayedColumnsCantieri_edit.includes('Description')">
                <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
              </ng-container>

              <ng-container matColumnDef="DescriptionExtended" *ngIf="displayedColumnsCantieri_edit.includes('DescriptionExtended')">
                <th mat-header-cell *matHeaderCellDef> Descrizione estesa</th>
                <td mat-cell *matCellDef="let element"> {{element.DescriptionExtended}} </td>
              </ng-container>

              <ng-container matColumnDef="DateStart" *ngIf="displayedColumnsCantieri_edit.includes('DateStart')">
                <th mat-header-cell *matHeaderCellDef> Data inizio </th>
                <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy"}} </td>
              </ng-container>

              <ng-container matColumnDef="DateEnd" *ngIf="displayedColumnsCantieri_edit.includes('DateEnd')">
                <th mat-header-cell *matHeaderCellDef> Data fine </th>
                <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy"}} </td>
              </ng-container>

              <ng-container matColumnDef="Client" *ngIf="displayedColumnsCantieri_edit.includes('Client')">
                <th mat-header-cell *matHeaderCellDef> Committente </th>
                <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
              </ng-container>

              <ng-container matColumnDef="Indirizzo" *ngIf="displayedColumnsCantieri_edit.includes('Indirizzo')">
                <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
                <td mat-cell *matCellDef="let element"> {{element.Indirizzo}} </td>
              </ng-container>

              <ng-container matColumnDef="BossName" *ngIf="displayedColumnsCantieri_edit.includes('BossName')">
                <th mat-header-cell *matHeaderCellDef> Capocantiere </th>
                <td mat-cell *matCellDef="let element"> {{element.BossName}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsCantieri_edit"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsCantieri_edit;"></tr>

            </table>

          </div>

          <div *ngIf="item == 'Mezzi'" class="container-table-anteprima-oneElement">

            <table mat-table *ngIf="reportDataForDownload[0].Mezzi.length != 0" [dataSource]="reportDataForDownload[0].Mezzi" class="mat-elevation-z8" style="width: 100%;">

              <ng-container matColumnDef="Name" *ngIf="displayedColumnsMezzi_edit.includes('Name')">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
              </ng-container>

              <ng-container matColumnDef="Model" *ngIf="displayedColumnsMezzi_edit.includes('Model')">
                <th mat-header-cell *matHeaderCellDef> Modello </th>
                <td mat-cell *matCellDef="let element"> {{element.Model}} </td>
              </ng-container>

              <ng-container matColumnDef="SerialNumber" *ngIf="displayedColumnsMezzi_edit.includes('SerialNumber')">
                <th mat-header-cell *matHeaderCellDef> Numero Seriale </th>
                <td mat-cell *matCellDef="let element"> {{element.SerialNumber}} </td>
              </ng-container>

              <ng-container matColumnDef="OEMName" *ngIf="displayedColumnsMezzi_edit.includes('OEMName')">
                <th mat-header-cell *matHeaderCellDef> OEM </th>
                <td mat-cell *matCellDef="let element"> {{element.OEMName}} </td>
              </ng-container>

              <ng-container matColumnDef="Banco" *ngIf="displayedColumnsMezzi_edit.includes('Banco')">
                <th mat-header-cell *matHeaderCellDef> Banco </th>
                <td mat-cell *matCellDef="let element"> {{element.Banco}} </td>
              </ng-container>

              <ng-container matColumnDef="IsValid" *ngIf="displayedColumnsMezzi_edit.includes('IsValid')">
                <th mat-header-cell *matHeaderCellDef> Censito </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
                  <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="Dynakit" *ngIf="displayedColumnsMezzi_edit.includes('Dynakit')">
                <th mat-header-cell *matHeaderCellDef> Dyn@Kit </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.Dynakit" class="ok-icon">done</mat-icon>
                  <mat-icon *ngIf="!element.Dynakit" class="error-icon">close</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="IsAssociate" *ngIf="displayedColumnsMezzi_edit.includes('IsAssociate')">
                <th mat-header-cell *matHeaderCellDef> Associato </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.IsAssociate" class="ok-icon">done</mat-icon>
                  <mat-icon *ngIf="!element.IsAssociate" class="error-icon">close</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="IsActive" *ngIf="displayedColumnsMezzi_edit.includes('IsActive')">
                <th mat-header-cell *matHeaderCellDef> Disponibilità </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.IsActive" class="ok-icon">done</mat-icon>
                  <mat-icon *ngIf="!element.IsActive" class="error-icon">close</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="Cantiere" *ngIf="displayedColumnsMezzi_edit.includes('Cantiere')">
                <th mat-header-cell *matHeaderCellDef> Cantiere attuale </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.Cantiere != null">{{element.Cantiere}}</span>
                  <mat-icon *ngIf="element.Cantiere == null" class="worksiteNull-icon">close</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="DateEnd" *ngIf="displayedColumnsMezzi_edit.includes('DateEnd')">
                <th mat-header-cell *matHeaderCellDef> Scadenza licenza </th>
                <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy"}} </td>
              </ng-container>

              <ng-container matColumnDef="CumulativeHours" *ngIf="displayedColumnsMezzi_edit.includes('CumulativeHours')">
                <th mat-header-cell *matHeaderCellDef> Ore cumulative </th>
                <td mat-cell *matCellDef="let element"> {{element.CumulativeHours}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumnsMezzi_edit"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsMezzi_edit;"></tr>

            </table>

            <span *ngIf="reportDataForDownload[0].Mezzi.length == 0" class="TableEmptyMessage">Nessun mezzo trovato per questo report</span>

          </div>

          <div *ngIf="item == 'ODL'" class="container-table-anteprima-oneElement">

            <table mat-table *ngIf="reportDataForDownload[0].ODL.length != 0" [dataSource]="reportDataForDownload[0].ODL" class="mat-elevation-z8" style="width: 100%;">

              <ng-container matColumnDef="Code" *ngIf="displayedColumnsODL_edit.includes('Code')">
                <th mat-header-cell *matHeaderCellDef> Codice </th>
                <td mat-cell *matCellDef="let element"> {{element.Code}} </td>
              </ng-container>

              <ng-container matColumnDef="Description" *ngIf="displayedColumnsODL_edit.includes('Description')">
                <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
              </ng-container>

              <ng-container matColumnDef="Note" *ngIf="displayedColumnsODL_edit.includes('Note')">
                <th mat-header-cell *matHeaderCellDef> Note </th>
                <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
              </ng-container>

              <ng-container matColumnDef="Model" *ngIf="displayedColumnsODL_edit.includes('Model')">
                <th mat-header-cell *matHeaderCellDef> Modello </th>
                <td mat-cell *matCellDef="let element"> {{element.Model}}</td>
              </ng-container>

              <ng-container matColumnDef="SerialNumber" *ngIf="displayedColumnsODL_edit.includes('SerialNumber')">
                <th mat-header-cell *matHeaderCellDef> Numero seriale </th>
                <td mat-cell *matCellDef="let element">{{element.SerialNumber}} </td>
              </ng-container>

              <ng-container matColumnDef="DateStart" *ngIf="displayedColumnsODL_edit.includes('DateStart')">
                <th mat-header-cell *matHeaderCellDef> Data inizio (Prevista) </th>
                <td mat-cell *matCellDef="let element"> {{element.DateStart | date: "dd/MM/yyyy HH:mm"}} </td>
              </ng-container>

              <ng-container matColumnDef="DateEnd" *ngIf="displayedColumnsODL_edit.includes('DateEnd')">
                <th mat-header-cell *matHeaderCellDef> Data fine (Prevista) </th>
                <td mat-cell *matCellDef="let element"> {{element.DateEnd | date: "dd/MM/yyyy HH:mm"}} </td>
              </ng-container>

              <ng-container matColumnDef="DateStartEffettivo" *ngIf="displayedColumnsODL_edit.includes('DateStartEffettivo')">
                <th mat-header-cell *matHeaderCellDef> Data inizio (Effettiva) </th>
                <td mat-cell *matCellDef="let element"> {{element.DateStartEffettivo | date: "dd/MM/yyyy HH:mm"}} </td>
              </ng-container>

              <ng-container matColumnDef="DateEndEffettivo" *ngIf="displayedColumnsODL_edit.includes('DateEndEffettivo')">
                <th mat-header-cell *matHeaderCellDef> Data fine (Effettiva) </th>
                <td mat-cell *matCellDef="let element"> {{element.DateEndEffettivo | date: "dd/MM/yyyy HH:mm"}} </td>
              </ng-container>

              <ng-container matColumnDef="Azione" *ngIf="displayedColumnsODL_edit.includes('Azione')">
                <th mat-header-cell *matHeaderCellDef> Attività </th>
                <td mat-cell *matCellDef="let element"> {{element.Azione}} </td>
              </ng-container>

              <ng-container matColumnDef="OperatorName" *ngIf="displayedColumnsODL_edit.includes('OperatorName')">
                <th mat-header-cell *matHeaderCellDef> Operatore </th>
                <td mat-cell *matCellDef="let element"> {{element.OperatorName}} </td>
              </ng-container>

              <ng-container matColumnDef="TipoLavoro" *ngIf="displayedColumnsODL_edit.includes('TipoLavoro')">
                <th mat-header-cell *matHeaderCellDef> Tipologia </th>
                <td mat-cell *matCellDef="let element"> {{element.TipoLavoro}} </td>
              </ng-container>

              <ng-container matColumnDef="TempoStimatoOre" *ngIf="displayedColumnsODL_edit.includes('TempoStimatoOre')">
                <th mat-header-cell *matHeaderCellDef> Ore previste </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.TempoStimatoOre | number : '1.0-0'}}h </span>
                  <span *ngIf="getMinutes(element.TempoStimatoOre) != 0">{{getMinutes(element.TempoStimatoOre) | number : '1.0-0'}}m</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="TempoOreDynakit" *ngIf="displayedColumnsODL_edit.includes('TempoOreDynakit')">
                <th mat-header-cell *matHeaderCellDef> Ore Dyn@Kit </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.TempoOreDynakit | number : '1.0-0'}}h </span>
                  <span *ngIf="getMinutes(element.TempoOreDynakit) != 0">{{getMinutes(element.TempoOreDynakit) | number : '1.0-0'}}m</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="TempoOreMotore" *ngIf="displayedColumnsODL_edit.includes('TempoOreMotore')">
                <th mat-header-cell *matHeaderCellDef> Ore motore </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.TempoOreMotore | number : '1.0-0'}}h </span>
                  <span *ngIf="getMinutes(element.TempoOreMotore) != 0">{{getMinutes(element.TempoOreMotore) | number : '1.0-0'}}m</span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsODL_edit"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsODL_edit;"></tr>

            </table>

            <span *ngIf="reportDataForDownload[0].ODL.length == 0" class="TableEmptyMessage">Nessun ODL trovato per questo report</span>

          </div>

          <div *ngIf="item == 'Operatori'" class="container-table-anteprima-oneElement">

            <table mat-table *ngIf="reportDataForDownload[0].Operatori.length != 0" [dataSource]="reportDataForDownload[0].Operatori" class="mat-elevation-z8" style="width: 100%;">

              <ng-container matColumnDef="Name" *ngIf="displayedColumnsOperatori_edit.includes('Name')">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
              </ng-container>

              <ng-container matColumnDef="Pin" *ngIf="displayedColumnsOperatori_edit.includes('Pin')">
                <th mat-header-cell *matHeaderCellDef> PIN </th>
                <td mat-cell *matCellDef="let element"> {{element.Pin}} </td>
              </ng-container>

              <ng-container matColumnDef="ValidityDate" *ngIf="displayedColumnsOperatori_edit.includes('ValidityDate')">
                <th mat-header-cell *matHeaderCellDef> Data fine validità </th>
                <td mat-cell *matCellDef="let element"> {{element.ValidityDate | date: "dd/MM/yyyy"}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsOperatori_edit"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsOperatori_edit;"></tr>

            </table>

            <span *ngIf="reportDataForDownload[0].Operatori.length == 0" class="TableEmptyMessage">Nessun operatore trovato per questo report</span>

          </div>

          <div *ngIf="item == 'Committenti'" class="container-table-anteprima-oneElement">
            <table mat-table *ngIf="reportDataForDownload[0].Committenti.length != 0" [dataSource]="reportDataForDownload[0].Committenti" class="mat-elevation-z8" style="width: 100%;">

              <ng-container matColumnDef="RagioneSociale" *ngIf="displayedColumnsCommittenti_edit.includes('RagioneSociale')">
                <th mat-header-cell *matHeaderCellDef> Committente </th>
                <td mat-cell *matCellDef="let element"> {{element.RagioneSociale}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsCommittenti_edit"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsCommittenti_edit"></tr>

            </table>

            <span *ngIf="reportDataForDownload[0].Committenti.length == 0" class="TableEmptyMessage">Nessun Committente trovato per questo report</span>
          </div>

        </mat-tab>

      </mat-tab-group>


      <!-- SEZIONE ACTION BUTTON -->
      <div class="container-cancel-buttons-group">

        <div class="container-button-action-stepper-download">
          <button mat-button matStepperPrevious mat-raised-button color="warn" style="margin-right: 10px;" (click)="resetAll('step4_Back')">Indietro</button>
          <button mat-button matStepperNext mat-raised-button color="warn" [disabled]="" (click)="stepper.reset(); resetAll('step4')">Annulla tutto</button>
        </div>

        <button mat-button mat-raised-button color="warn" (click)="reportDownload(reportDataForDownload)">

          <div class="format-button-icon-and-label">
            <mat-icon>save_alt</mat-icon>
            <span *ngIf="dataLength == 1" style="padding-top: 2px;">Scarica</span>
            <span *ngIf="dataLength > 1" style="padding-top: 2px;">Scarica tutti</span>
          </div>

        </button>

      </div>

    </mat-step>

  </mat-stepper>
</mat-card>


<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<!-- FILTRI & NEW OPERATORE-->

<div #riferimento>
  <mat-card>
    <mat-card-title> Dyn@kit </mat-card-title>
    <mat-card-content>
    </mat-card-content>
  </mat-card>
</div>

<!-- TABELLA OPERATORI -->

<div class="table-container mat-elevation-z8">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="desc"
    matSortDisableClear
  >
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="machineName" sticky>
      <th mat-header-cell *matHeaderCellDef>Nome Macchina</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MachineName }}
      </td>
    </ng-container>

    <!-- Date Activation Column -->
    <ng-container matColumnDef="dateActivation">
      <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">Data Inizio Validità</th>
      <td mat-cell *matCellDef="let element">{{ element.DateActivation != null ? (element.DateActivation | date: "dd/MM/yyyy HH:mm") : "---" }}</td>
    </ng-container>

    <!-- Date End Column -->
    <ng-container matColumnDef="dateEnd">
      <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">Data Fine Validità</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DateEnd != null ? (element.DateEnd | date: "dd/MM/yyyy HH:mm") : "---"}}
      </td>
    </ng-container>

    <!-- NomeDynakit Column -->
    <ng-container matColumnDef="nomeDynakit" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Dyn@kit</th>
      <td mat-cell *matCellDef="let element">
        {{ element.NomeDynakit }}
      </td>
    </ng-container>

    <!-- IsValid Column -->
    <ng-container matColumnDef="isvalid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; padding-right: 20px; width: 50px;">Associato</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
          <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
          <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
        </div>
      </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<mat-paginator
  [pageSizeOptions]="[5, 10, 15, 50]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
></mat-paginator>

export class ParameterModel {

  constructor(
    public id: number,
    public chiave: string,
    public model: string,
    public gruppo: string,
    public dynalink: string,
    public vibrazione: string,
    public frequenzahilo: string,
    public ampiezzahilo : string,
    public forzacentrifugahilo : string,
    public velocitastesa : string,
    public spessorestesamax : string,
    public larghezzastesa : string,
    public banco : string,
    public tampering : string,
    public tamperinghilo : string,
    //public frequenzatampering : string,
    //public frequenzatamperinghilo : string,
    public fueltank : number,
  ) {  }

}


<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<mat-card>
  <mat-card-header>
    <mat-card-title>Report</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="reportForm">
      <div class="edit-container">
        <mat-form-field appearance="fill">
          <mat-label>Cantiere</mat-label>
          <mat-select
            formControlName="idworksite"
            required
            (selectionChange)="GetJobs()"
          >
            <mat-option *ngFor="let ws of workSiteSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Mezzo</mat-label>
          <mat-select formControlName="idmachine" (selectionChange)="GetJobs()">
            <mat-option *ngFor="let ws of machineFilterSource" [value]="ws.Id">
              {{ ws.MachineDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Data Inizio</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="datestart"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->

        <!-- <mat-form-field appearance="fill">
          <mat-label>Data Fine</mat-label>
          <input
            matInput
            [matDatepicker]="pickerEnd"
            formControlName="dateend"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerEnd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field> -->

        <!-- <button
          [disabled]="!reportForm.valid"
          (click)="Report()"
          mat-stroked-button
          color="primary"
          matTooltip="Download Excel"
          [matTooltipShowDelay]="1000"
        >
          <mat-icon>download</mat-icon>
        </button> -->
      </div>
    </form>

    <!-- <div *ngIf="dataSource.length > 0">

      <table mat-table [dataSource]="dataSource">
        class="mat-elevation-z8">

        <ng-container matColumnDef="macchina">
          <th mat-header-cell *matHeaderCellDef>Macchina</th>
          <td mat-cell *matCellDef="let element">{{ element.Machine }}</td>
        </ng-container>

        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef>Descrizione</th>
          <td mat-cell *matCellDef="let element">
            <span class="truncate-text">{{ element.Description }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="tempostimato">
          <th mat-header-cell *matHeaderCellDef>Tempo Stimato</th>
          <td mat-cell *matCellDef="let element">
            {{ element.TempoStimato }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tempodynalink">
          <th mat-header-cell *matHeaderCellDef>Tempo Dynalink</th>
          <td mat-cell *matCellDef="let element">
            {{ element.TempoDynalink }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tempodynakit">
          <th mat-header-cell *matHeaderCellDef>Tempo DynaKit</th>
          <td mat-cell *matCellDef="let element">
            {{ element.TempoDynakit }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div> -->
  </mat-card-content>
</mat-card>

<div>
  <br />
</div>

<mat-grid-list cols="3" rowHeight="1:1" *ngIf="showChart">
  <mat-grid-tile>
    <mat-card class="mat-card-full">
      <mat-card-title>Cantiere</mat-card-title>
      <mat-card-content>
        <form [formGroup]="cantiereForm">
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Cantiere</mat-label>
              <input matInput formControlName="description" readonly />
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Data Inizio</mat-label>
              <input matInput formControlName="datestart" readonly />
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Data Fine</mat-label>
              <input matInput formControlName="dateend" readonly />
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Committente</mat-label>
              <input matInput formControlName="client" readonly />
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Capo Cantiere</mat-label>
              <input matInput formControlName="boss" readonly />
            </mat-form-field>
          </p>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="mat-card-full">
      <mat-card-title>Lavori</mat-card-title>
      <mat-card-content>
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="chartPie"
          [gradient]="gradient"
          [legend]="showLegend"
          [labels]="showLabels"
          [doughnut]="isDoughnut"
        ></ngx-charts-pie-chart>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="mat-card-full">
      <mat-card-title>Tempo Impiegato</mat-card-title>
      <mat-card-content>
        <ngx-charts-bar-horizontal
          [scheme]="colorScheme"
          [results]="chartTotal"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
        ></ngx-charts-bar-horizontal>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<div class="container-title">
  <mat-icon class="icon-title">
    <span>donut_large</span>
  </mat-icon>
  <span class="title">Overview</span>
</div>


<!-- ELENCO DATI REPORT -->

<div class="container-card">

  <mat-card class="card">
    <mat-card-title>Aziende</mat-card-title>
    <mat-card-subtitle>Panoramica generale delle aziende</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content-card" *ngIf="AZIENDE != null">
        <div class="container-icon"><mat-icon class="material-icon">business_center</mat-icon> <span class="title-content-info">Totali: </span>{{AZIENDE.Totali}}</div>
        <div class="container-icon"><mat-icon class="material-icon">verified_user</mat-icon> <span class="title-content-info">Attive: </span>{{AZIENDE.Attive}}</div>
        <div class="container-icon"><mat-icon class="material-icon">report</mat-icon> <span class="title-content-info">Da attivare: </span>{{AZIENDE.Totali - AZIENDE.Attive}}</div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="query" *ngIf="AZIENDE == null" style="margin-top: auto;"></mat-progress-bar>
  </mat-card>

  <mat-card class="card">
    <mat-card-title>Macchine</mat-card-title>
    <mat-card-subtitle>Panoramica generale delle macchine</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content-card" *ngIf="MACCHINE != null">
        <div class="container-icon"><mat-icon class="material-icon">directions_car</mat-icon> <span class="title-content-info">Totali: </span>{{MACCHINE.Totali}}</div>
        <div class="container-icon"><mat-icon class="material-icon">mobile_friendly</mat-icon> <span class="title-content-info">Associate: </span>{{MACCHINE.Attive}}</div>
        <div class="container-icon"><mat-icon class="material-icon">device_unknown</mat-icon> <span class="title-content-info">Da associare: </span>{{MACCHINE.Totali - MACCHINE.Attive}}</div>
        <div class="container-icon"><mat-icon class="material-icon">tune</mat-icon> <span class="title-content-info">Da censire: </span>{{MACCHINE.NotCensite}}</div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="query" *ngIf="MACCHINE == null" style="margin-top: auto;"></mat-progress-bar>
  </mat-card>

  <mat-card class="card">
    <mat-card-title>Dyn@kit</mat-card-title>
    <mat-card-subtitle>Panoramica generale dei device</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content-card" *ngIf="KIT != null">
        <div class="container-icon"><mat-icon class="material-icon">directions_car</mat-icon> <span class="title-content-info">Totali: </span>{{KIT.Totali}}</div>
        <div class="container-icon"><mat-icon class="material-icon">mobile_friendly</mat-icon> <span class="title-content-info">Attivi: </span>{{KIT.Attivi}}</div>
        <div class="container-icon"><mat-icon class="material-icon">device_unknown</mat-icon> <span class="title-content-info">Da associare: </span>{{KIT.DaAssociare}}</div>
        <div class="container-icon"><mat-icon class="material-icon">add_to_home_screen</mat-icon> <span class="title-content-info">In scadenza: </span>{{KIT.InScadenza}}</div>
        <div class="container-icon"><mat-icon class="material-icon">phonelink_erase</mat-icon> <span class="title-content-info">Scaduti: </span>{{KIT.Scaduti}}</div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="query" *ngIf="KIT == null" style="margin-top: auto;"></mat-progress-bar>
  </mat-card>

</div>


import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Globals } from '../../globals';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { ODL_panoramica } from '../../data';
import { MachineHours } from '../../data';
import { MachineHours_Activity } from '../../data';
import { FilterWorkSiteModel } from 'src/app/model/FilterWorkSiteModel';
import { ApiService } from 'src/app/services/api.service';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterJobModel } from 'src/app/model/FilterJobModel';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { JobModel } from 'src/app/model/JobModel';
import { JobWorkModel } from 'src/app/model/JobWorkModel';
import { JobDialogComponent } from '../job-dialog-page/job-dialog-page.component';
import { JobEngagedDialogComponent } from '../job-engaged-dialog/job-engaged-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { FilterMachineModel } from 'src/app/model/FilterMachineModel';
import { DynalinkDialogComponent } from '../dynalink-dialog/dynalink-dialog.component';
import { FilterMachineAssociateModel } from 'src/app/model/FilterMachineAssociateModel';

import { MatDateRangePicker } from '@angular/material/datepicker';
import { isJsxFragment, Map } from 'typescript';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { max } from 'd3';
import { DialogConfermaComponent } from '../Dialog-conferma/Dialog-conferma.component';
interface selectWorkSite {
  Id: string;
  Description: string;

}
interface WorkSiteModel {
  Id: string;
  Description: string;
  DateStart: Date;
  DateEnd: Date;
  BossName: string;
  DescriptionExtended: string;
  Client: string,
  CodiceCommessa: string,
  Indirizzo: string
}
interface selectMachine {
  Id: string;
  Name: string;
}

interface selectOperator {
  Id: string;
  Name: string;
}

interface selectAction {
  Id: string;
  Description: string;
}

interface selectActive {
  Id: string;
  Description: string;
}

interface selectBanco {
  Id: string;
  Name: string;
}

@Component({
  selector: 'app-management-page',
  templateUrl: './management-page.component.html',
  styleUrls: ['./management-page.component.scss']
})
export class ManagementPageComponent implements OnInit {

  checked = true;
  bancoForm = this.fb.group({
    banco: [''],
  });

  name = '';
  data_inizio = '';
  data_fine = '';
  commessa = '';
  committente = '';
  capocantiere = '';
  indirizzo = '';
  note = '';
  nmezzi = 0;

  IdMachine = 0;
  zoom = 0;

  ODL_tot_cantiere = 15
  ODL_conclu_cantiere = 10

  isWorking = false;
  // options
  tooltipDisabled: boolean = false;
  labels: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  arcWidth: number = 0.45;
  legendPosition: any = 'below';
  title: string = ""


  colorScheme: Color =
    {
      domain: ['#3CB371', '#004162', '#EB861B'],
      group: ScaleType.Ordinal,
      selectable: false,
      name: 'Customer Usage',
    };


  markers: any[] = [];


  center: any;
  globals: Globals;
  ODL_panoramica: any[] = [];
  MachineHours: any[] = [];
  MachineHours_Activity: any[] = []
  view!: [number, number];
  campaignOne!: FormGroup;

  @ViewChild(GoogleMap) map!: GoogleMap;


  constructor(globals: Globals,
    private apiService: ApiService,
    private dateAdapter: DateAdapter<any>,
    public dialog: MatDialog,

    private fb: FormBuilder,
    private snackBar: MatSnackBar) {
    this.globals = globals;
    Object.assign(this, { ODL_panoramica });
    Object.assign(this, { MachineHours });
    Object.assign(this, { MachineHours_Activity });
  }

  // ngOnInit(): void {
  //   // Prendere posizione attuale
  //   // navigator.geolocation.getCurrentPosition((position) => {
  //   //   this.center = {
  //   //     lat: position.coords.latitude,
  //   //     lng: position.coords.longitude,
  //   //   }
  //   // })

  // }

  onSelect(data: any): void {
    //////////console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    //////////console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    //////////console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


  pageEvent!: PageEvent;
  @ViewChildren(MatSort) sort!: QueryList<MatSort>;
  sort1!: MatSort;
  sort2!: MatSort;

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 10;

  pageSizeMezzi: number = 2;
  pageIndexMezzi: number = 0;
  length = 0;
  lengthMezzi = 0;
  displayedColumns: string[] = [
    'code',
    'datestart',
    'dateend',
    'machine',
    'operator',
    'description',
    'stato',
    'tipolavoro',
    'actions',
    //'dynakit'
  ];

  displayedColumnsMezzi: string[] = [
    'image',
    'name',
    'model',
    'serialnumber',
    // 'cumulativeHours',
    'ODLAssegnati',
    'ODLCompletati',
    'actions'
  ];

  dataSource: any = [];

  dataSourceMezzi = [];

  workSiteSource: selectWorkSite[] = [];

  workSiteData: WorkSiteModel[] = [];

  machineSource: selectMachine[] = [];
  workSiteFilterSource: selectWorkSite[] = [];
  machineFilterSource: selectMachine[] = [];

  machineFilterSourceBoxElenco: selectMachine[] = [];
  machineFilterSourceBoxElencoNoTutti: selectMachine[] = [];
  activeFilterSource: selectWorkSite[] = [];
  completedFilterSource: selectWorkSite[] = [];
  //typeworkSource: selectWorkSite[] = [];
  typeworkFilterSource: selectWorkSite[] = [];
  operatorFilterSource: selectOperator[] = [];
  operatorSource: selectOperator[] = [];
  azioniSource: selectAction[] = [];
  materialiSource: selectAction[] = [];

  macchineAssociate: selectMachine[] = [];

  isEdit = false;
  isEditMezzi = false;
  isNew = false;
  idJob = 0;
  sortField: string = 'datestart';
  sortOrder: string = 'desc';


  sortFieldMezzi: string = 'model';
  sortOrderMezzi: string = 'asc';

  worksiteFilterId = '0';
  machineFilterId = '0';
  AssegnaMachine = '0';
  activeFilterId = '0';
  completedFilterId = '0';
  operatorFilterId = '0';
  typeworkFilterId = '0';

  showVibrazione = false;
  showFrequenza = false;
  showAmpiezza = false;
  showForzaCentrifuga = false;
  showVelocitaStesa = false;
  showSpessoreStesa = false;
  showLarghezzaStesa = false;
  frequenzaSource = [];
  frequenzaBancoSource = [];
  frequenzaTamperingBancoSource = [];
  ampiezzaSource = [];
  ampiezzaBancoSource = [];
  centrifugheSource = [];
  centrifugheBancoSource = [];
  velocitaStesaMin = 0;
  velocitaStesaMax = 0;
  spessoreStesaMin = 0;
  spessoreStesaMax = 0;
  larghezzaStesaMin = 0;
  larghezzaStesaMax = 0;
  showBanco = false;
  showTampering = false;
  showNumeroPassate = false;


  btnEnabled = true;

  Model = '';
  Dynalink = '';
  ShowVibrazione = false;
  Vibrazione = '';
  ShowFrequenzaHiLo = false;
  FrequenzaHiLo = '';
  ShowAmpiezzaHiLo = false;
  AmpiezzaHiLo = '';
  ShowForzaCentrifugaHiLo = false;
  ForzaCentrifugaHiLo = '';
  ShowVelocitaStesa = false;
  VelocitaStesa = '';
  ShowSpessoreStesaMax = false;
  SpessoreStesaMax = '';
  ShowLarghezzaStesa = false;
  LarghezzaStesa = '';
  ShowBanco = false;
  ShowVibrazioneBanco = false;
  VibrazioneBanco = '';
  ShowFrequenzaHiLoBanco = false;
  FrequenzaHiLoBanco = '';
  ShowAmpiezzaHiLoBanco = false;
  AmpiezzaHiLoBanco = '';
  ShowForzaCentrifugaHiLoBanco = false;
  ForzaCentrifugaHiLoBanco = '';
  ShowTamperingBanco = false;
  Tampering = '';
  TamperingHiLo = '';
  bancoSource: selectBanco[] = [];
  jobForm = this.fb.group({
    datestart: ['', Validators.required],
    dateend: ['', Validators.required],
    // code: ['', Validators.required],
    description: ['', Validators.required],
    idworksite: [''],
    idmachine: ['', Validators.required],
    idoperator: ['', Validators.required],
    idtypework: [''],
    mezzo: [''],
    vibrazione: [''],
    frequenza: [''],
    ampiezza: [''],
    forzacentrifuga: [''],
    velocitastesa: [''],
    spessorestesa: [''],
    larghezzastesa: [''],
    note: ['', Validators.maxLength(300)],
    //isactive: [true],
    numeropassate: [0],
    azione: [''],
    materiale: [''],
    banco: [''],
    tamperingbanco: false,
    frequenzatamperingbanco: [''],
    tempostimato: [''],
  });

  async ngOnInit(): Promise<void> {
    //////////console.log("hello!");
    this.GetWorkSiteActive();
    this.GetMacchineAssociate();
    //this.getAddress("London");
    this.dateAdapter.setLocale('it-IT');

    this.activeFilterSource = [];

    var item: selectWorkSite = { Id: '0', Description: 'Tutti' };
    this.activeFilterSource.push(item);

    var itemAttivi: selectWorkSite = { Id: '1', Description: 'Attivi' };
    this.activeFilterSource.push(itemAttivi);

    var itemNonAttivi: selectWorkSite = { Id: '2', Description: 'Non Attivi' };
    this.activeFilterSource.push(itemNonAttivi);

    this.completedFilterSource = [];

    var item: selectWorkSite = { Id: '0', Description: 'Tutti' };
    this.completedFilterSource.push(item);

    var itemNonConclusi: selectWorkSite = {
      Id: '1',
      Description: 'Non Conclusi',
    };
    this.completedFilterSource.push(itemNonConclusi);

    var itemConclusi: selectWorkSite = { Id: '2', Description: 'Conclusi' };
    this.completedFilterSource.push(itemConclusi);

    //this.typeworkSource = [];
    this.typeworkFilterSource = [];

    var itemTutti: selectWorkSite = { Id: '0', Description: 'Tutti' };
    this.typeworkFilterSource.push(itemTutti);

    var itemLavoro: selectWorkSite = { Id: '1', Description: 'Lavoro' };
    this.typeworkFilterSource.push(itemLavoro);
    //this.typeworkSource.push(itemLavoro);
    ////////console.log(new Date(2022, 13, 0));
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    var itemTrasferimento: selectWorkSite = {
      Id: '2',
      Description: 'Trasferimento',
    };
    this.typeworkFilterSource.push(itemTrasferimento);
    //this.typeworkSource.push(itemTrasferimento);

    this.campaignOne = new FormGroup({

      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month + 1, 0)),
    });


    if (this.worksiteFilterId != '0') {

      var found = false;
      var i = 0;
      while (!found && i < this.workSiteData.length) {
        if (this.workSiteData[i].Id == this.worksiteFilterId)
          found = true;
        else
          i++;
      }
      this.name = this.workSiteData[i].Description;

      var veraDataInizio = new Date(this.workSiteData[i].DateStart);

      var veraDataFine = new Date(this.workSiteData[i].DateEnd);
      //////console.log(veraDataInizio);
      this.campaignOne = new FormGroup({

        start: new FormControl(veraDataInizio),
        end: new FormControl(veraDataFine),
      });

      this.data_inizio = veraDataInizio.getDate() + "/" + (veraDataInizio.getMonth() + 1) + "/" + veraDataInizio.getFullYear();

      this.data_fine = veraDataFine.getDate() + "/" + (veraDataFine.getMonth() + 1) + "/" + veraDataFine.getFullYear();

      this.commessa = this.workSiteData[i].CodiceCommessa;
      this.committente = this.workSiteData[i].Client;
      this.capocantiere = this.workSiteData[i].BossName;
      this.indirizzo = this.workSiteData[i].Indirizzo;
      this.note = this.workSiteData[i].DescriptionExtended;

      //////////console.log(this.sort);
      //////console.log(this.sort);

      //ATTENZIONE!!!!! NON USARE NGIF MA HIDDEN SU TABLE E ELEMENTI PADRE INTERFERISCE!!!!!
      var sort1app = this.sort.last;
      var sort2app = this.sort.first;
      //////console.log(sort1app);
      //////console.log(sort2app);
      if (sort1app) {
        this.sort1 = sort1app;
        //////console.log("HELLO1")
        this.sort1.sortChange.subscribe((data: any) => {
          //////console.log("HELLO1")
          this.pageIndex = 0;
          this.sortField = this.sort1.active;
          this.sortOrder = this.sort1.direction;
          this.GetJob();
        });
      }
      if (sort2app) {
        this.sort2 = sort2app;
        this.sort2.sortChange.subscribe((data: any) => {
          this.pageIndexMezzi = 0;
          //////console.log("HELLO2")
          this.sortFieldMezzi = this.sort2.active;
          this.sortOrderMezzi = this.sort2.direction;
          this.GetMachine();
          this.GetMachineStorico();
        });
      }
    }

    this.GetMachineActive();
    this.GetMachineStorico();
    this.GetOperatorActive();
    this.GetJob();
    this.GetBanco();
    this.GetMachine();
    this.GetPosizioni()
    this.GetDataChart();

  }

  UpdatePage() {
    this.GetMachineActive();
    this.GetMachineStorico();
    this.GetOperatorActive();
    this.GetJob();
    this.GetBanco();
    this.GetMachine();
    this.GetDataChart();
  }

  sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }


  GetDataChart(): void {
    if (this.worksiteFilterId != '0')
      this.apiService.GetDataPieChart(Number(this.worksiteFilterId)).subscribe(
        (data) => {
          this.ODL_panoramica = data.ODL;
          this.ODL_tot_cantiere = data.ODL[0].value + data.ODL[1].value + data.ODL[2].value;
          this.ODL_conclu_cantiere = data.ODL[0].value;
          //////console.log(this.ODL_tot_cantiere);
        },
        (error) => {
          //////console.log(error);
        }
      );
  }

  ngAfterViewInit() {

    ////////////console.log(this.sort1);
    // //////////console.log(this.sort2);

  }



  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.GetJob();
  }


  handlePageEventMezzi(event: PageEvent) {

    this.lengthMezzi = event.length;
    this.pageSizeMezzi = event.pageSize;
    this.pageIndexMezzi = event.pageIndex;
    this.GetMachine();
    this.GetMachineStorico();
  }

  GetBanco(): void {

    this.apiService.GetMachineBanco().subscribe(
      (data) => {
        this.bancoSource = [];

        const itemBlank: selectBanco = { Id: "", Name: "" };
        this.bancoSource.push(itemBlank);

        data.forEach((w: selectBanco) => {
          this.bancoSource.push(w);
        });

      },
      (error) => {
        //////////console.log(error);
      }
    );
  }

  GetPosizioni() {
    ////////console.log("getPosizioni");
    if (this.worksiteFilterId != '0')
      this.apiService.getPosizioneMacchine(this.worksiteFilterId).subscribe(
        (data) => {
          ////console.log(data);
          this.markers = [];
          let southWest, northEast;
          this.center = {
            lat: 45.466290,
            lng: 9.187252,
          }
          ////////console.log(data.length);
          if (data.length > 0) {
            ////////console.log(data);
            let maxX = parseFloat(data[0].Lon), maxY = parseFloat(data[0].Lat), minX = parseFloat(data[0].Lon), minY = parseFloat(data[0].Lat);
            let maxDistance = 0;
            data.forEach((item: any) => {
              const image =
                "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

              let distance;
              if (maxX < parseFloat(item.Lon))
                maxX = parseFloat(item.Lon);
              if (maxY < parseFloat(item.Lat))
                maxY = parseFloat(item.Lat);
              if (minX > parseFloat(item.Lon))
                minX = parseFloat(item.Lon);
              if (minY > parseFloat(item.Lat))
                minY = parseFloat(item.Lat);
              let single = {
                position: {

                  lat: parseFloat(item.Lat),
                  lng: parseFloat(item.Lon),
                },
                label: {
                  color: 'black',
                  text: item.Name,
                },
                title: item.Name,
                options: {
                  animation: google.maps.Animation.DROP,
                  icon: item.isCantiere ? image : ''

                }
              };
              this.markers.push(single)

            }
            );

            this.center = {
              lat: (maxY + minY) / 2,
              lng: (maxX + minX) / 2,
            }

            southWest = {
              lat: minY - 0.01,
              lng: minX - 0.01,
            }
            northEast = {
              lat: maxY + 0.01,
              lng: maxX + 0.01,
            }

            //this.zoom
          }
          else {
            southWest = {
              lat: parseFloat(this.center.lat) - 0.5,
              lng: parseFloat(this.center.lng) - 0.5,
            }
            northEast = {
              lat: parseFloat(this.center.lat) + 0.5,
              lng: parseFloat(this.center.lng) + 0.5,
            }
          }

          if (this.map) {
            ////////console.log(southWest);
            ////////console.log(northEast);
            var bounds = new google.maps.LatLngBounds(southWest, northEast);

            this.map.fitBounds(bounds, 3);
          }
        },
        (error) => {
          ////////console.log(error);
        }
      );
  }

  GetMacchineAssociate(): void{
    this.apiService.GetMacchineAssociateNoFilter(this.worksiteFilterId).subscribe(
      (data) => {

        ////console.log("data: ", data)
        this.macchineAssociate = data

      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');
      }
    );

  }

  GetMachine(): void {

    this.isLoadingResults = true;

    let dataFilter = new FilterMachineAssociateModel(this.pageIndexMezzi, this.pageSizeMezzi, this.sortFieldMezzi, this.sortOrderMezzi, this.activeFilterId, this.worksiteFilterId);
    //////////console.log(dataFilter);

    this.apiService.GetMachineAssociate(dataFilter).subscribe(
      (data) => {

        this.dataSourceMezzi = data.Data;
        ////console.log(this.dataSourceMezzi);
        this.machineFilterSourceBoxElencoNoTutti = [];


        data.Data.forEach((item: selectMachine) => {
          this.machineFilterSourceBoxElencoNoTutti.push(item);
        });
        this.lengthMezzi = data.Count;

        this.isLoadingResults = false;

      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');
      }
    );
  }

  GetMachineStorico(): void {

    this.isLoadingResults = true;

    //////////console.log(dataFilter);
    this.apiService.GetMachineStorico(this.worksiteFilterId).subscribe(
      (data) => {

        this.machineFilterSourceBoxElenco = [];

        var itemTutti: selectMachine = { Id: '0', Name: 'Tutti' };
        this.machineFilterSourceBoxElenco.push(itemTutti);

        data.forEach((item: selectMachine) => {
          this.machineFilterSourceBoxElenco.push(item);

        });

        this.isLoadingResults = false;
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');
      }
    );
  }


  GetDataBarchart(idWorksite: number): void {

  }

  GetWorkSiteActive(): void {
    this.apiService.GetWorkSiteActive().subscribe(
      (data) => {
        this.workSiteSource = [];
        this.workSiteFilterSource = [];

        data.forEach((w: WorkSiteModel) => {
          this.workSiteData.push(w)
          this.workSiteSource.push(w);
          this.workSiteFilterSource.push(w);
        });
      },
      (error) => {
        //////////console.log(error);
      }
    );

  }

  GetMachineActive(): void {
    if (this.worksiteFilterId != '0')

      this.apiService.GetListMachineAssegnabili(this.worksiteFilterId).subscribe(
        (data) => {
          this.machineSource = [];
          this.machineFilterSource = [];



          data.forEach((item: selectMachine) => {
            this.machineSource.push(item);
            this.machineFilterSource.push(item);
          });
        },
        (error) => {
          //////////console.log(error);
        }
      );
  }

  GetOperatorActive(): void {

    this.apiService.GetOperatorActive().subscribe(
      (data) => {

        this.operatorSource = [];
        this.operatorFilterSource = [];
        var item: selectOperator = { Id: '0', Name: 'Tutti' };
        this.operatorFilterSource.push(item);

        data.forEach((item: selectOperator) => {
          this.operatorFilterSource.push(item);
          this.operatorSource.push(item);
        });
        //////////console.log(this.operatorFilterSource);
      },
      (error) => {
        //////////console.log(error);
      }
    );
  }

  changeToggle(element: any) {
    ////////console.log(element);
    this.apiService.UpdateJobToggle(element.Id).subscribe(
      () => {
        //this.GetJob();

      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  changeAllToggle() {
    ////////console.log(element);
    let dataFilter = new FilterJobModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.worksiteFilterId,
      this.machineFilterId,
      this.activeFilterId,
      this.completedFilterId,
      this.typeworkFilterId,
      this.operatorFilterId,
      this.campaignOne.value.start,
      this.campaignOne.value.end
    );
    //////////console.log(this.operatorFilterId);
    if (this.campaignOne.value.end)
      this.apiService.UpdateAllJobToggle(dataFilter).subscribe(
        (data) => {

          this.GetJob();
        },
        (error) => {
          //////////console.log(error);
          this.openSnackBarError(error);
          this.isLoadingResults = false;
        }
      );

  }

  GetJob(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterJobModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.worksiteFilterId,
      this.machineFilterId,
      this.activeFilterId,
      this.completedFilterId,
      this.typeworkFilterId,
      this.operatorFilterId,
      this.campaignOne.value.start,
      this.campaignOne.value.end
    );
    //////////console.log(this.operatorFilterId);
    if (this.campaignOne.value.end)
      this.apiService.GetJob(dataFilter).subscribe(
        (data) => {

          this.dataSource = data.Data;
          ////////console.log(this.dataSource)
          this.length = data.Count;
          this.isLoadingResults = false;
        },
        (error) => {
          //////////console.log(error);
          this.openSnackBarError(error);
          this.isLoadingResults = false;
        }
      );
  }

  Add(): void {
    this.isEdit = true;
    this.isNew = true;

    this.jobForm.setValue({
      datestart: new Date(),
      dateend: new Date(),
      // code: '',
      description: '',
      mezzo: '',
      //isactive: true,
      idworksite: this.worksiteFilterId,
      idmachine: '',
      idoperator: '',
      idtypework: '1',
      vibrazione: false,
      frequenza: '',
      ampiezza: '',
      forzacentrifuga: '',
      velocitastesa: '',
      spessorestesa: '',
      larghezzastesa: '',
      note: '',
      azione: '',
      materiale: '',
      numeropassate: 0,
      banco: '',
      tamperingbanco: false,
      frequenzatamperingbanco: '',
      tempostimato: ''
    });
  }

  Edit(element: any): void {
    this.isEdit = true;
    this.isNew = false;

    this.idJob = element.Id;

    this.readParameter(element.IdMachine);
    this.readMaterial(element.IdMachine);
    this.readAction(element.IdMachine);

    var typeid = '0';
    if (element.TipoLavoro == 'Lavoro') {
      typeid = '1';
    }
    if (element.TipoLavoro == 'Trasferimento') {
      typeid = '2';
    }

    this.jobForm.setValue({
      datestart: element.DateStart,
      dateend: element.DateEnd,
      // code: element.Code,
      description: element.Description,
      //isactive: true,
      idworksite: this.worksiteFilterId,
      idmachine: element.IdMachine,
      mezzo: element.Model,
      banco: element.Banco,
      idoperator: element.IdOperator,
      idtypework: typeid,
      vibrazione: element.Vibrazione,
      frequenza: element.Frequenza,
      ampiezza: element.Ampiezza,
      forzacentrifuga: element.ForzaCentrifuga,
      velocitastesa: element.VelocitaStesa,
      spessorestesa: element.SpessoreStesa,
      larghezzastesa: element.LarghezzaStesa,
      note: element.Note,
      azione: element.Azione,
      materiale: element.Materiale,
      numeropassate: element.NumeroPassate,
      tamperingbanco: element.TamperingBanco,
      frequenzatamperingbanco: element.FrequenzaTamperingBanco,
      tempostimato: element.TempoStimatoOre,
    });
  }

  Annulla(): void {
    this.isEdit = false;
    this.isNew = false;
  }

  Salva(): void {
    let datestart = new Date(this.jobForm.get('datestart')?.value).getTime();
    let dateend = new Date(this.jobForm.get('dateend')?.value).getTime();

    if (datestart > dateend) {
      this.openSnackBarError('Data di fine minore di data inizio !');
      return;
    }

    let dataJob = new JobModel(
      this.idJob,
      // this.jobForm.get('code')?.value,
      this.jobForm.get('description')?.value,
      //this.jobForm.get('isactive')?.value,
      this.jobForm.get('datestart')?.value,
      this.jobForm.get('dateend')?.value,
      this.worksiteFilterId,
      this.jobForm.get('idmachine')?.value,
      this.jobForm.get('idoperator')?.value,
      this.jobForm.get('idtypework')?.value,
      this.jobForm.get('vibrazione')?.value,
      this.jobForm.get('frequenza')?.value,
      this.jobForm.get('ampiezza')?.value,
      this.jobForm.get('forzacentrifuga')?.value,
      this.jobForm.get('velocitastesa')?.value,
      this.jobForm.get('spessorestesa')?.value,
      this.jobForm.get('larghezzastesa')?.value,
      this.jobForm.get('note')?.value,
      this.jobForm.get('azione')?.value,
      this.jobForm.get('tempostimato')?.value,
      this.jobForm.get('materiale')?.value,
      this.jobForm.get('numeropassate')?.value,
      this.jobForm.get('banco')?.value,
      this.jobForm.get('tamperingbanco')?.value,
      this.jobForm.get('frequenzatamperingbanco')?.value,
    );

    if (this.isNew) {
      this.apiService.AddJob(dataJob).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idJob = 0;
          this.ngOnInit();
          this.openSnackBarOk('Lavoro aggiunto');
        },
        (error) => {
          //////////console.log(error);
          this.openSnackBarError(error);
        }
      );
    } else {
      this.apiService.UpdateJob(dataJob).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idJob = 0;
          this.ngOnInit();
          this.openSnackBarOk('Lavoro modificato');
        },
        (error) => {
          //////////console.log(error);
          this.openSnackBarError(error.error.message);
        }
      );
    }
  }

  Info(element: any): void {
    this.isLoadingResults = true;

    let dataJob = new JobWorkModel(element.Id, '', false, new Date(), 0, 0);

    this.apiService.StatusJob(dataJob).subscribe(
      (data) => {
        console.log('Dati per dialog storico: ', data);
        this.isLoadingResults = false;

        this.dialog.open(JobDialogComponent, {
          panelClass: 'full-width-dialog',
          data: [data, element],
        });
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  EditMezzi(element: any): void {
    //////////console.log(element);

    this.Model = element.Model;
    this.IdMachine = element.Id;
    this.bancoForm.setValue({
      banco: element.Banco,
    });

    this.apiService.GetMachineParameterFromModel(element.Model).subscribe(
      (data) => {
        this.Dynalink = data.Dynalink;
        this.ShowVibrazione = data.ShowVibrazione;
        this.Vibrazione = data.Vibrazione;
        this.ShowFrequenzaHiLo = data.ShowFrequenzaHiLo;
        this.FrequenzaHiLo = data.FrequenzaHiLo;
        this.ShowAmpiezzaHiLo = data.ShowAmpiezzaHiLo;
        this.AmpiezzaHiLo = data.AmpiezzaHiLo;
        this.ShowForzaCentrifugaHiLo = data.ShowForzaCentrifugaHiLo;
        this.ForzaCentrifugaHiLo = data.ForzaCentrifugaHiLo;
        this.ShowVelocitaStesa = data.ShowVelocitaStesa;
        this.VelocitaStesa = data.VelocitaStesa;
        this.ShowSpessoreStesaMax = data.ShowSpessoreStesaMax;
        this.SpessoreStesaMax = data.SpessoreStesaMax;
        this.ShowLarghezzaStesa = data.ShowLarghezzaStesa;
        this.LarghezzaStesa = data.LarghezzaStesa;

        this.ShowBanco = false;

        if (data.Gruppo == "FINITRICE") {
          this.ShowBanco = true;
        }

        // Banco
        this.ShowVibrazioneBanco = data.ShowVibrazioneBanco;
        this.VibrazioneBanco = data.VibrazioneBanco;
        this.ShowFrequenzaHiLoBanco = data.ShowFrequenzaHiLoBanco;
        this.FrequenzaHiLoBanco = data.FrequenzaHiLoBanco;
        this.ShowAmpiezzaHiLoBanco = data.ShowAmpiezzaHiLoBanco;
        this.AmpiezzaHiLoBanco = data.AmpiezzaHiLoBanco;
        this.ShowForzaCentrifugaHiLoBanco = data.ShowForzaCentrifugaHiLoBanco;
        this.ForzaCentrifugaHiLoBanco = data.ForzaCentrifugaHiLoBanco;
        this.ShowTamperingBanco = data.ShowTampering;
        this.Tampering = data.Tampering;
        this.TamperingHiLo = data.TamperingHiLo;

        this.isEdit = true;
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  AnnullaMezzi(): void {
    this.isEdit = false;

    this.IdMachine = 0;
  }

  Read(): void {
    this.apiService.ReadMachine().subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else {
          this.openSnackBarOk('Lettura Effettuata');
          this.GetMachine();
          this.GetMachineStorico();
        }
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');
      }
    );
  }

  onChangeMachine() {
    let idmachine = this.jobForm.get('idmachine')?.value;
    let date: Date = this.jobForm.get('datestart')?.value;

    this.readParameter(idmachine);
    this.readEngaged(idmachine, date);
    this.readAction(idmachine);
    this.readMaterial(idmachine);
  }
  onChangeAzione() {
    let azione = this.jobForm.get('azione')?.value;
    //////console.log(azione);
    if (azione == "Trasferimento") {
      this.jobForm.get('velocitastesa')?.clearValidators();
      this.jobForm.get('velocitastesa')?.updateValueAndValidity();
      this.jobForm.get('spessorestesa')?.clearValidators();
      this.jobForm.get('spessorestesa')?.updateValueAndValidity();
      this.jobForm.get('larghezzastesa')?.clearValidators();
      this.jobForm.get('larghezzastesa')?.updateValueAndValidity();
      this.showNumeroPassate = false;
      this.showForzaCentrifuga = false;
      this.showAmpiezza = false;
      this.showVibrazione = false;
      this.showVelocitaStesa = false;
      this.showTampering = false;
      this.showSpessoreStesa = false;
      this.showLarghezzaStesa = false;
    }
    else {
      let idmachine = this.jobForm.get('idmachine')?.value;
      this.readParameter(idmachine);
    }
  }
  onChangeWorkSiteFilter() {
    this.GetJob();
  }

  readParameter(id: number) {
    this.apiService.GetParameter(id).subscribe(
      (data) => {
        // leggo parametri modello
        this.showNumeroPassate = true;

        this.showVibrazione = data.ShowVibrazione;
        this.showFrequenza = data.ShowFrequenzaHiLo;
        this.frequenzaSource = data.Frequenze;
        this.showAmpiezza = data.ShowAmpiezzaHiLo;
        this.ampiezzaSource = data.Ampiezze;
        this.showForzaCentrifuga = data.ShowForzaCentrifugaHiLo;
        this.centrifugheSource = data.ForzeCentrifuga;
        this.showVelocitaStesa = data.ShowVelocitaStesa;
        this.velocitaStesaMax = data.VelocitaStesaMax;
        this.velocitaStesaMin = data.VelocitaStesaMin;
        this.showSpessoreStesa = data.ShowSpessoreStesaMax;
        this.spessoreStesaMax = data.SpessoreStesaMaxValore;

        this.showLarghezzaStesa = false;

        this.jobForm.get('velocitastesa')?.clearValidators();
        this.jobForm.get('spessorestesa')?.clearValidators();
        this.jobForm.get('larghezzastesa')?.clearValidators();

        this.jobForm.get('mezzo')?.setValue(data.Model);

        if (this.showVelocitaStesa) {
          this.jobForm
            .get('velocitastesa')
            ?.addValidators([
              Validators.required,
              Validators.min(this.velocitaStesaMin),
              Validators.max(this.velocitaStesaMax),
            ]);
        }

        if (this.showSpessoreStesa) {
          this.jobForm
            .get('spessorestesa')
            ?.addValidators([
              Validators.required,
              Validators.min(this.spessoreStesaMin),
              Validators.max(this.spessoreStesaMax),
            ]);
        }

        // banco
        this.showBanco = data.ShowBanco;

        if (this.showBanco) {
          this.jobForm.get('banco')?.setValue(data.Banco);
          this.showNumeroPassate = false;
          this.showAmpiezza = false;

          this.showVibrazione = data.ShowVibrazioneBanco;
          this.frequenzaSource = data.FrequenzeBanco;

          //this.showAmpiezza = data.ShowAmpiezzaHiLoBanco;
          //this.ampiezzaSource = data.AmpiezzeBanco;

          this.showLarghezzaStesa = data.ShowLarghezzaStesaBanco;
          this.larghezzaStesaMin = data.LarghezzaStesaBancoMin;
          this.larghezzaStesaMax = data.LarghezzaStesaBancoMax;

          if (this.showLarghezzaStesa) {
            this.jobForm
              .get('larghezzastesa')
              ?.addValidators([
                Validators.required,
                Validators.min(this.larghezzaStesaMin),
                Validators.max(this.larghezzaStesaMax),
              ]);
          }




          this.showForzaCentrifuga = data.ShowForzaCentrifugaHiLoBanco;
          this.centrifugheSource = data.ForzeCentrifugaBanco;

          this.showTampering = data.ShowTampering;
          this.frequenzaTamperingBancoSource = data.TamperingValue;
        }
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  readAction(id: number) {
    this.apiService.GetAction(id).subscribe(
      (data) => {
        this.azioniSource = [];
        //var itemBlank: selectAction = { Id: '', Description: '' };
        //this.azioniSource.push(itemBlank);

        data.forEach((item: selectAction) => {
          var itemToAdd: selectAction = {
            Id: item.Description,
            Description: item.Description,
          };
          this.azioniSource.push(itemToAdd);
        });
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  readMaterial(id: number) {
    this.apiService.GetMaterial(id).subscribe(
      (data) => {
        this.materialiSource = [];
        var itemBlank: selectAction = { Id: '', Description: '' };
        this.materialiSource.push(itemBlank);

        data.forEach((item: selectAction) => {
          var itemToAdd: selectAction = {
            Id: item.Description,
            Description: item.Description,
          };
          this.materialiSource.push(itemToAdd);
        });
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  readEngaged(id: number, date: Date) {
    this.apiService.Engaged(id, date).subscribe(
      (data) => {
        if (data.IsEngaged == true) {
          //////////console.log(data.Text);

          this.dialog.open(JobEngagedDialogComponent, { data: data.Text });
        }
      },
      (error) => {
        //////////console.log(error);
      }
    );
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

  onChangeBanco() {
    let banco = this.bancoForm.get('banco')?.value;

    //////////console.log(banco);

    this.apiService.GetMachineParameterFromModelBanco(banco).subscribe(
      (data) => {

        this.ShowVibrazioneBanco = data.ShowVibrazione;
        this.VibrazioneBanco = data.Vibrazione;
        this.FrequenzaHiLoBanco = data.FrequenzaHiLo;

        this.ShowAmpiezzaHiLoBanco = data.ShowAmpiezzaHiLoBanco;
        this.AmpiezzaHiLoBanco = data.AmpiezzaHiLoBanco;

        this.ShowForzaCentrifugaHiLoBanco = data.ShowForzaCentrifugaHiLoBanco;
        this.ForzaCentrifugaHiLoBanco = data.ForzaCentrifugaHiLoBanco;

        this.ShowTamperingBanco = data.ShowTampering;
        this.Tampering = data.Tampering;
        this.TamperingHiLo = data.TamperingHiLo;

      },
      (error) => {
        //////////console.log(error);

        this.ShowVibrazioneBanco = false;
        this.VibrazioneBanco = '';
        this.FrequenzaHiLoBanco = '';
        this.AmpiezzaHiLoBanco = '';
        this.ForzaCentrifugaHiLoBanco = '';
        this.ShowTamperingBanco = false;
        this.Tampering = '';
        this.TamperingHiLo = '';

      }
    );


  }


  Test(element: any): void {
    //////////console.log(element);

    this.btnEnabled = false;

    this.apiService.ReadSingleMachine(element.Id).subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else {
          //////////console.log(data.Xml);
          this.dialog.open(DynalinkDialogComponent, { panelClass: 'full-width-dialog', data: data.Xml });
        }

        this.btnEnabled = true;
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');

        this.btnEnabled = true;
      }
    );
  }

  TestFuel(element: any): void {
    //////////console.log(element);

    this.btnEnabled = false;

    this.apiService.ReadSingleMachineFuel(element.Id).subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else {
          //////////console.log(data.Xml);
          this.dialog.open(DynalinkDialogComponent, { panelClass: 'full-width-dialog', data: data.Xml });
        }

        this.btnEnabled = true;
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');

        this.btnEnabled = true;
      }
    );



  }
  Assegna() {
    ////////console.log(this.AssegnaMachine);
    if (this.AssegnaMachine != '0')
      this.apiService.AssociaMachineWorksite(this.worksiteFilterId, this.AssegnaMachine).subscribe(
        (data) => {
          this.ngOnInit();
        },
        (error) => {
          //////////console.log(error);

        }
      );

  }
  EliminaMezzo(element: any): void {
    ////console.log(element.ODLAssegnati)

      this.apiService.DisassociaMachineWorksite(element.Id, this.worksiteFilterId).subscribe(
        (data) => {
          this.pageIndexMezzi = 0;
          this.ngOnInit();
        },
        (error) => {
          this.openSnackBarError('Il mezzo ha ancora degli ODL assegnati');
         }
      );

  }

  EliminaODL(element: any): void {

    let dataDialog = {
      text: "Vuoi davvero eliminare l'ordine di lavoro " + element.Code + "?",
      title: "Conferma eliminazione ODL",
    }

    const dialogConf = this.dialog.open(DialogConfermaComponent, {
      data: dataDialog
    });

    dialogConf.afterClosed().subscribe(result => {
      if (result == 'true') {
        this.apiService.EliminaODL(element.Id).subscribe(
          (data) => {
            this.openSnackBarOk("Ordine di lavoro eliminato con successo")
            this.ngOnInit();
          },
          (error) => {

          }
        );
      }
    });


  }

  TestEngine(element: any): void {
    //////////console.log(element);

    this.btnEnabled = false;

    this.apiService.ReadSingleMachineEngine(element.Id).subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else {
          //////////console.log(data.Xml);
          this.dialog.open(DynalinkDialogComponent, { panelClass: 'full-width-dialog', data: data.Xml });
        }

        this.btnEnabled = true;
      },
      (error) => {
        //////////console.log(error);
        this.openSnackBarError('Error');

        this.btnEnabled = true;
      }
    );



  }


  showSpinner() {

  }
  // latRad(lat: any) {
  //   var sin = Math.sin(lat * Math.PI / 180);
  //   var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  //   return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  // }

  // fzoom(mapPx:any, worldPx:any, fraction:any) {
  //   return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  // }

  // getBoundsZoomLevel(bounds:any, mapDim:any) {
  //   var WORLD_DIM = { height: 256, width: 256 };
  //   var ZOOM_MAX = 21;

  //   var ne = bounds.getNorthEast();
  //   var sw = bounds.getSouthWest();

  //   var latFraction = (this.latRad(ne.lat()) - this.latRad(sw.lat())) / Math.PI;

  //   var lngDiff = ne.lng() - sw.lng();
  //   var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  //   var latZoom = this.fzoom(mapDim.height, WORLD_DIM.height, latFraction);
  //   var lngZoom = this.fzoom(mapDim.width, WORLD_DIM.width, lngFraction);

  //   return Math.min(latZoom, lngZoom, ZOOM_MAX);
  // }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { saveAs } from '@progress/kendo-file-saver';
import * as JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { file } from 'jszip';

@Component({
  selector: 'app-GeneraReport',
  templateUrl: './GeneraReport.component.html',
  styleUrls: ['./GeneraReport.component.scss']
})
export class GeneraReportComponent implements OnInit {

  isLinear = true;
  tipologia: any = ''
  tipologie: string[] = ['Cantiere', 'Operatore', 'Committente'];
  cantieri: any = []
  operatori: any = []
  committenti: any = []
  cantieriSelezionati = new FormControl('');
  operatoriSelezionati = new FormControl('');
  committentiSelezionati = new FormControl('');

  displayedColumnsCantieri: string[] = ['CodiceCommessa', 'DateStart', 'DateEnd', 'Description', 'Indirizzo', 'BossName'];
  displayedColumnsOperatori: string[] = ['Name','Pin','ValidityDate']
  displayedColumnsCommittenti: string[] = ['RagioneSociale']
  displayedColumnsMezzi: string[] = ['Name', 'Model', 'SerialNumber', 'OEMName', 'Banco', 'IsValid', 'Dynakit', 'IsAssociate', 'IsActive', 'Cantiere', 'DateEnd', 'CumulativeHours']
  displayedColumnsODL: string[] = ['Code','Description', 'Note', 'Model', 'SerialNumber', 'DateStart', 'DateEnd', 'DateStartEffettivo', 'DateEndEffettivo', 'Azione', 'OperatorName', 'TipoLavoro', 'TempoStimatoOre', 'TempoOreDynakit', 'TempoOreMotore']

  displayedColumnsCantieri_edit: string[] = []
  displayedColumnsOperatori_edit: string[] = []
  displayedColumnsCommittenti_edit: string[] = []
  displayedColumnsMezzi_edit: string[] = []
  displayedColumnsODL_edit: string[] = []

  allComplete: boolean = true;

  idListCantieri: any = []
  idListOperatori: any = []
  idListCommittenti: any = []

  reportDataForDownload: any
  dataLength: any
  Primary: ThemePalette = 'warn'
  tabsLabels: any = []
  sheets: any
  fileName: any

  pageIndex: number = 0;
  pageSize: number = 10;
  length: any
  mode : ProgressSpinnerMode = 'indeterminate';
  spinner: boolean = false

  ObjCommittenti: any
  committenti_sheet!: XLSX.WorkSheet;
  zipName: any
  currentDateFormatted: any

  workbook!: XLSX.WorkBook

  CANTIERE_FORM_DATA: any = {
    name: 'Seleziona tutti',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Commessa', description: 'CodiceCommessa', completed: true, color: 'primary'},
      {name: 'Data inizio', description: 'DateStart', completed: true, color: 'primary'},
      {name: 'Data fine', description: 'DateEnd', completed: true, color: 'primary'},
      {name: 'Descrizione', description: 'Description', completed: true, color: 'primary'},
      {name: 'Indirizzo', description: 'Indirizzo', completed: true, color: 'primary'},
      {name: 'Capocantiere', description: 'BossName', completed: true, color: 'primary'},
    ],
  };

  OPERATORE_FORM_DATA: any = {
    name: 'Seleziona tutti',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Nome & Cognome', description: 'Name', completed: true, color: 'primary'},
      {name: 'Pin', description: 'Pin', completed: true, color: 'primary'},
      {name: 'Data fine validità', description: 'ValidityDate', completed: true, color: 'primary'},
    ],
  };

  ODL_FORM_DATA: any = {
    name: 'Seleziona tutti',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Codice', description: 'Code', completed: true, color: 'primary'},
      {name: 'Modello', description: 'Model', completed: true, color: 'primary'},
      {name: 'Numero siariale', description: 'SerialNumber', completed: true, color: 'primary'},
      {name: 'Data inizio (Prevista)', description: 'DateStart', completed: true, color: 'primary'},
      {name: 'Data fine (Prevista)', description: 'DateEnd', completed: true, color: 'primary'},
      {name: 'Data inizio (Effettiva)', description: 'DateStartEffettivo', completed: true, color: 'primary'},
      {name: 'Data fine (Effettiva)', description: 'DateEndEffettivo', completed: true, color: 'primary'},
      {name: 'Descrizione', description: 'Description', completed: true, color: 'primary'},
      {name: 'Note', description: 'Note', completed: true, color: 'primary'},
      {name: 'Attività', description: 'Azione', completed: true, color: 'primary'},
      {name: 'Operatore', description: 'OperatorName', completed: true, color: 'primary'},
      {name: 'Tipologia', description: 'TipoLavoro', completed: true, color: 'primary'},
      {name: 'Ore previste', description: 'TempoStimatoOre', completed: true, color: 'primary'},
      {name: 'Ore Dyn@Kit', description: 'TempoOreDynakit', completed: true, color: 'primary'},
      {name: 'Ore motore', description: 'TempoOreMotore', completed: true, color: 'primary'},
    ],
  };

  MEZZO_FORM_DATA: any = {
    name: 'Seleziona tutti',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Modello', description: 'Model', completed: true, color: 'primary'},
      {name: 'Numero seriale', description: 'SerialNumber', completed: true, color: 'primary'},
      {name: 'Nome', description: 'Name', completed: true, color: 'primary'},
      {name: 'Banco', description: 'Banco', completed: true, color: 'primary'},
      {name: 'Ore cumulative', description: 'CumulativeHours', completed: true, color: 'primary'},
      {name: 'Scadenza licenza', description: 'DateEnd', completed: true, color: 'primary'},
      {name: 'Cantiere attuale', description: 'Cantiere', completed: true, color: 'primary'},
      {name: 'Censito', description: 'IsValid', completed: true, color: 'primary'},
      {name: 'Dyn@kit', description: 'Dynakit', completed: true, color: 'primary'},
      {name: 'Associato', description: 'IsAssociate', completed: true, color: 'primary'},
      {name: 'Disponibilità', description: 'IsActive', completed: true, color: 'primary'},
      {name: 'OEM', description: 'OEMName', completed: true, color: 'primary'},
    ],
  };

  COMMITTENTE_FORM_DATA: any = {
    name: 'Seleziona tutti',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Ragione sociale', description: 'RagioneSociale', completed: true, color: 'primary'},
    ],
  };

  FORM_DATA_LIST: any = [this.CANTIERE_FORM_DATA, this.OPERATORE_FORM_DATA, this.ODL_FORM_DATA, this.MEZZO_FORM_DATA, this.COMMITTENTE_FORM_DATA]

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    ) { }

  ngOnInit() {
    this.GetCantieri()
    this.GetOperatori()
    this.GetCommittenti()
  }

  GetCantieri(){
    this.apiService.GetAllWorkSite().subscribe(
      (data) => {
        this.cantieri = data
        //console.log('AllCantieri: ', this.cantieri)
      },
      (error) => {
        this.openSnackBarError('Impossibile recuperare i cantieri');
      }
    );
  }

  GetOperatori(){
    this.apiService.GetOperatorAll().subscribe(
      (data) => {
        this.operatori = data
        //console.log('AllOperatori: ', this.operatori)
      },
      (error) => {
        this.openSnackBarError('Impossibile recuperare gli operatori');
      }
    );
  }

  GetCommittenti(){
    this.apiService.GetAllCommittenti().subscribe(
      (data) => {
        this.committenti = data
        let result: any = [];
        for (const item of this.committenti) {
            if (!result.includes(item.Client)) {
                result.push(item.Client);
            }
        }

        this.committenti = result
        //console.log('AllCommittenti: ', this.committenti)
      },
      (error) => {
        this.openSnackBarError('Impossibile recuperare i committenti');
      }
    );
  }

  resetAll(stepType: any){

    switch (stepType) {

      case('step2'):
        //this.reportDataForDownload = []
        this.tipologia = '';
        this.cantieriSelezionati = new FormControl('');
        this.operatoriSelezionati = new FormControl('');
        this.committentiSelezionati = new FormControl('');

        this.idListCantieri = []
        this.idListOperatori = []
        this.idListCommittenti = []

        // RESET DI TUTTI I FORM MULTI SELECT PER FILTRI DATI REPORT (STEP 3):

        this.FORM_DATA_LIST.forEach((element: any) => {
          (element.subtasks).forEach((element_subtasks: any) => {
            element_subtasks.completed = true
          });
        });

        this.GetCantieri()
        this.GetOperatori()
        this.GetCommittenti()

        break;

      case ('step3'):
        this.idListCantieri = []
        this.idListOperatori = []
        this.idListCommittenti = []
        break;

      case ('step4'):
        this.tipologia = ''
        this.cantieriSelezionati = new FormControl('');
        this.operatoriSelezionati = new FormControl('');
        this.committentiSelezionati = new FormControl('');


        // RESET DI TUTTI I FORM MULTI SELECT PER FILTRI DATI REPORT (STEP 3):

        this.FORM_DATA_LIST.forEach((element: any) => {
          (element.subtasks).forEach((element_subtasks: any) => {
            element_subtasks.completed = true
          });
        });


        //RESET COLONNE TABELLE ANTEPRIMA REPORT

        this.displayedColumnsCantieri_edit = []
        this.displayedColumnsOperatori_edit = []
        this.displayedColumnsCommittenti_edit = []
        this.displayedColumnsMezzi_edit = []
        this.displayedColumnsODL_edit = []


        //RESET LISTA DI ID DELLE ENTITA' SELEZIONATE ALLO STEP2
        this.idListCantieri = []
        this.idListOperatori = []
        this.idListCommittenti = []

        this.GetCantieri()
        this.GetOperatori()
        this.GetCommittenti()

        break;

      case ('step4_Back'):

        //RESET COLONNE TABELLE ANTEPRIMA REPORT

        this.displayedColumnsCantieri_edit = []
        this.displayedColumnsOperatori_edit = []
        this.displayedColumnsCommittenti_edit = []
        this.displayedColumnsMezzi_edit = []
        this.displayedColumnsODL_edit = []
        break;
    }
  }

  updateAllComplete(obj: any) {
    this.allComplete = obj.subtasks != null && obj.subtasks.every((t: { completed: any; }) => t.completed);
  }

  someComplete(obj: any): boolean {
    if (obj.subtasks == null) {
      return false;
    }
    return obj.subtasks.filter((t: { completed: any; }) => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean, obj: any) {
    this.allComplete = completed;
    if (obj.subtasks == null) {
      return;
    }
    obj.subtasks.forEach((t: { completed: any; }) => (t.completed = completed));
  }

  buildDisplayColumnForStep4(){
    (this.CANTIERE_FORM_DATA.subtasks).forEach((sub: any) => {
      if (sub.completed) {
        this.displayedColumnsCantieri_edit.push(sub.description)
      }
    });

    (this.OPERATORE_FORM_DATA.subtasks).forEach((sub: any) => {
      if (sub.completed) {
        this.displayedColumnsOperatori_edit.push(sub.description)
      }
    });

    (this.ODL_FORM_DATA.subtasks).forEach((sub: any) => {
      if (sub.completed) {
        this.displayedColumnsODL_edit.push(sub.description)
      }
    });

    (this.MEZZO_FORM_DATA.subtasks).forEach((sub: any) => {
      if (sub.completed) {
        this.displayedColumnsMezzi_edit.push(sub.description)
      }
    });

    (this.COMMITTENTE_FORM_DATA.subtasks).forEach((sub: any) => {
      if (sub.completed) {
        this.displayedColumnsCommittenti_edit.push(sub.description)
      }
    });

    //console.log(this.displayedColumnsCantieri_edit, this.displayedColumnsOperatori_edit, this.displayedColumnsODL_edit, this.displayedColumnsMezzi_edit, this.displayedColumnsCommittenti_edit)
  }

  GetDataForDownload(cantieri: any, operatori: any, committenti: any){

    cantieri.forEach((element: any) => {
      this.idListCantieri.push(element.Id)
    });

    if (operatori != '') {
      operatori.forEach((element: any) => {
        this.idListOperatori.push(element.Id)
      });
    }

    if (committenti != '') {
      committenti.forEach((element: any) => {
        this.idListCommittenti.push(element)
      });
    }

    switch (this.tipologia) {

      case ('Cantiere'):

        this.apiService.GetDataCantieriForDownload(this.idListCantieri).subscribe(
          (data: any) => {
            this.tabsLabels = data.Labels
            this.reportDataForDownload = data.ReportData
            this.dataLength = this.reportDataForDownload.length

          },
          (error: any) => {
            this.openSnackBarError('Impossibile recuperare i dati per la tipolgia cantieri');
          }
        );
        break;

      case ('Operatore'):
        this.apiService.GetDataOperatoriForDownload(this.idListOperatori , this.idListCantieri).subscribe(
          (data: any) => {
            this.tabsLabels = data.Labels
            this.reportDataForDownload = data.ReportData
            this.dataLength = this.reportDataForDownload.length

          },
          (error: any) => {
            this.openSnackBarError('Impossibile recuperare i dati per la tipolgia operatori');
          }
        );
        break;

      case ('Committente'):
        this.apiService.GetDataCommittentiForDownload(this.idListCommittenti , this.idListCantieri).subscribe(
          (data: any) => {
            this.tabsLabels = data.Labels
            this.reportDataForDownload = data.ReportData
            this.dataLength = this.reportDataForDownload.length

            console.log('LunghezzaData: ', this.dataLength)
            console.log('Labels: ', this.tabsLabels)
            console.log('Data: ', this.reportDataForDownload)
            //console.log('lunghezza operatori', this.reportDataForDownload[0].Operatori.length)
            //console.log(data)

          },
          (error: any) => {
            this.openSnackBarError('Impossibile recuperare i dati per la tipolgia committenti');
          }
        );
        break;
    }
  }

  GetIntero(input: any) {
    if (!isNaN(input)) {
      return Math.floor(input);
    }
    return input;
  };

  onChangeCommittente(listCommittenti: any){
    console.log('change!', listCommittenti.value)
    this.apiService.GetCantieriByCommittente(listCommittenti.value).subscribe(
      (data: any) => {
        this.cantieri = data
      },
      (error: any) => {
        this.openSnackBarError('Impossibile recuperare i cantieri del committente');
      }
    );
  }

  getList(tipologia: any){

    switch (tipologia) {

      case ('Cantiere'):
        return(this.cantieriSelezionati.value)

      case('Operatore'):
        return(this.operatoriSelezionati.value)

      case('Committente'):
        return(this.committentiSelezionati.value)
    }
  }

  filterData(data: { [key: string]: any }[], displayedColumns: string[]) {
    type DataItem = { [key: string]: any };

    return data.map((item: DataItem) => {
      return Object.keys(item)
        .filter(chiave => displayedColumns.includes(chiave))
        .reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {} as DataItem);
    });
  }

  reportDownload(dataReport: any){

    if(this.dataLength > 1)
    {
      const jszip = new JSZip();

      const workbook = XLSX.utils.book_new();

      dataReport.forEach((report: any) => {
        const ObjCantieri = this.filterData(report.Cantieri, this.displayedColumnsCantieri_edit);
        const ObjMezzi = this.filterData(report.Mezzi, this.displayedColumnsMezzi_edit);
        const ObjODL = this.filterData(report.ODL, this.displayedColumnsODL_edit);
        const ObjOperatori = this.filterData(report.Operatori, this.displayedColumnsOperatori_edit);

        if (this.tipologia == 'Committente') {
          this.ObjCommittenti = this.filterData(report.Committenti, this.displayedColumnsCommittenti_edit);
        }

        const cantieri_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjCantieri)
        const mezzi_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjMezzi)
        const operatori_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjOperatori)
        const odl_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjODL)

        if (this.tipologia == 'Committente') {
          this.committenti_sheet = XLSX.utils.json_to_sheet(this.ObjCommittenti)
        }

        switch (this.tipologia) {

          case ('Cantiere'):
            this.sheets = {
              'Cantieri': cantieri_sheet,
              'Mezzi': mezzi_sheet,
              'ODL': odl_sheet,
              'Operatori': operatori_sheet,
            }

            this.fileName = report.Cantieri[0].Description;

            break;

          case ('Operatore'):
            this.sheets = {
              'Operatori': operatori_sheet,
              'Cantieri': cantieri_sheet,
              'Mezzi': mezzi_sheet,
              'ODL': odl_sheet,
            }

            this.fileName = report.Operatori[0].Name;

            break;

          case ('Committente'):
            this.sheets = {
              'Committenti': this.committenti_sheet,
              'Cantieri': cantieri_sheet,
              'Operatori': operatori_sheet,
              'Mezzi': mezzi_sheet,
              'ODL': odl_sheet,
            }

            this.fileName = report.Committenti[0].RagioneSociale;

            break;
        }

        const ReportWorkbook: XLSX.WorkBook = {
          Sheets: this.sheets,
          SheetNames: this.tabsLabels,
        };


        //DATA CORRENTE IN FORMATO YYYYMMDD
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        const day = ('0' + currentDate.getDate()).slice(-2);

        this.currentDateFormatted = year.toString() + month.toString() + day.toString();

        const FileName = 'Report_' + this.fileName + '_' + this.currentDateFormatted + '.xlsx';

        const FileNameTosave = 'Report_' + this.fileName + '_' + this.currentDateFormatted

        const buffer = XLSX.write(ReportWorkbook, { type: 'buffer', bookType: 'xlsx' });

        const excelBufferToSend: any = XLSX.write(ReportWorkbook, { bookType: 'xlsx', type: 'base64' });

        this.saveReport(excelBufferToSend, FileNameTosave)

        jszip.file(FileName, buffer);

      });

      switch (this.tipologia) {
        case 'Cantiere':
          this.zipName = 'Report_Cantieri_' + this.currentDateFormatted;
          break;

        case 'Operatore':
          this.zipName = 'Report_Operatori_' + this.currentDateFormatted;
          break;

        case 'Committente':
          this.zipName = 'Report_Committenti_' + this.currentDateFormatted;
          break;
      }

      jszip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, this.zipName);
      });

    }
    else
    {
      const ObjCantieri = this.filterData(dataReport[0].Cantieri, this.displayedColumnsCantieri_edit);
      const ObjMezzi = this.filterData(dataReport[0].Mezzi, this.displayedColumnsMezzi_edit);
      const ObjODL = this.filterData(dataReport[0].ODL, this.displayedColumnsODL_edit);
      const ObjOperatori = this.filterData(dataReport[0].Operatori, this.displayedColumnsOperatori_edit);

      if (this.tipologia == 'Committente') {
        this.ObjCommittenti = this.filterData(dataReport[0].Committenti, this.displayedColumnsCommittenti_edit);
      }

      const cantieri_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjCantieri)
      const mezzi_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjMezzi)
      const operatori_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjOperatori)
      const odl_sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ObjODL)

      if (this.tipologia == 'Committente') {
        this.committenti_sheet = XLSX.utils.json_to_sheet(this.ObjCommittenti)
      }

      switch (this.tipologia) {

        case ('Cantiere'):
          this.sheets = {
            'Cantieri': cantieri_sheet,
            'Mezzi': mezzi_sheet,
            'ODL': odl_sheet,
            'Operatori': operatori_sheet,
          }

          this.fileName = this.reportDataForDownload[0].Cantieri[0].Description;

          break;

        case ('Operatore'):
          this.sheets = {
            'Operatori': operatori_sheet,
            'Cantieri': cantieri_sheet,
            'Mezzi': mezzi_sheet,
            'ODL': odl_sheet,
          }

          this.fileName = this.reportDataForDownload[0].Operatori[0].Name;

          break;

        case ('Committente'):
          this.sheets = {
            'Committenti': this.committenti_sheet,
            'Cantieri': cantieri_sheet,
            'Operatori': operatori_sheet,
            'Mezzi': mezzi_sheet,
            'ODL': odl_sheet,
          }

          this.fileName = this.reportDataForDownload[0].Committenti[0].RagioneSociale;

          break;
      }

      const workbook: XLSX.WorkBook = {
        Sheets: this.sheets,
        SheetNames: this.tabsLabels,
      };

      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const data: Blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);


      //DATA CORRENTE IN FORMATO YYYYMMDD
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      const day = ('0' + currentDate.getDate()).slice(-2);

      this.currentDateFormatted = year.toString() + month.toString() + day.toString();

      link.download = 'Report_' + this.fileName + '_' + this.currentDateFormatted;
      link.click();

      const excelBufferToSend: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

      console.log('excelBufferToSend: ', excelBufferToSend)


      this.saveReport(excelBufferToSend, link.download)

    }
  }

  saveReport(data: any, name: any){
    this.apiService.saveReport(data, name).subscribe(
      (data: any) => {

      },
      (error: any) => {
        console.log('Errore nel salvataggio del file')
      }
    );
  }

  readFile(data: any, name: any){
    const bytes = atob(data);
    const byteLength = bytes.length;
    const byteArray = new Uint8Array(byteLength);
    for (let i = 0; i < byteLength; i++) {
        byteArray[i] = bytes.charCodeAt(i);
    }

    // Use SheetJS to create a new workbook from the byte array
    const workbook = XLSX.read(byteArray, { type: 'array' });

    // Use SheetJS to convert the workbook to a new file blob
    const fileBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', bookSST: true });

    // Convert the file blob to a Blob object
    const blob = new Blob([fileBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    name = name + '.xlsx'
    // Save the blob as a file using FileSaver.js
    saveAs(blob, name);

  }

  getMinutes(num:any) {
    num %= 1
    num = parseFloat(num).toFixed(2)
    num*=60
    return num;
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

export var ODL = [
  {
    "name": "Conclusi",
    "value": 27
  },
  {
    "name": "In attesa",
    "value": 7
  }
];

export var Cantieri = [
  {
    "name": "Aperti",
    "value": 10
  },
  {
    "name": "Chiusi",
    "value": 15
  },
];

export var Mezzi = [
  {
    "name": "Impiegati",
    "value": 47
  },
  {
    "name": "Fermi",
    "value": 10
  },

];

export var ODL_panoramica = [
  {
    "name": "Conclusi",
    "value": 10
  },
  {
    "name": "In attesa",
    "value": 5
  },

];

export var MachineHours = [
  {
    "name": "Ore motore",
    "value": 0
  },
  {
    "name": "Ore Dyn@kit",
    "value": 0
  },
  {
    "name": "Ore previste",
    "value": 0
  },
];

export var MachineHours_Activity = [
  {
    "name": "Compatta",
    "series": [
      {
        "name": "Ore motore",
        "value": 0
      },
      {
        "name": "Ore Dyn@kit",
        "value": 0
      },
      {
        "name": "Ore previste",
        "value": 8
      },
    ]
  },

  {
    "name": "Trasferimento",
    "series": [
      {
        "name": "Ore motore",
        "value": 16
      },
      {
        "name": "Ore Dyn@kit",
        "value": 7
      },
      {
        "name": "Ore previste",
        "value": 4
      },
    ]
  },
];

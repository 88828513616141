export class JobWorkModel {

  constructor(
    public id: number,
    public description: string,
    public isactive: boolean,
    public datestart: Date,
    public idworksite: number,
    public idmachine: number,
  ) {  }

}


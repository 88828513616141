import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn() !== true) {
      this.router.navigate(['/login']);
    }

    var result = false;

    var role = this.authService.getRole();

    var url = next.url[0].path;
    console.log(url);

    if (role == 'admin') {
      switch (url) {
        case 'profile':
          result = true;
          break;
        case 'dashboard':
          result = true;
          break;
        case 'users-admin':
          result = true;
          break;
        case 'parameter':
          result = true;
          break;
        case 'dynakit':
          result = true;
          break;
        case 'help':
          result = false;
          break;
        case 'home-admin':
            result = true;
            break;
        default:
          break;
      }
    }

    if (role == 'supervisor') {
      switch (url) {
        case 'profile':
          result = true;
          break;
        case 'dashboard':
          result = true;
          break;
        case 'users-admin':
          result = true;
          break;
        case 'dynakit':
          result = true;
          break;
        case 'help':
          result = false;
          break;
        case 'home-supervisor':
            result = true;
            break;
        default:
          break;
      }
    }

    if (role == 'poweruser') {
      switch (url) {
        case 'dashboard':
          result = true;
          break;
        case 'users':
          result = true;
          break;
        case 'report':
          result = true;
          break;
        case 'help':
          result = true;
          break;
        case 'profile':
          result = true;
          break;
        case 'machine':
          result = true;
          break;
        case 'job':
          result = true;
          break;
        case 'worksite':
          result = true;
          break;
        case 'firstpage':
          result = true;
          break;
        case 'operator':
          result = true;
          break;
        case 'dashboardCantiere':
          result = true;
          break;
        case 'dashboardMezzo':
          result = true;
          break;
        case 'GeneraReport':
          result = true;
          break;
        case 'StoricoReport':
          result = true;
          break;
        case 'management':
          result = true;
          break;
        case 'anagraficaDynaKit':
          result = true;
          break;
        default:
          break;
      }
    }

    if (role == 'user') {
      switch (url) {
        case 'jobtest':
          result = true;
          break;
        case 'job':
          result = true;
          break;
        case 'profile':
          result = true;
          break;
        case 'worksite':
          result = true;
          break;
        case 'help':
          result = true;
          break;
        case 'management':
          result = true;
          break;
        case 'dashboardCantiere':
          result = true;
          break;
        case 'dashboardMezzo':
          result = true;
          break;
        case 'anagraficaDynaKit':
          result = true;
          break;
        default:
          break;
      }
    }

    return result;
  }
}

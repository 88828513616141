import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-home-supervisor',
  templateUrl: './home-supervisor.component.html',
  styleUrls: ['./home-supervisor.component.scss']
})
export class HomeSupervisorComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApiService,
  ) { }

  AZIENDE: any
  MACCHINE: any
  KIT: any

  selectedAnomalia: any
  linkBugTrucker: any

  ngOnInit() {
    this.GetInfoAziende()
    this.GetInfoMachine()
    this.GetInfoKit()
  }

  GetInfoAziende(){
    this.apiService.GetInfoAziende().subscribe(
      (data: any) => {
        this.AZIENDE = data
      },
      (error: any) => {
        this.openSnackBarError('Impossibile recuperare i dati delle aziende');
      }
    );
  }

  GetInfoMachine(){
    this.apiService.GetInfoMachine().subscribe(
      (data: any) => {
        this.MACCHINE = data
      },
      (error: any) => {
        this.openSnackBarError('Impossibile recuperare i dati dei kit');
      }
    );
  }

  GetInfoKit(){
    this.apiService.GetInfoKit().subscribe(
      (data: any) => {
        console.log('kit: ', data)
        this.KIT = data
      },
      (error: any) => {
        this.openSnackBarError('Impossibile recuperare i dati dei kit');
      }
    );
  }

  checkCreateDate(dateString: string): boolean {
    const dateToCheck = new Date(dateString);
    const today = new Date()
    console.log(dateToCheck)
    console.log(today)
    const date = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
    date.setDate(date.getDate() + 3);
    return date <= today
  }

  showAnteprima(anomalia: any){
    this.selectedAnomalia = anomalia
    this.linkBugTrucker = "http://bugtracker.ulisse1984.it/view.php?id=" + anomalia.id.toString()
  }

  //GESTIONE SNACKBAR

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition:'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

<!-- TITOLO E FILTRO -->

<div #riferimento>
  <mat-card class="card_filtri">

  <mat-card-title id="title_dashboard">
    Dashboard cantiere
  </mat-card-title>

  <mat-card-content>

    <mat-form-field appearance="fill" id="filtro_cantiere">
      <mat-label>Filtra Cantiere</mat-label>
      <mat-select [(ngModel)]="idWorksite" (selectionChange)="ngOnInit()">
        <mat-option *ngFor="let ws of worksitelist" [value]="ws.Id">
          {{ ws.Description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-card-content>

</mat-card>
</div>

<!-- BOX AVVISO INSERIMENTO CANTIERE -->

<mat-card id="alert-card-filterWorksite" *ngIf="idWorksite == 0">
  <mat-icon style="font-size: 35px; margin-top: 7px; margin-left: 10px;">warning</mat-icon>
  <p id="alert-text">Per generare la dashboard occorre selezionare un cantiere</p>
</mat-card>

<!-- BOX ANAGRAFICA -->

<div #anagrafica>
  <mat-card class="box_anagrafica" *ngIf="idWorksite != 0" [ngClass]="{'box_anagrafica_1':  riferimento.offsetWidth <= 1450}">

  <mat-card-title class="title-box">
    <mat-icon>library_books</mat-icon><span>Anagrafica</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-form-field appearance="fill" class="input_label">
      <mat-label>Data di inizio</mat-label>
      <input matInput value={{data_inizio}} readonly>
      <mat-datepicker-toggle matSuffix [for]="inizio"></mat-datepicker-toggle>
      <mat-datepicker #inizio></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_end">
      <mat-label>Data di fine</mat-label>
      <input matInput value={{data_fine}} readonly>
      <mat-datepicker-toggle matSuffix [for]="fine"></mat-datepicker-toggle>
      <mat-datepicker #fine></mat-datepicker>
    </mat-form-field>

    <!-- <mat-checkbox [checked]="checked" [disabled]="true"> Attivo</mat-checkbox> -->

    <mat-form-field appearance="fill" class="input_label">
      <mat-label>Nome</mat-label>
      <input matInput value={{name}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_end">
      <mat-label>Commessa</mat-label>
      <input matInput value={{commessa}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label">
      <mat-label>Committente</mat-label>
      <input matInput value={{committente}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_end">
      <mat-label>Capocantiere</mat-label>
      <input matInput value={{capocantiere}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_long">
      <mat-label>Indirizzo</mat-label>
      <input matInput value={{indirizzo}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_long">
      <mat-label>Note</mat-label>
      <input matInput value={{note}} readonly>
    </mat-form-field>

  </mat-card-content>

</mat-card>
</div>


<!-- BOX PANORAMICA -->

<mat-card class="box_panoramica" *ngIf="idWorksite != 0" [ngClass]="{'box_panoramica_1':  riferimento.offsetWidth < 1450, 'box_panoramica_2': machineName != ''}" >
  <mat-card-title class="title-box">
    <mat-icon>data_usage</mat-icon><span>Panoramica</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <div #riferimento1>
    <mat-card-content >

    <!-- LABEL DATI PIECHART -->

    <div style="float: left; width: 290px;" #piechart>
      <mat-form-field appearance="fill" class="input_label_panoramica">
        <mat-label>N° ODL totali</mat-label>
        <input matInput value={{ODL_tot_cantiere}} readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" class="input_label_panoramica">
        <mat-label>N° ODL conclusi</mat-label>
        <input matInput value={{ODL_conclu_cantiere}} readonly>
      </mat-form-field>

      <div #dividerpiechart>
        <mat-divider [inset]="true" style="width: 288px;"></mat-divider>
      </div>


      <mat-card id="alert-card-piechart" *ngIf="ODL_tot_cantiere == 0">
        <mat-icon style="font-size: 43px; margin-top: 5px; margin-left: -25px;">warning</mat-icon>
        <p id="alert-text-piechart">Dati insufficienti per la generazione del grafico</p>
      </mat-card>

      <!-- PIE CHART -->

      <div class="grafico">
        <ngx-charts-pie-chart *ngIf="ODL_tot_cantiere != 0"
        [view]="view" [results]="ODL_panoramica"
          [arcWidth]="arcWidth" [gradient]="gradient" [legendTitle]="title" [legend]="showLegend"
          [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="isDoughnut" [scheme]="colorScheme">
        </ngx-charts-pie-chart>

      </div>
    </div>

    <mat-divider [inset]="true" id="vertical_divider"></mat-divider>

    <mat-form-field appearance="fill" class="filtro-mezzo">
      <mat-label>Filtra mezzo</mat-label>
      <mat-select [(ngModel)]="machineName" (selectionChange)="DataBarChart()">
        <mat-option *ngFor="let element of macchine" [value]="element.SerialNumber">
          {{element.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- BOTTONE DOWNLOAD REPORT -->

    <!-- <button mat-stroked-button matTooltip="Scarica report in excel" style="float: right; margin-top: 10px;" disabled>
      <mat-icon>download</mat-icon>
    </button> -->

    <!-- BOTTONE MOUSEHOVER INFO DATI BARCHART -->

    <button *ngIf="machineName != ''"
      style="float: right; margin-top: 10px; margin-right: 5px;"
      mat-stroked-button
      matTooltip="Ore motore: Tempo di Utilizzo effettivo del motore
                  Ore dyn@kit: Tempo impiegato per gli ordini di lavoro
                  Ore previste: Tempo stimato per gli ordini di lavoro"
      [matTooltipPosition]="positionOptions"
    >
      <mat-icon>info</mat-icon>
    </button>


    <mat-divider [inset]="true" style="margin-left: 318px;" [style.width.px]="riferimento1.offsetWidth - dividerpiechart.offsetWidth - 30"></mat-divider>

    <!-- BOX AVVISO INSERIMENTO MEZZO BARCHART -->

    <mat-card id="alert-card-barchart" *ngIf="machineName == ''">
      <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
      <p id="alert-text-barchart" *ngIf="dataListBarChart.length != 0">Per generare il grafico occorre selezionare un
        mezzo</p>
      <p id="alert-text-barchart" *ngIf="dataListBarChart.length == 0">Dati insufficienti per la generazione del grafico
      </p>
    </mat-card>

    <!-- BARCHART -->

    <div id="grafico_MachineHours">
      <ngx-charts-bar-horizontal *ngIf="machineName != ''" [view]="[(riferimento1.offsetWidth - piechart.offsetWidth - 10), 215]" [scheme]="colorSchemeBarChart"
        [results]="MachineHours" [legendTitle]="title" [legendPosition]="legendPositionBarChart" [xAxis]="showXAxis"
        [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel" [showGridLines]="showGridLines">
      </ngx-charts-bar-horizontal>
    </div>



    <mat-divider [inset]="true" id="divider_after_grafico" *ngIf="machineName != ''"></mat-divider>

    <!-- BARCHART A GRUPPI PER TIPO DI ATTIVITA' -->

    <div id="grafico_MachineHours_Activity">
      <ngx-charts-bar-horizontal-2d *ngIf="machineName != ''"
        [view]="[(riferimento1.offsetWidth), 295]"
        [scheme]="colorSchemeBarChart" [legendTitle]="title" [results]="MachineHours_Activity" [gradient]="gradient" [xAxis]="showXAxis"
        [yAxis]="showYAxis" [legend]="showLegend" [legendPosition]="legendPosition" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [groupPadding]="groupPadding" [xAxisLabel]="xAxisLabel">
      </ngx-charts-bar-horizontal-2d>
    </div>

    </mat-card-content>
  </div>


</mat-card>


<!-- BOX MEZZI -->

<mat-card *ngIf="idWorksite != 0" class="box_mezzi">
  <mat-card-title class="title-box">
    <mat-icon>directions_car</mat-icon><span>Storico mezzi utilizzati</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-card id="alert-card-mezzi" *ngIf="dataSourceMezzi.length == 0">
      <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
      <p id="alert-text-mezzi" *ngIf="dataSourceMezzi.length == 0">Nessun mezzo assegnato al cantiere</p>
    </mat-card>

    <div class="table-container mat-elevation-z8">
      <table mat-table [dataSource]="dataSourceMezzi" matSort #MatSort2 matSortActive="model" matSortDirection="asc"
        matSortDisableClear *ngIf="dataSourceMezzi.length != 0">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
        </ng-container>
        <!-- IsAcceso Column -->
        <ng-container matColumnDef="IsAcceso">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.IsAcceso">
              <span class="material-icons" style="color:green">
                power_settings_new
              </span>
            </div>
            <div *ngIf="!element.IsAcceso" style="color:red">
              <span class="material-icons">
                power_settings_new
              </span>
            </div>

          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image" sticky>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">

            <img [src]='element.LinkImage' width="100" height="80">
          </td>
        </ng-container>


        <!-- Type Column -->
        <ng-container matColumnDef="oemname">
          <th mat-header-cell *matHeaderCellDef>Oem</th>
          <td mat-cell *matCellDef="let element">{{ element.OEMName }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Nome</th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>

        <!-- Model Column -->
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Modello</th>
          <td mat-cell *matCellDef="let element">{{ element.Model }}</td>
        </ng-container>

        <!-- Password Column -->
        <ng-container matColumnDef="serialnumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Numero Seriale</th>
          <td mat-cell *matCellDef="let element">{{ element.SerialNumber }}</td>
        </ng-container>


        <!-- CumulativeHours Column -->
        <ng-container matColumnDef="cumulativeHours">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Ore operative</th>
          <td mat-cell *matCellDef="let element">{{ element.CumulativeHours }}</td>
        </ng-container>

        <!-- CumulativeHours Column -->
        <ng-container matColumnDef="ODLCompletati">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">ODL Completati</th>
          <td mat-cell *matCellDef="let element">{{ element.ODLCompletati }}</td>
        </ng-container>

        <!-- CumulativeHours Column -->
        <ng-container matColumnDef="ODLAssegnati">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">ODL Assegnati</th>
          <td mat-cell *matCellDef="let element">{{ element.ODLAssegnati }}</td>
        </ng-container>

        <!-- Date End -->
        <ng-container matColumnDef="dateend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Scadenza Licenza</th>
          <td mat-cell *matCellDef="let element">{{ element.DateEnd | date: "dd/MM/yyyy" }}</td>
        </ng-container>

        <!-- IsValid Column -->
        <ng-container matColumnDef="isvalid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Censito</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- Dinakit Column -->
        <ng-container matColumnDef="dynakit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Dyn@kit</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.Dynakit" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.Dynakit" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- IsAssociate Column -->
        <ng-container matColumnDef="isassociate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Associato</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsAssociate" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.IsAssociate" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- Banco Column -->
        <ng-container matColumnDef="banco">
          <th mat-header-cell *matHeaderCellDef>Banco</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Banco }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMezzi"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMezzi"></tr>
      </table>
    </div>

  </mat-card-content>

</mat-card>

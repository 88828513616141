import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterJobModel } from 'src/app/model/FilterJobModel';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { JobModel } from 'src/app/model/JobModel';
import { JobWorkModel } from 'src/app/model/JobWorkModel';
import { JobDialogComponent } from '../job-dialog-page/job-dialog-page.component';
import { JobEngagedDialogComponent } from '../job-engaged-dialog/job-engaged-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';

interface selectWorkSite {
  Id: string;
  Description: string;
}

interface selectMachine {
  Id: string;
  MachineDescription: string;
}

interface selectOperator {
  Id: string;
  Name: string;
}

interface selectAction {
  Id: string;
  Description: string;
}

@Component({
  selector: 'app-job-page',
  templateUrl: './job-page.component.html',
  styleUrls: ['./job-page.component.scss'],
})
export class JobPageComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<any>,
    public dialog: MatDialog,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  pageEvent!: PageEvent;
  @ViewChild(MatSort) sort!: MatSort;

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 10;
  length = 0;
  displayedColumns: string[] = [
    'worksite',
    'machine',
    'description',
    'operator',
    'stato',
    'actions',
  ];

  dataSource = [];
  workSiteSource: selectWorkSite[] = [];
  machineSource: selectMachine[] = [];
  workSiteFilterSource: selectWorkSite[] = [];
  machineFilterSource: selectMachine[] = [];
  activeFilterSource: selectWorkSite[] = [];
  completedFilterSource: selectWorkSite[] = [];
  //typeworkSource: selectWorkSite[] = [];
  typeworkFilterSource: selectWorkSite[] = [];
  operatorSource: selectOperator[] = [];
  azioniSource: selectAction[] = [];
  materialiSource: selectAction[] = [];

  isEdit = false;
  isNew = false;
  idJob = 0;
  sortField: string = 'worksite';
  sortOrder: string = 'desc';

  worksiteFilterId = '0';
  machineFilterId = '0';
  activeFilterId = '0';
  completedFilterId = '0';
  typeworkFilterId = '0';

  showVibrazione = false;
  showFrequenza = false;
  showAmpiezza = false;
  showForzaCentrifuga = false;
  showVelocitaStesa = false;
  showSpessoreStesa = false;
  showLarghezzaStesa = false;
  frequenzaSource = [];
  frequenzaBancoSource = [];
  frequenzaTamperingBancoSource = [];
  ampiezzaSource = [];
  ampiezzaBancoSource = [];
  centrifugheSource = [];
  centrifugheBancoSource = [];
  velocitaStesaMin = 0;
  velocitaStesaMax = 0;
  spessoreStesaMin = 0;
  spessoreStesaMax = 0;
  larghezzaStesaMin = 0;
  larghezzaStesaMax = 0;
  showBanco = false;
  showTampering = false;
  showNumeroPassate = false;

  jobForm = this.fb.group({
    //datestart: ['', Validators.required],
    //dateend: ['', Validators.required],
    code: ['', Validators.required],
    description: ['', Validators.required],
    idworksite: ['', Validators.required],
    idmachine: ['', Validators.required],
    idoperator: ['', Validators.required],
    idtypework: [''],
    mezzo: [''],
    vibrazione: [''],
    frequenza: [''],
    ampiezza: [''],
    forzacentrifuga: [''],
    velocitastesa: [''],
    spessorestesa: [''],
    larghezzastesa: [''],
    note: ['', Validators.maxLength(300)],
    //isactive: [true],
    numeropassate: [0],
    azione: [''],
    materiale: [''],
    banco: [''],
    tamperingbanco: false,
    frequenzatamperingbanco: [''],
    tempostimato : [''],
  });

  ngOnInit(): void {
    this.dateAdapter.setLocale('it-IT');

    this.activeFilterSource = [];

    var item: selectWorkSite = { Id: '0', Description: 'Tutti' };
    this.activeFilterSource.push(item);

    var itemAttivi: selectWorkSite = { Id: '1', Description: 'Attivi' };
    this.activeFilterSource.push(itemAttivi);

    var itemNonAttivi: selectWorkSite = { Id: '2', Description: 'Non Attivi' };
    this.activeFilterSource.push(itemNonAttivi);

    this.completedFilterSource = [];

    var item: selectWorkSite = { Id: '0', Description: 'Tutti' };
    this.completedFilterSource.push(item);

    var itemNonConclusi: selectWorkSite = {
      Id: '1',
      Description: 'Non Conclusi',
    };
    this.completedFilterSource.push(itemNonConclusi);

    var itemConclusi: selectWorkSite = { Id: '2', Description: 'Conclusi' };
    this.completedFilterSource.push(itemConclusi);

    //this.typeworkSource = [];
    this.typeworkFilterSource = [];

    // var itemTutti: selectWorkSite = { Id: '0', Description: 'Tutti' };
    // this.typeworkFilterSource.push(itemTutti);

    // var itemLavoro: selectWorkSite = { Id: '1', Description: 'Lavoro' };
    // this.typeworkFilterSource.push(itemLavoro);
    // //this.typeworkSource.push(itemLavoro);

    // var itemTrasferimento: selectWorkSite = {
    //   Id: '2',
    //   Description: 'Trasferimento',
    // };
    // this.typeworkFilterSource.push(itemTrasferimento);
    // //this.typeworkSource.push(itemTrasferimento);

    this.GetWorkSiteActive();
    this.GetMachineActive();
    this.GetOperatorActive();
    this.GetJob();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;
      this.sortField = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.GetJob();
    });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.GetJob();
  }

  GetWorkSiteActive(): void {
    this.apiService.GetWorkSiteActive().subscribe(
      (data) => {
        this.workSiteSource = [];
        this.workSiteFilterSource = [];

        const itemTutti: selectWorkSite = { Id: '0', Description: 'Tutti' };

        this.workSiteFilterSource.push(itemTutti);

        data.forEach((w: selectWorkSite) => {
          this.workSiteSource.push(w);
          this.workSiteFilterSource.push(w);
        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetMachineActive(): void {
    this.apiService.GetMachineActive().subscribe(
      (data) => {
        this.machineSource = [];
        this.machineFilterSource = [];

        var item: selectMachine = { Id: '0', MachineDescription: 'Tutti' };
        this.machineFilterSource.push(item);

        data.forEach((item: selectMachine) => {
          this.machineSource.push(item);
          this.machineFilterSource.push(item);
        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetOperatorActive(): void {
    this.apiService.GetOperatorActive().subscribe(
      (data) => {
        this.operatorSource = [];

        data.forEach((item: selectOperator) => {
          this.operatorSource.push(item);
        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetJob(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterJobModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.worksiteFilterId,
      this.machineFilterId,
      this.activeFilterId,
      this.completedFilterId,
      this.typeworkFilterId
    );

    this.apiService.GetJob(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  Add(): void {
    this.isEdit = true;
    this.isNew = true;

    this.jobForm.setValue({
      //datestart: new Date(),
      //dateend: new Date(),
      code: '',
      description: '',
      mezzo: '',
      //isactive: true,
      idworksite: '',
      idmachine: '',
      idoperator: '',
      idtypework: '1',
      vibrazione: false,
      frequenza: '',
      ampiezza: '',
      forzacentrifuga: '',
      velocitastesa: '',
      spessorestesa: '',
      larghezzastesa: '',
      note: '',
      azione: '',
      materiale: '',
      numeropassate: 0,
      banco: '',
      tamperingbanco: false,
      frequenzatamperingbanco: '',
      tempostimato : ''
    });
  }

  Edit(element: any): void {
    this.isEdit = true;
    this.isNew = false;

    this.idJob = element.Id;

    this.readParameter(element.IdMachine);
    this.readMaterial(element.IdMachine);
    this.readAction(element.IdMachine);

    var typeid = '0';
    if (element.TipoLavoro == 'Lavoro') {
      typeid = '1';
    }
    if (element.TipoLavoro == 'Trasferimento') {
      typeid = '2';
    }

    this.jobForm.setValue({
      //datestart: element.DateStart,
      //dateend: element.DateEnd,
      code: element.Code,
      description: element.Description,
      //isactive: true,
      idworksite: element.IdWorkSite,
      idmachine: element.IdMachine,
      mezzo: element.Model,
      banco: element.Banco,
      idoperator: element.IdOperator,
      idtypework: typeid,
      vibrazione: element.Vibrazione,
      frequenza: element.Frequenza,
      ampiezza: element.Ampiezza,
      forzacentrifuga: element.ForzaCentrifuga,
      velocitastesa: element.VelocitaStesa,
      spessorestesa: element.SpessoreStesa,
      larghezzastesa: element.LarghezzaStesa,
      note: element.Note,
      azione: element.Azione,
      materiale: element.Materiale,
      numeropassate: element.NumeroPassate,
      tamperingbanco: element.TamperingBanco,
      frequenzatamperingbanco: element.FrequenzaTamperingBanco,
      tempostimato: element.TempoStimatoOre,
    });
  }

  Annulla(): void {
    this.isEdit = false;
    this.isNew = false;
  }

  Salva(): void {
    //let datestart = new Date(this.jobForm.get('datestart')?.value).getTime();
    //let dateend = new Date(this.jobForm.get('dateend')?.value).getTime();

    // if (datestart > dateend) {
    //   this.openSnackBarError('Data di fine maggiore di data inizio !');
    //   return;
    // }

    let dataJob = new JobModel(
      this.idJob,
      this.jobForm.get('code')?.value,
      this.jobForm.get('description')?.value,
      //this.jobForm.get('isactive')?.value,
      //this.jobForm.get('datestart')?.value,
      //this.jobForm.get('dateend')?.value,
      this.jobForm.get('idworksite')?.value,
      this.jobForm.get('idmachine')?.value,
      this.jobForm.get('idoperator')?.value,
      this.jobForm.get('idtypework')?.value,
      this.jobForm.get('vibrazione')?.value,
      this.jobForm.get('frequenza')?.value,
      this.jobForm.get('ampiezza')?.value,
      this.jobForm.get('forzacentrifuga')?.value,
      this.jobForm.get('velocitastesa')?.value,
      this.jobForm.get('spessorestesa')?.value,
      this.jobForm.get('larghezzastesa')?.value,
      this.jobForm.get('note')?.value,
      this.jobForm.get('azione')?.value,
      this.jobForm.get('tempostimato')?.value,
      this.jobForm.get('materiale')?.value,
      this.jobForm.get('numeropassate')?.value,
      this.jobForm.get('banco')?.value,
      this.jobForm.get('tamperingbanco')?.value,
      this.jobForm.get('frequenzatamperingbanco')?.value,
    );

    if (this.isNew) {
      this.apiService.AddJob(dataJob).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idJob = 0;
          this.GetJob();
          this.openSnackBarOk('Lavoro aggiunto');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error);
        }
      );
    } else {
      this.apiService.UpdateJob(dataJob).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idJob = 0;
          this.GetJob();
          this.openSnackBarOk('Lavoro modificato');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error.error.message);
        }
      );
    }
  }

  Info(element: any): void {
    this.isLoadingResults = true;

    let dataJob = new JobWorkModel(element.Id, '', false, new Date(), 0, 0);

    this.apiService.StatusJob(dataJob).subscribe(
      (data) => {
        this.isLoadingResults = false;

        this.dialog.open(JobDialogComponent, {
          panelClass: 'full-width-dialog',
          data: data,
        });
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  onChangeMachine() {
    let idmachine = this.jobForm.get('idmachine')?.value;
    let date: Date = this.jobForm.get('datestart')?.value;

    this.readParameter(idmachine);
    this.readEngaged(idmachine, date);
    this.readAction(idmachine);
    this.readMaterial(idmachine);
  }

  onChangeWorkSiteFilter() {
    this.GetJob();
  }

  readParameter(id: number) {
    this.apiService.GetParameter(id).subscribe(
      (data) => {
        // leggo parametri modello
        this.showNumeroPassate = true;

        this.showVibrazione = data.ShowVibrazione;
        this.showFrequenza = data.ShowFrequenzaHiLo;
        this.frequenzaSource = data.Frequenze;
        this.showAmpiezza = data.ShowAmpiezzaHiLo;
        this.ampiezzaSource = data.Ampiezze;
        this.showForzaCentrifuga = data.ShowForzaCentrifugaHiLo;
        this.centrifugheSource = data.ForzeCentrifuga;
        this.showVelocitaStesa = data.ShowVelocitaStesa;
        this.velocitaStesaMax = data.VelocitaStesaMax;
        this.velocitaStesaMin = data.VelocitaStesaMin;
        this.showSpessoreStesa = data.ShowSpessoreStesaMax;
        this.spessoreStesaMax = data.SpessoreStesaMaxValore;

        this.showLarghezzaStesa = false;

        this.jobForm.get('velocitastesa')?.clearValidators();
        this.jobForm.get('spessorestesa')?.clearValidators();
        this.jobForm.get('larghezzastesa')?.clearValidators();

        this.jobForm.get('mezzo')?.setValue(data.Model);

        if (this.showVelocitaStesa) {
          this.jobForm
            .get('velocitastesa')
            ?.addValidators([
              Validators.required,
              Validators.min(this.velocitaStesaMin),
              Validators.max(this.velocitaStesaMax),
            ]);
        }

        if (this.showSpessoreStesa) {
          this.jobForm
            .get('spessorestesa')
            ?.addValidators([
              Validators.required,
              Validators.min(this.spessoreStesaMin),
              Validators.max(this.spessoreStesaMax),
            ]);
        }

        // banco
        this.showBanco = data.ShowBanco;

        if (this.showBanco) {
          this.jobForm.get('banco')?.setValue(data.Banco);
          this.showNumeroPassate = false;
          this.showAmpiezza = false;

          this.showVibrazione = data.ShowVibrazioneBanco;
          this.frequenzaSource = data.FrequenzeBanco;

          //this.showAmpiezza = data.ShowAmpiezzaHiLoBanco;
          //this.ampiezzaSource = data.AmpiezzeBanco;

          this.showLarghezzaStesa = data.ShowLarghezzaStesaBanco;
          this.larghezzaStesaMin = data.LarghezzaStesaBancoMin;
          this.larghezzaStesaMax = data.LarghezzaStesaBancoMax;

          if (this.showLarghezzaStesa) {
            this.jobForm
              .get('larghezzastesa')
              ?.addValidators([
                Validators.required,
                Validators.min(this.larghezzaStesaMin),
                Validators.max(this.larghezzaStesaMax),
              ]);
          }




          this.showForzaCentrifuga = data.ShowForzaCentrifugaHiLoBanco;
          this.centrifugheSource = data.ForzeCentrifugaBanco;

          this.showTampering = data.ShowTampering;
          this.frequenzaTamperingBancoSource = data.TamperingValue;
        }
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  readAction(id: number) {
    this.apiService.GetAction(id).subscribe(
      (data) => {
        this.azioniSource = [];
        //var itemBlank: selectAction = { Id: '', Description: '' };
        //this.azioniSource.push(itemBlank);

        data.forEach((item: selectAction) => {
          var itemToAdd: selectAction = {
            Id: item.Description,
            Description: item.Description,
          };
          this.azioniSource.push(itemToAdd);
        });
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  readMaterial(id: number) {
    this.apiService.GetMaterial(id).subscribe(
      (data) => {
        this.materialiSource = [];
        var itemBlank: selectAction = { Id: '', Description: '' };
        this.materialiSource.push(itemBlank);

        data.forEach((item: selectAction) => {
          var itemToAdd: selectAction = {
            Id: item.Description,
            Description: item.Description,
          };
          this.materialiSource.push(itemToAdd);
        });
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  readEngaged(id: number, date: Date) {
    this.apiService.Engaged(id, date).subscribe(
      (data) => {
        if (data.IsEngaged == true) {
          ////console.log(data.Text);

          this.dialog.open(JobEngagedDialogComponent, { data: data.Text });
        }
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }
}

export class FilterJobModel {

  constructor(
    public page: number,
    public itempage: number,
    public sort: string,
    public sortorder: string,
    public filterworksiteid?: string,
    public filtermachineid?: string,
    public filteractiveid?: string,
    public filtercompletedid?: string,
    public filtertypeworkid?: string,
    public filteroperatorid?: string,
    public filterdatastart?: string,
    public filterdataend?: string,
  ) {  }

}

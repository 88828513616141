import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterWorkSiteModel } from 'src/app/model/FilterWorkSiteModel';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { WorkSiteModel } from 'src/app/model/WorkSiteModel';
import { DateAdapter } from '@angular/material/core';
import * as fileSaver from 'file-saver';
// import { DateValidator } from 'src/app/services/WorkSiteValidator';


interface selectActive {
  Id: string;
  Description: string;
}

interface selectUser {
  UserId: string;
  Name: string;
}

@Component({
  selector: 'app-worksite-page',
  templateUrl: './worksite-page.component.html',
  styleUrls: ['./worksite-page.component.scss'],
})
export class WorksitePageComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<any>,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  pageEvent!: PageEvent;
  @ViewChild(MatSort) sort!: MatSort;

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 10;
  length = 0;
  displayedColumns: string[] = [
    'commessa',
    'datestart',
    'dateend',
    'description',
    'client',
    'Indirizzo',
    'bossname',
    'isactive',
    'actions'

  ];

  formValid: any

  dataSource = [];
  isEdit = false;
  isNew = false;
  idWorkSite = 0;
  sortField: string = 'description';
  sortOrder: string = 'asc';

  activeFilterId = '0';
  bossFilterId = '0';

  activeFilterSource: selectActive[] = [];
  bossFilterSource: selectUser[] = [];
  bossSource: selectUser[] = [];
  lat: any
  lon: any

  submitted: boolean = false

  dateStartError: boolean = false
  dataEmptyError: boolean = false
  dataEndError: boolean = false
  mapError: boolean = false
  commessaError: boolean = false
  descriptionextendedError: boolean = false
  descriptionError: boolean = false
  bossidError: boolean = false
  clientError: boolean = false

  notEdit: boolean = false

  worksiteForm = this.fb.group({
    datestart: ['', Validators.required],
    dateend: ['', Validators.required],
    description: ['', Validators.required],
    descriptionextended: [''],
    client: ['', Validators.required],
    isactive: [true],
    bossid: ['', Validators.required],
    commessa: ['', Validators.compose([
      Validators.required,
      Validators.minLength(3)])],
    indirizzo: ['', Validators.required],
  },
    );
    // { validators: DateValidator.dataEmpty("datestart", "dateend") }
  get wk() {
    return this.worksiteForm.controls;
  }


  ngOnInit(): void {

    this.dateAdapter.setLocale('it-IT');

    this.activeFilterSource = [];

    var item: selectActive = { Id: '0', Description: 'Tutti' };
    this.activeFilterSource.push(item);

    var itemAttivi: selectActive = { Id: '1', Description: 'Attivi' };
    this.activeFilterSource.push(itemAttivi);

    var itemNonAttivi: selectActive = { Id: '2', Description: 'Non Attivi' };
    this.activeFilterSource.push(itemNonAttivi);

    // carico i capi cantiere
    this.GetBossUsers();
    this.GetBossUsersStorico();
    this.GetWorkSite();
  }

  isActive(id: string) {
    var found = false;
    var i = 0;
    while (!found && i < this.bossSource.length)
      if (this.bossSource[i].UserId == id)
        found = true;
      else
        i++;
    ////console.log(this.bossSource);
    return found;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;

      this.sortField = this.sort.active;

      this.sortOrder = this.sort.direction;

      this.GetWorkSite();
    });
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.GetWorkSite();
  }


  GetBossUsers(): void {

    this.bossSource = [];

    this.apiService.BossUsers().subscribe(
      (data) => {
        var data = data;



        data.forEach((w: selectUser) => {

          this.bossSource.push(w);
        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }


  GetBossUsersStorico(): void {
    this.bossFilterSource = [];


    this.apiService.BossUsersStorico().subscribe(
      (data) => {
        var data = data;

        const itemTutti: selectUser = { UserId: '0', Name: 'Tutti' };

        this.bossFilterSource.push(itemTutti);

        data.forEach((w: selectUser) => {
          this.bossFilterSource.push(w);

        });
      },
      (error) => {
        ////console.log(error);
      }
    );
  }

  GetWorkSite(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterWorkSiteModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.activeFilterId,
      this.bossFilterId
    );

    this.apiService.GetCantieri(dataFilter).subscribe(
      (data) => {
        ////console.log("Data: ", data.Data);
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  Add(): void {
    this.submitted = false
    this.notEdit = true
    this.isEdit = true;
    this.isNew = true;

    this.worksiteForm.setValue({
      datestart: null,
      dateend: null,
      description: '',
      descriptionextended: '',
      client: '',
      commessa: '',
      indirizzo: '',
      isactive: true,
      bossid: '',
    });
  }

  Edit(element: any): void {
    ////console.log(element);
    this.notEdit = false
    this.isEdit = true;
    this.isNew = false;

    this.idWorkSite = element.Id;

    this.worksiteForm.setValue({
      datestart: element.DateStart,
      dateend: element.DateEnd,
      description: element.Description,
      descriptionextended: element.DescriptionExtended,
      isactive: element.IsActive,
      client: element.Client,
      bossid: element.BossId,
      commessa: element.CodiceCommessa,
      indirizzo: element.Indirizzo
    });
  }

  Annulla(): void {
    this.submitted = false
    this.isEdit = false;
    this.isNew = false;
  }

  Salva(): void {
    this.submitted = true
    let dstart = new Date(this.worksiteForm.get('datestart')?.value).getTime();
    let dend = new Date(this.worksiteForm.get('dateend')?.value).getTime();
    let today = new Date().getTime();


    let datestart = this.worksiteForm.get('datestart')?.value;
    let dateend = this.worksiteForm.get('dateend')?.value;
    let description = this.worksiteForm.get('description')?.value;
    let descriptionextended = this.worksiteForm.get('descriptionextended')?.value;
    let isactive = this.worksiteForm.get('isactive')?.value;
    let client = this.worksiteForm.get('client')?.value;
    let bossid = this.worksiteForm.get('bossid')?.value;
    let commessa = this.worksiteForm.get('commessa')?.value;
    let address = this.worksiteForm.get('indirizzo')?.value;

    // Converti timestamp in oggetti Date e formattali
    //let formattedStartDate = new Date(dstart).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    //let formattedEndDate = new Date(dend).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    //console.log("Il cantiere inizia il " + formattedStartDate + " e termina il " + formattedEndDate);


    var geo = new google.maps.Geocoder();


    geo.geocode({ address: address }, (results, status) => {
      // If that was successful

      if (status == google.maps.GeocoderStatus.OK && results != null) {
        this.lat = results[0].geometry.location.lat();
        this.lon = results[0].geometry.location.lng();
      }
      else{
        !this.mapError
      }
    });


    let dataWorkSite = new WorkSiteModel(
      this.idWorkSite,
      description,
      isactive,
      datestart,
      dateend,
      descriptionextended,
      client,
      bossid,
      commessa,
      address,
      this.lat,
      this.lon
    );

    if(this.worksiteForm.valid){
      if (this.isNew) {
        this.apiService.AddCantiere(dataWorkSite).subscribe(
          (data) => {
            this.isEdit = false;
            this.isNew = false;
            this.idWorkSite = 0;
            this.GetWorkSite();
            this.openSnackBarOk('Cantiere aggiunto');
            this.submitted = false
          },
          (error) => {

            this.openSnackBarError('Impossibile creare il cantiere');
          }
        );
      } else {
        this.apiService.UpdateCantiere(dataWorkSite).subscribe(
          (data: any) => {
            this.isEdit = false;
            this.isNew = false;
            this.idWorkSite = 0;
            this.GetWorkSite();
            this.openSnackBarOk('Cantiere modificato');
            this.submitted = false
          },
          (error: any) => {
            this.openSnackBarError('Impossibile modificare il cantiere');
          }
        );
      }
    }else{
      this.openSnackBarError("Impossibile eseguire l'operazione");
    }

  }

  keyPressAlphaNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  Report(element: any): void {
    this.isLoadingResults = true;
    this.idWorkSite = element.Id;

    this.apiService.ReportCantiere(this.idWorkSite)
      .subscribe((response: any) => {
        let blob: any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fileSaver.saveAs(blob, 'report.xlsx');
        this.isLoadingResults = false;
      }),
      (error: any) => {
        ////console.log('Error downloading the file');
        this.isLoadingResults = false;
      };

  }

  Dashboard(idWorkSite: number){
    // this.router.navigateByUrl('/dashboardCantiere');
    this.router.navigate(['dashboardCantiere', {IdWorkSite: idWorkSite}]);

  }

}

<h2 mat-dialog-title>Dyn@link</h2>
<mat-dialog-content class="mat-typography">
  <pre lang="xml" >{{text}}</pre>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
    Chiudi
  </button>
</mat-dialog-actions>


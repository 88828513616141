import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationModel } from 'src/app/model/RegistrationModel';
import { ApiService } from 'src/app/services/api.service';
import { PasswordValidator } from 'src/app/services/PasswordValidator';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit {

  //Languages: any = ['EN', 'IT'];
  hide = true;
  hide2 = true;
  //alreadyExist: boolean = false;
  emailError: any;

  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private router: Router,
              public translate: TranslateService,
              private snackBar: MatSnackBar) {
   }

  registrationForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    password: ['', [Validators.required ,Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
    confirm_password: [''],
    name: ['', [Validators.required]],
  }, { validators: PasswordValidator.confirmed("password", "confirm_password") });

  ngOnInit() {
  }

  Register(): void {
     let user = this.registrationForm.get('email')?.value;
     let pass = this.registrationForm.get('password')?.value;
     let confPass = this.registrationForm.get('confirm_password')?.value;
     let name = this.registrationForm.get('name')?.value;

    let dataRegister = new RegistrationModel(user, pass, confPass, name);

     this.apiService.powerUserRegister(dataRegister)
       .subscribe(
        data => {
           ////console.log(data);
           this.openSnackBarOk("Registrazione effettuata, controllare email");
           this.router.navigateByUrl('/login');
         },
         error => {
           ////console.log(error);
           this.openSnackBarError(error.error.Message);
         });
   }


   openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }


}

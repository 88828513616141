<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>




<mat-card>
  <mat-card-title>Dyn@kit</mat-card-title>
  <mat-card-content>
    <div class="edit-container" *ngIf="!isEdit">
      <mat-form-field appearance="fill">
        <mat-label>Filtra cliente o matricola</mat-label>
        <input matInput maxlength="100" [(ngModel)]="filtercustomer">
      </mat-form-field>

      <button mat-stroked-button color="primary" (click)="GetDynakit()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div fxFlex></div>
    <div>
      <button mat-stroked-button matTooltip="Aggiorna" style="float: right;" [matTooltipShowDelay]="1000"
        color="primary" (click)="UpdatePage()" class="margin">
        <mat-icon>autorenew</mat-icon> Sincronizza tutto
      </button>
    </div>
  </mat-card-content>
</mat-card>






<div class="table-container mat-elevation-z8">
  <table *ngIf="!isEdit" mat-table [dataSource]="dataSource" matSort matSortActive="key" matSortDirection="asc"
    matSortDisableClear>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
    </ng-container>

    <!-- Dinakit Column -->
    <ng-container matColumnDef="dynakit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; width: 50px;">Dyn@kit</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
          <mat-icon *ngIf="element.Dynakit" class="ok-icon">done</mat-icon>
          <mat-icon *ngIf="!element.Dynakit" class="error-icon">close</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- DateEnd Column -->
    <ng-container matColumnDef="dateend">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; width: 100px;">Data Scadenza Licenza</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DateEnd | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- NomeDynaKit Column -->
    <ng-container matColumnDef="nomeDynakit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; padding-right: 20px; width: 50px;">Nome Dyn@Kit</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
          {{ element.NomeDynakit }}
        </div>
      </td>
    </ng-container>

    <!-- LicenzaScaleFusion Column -->
    <ng-container matColumnDef="licenzaScaleFusion" fill>
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; width: 180px;">Licenza ScaleFusion</th>
      <td mat-cell *matCellDef="let element">
        {{ element.LicenzaScaleFusion }}
      </td>
    </ng-container>

    <!-- DateAssociate Column -->
    <ng-container matColumnDef="dateassociate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; width: 100px;">Data Associazione</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DateActivation | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- DateEndScaleFusion Column -->
    <ng-container matColumnDef="dateEndScaleFusion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; width: 100px;">Scadenza Licenza ScaleFusion</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DateEndScaleFusion | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- IsValid Column -->
    <ng-container matColumnDef="isvalid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px; padding-right: 20px; width: 50px;">Attivo</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
          <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
          <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
        </div>
      </td>
    </ng-container>
    

    <!-- Machine Column -->
    <ng-container matColumnDef="machine" fill>
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Mezzo</th>
      <td mat-cell *matCellDef="let element">{{ element.MachineName }}</td>
    </ng-container>

    <!-- Customer Column -->
    <ng-container matColumnDef="customer" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Cliente</th>
      <td mat-cell *matCellDef="let element">{{ element.CustomerName }}</td>
    </ng-container>

    <!--Id customer Column -->
    <ng-container matColumnDef="idcustomer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Cliente id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;">Azioni</th>
      <ng-container *matCellDef="let element">
        <td mat-cell>
          <button style="margin-top: 5px; width: 40px;" (click)="Edit(element)" mat-stroked-button color="primary" class="margin-action" matTooltip="Modifica"
            [matTooltipShowDelay]="1000">
            <mat-icon>edit</mat-icon>
          </button>
          <button style="margin-top: 5px; margin-bottom: 5px; width: 40px;" *ngIf="element.DateActivation" (click)="Disassociate(element)" mat-stroked-button color="primary"
            class="margin-action" matTooltip="Disassociare" [matTooltipShowDelay]="1000">
            <mat-icon>app_blocking</mat-icon>
          </button>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>


<mat-paginator *ngIf="!isEdit" [pageSizeOptions]="[10]" (page)="handlePageEvent($event)" [length]="length"
  [pageIndex]="pageIndex" [pageSize]="pageSize" showFirstLastButtons>
</mat-paginator>





<mat-card class="edit-box" *ngIf="isEdit">
  <mat-card-header>
    <mat-card-title>Dyn@kit</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="dynakitForm">

      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Mezzo</mat-label>
          <input matInput readonly formControlName="machinename" />

        </mat-form-field>

      </div>


      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Data Scadenza Licenza</mat-label>
          <input matInput [matDatepicker]="pickerEnd" formControlName="dateend" />
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>

      </div>

      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Nome Dyn@kit</mat-label>
          <input matInput maxlength="4" minlength="4" formControlName="nomeDynakit" />

        </mat-form-field>

      </div>

      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Licenza ScaleFusion</mat-label>
          <input matInput formControlName="licenzaScaleFusion" />

        </mat-form-field>

      </div>

      <div class="edit-container">

        <mat-form-field appearance="fill">
          <mat-label>Data Scadenza Licenza ScaleFusion</mat-label>
          <input matInput [matDatepicker]="pickerEndDate" formControlName="dateEndScaleFusion" />
          <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
        </mat-form-field>

      </div>

    </form>
  </mat-card-content>
  <mat-divider inset></mat-divider>


  <mat-card-actions>
    <div fxFlex></div>
    <button [disabled]="!dynakitForm.valid" mat-stroked-button color="primary" class="btn-block margin"
      (click)="Salva()">
      <mat-icon>check</mat-icon>
    </button>
    <button mat-stroked-button color="warn" class="btn-block margin" (click)="Annulla()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-actions>


</mat-card>

export class DynakitModel {

  constructor(
    public id: number,
    public dateend: Date,
    public nomeDynakit: String,
    public licenzaScaleFusion: String,
    public dateEndScaleFusion: Date
  ) {  }

}


import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginModel } from 'src/app/model/LoginModel';
import { RegistrationModel } from 'src/app/model/RegistrationModel';
import { environment } from '../../environments/environment';
import { UserModel } from 'src/app/model/UserModel';
import { ConfigurationModel } from 'src/app//model/ConfigurationModel';
import { FilterModel } from 'src/app/model/FilterModel';
import { WorkSiteModel } from 'src/app/model/WorkSiteModel';
import { JobModel } from 'src/app/model/JobModel';
import { DynakitModel } from 'src/app/model/DynakitModel';
import { JobWorkModel } from 'src/app/model/JobWorkModel';
import { OperatorModel } from 'src/app/model/OperatorModel';
import { ParameterModel } from 'src/app/model/ParameterModel';
import { FilterJobModel } from 'src/app/model/FilterJobModel';
import { FilterDynakitModel } from 'src/app/model/FilterDynakitModel';
import { FilterWorkSiteModel } from 'src/app/model/FilterWorkSiteModel';
import { FilterMachineModel } from 'src/app/model/FilterMachineModel';
import { FilterUserModel } from 'src/app/model/FilterUserModel';
import { FilterOperatorModel } from 'src/app/model/FilterOperatorModel';
import { RegisterUserModel } from 'src/app/model/RegisterUserModel';
import { ChangePasswordModel } from 'src/app/model/ChangePasswordModel';
import { ChangeNameModel } from 'src/app/model/ChangeNameModel';
import { DefaultLayoutAlignDirective } from '@angular/flex-layout';
import { FilterMachineAssociateModel } from '../model/FilterMachineAssociateModel';
import { FilterWorkSiteOperatorModel } from '../model/FilterWorkSiteOperatorModel';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient,
    private snackBar: MatSnackBar,) { }

  createAuthorizationHeader() {
    var temp = sessionStorage.getItem('token')
    return new HttpHeaders()
      .set('Authorization', `Bearer ${sessionStorage.getItem('token')}`)
      .set('Content-Type', 'application/json');
  }

  userLogin(data: LoginModel): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded;'
    );

    let body =
      'grant_type=password' +
      '&username=' +
      data.email +
      '&password=' +
      data.password;
    return this.httpClient.post(environment.apiUrl + '/token', body, {
      headers: headers,
    });
  }

  userResetPassword(email: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let body =
    {
      Email: email
    }
    return this.httpClient.post(environment.apiUrl + '/api/account/ResetPassword', body, options);
  }

  userConfirmResetPassword(userID: string, password: string, token: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let body =
    {
      UserId: userID,
      Password: password,
      Token: token
    }
    return this.httpClient.post(environment.apiUrl + '/api/account/ConfirmResetPassword', body, options);
  }

  GetInfoBannerSamoter(): Observable<any> {
    return this.httpClient.get(
      environment.apiUrl + '/api/admin/checkBannerSamoter',
      { headers: this.createAuthorizationHeader() }
    );
  }

  powerUserRegister(data: RegistrationModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const body = {
      Email: data.email,
      Password: data.password,
      ConfirmPassword: data.confirmPassword,
      Name: data.name,
    };
    return this.httpClient.post(
      environment.apiUrl + '/api/account/RegisterPowerUser',
      body,
      options
    );
  }

  // forgotPassword(email: string): Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       'Authorization': authorizationData
  //     })
  //   };
  //   const body = {
  //     "Email": email
  //   };
  //   //////console.log(JSON.stringify(body));
  //   return this.httpClient
  //     .post(environment.apiUrl + '/api/AllApi/ForgotPassword', body, options);
  // }

  GetPowerUsers(page: number, itempage: number, sort: string, sortorder: string, search:string): Observable<any> {
    // clienti

    let body = {
      Page: page,
      ItemPage: itempage,
      Sort: sort,
      SortOrder: sortorder,
      Search: search
    };

    return this.httpClient.post(environment.apiUrl + '/api/admin/powerusers',
      body,
      {
        headers: this.createAuthorizationHeader(),
      });
  }

  AttivaUtente(data: UserModel): Observable<any> {
    let body = {
      userid: data.userId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/admin/activate',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  DisattivaUtente(data: UserModel): Observable<any> {
    let body = {
      userid: data.userId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/admin/banned',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  SendConfirmEmail(data: UserModel): Observable<any> {
    let body = {
      userid: data.userId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/admin/resend',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetConfiguration(): Observable<any> {
    return this.httpClient.get(
      environment.apiUrl + '/api/aempconfiguration/list',
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetSingleConfiguration(userid: string): Observable<any> {

    let body = {
      userid: userid
    };
    return this.httpClient.post(
      environment.apiUrl + '/api/aempconfiguration/get',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  AddConfiguration(data: ConfigurationModel): Observable<any> {
    let body = {
      //userid: data.userId,
      Type: data.type,
      UserName: data.user,
      Password: data.password,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/aempconfiguration/add',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  UpdateConfiguration(data: ConfigurationModel): Observable<any> {
    let body = {
      Type: data.type,
      UserName: data.user,
      Password: data.password,
    };

    return this.httpClient.put(
      environment.apiUrl + '/api/aempconfiguration/update',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  NewConfigurationUser(user: any, type: any, email: any, password: any): Observable<any> {
    let body = {
      Type: type,
      UserName: user,
      Email: email,
      Password: password,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/account/RegisterNewUser',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  UpdateConfigurationUser(data: ConfigurationModel,userId: string): Observable<any> {
    let body = {
      UserId: userId,
      Type: data.type,
      UserName: data.user,
      Password: data.password,
    };

    return this.httpClient.put(
      environment.apiUrl + '/api/aempconfiguration/updateUser',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }



  DeleteConfiguration(data: ConfigurationModel): Observable<any> {
    let body = {
      Type: data.type,
      UserName: data.user,
      Password: data.password,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/aempconfiguration/delete',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetMachine(data: FilterMachineModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterActiveid: data.filteractiveid,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/list',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetCoordinate(idMachine: number): Observable<any> {
    let body = {
      idMachine: idMachine
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/coordinate',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetMachineAssociate(data: FilterMachineAssociateModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterActiveid: data.filteractiveid,
      IdWorkSite: data.idWorksite
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/ListAssegnateCantiere',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }
  jobstats(idJob: number): Observable<any> {
    let body = {
      IdJob: idJob
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/report/jobstats',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }
  GetMachineStorico(idWorksite: String): Observable<any> {
    let body = {
      Id: idWorksite
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/GetMachineStorico',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  ReadMachine(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/read', {
      headers: this.createAuthorizationHeader(),
    });
  }
  CheckCensimento(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/checkcensimento', {
      headers: this.createAuthorizationHeader(),
    });
  }
  ReadSingleMachine(id: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/readsingle/' + id, {
      headers: this.createAuthorizationHeader(),
    });
  }

  ReadSingleMachineFuel(id: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/readsinglefuel/' + id, {
      headers: this.createAuthorizationHeader(),
    });
  }

  ReadSingleMachineEngine(id: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/readsingleengine/' + id, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetListMachineAssegnabili(id: string): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/ListAssegnabili/' + id, {
      headers: this.createAuthorizationHeader(),
    });
  }

  AssociaMachineWorksite(idWorksite: string, idMachine: string): Observable<any> {
    let body = {
      IdWorksite: idWorksite,
      Idmachine: idMachine
    };
    return this.httpClient.post(
      environment.apiUrl + '/api/machine/associa',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  DisassociaMachineWorksite(idMachine: string, idCantiere: string): Observable<any> {
    let body = {
      Idmachine: idMachine,
      IdCantiere: idCantiere
    };
    return this.httpClient.post(
      environment.apiUrl + '/api/machine/disassocia',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetCantieri(data: FilterWorkSiteModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterActiveId: data.filteractiveid,
      FilterBossId: data.filterbossid,
      idOperator: data.idOperator,
      idUser: data.idUser,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/worksite/list',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetDataCantieriForDownload(idListCantieri: any): Observable<any> {
    let body = {
      ListIdCantieri : idListCantieri
    }

    return this.httpClient.post(environment.apiUrl + '/api/worksite/GetDataCantieriForDownload', body,
    {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataOperatoriForDownload(idListOperatori: any, idListCantieri: any): Observable<any> {
    let body = {
      ListIdCantieri : idListCantieri,
      ListIdOperatori : idListOperatori
    }

    return this.httpClient.post(environment.apiUrl + '/api/worker/GetDataOperatoriForDownload', body,
    {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataCommittentiForDownload(idListCommittenti: any, idListCantieri: any): Observable<any> {
    let body = {
      ListIdCantieri : idListCantieri,
      ListIdCommittenti : idListCommittenti
    }

    return this.httpClient.post(environment.apiUrl + '/api/worksite/GetDataCommittentiForDownload', body,
    {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetCantieriByOperatore(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worksite/getCantieriByOperatore', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetCantieriByCommittente(ListCommittenti: any): Observable<any> {
    let body = {
      ListIdCommittenti : ListCommittenti
    }

    return this.httpClient.post(environment.apiUrl + '/api/worksite/GetCantieriByCommittente', body,
    {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetWorkSiteActive(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worksite/get', {
      headers: this.createAuthorizationHeader(),
    });
  }

  saveReport(data: any, name: any): Observable<any> {
    let body = {
      Data : data,
      FileName: name
    }
    // const body: FormData = new FormData();
    // body.append('Excel', data, name);

    return this.httpClient.post(environment.apiUrl + '/api/report/saveReport', body,
    {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetAllWorkSite(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worksite/getall', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetAllCommittenti(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worksite/getallCommittenti', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetWorkSite(id: number): Observable<any> {

    let body = {
      Id: id,
    };

    return this.httpClient.post(environment.apiUrl + '/api/worksite/getsingle', body,
      {
        headers: this.createAuthorizationHeader(),
      });
  }


  getPosizioneMacchine(id: string): Observable<any> {

    let body = {
      Id: id,
    };

    return this.httpClient.post(environment.apiUrl + '/api/worksite/getPosizioneMacchine',
      body,
      {
        headers: this.createAuthorizationHeader(),
      });
  }

  DataDashboard(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/report/dashboardCantiere', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetMachineActive(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/machine/listall', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetMachineBanco(): Observable<any> {

    return this.httpClient.get(
      environment.apiUrl + '/api/machine/listbanco',
      { headers: this.createAuthorizationHeader() }
    );
  }

  UpdateMachineBanco(id: number, banco: string, machineName: string): Observable<any> {
    let body = {
      Id: id,
      Banco: banco,
      Name: machineName,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/updatemachinebanco',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  AddCantiere(data: WorkSiteModel): Observable<any> {
    let body = {
      Description: data.description,
      IsActive: data.isactive,
      DateStart: data.datestart,
      DateEnd: data.dateend,
      DescriptionExtended: data.descriptionextended,
      Client: data.client,
      BossId: data.bossid,
      CodiceCommessa: data.commessa,
      Indirizzo: data.address,
      Lat: data.lat,
      Lon: data.lon
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/worksite/add',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  UpdateCantiere(data: WorkSiteModel): Observable<any> {
    let body = {
      Id: data.id,
      Description: data.description,
      IsActive: data.isactive,
      DateStart: data.datestart,
      DateEnd: data.dateend,
      DescriptionExtended: data.descriptionextended,
      Client: data.client,
      BossId: data.bossid,
      CodiceCommessa: data.commessa,
      Indirizzo: data.address,
      Lat: data.lat,
      Lon: data.lon
    };

    return this.httpClient.put(
      environment.apiUrl + '/api/worksite/update',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetJob(data: FilterJobModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterWorksiteId: data.filterworksiteid,
      FilterMachineId: data.filtermachineid,
      FilterActiveId: data.filteractiveid,
      FilterCompletedId: data.filtercompletedid,
      FilterTypeWorkId: data.filtertypeworkid,
      FilterOperatorId: data.filteroperatorid,
      FilterDataStart: data.filterdatastart,
      FilterDataEnd: data.filterdataend
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/list', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetJobs(idworksite: number): Observable<any> {
    let body = {
      WorksiteId: idworksite,
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/listworksite', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  AddJob(data: JobModel): Observable<any> {

    let body = {
      // Code: data.code,
      Description: data.description,
      //IsActive: data.isactive,
      DateStart: data.datestart,
      DateEnd: data.dateend,
      IdWorkSite: data.idworksite,
      IdMachine: data.idmachine,
      IdOperator: data.idoperator,
      Vibrazione: data.vibrazione,
      Frequenza: data.frequenza,
      Ampiezza: data.ampiezza,
      ForzaCentrifuga: data.forzacentrifuga,
      VelocitaStesa: data.velocitastesa,
      SpessoreStesa: data.spessorestesa,
      Note: data.note,
      Azione: data.azione,
      TempoStimato: data.tempostimato,
      Materiale: data.materiale,
      IdTypeWork: data.idtypework,
      NumeroPassate: data.numeropassate,
      Banco: data.banco,
      LarghezzaStesa: data.larghezzastesa,
      TamperingBanco: data.tamperingbanco,
      FrequenzaTamperingBanco: data.frequenzatamperingbanco,
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/add', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  UpdateJob(data: JobModel): Observable<any> {
    let body = {
      Id: data.id,
      // Code: data.code,
      Description: data.description,
      //IsActive: data.isactive,
      DateStart: data.datestart,
      DateEnd: data.dateend,
      IdWorkSite: data.idworksite,
      IdMachine: data.idmachine,
      IdOperator: data.idoperator,
      Vibrazione: data.vibrazione,
      Frequenza: data.frequenza,
      Ampiezza: data.ampiezza,
      ForzaCentrifuga: data.forzacentrifuga,
      VelocitaStesa: data.velocitastesa,
      SpessoreStesa: data.spessorestesa,
      LarghezzaStesa: data.larghezzastesa,
      Note: data.note,
      Azione: data.azione,
      TempoStimato: data.tempostimato,
      Materiale: data.materiale,
      IdTypeWork: data.idtypework,
      NumeroPassate: data.numeropassate,
      Banco: data.banco,
      TamperingBanco: data.tamperingbanco,
      FrequenzaTamperingBanco: data.frequenzatamperingbanco,
    };

    return this.httpClient.put(environment.apiUrl + '/api/job/update', body, {
      headers: this.createAuthorizationHeader(),
    });
  }


  UpdateJobToggle(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.put(environment.apiUrl + '/api/job/toggle', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  UpdateMachineToggle(id: number): Observable<any> {
    let body = {
      Idmachine: id,
    };

    return this.httpClient.put(environment.apiUrl + '/api/machine/toggle', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  UpdateAllJobToggle(data: FilterJobModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterWorksiteId: data.filterworksiteid,
      FilterMachineId: data.filtermachineid,
      FilterActiveId: data.filteractiveid,
      FilterCompletedId: data.filtercompletedid,
      FilterTypeWorkId: data.filtertypeworkid,
      FilterOperatorId: data.filteroperatorid,
      FilterDataStart: data.filterdatastart,
      FilterDataEnd: data.filterdataend
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/allToggle', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  StartJob(data: JobWorkModel): Observable<any> {
    let body = {
      Id: data.id,
      DateTime: data.datestart,
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/start', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  EndJob(data: JobWorkModel): Observable<any> {
    let body = {
      Id: data.id,
      DateTime: data.datestart,
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/end', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  StatusJob(data: JobWorkModel): Observable<any> {
    let body = {
      Id: data.id,
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/status', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetMachineParameters(data: FilterModel): Observable<any> {
    let params = new HttpParams();
    params = params.append('Page', data.page);
    params = params.append('ItemPage', data.itempage);
    params = params.append('Sort', data.sort);

    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/parameter/list',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetMachineParameter(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/parameter/get',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetMachineParameterFromModel(model: string): Observable<any> {
    let body = {
      model: model,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/parameter/getmodel',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetMachineParameterFromModelBanco(model: string): Observable<any> {
    let body = {
      model: model,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/parameter/getmodelbanco',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  Engaged(id: number, date: Date): Observable<any> {
    let body = {
      Id: id,
      Date: date,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/engaged',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  AddParameter(data: ParameterModel): Observable<any> {

    let body = {
      Chiave: data.chiave,
      Model: data.model,
      Gruppo: data.gruppo,
      Dynalink: data.dynalink,
      Vibrazione: data.vibrazione,
      FrequenzaHiLo: data.frequenzahilo,
      AmpiezzaHiLo: data.ampiezzahilo,
      ForzaCentrifugaHiLo: data.forzacentrifugahilo,
      VelocitaStesa: data.velocitastesa,
      SpessoreStesaMax: data.spessorestesamax,
      LarghezzaStesa: data.larghezzastesa,
      Banco: data.banco,
      Tampering: data.tampering,
      TamperingHiLo: data.tamperinghilo,
      //FrequenzaTampering: data.frequenzatampering,
      //FrequenzaTamperingHiLo: data.frequenzatamperinghilo,
      FuelTank: data.fueltank ?? 0,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/parameter/add',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  UpdateParameter(data: ParameterModel): Observable<any> {
    let body = {
      Id: data.id,
      Chiave: data.chiave,
      Model: data.model,
      Gruppo: data.gruppo,
      Dynalink: data.dynalink,
      Vibrazione: data.vibrazione,
      FrequenzaHiLo: data.frequenzahilo,
      AmpiezzaHiLo: data.ampiezzahilo,
      ForzaCentrifugaHiLo: data.forzacentrifugahilo,
      VelocitaStesa: data.velocitastesa,
      SpessoreStesaMax: data.spessorestesamax,
      LarghezzaStesa: data.larghezzastesa,
      Banco: data.banco,
      FuelTank: data.fueltank ?? 0,
      Tampering: data.tampering,
      TamperingHiLo: data.tamperinghilo,
      //FrequenzaTampering: data.frequenzatampering,
      //FrequenzaTamperingHiLo: data.frequenzatamperinghilo,
    };

    return this.httpClient.put(
      environment.apiUrl + '/api/parameter/update',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetParameter(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/parameter',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  DeleteParameter(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/parameter/delete',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  EliminaODL(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/job/delete',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetAction(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/action',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetMaterial(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/machine/material',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetUsers(data: FilterUserModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterActiveId: data.filteractiveid,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/poweruser/users',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  ResendMailUser(dataId: string): Observable<any> {
    let body = {
      UserId: dataId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/poweruser/resend',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  BanUser(dataId: string): Observable<any> {
    let body = {
      UserId: dataId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/poweruser/banned',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  ActivateUser(dataId: string): Observable<any> {
    let body = {
      UserId: dataId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/poweruser/activate',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  DeleteUser(dataId: string): Observable<any> {
    let body = {
      UserId: dataId,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/poweruser/delete',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  RegisterUser(data: RegisterUserModel): Observable<any> {
    let body = {
      Email: data.email,
      Password: data.password,
      ConfirmPassword: data.confirmpassword,
      Name: data.name,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/poweruser/registeruser',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  EditUser(data: RegisterUserModel): Observable<any> {
    let body = {
      UserId: data.UserId,
      Email: data.email,
      Password: data.password,
      ConfirmPassword: data.confirmpassword,
      Name: data.name,
    };

    return this.httpClient.put(
      environment.apiUrl + '/api/poweruser/update',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }


  BossUsers(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worksite/getusers', {
      headers: this.createAuthorizationHeader(),
    });
  }

  BossUsersStorico(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worksite/getusersstorico', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetJobBossWorkSite(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/jobboss/worksite', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetJobBossMachine(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/jobboss/machine', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetJobBossParameter(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/jobboss/parameter',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  GetProfile(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/account/getprofile', {
      headers: this.createAuthorizationHeader(),
    });
  }

  ChangePassword(data: ChangePasswordModel): Observable<any> {
    let body = {
      OldPassword: data.oldpassword,
      NewPassword: data.password,
      ConfirmPassword: data.confirmpassword,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/account/changepassword',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  ChangeName(data: ChangeNameModel): Observable<any> {
    let body = {
      Name: data.name,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/account/changename',
      body,
      { headers: this.createAuthorizationHeader() }
    );
  }

  Dashboard(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/homeweb/dashboard', {
      headers: this.createAuthorizationHeader(),
    });
  }


  ReportCantiere(id: number, datestart?: Date, dateend?: Date): Observable<any> {
    let body = {
      IdWorksite: id,
      DateStart: datestart,
      DateEnd: dateend,
    };

    return this.httpClient.post(
      environment.apiUrl + '/api/report/worksitexls',
      body,
      {
        headers: this.createAuthorizationHeader(),
        responseType: 'blob',
      }
    );
  }

  GetOperator(data: FilterOperatorModel): Observable<any> {

    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterActiveId: data.activeFilterId,
      FilterName: data.filtername,
    };

    return this.httpClient.post(environment.apiUrl + '/api/worker/list', body, { headers: this.createAuthorizationHeader() });
  }

  AddOperator(data: OperatorModel): Observable<any> {
    let body = {
      Name: data.name,
      ValidityDate: data.date,
    };

    return this.httpClient.post(environment.apiUrl + '/api/worker/add', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  UpdateOperator(data: OperatorModel): Observable<any> {
    let body = {
      Id: data.id,
      Name: data.name,
      ValidityDate: data.date,
    };

    return this.httpClient.put(environment.apiUrl + '/api/worker/update', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  DeleteOperator(IdOperator: any): Observable<any> {
    let body = {
      Id: IdOperator,
    };

    return this.httpClient.put(environment.apiUrl + '/api/worker/Delete', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetOperatorActive(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worker/listall', {
      headers: this.createAuthorizationHeader(),
    });
  }

  

  GetOperatorAll(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/worker/listAllWorker', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetAllReport(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/report/GetAllReport', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDynakitList(data: FilterDynakitModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterCustomer: data.filtercustomer
    };

    return this.httpClient.post(environment.apiUrl + '/api/dynakit/listDynakit', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDynakit(data: FilterDynakitModel): Observable<any> {
    let body = {
      Page: data.page,
      ItemPage: data.itempage,
      Sort: data.sort,
      SortOrder: data.sortorder,
      FilterCustomer: data.filtercustomer
    };

    return this.httpClient.post(environment.apiUrl + '/api/dynakit/list', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetCustomer(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/poweruser/listall', {
      headers: this.createAuthorizationHeader(),
    });
  }

  AddDynakit(data: DynakitModel): Observable<any> {
    let body = {
      DateEnd: data.dateend,
      NomeDynakit: data.nomeDynakit,
      LicenzaScaleFusion: data.licenzaScaleFusion,
      DateEndScaleFusion: data.dateEndScaleFusion,
    };

    return this.httpClient.post(environment.apiUrl + '/api/dynakit/add', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  UpdateDynakit(data: DynakitModel): Observable<any> {
    let body = {
      Id: data.id,
      DateEnd: data.dateend,
      NomeDynakit: data.nomeDynakit,
      LicenzaScaleFusion: data.licenzaScaleFusion,
      DateEndScaleFusion: data.dateEndScaleFusion,
    };

    return this.httpClient.put(environment.apiUrl + '/api/dynakit/update', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  DisassociateDynakit(id: number): Observable<any> {
    let body = {
      Id: id,
    };

    return this.httpClient.post(environment.apiUrl + '/api/dynakit/disassociate', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  DownloadManuale(): Observable<any> {

    return this.httpClient.get(
      environment.apiUrl + '/api/assistenza/donloadmanuale',
      {
        headers: this.createAuthorizationHeader(),
        responseType: 'blob',
      }
    );
  }

  DownloadManualeTelefono(): Observable<any> {

    return this.httpClient.get(
      environment.apiUrl + '/api/assistenza/donloadmanualetelefono',
      {
        headers: this.createAuthorizationHeader(),
        responseType: 'blob',
      }
    );
  }

  DownloadManualeDynakit(): Observable<any> {

    return this.httpClient.get(
      environment.apiUrl + '/api/assistenza/donloadmanualedynakit',
      {
        headers: this.createAuthorizationHeader(),
        responseType: 'blob',
      }
    );
  }

  GetChartTotal(idworksite: number, idmachine: string): Observable<any> {
    let body = {
      WorksiteId: idworksite,
      IdMachine: idmachine
    };

    return this.httpClient.post(environment.apiUrl + '/api/job/charttotal', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  Chartfirstpage(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/report/chartfirstpage', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataPieChart(idworksite: number): Observable<any> {
    let body = {
      IdWorksite: idworksite,
    };

    return this.httpClient.post(environment.apiUrl + '/api/report/piechartdashboardWorksite', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataPieChartMezzi(idMachine: number): Observable<any> {
    let body = {
      Idmachine: idMachine,
    };

    return this.httpClient.post(environment.apiUrl + '/api/report/piechartdashboardMachine', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataBarGroup(serialNumber: string, idworksite: number): Observable<any> {
    let body = {
      SerialNumber: serialNumber,
      IdWorksite: idworksite,
    };

    return this.httpClient.post(environment.apiUrl + '/api/report/databargroupchart', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetBarGroup(serialNumber: string, idworksite: number): Observable<any> {
    let body = {
      SerialNumber: serialNumber,
      IdWorksite: idworksite,
    };

    return this.httpClient.post(environment.apiUrl + '/api/report/barChartCantiere', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetListMachine(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/report/machineDataList', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataBarChartMezzi(idMachine: number): Observable<any> {
    let body = {
      Idmachine: idMachine,
    };

    return this.httpClient.post(environment.apiUrl + '/api/report/dataBarChartMezzo', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetDataBarChartGroupMezzi(serialNumber: string, idworksite: number): Observable<any> {
    let body = {
      SerialNumber: serialNumber,
      IdWorksite: idworksite,
    };

    return this.httpClient.post(environment.apiUrl + '/api/report/dataBarChartGroupMezzo', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetListMachineHours(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/api/report/machineDataListHours', {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetMacchineAssociateNoFilter(idworksite: string): Observable<any> {
    let body = {
      IdWorksite: idworksite,
    };

    return this.httpClient.post(environment.apiUrl + '/api/machine/getMacchineAssociateNoFilter', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  sendSegnalazione(bodyText: any, tipologia: any): Observable<any>{
    let body = {
      Body: bodyText,
      Tipologia: tipologia
    };

    return this.httpClient.post(environment.apiUrl + '/api/Account/SendSegnalazione', body, {
      headers: this.createAuthorizationHeader(),
    });
  }

  GetInfoAziende(): Observable<any>{

    return this.httpClient.get(
      environment.apiUrl + '/api/admin/GetInfoAziende',
      {
        headers: this.createAuthorizationHeader()
      }
    );
  }

  GetInfoMachine(): Observable<any>{

    return this.httpClient.get(
      environment.apiUrl + '/api/admin/GetInfoMachine',
      {
        headers: this.createAuthorizationHeader()
      }
    );
  }

  GetInfoKit(): Observable<any>{

    return this.httpClient.get(
      environment.apiUrl + '/api/admin/GetInfoKit',
      {
        headers: this.createAuthorizationHeader()
      }
    );
  }

  GetAnomalie(): Observable<any>{

    return this.httpClient.get(
      environment.apiUrl + '/api/admin/GetAnomalie',
      {
        headers: this.createAuthorizationHeader()
      }
    );
  }



}

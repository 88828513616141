<!-- TITOLO E FILTRO -->
<div #riferimento>
  <mat-card  id="card-filtri">

    <mat-card-title id="title_dashboard">
      Gestione cantiere
    </mat-card-title>

    <mat-card-content>

      <mat-form-field appearance="fill">
        <mat-label>Filtra Cantiere</mat-label>
        <mat-select [(ngModel)]="worksiteFilterId" (selectionChange)="ngOnInit()">
          <mat-option *ngFor="let ws of workSiteFilterSource" [value]="ws.Id">
            {{ ws.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <!--  -->
      <button mat-stroked-button matTooltip="Aggiorna" style="float: right;" [matTooltipShowDelay]="1000"
        color="primary" (click)="UpdatePage()" class="margin">
        <mat-icon>autorenew</mat-icon>
      </button>

    </mat-card-content>
  </mat-card>
</div>

<!-- BOX AVVISO INSERIMENTO CANTIERE -->

<mat-card id="alert-card-filterWorksite" *ngIf="worksiteFilterId == '0'">
  <mat-icon style="font-size: 35px; margin-top: 7px; margin-left: 10px;">warning</mat-icon>
  <p id="alert-text">Per poter gestire un cantiere occore selezionarne uno</p>
</mat-card>

<!-- CARICAMENTO -->

<mat-card *ngIf="isWorking" class="overlay">
  <mat-card-content>
    <mat-spinner class="overlay-content"></mat-spinner>
  </mat-card-content>
</mat-card>


<!-- BOX ANAGRAFICA -->

<mat-card *ngIf="worksiteFilterId != '0'" class="box_anagrafica" [ngClass]="{'box_anagrafica_1':  riferimento.offsetWidth <= 943}">

  <mat-card-title class="title-box">
    <mat-icon>library_books</mat-icon><span>Anagrafica</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-form-field appearance="fill" class="input_label">
      <mat-label>Data di inizio</mat-label>
      <input matInput value={{data_inizio}} readonly>
      <mat-datepicker-toggle matSuffix [for]="inizio" [disabled]="true"></mat-datepicker-toggle>
      <mat-datepicker #inizio></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_end">
      <mat-label>Data di fine</mat-label>
      <input matInput value={{data_fine}} readonly>
      <mat-datepicker-toggle matSuffix [for]="fine" [disabled]="true"></mat-datepicker-toggle>
      <mat-datepicker #fine></mat-datepicker>
    </mat-form-field>



    <mat-form-field appearance="fill" class="input_label">
      <mat-label>Nome</mat-label>
      <input matInput value={{name}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_end">
      <mat-label>Commessa</mat-label>
      <input matInput value={{commessa}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label">
      <mat-label>Committente</mat-label>
      <input matInput value={{committente}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_end">
      <mat-label>Capocantiere</mat-label>
      <input matInput value={{capocantiere}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_long">
      <mat-label>Indirizzo</mat-label>
      <input matInput value={{indirizzo}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_long">
      <mat-label>Descrizione</mat-label>
      <input matInput value={{note}} readonly>
    </mat-form-field>

  </mat-card-content>

</mat-card>

<!-- BOX POSIZIONE -->

<mat-card *ngIf="worksiteFilterId != '0'" class="box_posizione" [ngClass]="{'box_posizione_1':  riferimento.offsetWidth < 1667 && riferimento.offsetWidth > 943,'box_posizione_2':  riferimento.offsetWidth <= 943}"> <!--riferimento.offsetWidth > 1334 &&-->

  <mat-card-title class="title-box">
    <mat-icon>place</mat-icon><span>Posizione</span>
  </mat-card-title>

  <mat-divider></mat-divider>
  <google-map height="85%" width="100%" [zoom]="zoom" [center]="center" >
    <map-marker *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label"
      [title]="marker.title" [options]="marker.options">
    </map-marker>

  </google-map>
  <!-- <img mat-card-image src="assets/img/img_cavalcanti.png" style="width: 450px; height: 215px; padding: 14px; padding-top: 10px;"> -->

</mat-card>

<!-- BOX PANORAMICA -->

<mat-card *ngIf="worksiteFilterId != '0'" class="box_panoramica"  [ngClass]="{'box_anagrafica_1':  riferimento.offsetWidth <= 943}">
  <mat-card-title class="title-box">
    <mat-icon>data_usage</mat-icon><span>Panoramica</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content #containerRef style="max-height: 80%;max-width: 100%;" *ngIf="this.ODL_tot_cantiere > 0">


    <mat-form-field appearance="fill" class="input_label_panoramica">
      <mat-label>N° ODL totali</mat-label>
      <input matInput value={{ODL_tot_cantiere}} readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="input_label_panoramica">
      <mat-label>N° ODL conclusi</mat-label>
      <input matInput value={{ODL_conclu_cantiere}} readonly>
    </mat-form-field>

    <mat-divider [inset]="true" style="width: 100%;"></mat-divider>

    <div class="pie-chart">
      <ngx-charts-pie-chart
        [view]="[containerRef.offsetWidth + 10, containerRef.offsetHeight-(containerRef.offsetHeight*20/100) -10]"
        [results]="ODL_panoramica" [arcWidth]="arcWidth" [gradient]="gradient" [legendTitle]="title" [legend]="showLegend"
        [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="isDoughnut" [scheme]="colorScheme"
        (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"
        *ngIf="ODL_tot_cantiere != 0">
      </ngx-charts-pie-chart>
    </div>

  </mat-card-content>

  <mat-card id="alert-card-piechart" *ngIf="ODL_tot_cantiere == 0">
    <mat-icon style="font-size: 43px; margin-top: 5px; margin-left: -25px;">warning</mat-icon>
    <p id="alert-text-piechart">Dati insufficienti per la generazione del grafico</p>
  </mat-card>

</mat-card>

<!-- BOX GANT -->

<mat-card *ngIf="worksiteFilterId != '0'" class="box_programmazione">
  <mat-card-title class="title-box">
    <mat-icon>calendar_month</mat-icon><span>Panoramica programmazione ODL</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>



  </mat-card-content>

</mat-card>

<!-- BOX MEZZI -->
<mat-card [hidden]="worksiteFilterId == '0'" class="box_mezzi"
  [ngClass]="{'box_mezzi_1': riferimento.offsetWidth > 1268 && riferimento.offsetWidth < 1667, 'box_mezzi_2': riferimento.offsetWidth <= 1268 && riferimento.offsetWidth > 943}">
  <!-- ", 'third': false}"> -->
  <mat-card-title class="title-box">
    <mat-icon>directions_car</mat-icon><span>Mezzi Assegnati</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>

    <div class="edit-container">

      <mat-form-field appearance="fill" style="margin-right: 12px">
        <mat-label>Numero mezzi disponibili</mat-label>
        <input matInput value={{machineFilterSource.length}} readonly>
      </mat-form-field>
      <mat-form-field appearance="fill" style="margin-right: 12px">
        <mat-label>Assegna mezzo</mat-label>
        <mat-select [(ngModel)]="AssegnaMachine" [disabled]="machineFilterSource.length == 0">
          <mat-option *ngFor="let ws of machineFilterSource" [value]="ws.Id">
            {{ ws.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="Assegna()"
        [disabled]="machineFilterSource.length == 0">Assegna</button>

    </div>


    <mat-card id="alert-card-mezzi" *ngIf="dataSourceMezzi.length == 0">
      <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
      <p id="alert-text-mezzi" *ngIf="machineFilterSource.length != 0">Assegna almeno un mezzo al cantiere per iniziare!
      </p>
      <p id="alert-text-mezzi" *ngIf="machineFilterSource.length == 0">Nessun mezzo disponibile</p>
    </mat-card>

    <div class="table-container mat-elevation-z8">
      <table [hidden]="isEditMezzi || dataSourceMezzi.length == 0" mat-table [dataSource]="dataSourceMezzi" matSort
        #MatSort2 matSortActive="model" matSortDirection="asc" matSortDisableClear>
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
        </ng-container>
        <!-- IsAcceso Column -->
        <ng-container matColumnDef="IsAcceso">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.IsAcceso">
              <span class="material-icons" style="color:green">
                power_settings_new
              </span>
            </div>
            <div *ngIf="!element.IsAcceso" style="color:red">
              <span class="material-icons">
                power_settings_new
              </span>
            </div>

          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image" sticky>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">

            <img [src]='element.LinkImage' width="100" height="80">
          </td>
        </ng-container>


        <!-- Type Column -->
        <ng-container matColumnDef="oemname">
          <th mat-header-cell *matHeaderCellDef>Oem</th>
          <td mat-cell *matCellDef="let element">{{ element.OEMName }}</td>
        </ng-container>

        <!-- Model Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px;">Nome</th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>

        <!-- Model Column -->
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">Modello</th>
          <td mat-cell *matCellDef="let element">{{ element.Model }}</td>
        </ng-container>

        <!-- Password Column -->
        <ng-container matColumnDef="serialnumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">Numero Seriale</th>
          <td mat-cell *matCellDef="let element">{{ element.SerialNumber }}</td>
        </ng-container>


        <!-- CumulativeHours Column -->
        <ng-container matColumnDef="cumulativeHours">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">Ore operative</th>
          <td mat-cell *matCellDef="let element">{{ element.CumulativeHours }}</td>
        </ng-container>

        <!-- CumulativeHours Column -->
        <ng-container matColumnDef="ODLCompletati">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">ODL Completati</th>
          <td mat-cell *matCellDef="let element">{{ element.ODLCompletati }}</td>
        </ng-container>

        <!-- CumulativeHours Column -->
        <ng-container matColumnDef="ODLAssegnati">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">ODL Assegnati</th>
          <td mat-cell *matCellDef="let element">{{ element.ODLAssegnati }}</td>
        </ng-container>

        <!-- Date End -->
        <ng-container matColumnDef="dateend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Scadenza Licenza</th>
          <td mat-cell *matCellDef="let element">{{ element.DateEnd | date: "dd/MM/yyyy" }}</td>
        </ng-container>

        <!-- IsValid Column -->
        <ng-container matColumnDef="isvalid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Censito</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsValid" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.IsValid" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- Dinakit Column -->
        <ng-container matColumnDef="dynakit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Dyn@kit</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.Dynakit" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.Dynakit" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- IsAssociate Column -->
        <ng-container matColumnDef="isassociate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Associato</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.IsAssociate" class="ok-icon">done</mat-icon>
            <mat-icon *ngIf="!element.IsAssociate" class="error-icon">close</mat-icon>
          </td>
        </ng-container>

        <!-- Banco Column -->
        <ng-container matColumnDef="banco">
          <th mat-header-cell *matHeaderCellDef>Banco</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Banco }}
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="padding-left: 15px">Azioni</th>
          <ng-container *matCellDef="let element">
            <td mat-cell>
              <button (click)="EliminaMezzo(element)" mat-stroked-button matTooltip="Elimina Mezzo"
                [matTooltipShowDelay]="1000" color="primary" class="margin">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMezzi"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMezzi"></tr>
      </table>
    </div>


    <mat-paginator *ngIf="dataSourceMezzi.length > 0" [pageSizeOptions]="[2]"
      (page)="handlePageEventMezzi($event)" [length]="lengthMezzi" [pageIndex]="pageIndexMezzi"
      [pageSize]="pageSizeMezzi" showFirstLastButtons>
    </mat-paginator>


  </mat-card-content>

</mat-card>

<!-- BOX ELENCO -->

<mat-card [hidden]="worksiteFilterId == '0'" class="box_elenco">
  <mat-card-title class="title-box">
    <mat-icon>list</mat-icon><span>Elenco ODL</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <mat-card-content>
      <div class="edit-container" *ngIf="!isEdit ">

        <mat-form-field appearance="fill" class="label-filter">
          <mat-label>Filtra Mezzo</mat-label>
          <mat-select [(ngModel)]="machineFilterId" (selectionChange)="GetJob()">
            <mat-option *ngFor="let ws of machineFilterSourceBoxElenco" [value]="ws.Id">
              {{ ws.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="label-filter">
          <mat-label>Filtra per dyn@kit</mat-label>
          <mat-select [(ngModel)]="activeFilterId" (selectionChange)="GetJob()">
            <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="label-filter">
          <mat-label>Filtra Stato</mat-label>
          <mat-select [(ngModel)]="completedFilterId" (selectionChange)="GetJob()">
            <mat-option *ngFor="let ws of completedFilterSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="label-filter">
          <mat-label>Filtra attività</mat-label>
          <mat-select [(ngModel)]="typeworkFilterId" (selectionChange)="GetJob()">
            <mat-option *ngFor="let ws of typeworkFilterSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="label-filter">
          <mat-label>Filtra per operatore</mat-label>
          <mat-select [(ngModel)]="operatorFilterId" (selectionChange)="GetJob()">
            <mat-option *ngFor="let ws of operatorFilterSource" [value]="ws.Id">
              {{ ws.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill" class="label-filter">
          <mat-label>Filtra per date</mat-label>
          <mat-date-range-input [rangePicker]="campaignOnePicker" [formGroup]="campaignOne">
            <input matStartDate placeholder="Start date" formControlName="start">
            <input matEndDate placeholder="End date" formControlName="end" (dateChange)="GetJob()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>



      </div>

      <div fxFlex></div>
      <div>
        <!-- (click)="GetJob()" -->

        <button mat-stroked-button color="primary" style="float: right;  margin-right: 10px; margin-bottom: 10px;" (click)="Add()" matTooltip="Nuovo ODL"
          *ngIf="!isEdit && dataSourceMezzi.length > 0">
          <mat-icon>add</mat-icon>
        </button>

        <button mat-stroked-button color="primary" style="float: right; margin-right: 10px; margin-bottom: 10px;" (click)="changeAllToggle()" matTooltip="Attiva tutti"
          *ngIf="!isEdit && dataSourceMezzi.length > 0">
          <mat-icon>edit_attributes</mat-icon>
        </button>

        <button mat-stroked-button matTooltip="Aggiorna tabella" *ngIf="!isEdit" style="float: right;  margin-right: 10px; margin-bottom: 10px;"
          (click)="GetJob()" color="primary" class="margin">
          <mat-icon>autorenew</mat-icon>
        </button>

      </div>
    </mat-card-content>

    <div class="table-container mat-elevation-z8">
      <table [hidden]="isEdit || dataSource.length == 0" mat-table [dataSource]="dataSource" matSort
        matSortActive="datestart" matSortDirection="desc" matSortDisableClear>

        <!-- Code Column -->
        <ng-container matColumnDef="code" sticky>
          <th mat-header-cell *matHeaderCellDef>Codice ODL</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 13px;">{{ element.Code }}</td>
        </ng-container>

        <!-- DateStart Column -->
        <ng-container matColumnDef="datestart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Inizio</th>
          <td mat-cell *matCellDef="let element">
            {{ element.DateStart | date: "dd/MM/yyyy H:mm" }}
          </td>
        </ng-container>

        <!-- DateEnd Column -->
        <ng-container matColumnDef="dateend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Fine</th>
          <td mat-cell *matCellDef="let element">
            {{ element.DateEnd | date: "dd/MM/yyyy H:mm" }}
          </td>
        </ng-container>

        <!-- IdWorkSite Column -->
        <ng-container matColumnDef="idworksite">
          <th mat-header-cell *matHeaderCellDef>Id Cantiere</th>
          <td mat-cell *matCellDef="let element">{{ element.IdWorkSite }}</td>
        </ng-container>

        <!-- IdMachine Column -->
        <ng-container matColumnDef="idmachine">
          <th mat-header-cell *matHeaderCellDef>Id Macchina</th>
          <td mat-cell *matCellDef="let element">{{ element.IdMachine }}</td>
        </ng-container>

        <!-- IdOperator Column -->
        <ng-container matColumnDef="idoperator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Operatore</th>
          <td mat-cell *matCellDef="let element">{{ element.IdOperator }}</td>
        </ng-container>

        <!-- Descrizione Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Descrizione</th>
          <td mat-cell *matCellDef="let element">
            <span class="truncate-text">{{ element.Description }}</span>
          </td>
        </ng-container>

        <!-- WorkSite Column -->
        <ng-container matColumnDef="worksite">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantiere</th>
          <td mat-cell *matCellDef="let element">
            {{ element.WorkSiteDescription }}
          </td>
        </ng-container>

        <!-- Machine Column -->
        <ng-container matColumnDef="machine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Mezzo</th>
          <td mat-cell *matCellDef="let element">
            {{ element.MachineDescription }}
          </td>
        </ng-container>

        <!-- operator Column -->
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef class="columns-title">Operatore</th>
          <td mat-cell *matCellDef="let element">{{ element.OperatorName }}</td>
        </ng-container>

        <!-- Stato Column -->
        <ng-container matColumnDef="stato">
          <th mat-header-cell *matHeaderCellDef class="columns-title">Stato</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
            <mat-icon matTooltip="Avviato" *ngIf="element.Stato == 'Avviato'">play_arrow</mat-icon>
            <mat-icon matTooltip="In pausa" *ngIf="element.Stato == 'In Pausa'">pause</mat-icon>
            <mat-icon matTooltip="Concluso" *ngIf="element.Stato == 'Concluso'">check</mat-icon>
            <mat-icon matTooltip="Non avviato" *ngIf="element.Stato == 'Non Avviato'">hourglass_empty</mat-icon>
          </td>
        </ng-container>

        <!-- Tipo Lavoro -->
        <ng-container matColumnDef="tipolavoro">
          <th mat-header-cell *matHeaderCellDef class="columns-title">Tipo</th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
            <mat-icon matTooltip="Lavoro" *ngIf="element.TipoLavoro == 'Lavoro'">build</mat-icon>
            <mat-icon matTooltip="Trasferimento" *ngIf="element.TipoLavoro == 'Trasferimento'" style="font-size: 200%;">
              transfer_within_a_station</mat-icon>
          </td>
        </ng-container>

        <!-- azione -->
        <ng-container matColumnDef="azione" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>Attività</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Azione }}
          </td>
        </ng-container>

        <!-- materiale -->
        <ng-container matColumnDef="materiale">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Materiale</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Materiale }}
          </td>
        </ng-container>

        <!-- numeropassate -->
        <ng-container matColumnDef="numeropassate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>N. Passate</th>
          <td mat-cell *matCellDef="let element">
            {{ element.NumeroPassate }}
          </td>
        </ng-container>

        <!-- IsActive Column -->
        <!-- <ng-container matColumnDef="isactive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Attivo</th>
            <td mat-cell *matCellDef="let element">
              {{ element.IsActive ? "Si" : "No" }}
            </td>
          </ng-container> -->

        <!-- Vibrazione Column -->
        <ng-container matColumnDef="vibrazione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vibrazione</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Vibrazione ? "Si" : "No" }}
          </td>
        </ng-container>

        <!-- Frequenza Column -->
        <ng-container matColumnDef="frequenza">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequenza</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Frequenza }}
          </td>
        </ng-container>

        <!-- Ampiezza Column -->
        <ng-container matColumnDef="ampiezza">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ampiezza</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Ampiezza }}
          </td>
        </ng-container>

        <!-- ForzaCentrifuga Column -->
        <ng-container matColumnDef="forzacentrifuga">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Forza Centrifuga</th>
          <td mat-cell *matCellDef="let element">
            {{ element.ForzaCentrifuga }}
          </td>
        </ng-container>

        <!-- VelocitaStesa Column -->
        <ng-container matColumnDef="velocitastesa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VelocitaStesa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.VelocitaStesa }}
          </td>
        </ng-container>

        <!-- SpessoreStesa Column -->
        <ng-container matColumnDef="spessorestesa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SpessoreStesa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.SpessoreStesa }}
          </td>
        </ng-container>

        <!-- larghezzaStesa Column -->
        <ng-container matColumnDef="larghezzastesa">
          <th mat-header-cell *matHeaderCellDef>LarghezzaStesa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.LarghezzaStesa }}
          </td>
        </ng-container>

        <!-- TempoStimato Column -->
        <ng-container matColumnDef="TempoStimato">
          <th mat-header-cell *matHeaderCellDef>TempoStimato</th>
          <td mat-cell *matCellDef="let element">
            {{ element.TempoStimatoOre }} Ore
          </td>
        </ng-container>

        <!-- Note Column -->
        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Note }}
          </td>
        </ng-container>

        <!-- IsCompleted -->
        <ng-container matColumnDef="iscompleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Concluso</th>
          <td mat-cell *matCellDef="let element">
            {{ element.IsCompleted }}
          </td>
        </ng-container>

        <!-- IsStarted -->
        <ng-container matColumnDef="isstarted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Avviato</th>
          <td mat-cell *matCellDef="let element">
            {{ element.IsStarted }}
          </td>
        </ng-container>

        <!-- Tampering Banco Column -->
        <ng-container matColumnDef="tamperingbanco">
          <th mat-header-cell *matHeaderCellDef>Tampering Banco</th>
          <td mat-cell *matCellDef="let element">
            {{ element.TamperingBanco }}
          </td>
        </ng-container>

        <!-- Tampering Frequenze Banco Column -->
        <ng-container matColumnDef="tamperingfrequenzabanco">
          <th mat-header-cell *matHeaderCellDef>Frequanza tampering banco</th>
          <td mat-cell *matCellDef="let element">
            {{ element.FrequenzaTamperingBanco }}
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;">Dyn@kit</th>
          <ng-container *matCellDef="let element">

            <td mat-cell style="width: 25px;">
              <mat-slide-toggle [disabled]="element.Stato == 'Concluso'" [checked]="element.IsActive"
                (change)="changeToggle(element)" style="padding-left: 8px; padding-right: 8px;">
              </mat-slide-toggle>
            </td>

            <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;">Azioni</th>
            <td mat-cell>

              <button (click)="Edit(element)" mat-stroked-button color="primary" class="margin-action"
                *ngIf="!element.IsStarted" matTooltip="Modifica" [matTooltipShowDelay]="1000" style="margin-right: 8px; margin-bottom: 8px; margin-top: 8px;">
                <mat-icon>edit</mat-icon>
              </button>

              <button (click)="Info(element)" mat-stroked-button *ngIf="element.IsStarted" matTooltip="Storico"
                [matTooltipShowDelay]="1000" color="primary" class="margin-action">
                <mat-icon>info</mat-icon>
              </button>

              <button (click)="EliminaODL(element)" *ngIf="!element.IsStarted" mat-stroked-button style="margin-bottom: 8px;"
                matTooltip="Elimina ODL" [matTooltipShowDelay]="1000" color="primary" class="margin">
                <mat-icon>delete</mat-icon>
              </button>

            </td>
          </ng-container>
        </ng-container>


        <!-- dynakit -->
        <ng-container matColumnDef="dynakit">
          <th mat-header-cell *matHeaderCellDef>Dyn@kit</th>
          <ng-container *matCellDef="let element">
            <td mat-cell>
              <mat-slide-toggle [disabled]="element.Stato == 'Concluso'" [checked]="element.IsActive"
                (change)="changeToggle(element)">
              </mat-slide-toggle>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>



    <mat-paginator *ngIf="!isEdit && dataSource.length > 0" [pageSizeOptions]="[10]" (page)="handlePageEvent($event)"
      [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>

    <mat-card id="alert-card-odl" *ngIf="dataSource.length == 0 && !isEdit">
      <mat-icon style="font-size: 30px; margin-top: 5px; margin-left: 10px;">warning</mat-icon>
      <p id="alert-text-odl">Nessun ODL trovato!</p>
    </mat-card>

    <mat-card *ngIf="isEdit" style="margin-bottom: 15px;">
      <mat-card-header>
        <mat-card-title>Lavoro</mat-card-title>
      </mat-card-header>

      <div #cardedit>
        <mat-card-content>
          <form [formGroup]="jobForm">

            <mat-form-field class="edit-half-width" appearance="fill">
              <mat-label>Descrizione</mat-label>
              <input matInput maxlength="500" placeholder="Descrizione" formControlName="description" />
            </mat-form-field>

            <mat-divider [inset]="true"></mat-divider>

            <div class="edit-container" style="margin-top: 15px">

              <mat-form-field appearance="fill" style="margin-right: 15px;">
                <mat-label>Data Inizio</mat-label>
                <input matInput type="datetime-local" formControlName="datestart" required />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Data Fine</mat-label>
                <input matInput type="datetime-local" formControlName="dateend" required />
              </mat-form-field>

            </div>

            <div class="edit-container">

              <!-- <mat-form-field appearance="fill" style="display: none;">
                <mat-label>Cantiere</mat-label>
                <mat-select formControlName="idworksite" required>
                  <mat-option *ngFor="let ws of workSiteSource" [value]="ws.Id">
                    {{ ws.Description }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field appearance="fill" style="margin-right: 15px;">
                <mat-label>Mezzo</mat-label>
                <mat-select formControlName="idmachine" required (selectionChange)="onChangeMachine()">
                  <mat-option *ngFor="let m of macchineAssociate" [value]="m.Id">
                    {{ m.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" style="margin-right: 15px;">
                <mat-label>Operatore</mat-label>
                <mat-select formControlName="idoperator" required>
                  <mat-option *ngFor="let m of operatorSource" [value]="m.Id">
                    {{ m.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" style="margin-right: 15px;">
                <mat-label>Attività</mat-label>
                <mat-select formControlName="azione" required (selectionChange)="onChangeAzione()">
                  <mat-option *ngFor="let m of azioniSource" [value]="m.Id">
                    {{ m.Description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" style="margin-right: 15px;">
                <mat-label>Ore previste</mat-label>
                <input matInput formControlName="tempostimato" type="number" required />
              </mat-form-field>

              <mat-divider [inset]="true"></mat-divider>

              <!-- <mat-form-field appearance="fill">
                  <mat-label>Tipo Attivita</mat-label>
                  <mat-select formControlName="idtypework" required>
                    <mat-option *ngFor="let m of typeworkSource" [value]="m.Id">
                      {{ m.Description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

              <!-- <mat-form-field appearance="fill">
                <mat-label>Codice di lavoro</mat-label>
                <input matInput formControlName="code" required />
              </mat-form-field> -->

              <!-- <mat-checkbox class="margin" formControlName="isactive"
                  >Attivo</mat-checkbox
                > -->


            </div>

            <!-- <div class="edit-container"> -->


            <!-- <mat-form-field appearance="fill">
                  <mat-label>Materiale</mat-label>
                  <mat-select
                    formControlName="materiale"
                  >
                    <mat-option *ngFor="let m of materialiSource" [value]="m.Id">
                      {{ m.Description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

            <!-- </div> -->

            <div style="margin-top: 15px;">

              <div class="edit-container" >

                <mat-checkbox class="check-margin" formControlName="vibrazione" *ngIf="showVibrazione">Vibrazione
                </mat-checkbox>
                <mat-form-field appearance="fill" *ngIf="showVibrazione" style="margin-right: 15px;">
                  <mat-label>Frequenza Vibrazione</mat-label>
                  <mat-select formControlName="frequenza">
                    <mat-option *ngFor="let m of frequenzaSource" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showAmpiezza" style="margin-right: 15px;">
                  <mat-label>Ampiezza</mat-label>
                  <mat-select formControlName="ampiezza">
                    <mat-option *ngFor="let m of ampiezzaSource" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showForzaCentrifuga" style="margin-right: 15px;">
                  <mat-label>Forza Centrifuga</mat-label>
                  <mat-select formControlName="forzacentrifuga">
                    <mat-option *ngFor="let m of centrifugheSource" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showNumeroPassate" style="margin-right: 15px;">
                  <mat-label>Numero Passate</mat-label>
                  <input type="number" matInput formControlName="numeropassate" />
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showVelocitaStesa" style="margin-right: 15px;">
                  <mat-label>Vel. Stesa min ({{ velocitaStesaMin }}) max ({{
                    velocitaStesaMax
                    }})</mat-label>
                  <input matInput formControlName="velocitastesa" type="number" />
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showSpessoreStesa" style="margin-right: 15px;">
                  <mat-label>Spes. Stesa max ({{ spessoreStesaMax }})</mat-label>
                  <input matInput formControlName="spessorestesa" type="number" />
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showLarghezzaStesa" style="margin-right: 15px;">
                  <mat-label>Lar. Stesa min ({{ larghezzaStesaMin }}) max ({{ larghezzaStesaMax }} </mat-label>
                  <input matInput formControlName="larghezzastesa" type="number" />
                </mat-form-field>

              </div>

              <div class="edit-container" *ngIf="showBanco">

                <!-- <mat-form-field appearance="fill">
                    <mat-label>Banco</mat-label>
                    <input matInput formControlName="banco" readonly />
                  </mat-form-field> -->

                <mat-checkbox class="check-margin" formControlName="tamperingbanco" *ngIf="showTampering" onchec>
                  Tampering
                </mat-checkbox>

                <mat-form-field appearance="fill" *ngIf="showTampering">
                  <mat-label>Frequenza Tampering</mat-label>
                  <mat-select formControlName="frequenzatamperingbanco">
                    <mat-option *ngFor="let m of frequenzaTamperingBancoSource" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <p>
                <mat-form-field appearance="fill" [style.width.px]="cardedit.offsetWidth - 575">
                  <mat-label>Note</mat-label>
                  <textarea matInput rows="3" maxlength="500" placeholder="Note" formControlName="note"></textarea>
                </mat-form-field>
              </p>
            </div>
          </form>
        </mat-card-content>
      </div>

      <mat-divider inset></mat-divider>
      <mat-card-actions>



        <div fxFlex></div>
        <button [disabled]="!jobForm.valid" mat-stroked-button color="primary" class="btn-block margin"
          (click)="Salva()">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-stroked-button color="warn" class="btn-block margin" (click)="Annulla()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-card-content>



</mat-card>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationModel } from 'src/app/model/ConfigurationModel';
import { DynalinkDialogComponent } from 'src/app/components/dynalink-dialog/dynalink-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss'],
})
export class ConfigurationPageComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  displayedColumns: string[] = ['tipo', 'user', 'password', 'actions'];
  dataSource = [];
  isEdit = false;
  isNew = false;

  btnEnabled = true;

  configForm = this.fb.group({
    type: ['', Validators.required],
    user: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnInit(): void {
    this.GetConfiguration();
  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

  GetConfiguration(): void {
    this.apiService.GetConfiguration().subscribe(
      (data) => {
        this.dataSource = data;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
      }
    );
  }

  Add(): void {
    this.isEdit = true;
    this.isNew = true;

    this.configForm.setValue({ type: 'dyn@link', user: '', password: '' });
  }

  Annulla(): void {
    this.isEdit = false;
    this.isNew = false;
  }

  Salva(): void {
    let user = this.configForm.get('user')?.value;
    let pass = this.configForm.get('password')?.value;
    let type = this.configForm.get('type')?.value;

    let dataConfig = new ConfigurationModel(type, user, pass);

    if (this.isNew) {
      this.apiService.AddConfiguration(dataConfig).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.GetConfiguration();

          this.openSnackBarOk('Configurazione aggiunta');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error);
        }
      );
    } else {
      this.apiService.UpdateConfiguration(dataConfig).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.GetConfiguration();

          this.openSnackBarOk('Configurazione modificata');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error);
        }
      );
    }
  }

  Edit(element: any): void {
    ////console.log(element);

    this.isEdit = true;
    this.isNew = false;

    this.configForm.setValue({
      type: element.Type,
      user: element.UserName,
      password: element.Password,
    });
  }

  Test(element: any): void {
    ////console.log(element);

    this.btnEnabled = false;

    this.apiService.ReadMachine().subscribe(
      (data) => {

        if (data.Errore) {
          this.openSnackBarError(data.Errore);
        }
        else
        {
           this.dialog.open(DynalinkDialogComponent, { panelClass:'full-width-dialog',  data: data.Xml });
        }

        this.btnEnabled = true;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError('Error');

        this.btnEnabled = true;
      }
    );



  }

  Delete(element: any): void {
    ////console.log(element);

    if(confirm("Confermi eliminazione ?" )) {

    let dataConfig = new ConfigurationModel(element.Type, element.UserName, element.Password);

      this.apiService.DeleteConfiguration(dataConfig).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.GetConfiguration();

          this.openSnackBarOk('Configurazione Eliminata');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError('Error');
        }
      );

    }

  }



}

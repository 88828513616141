<!-- MANUALE APP
MANUALE PORTALE -->

<div #riferimento>
  <mat-card>
    <mat-card-title class="header">Assistenza</mat-card-title>
  </mat-card>
</div>

<div class="container-card-manuali" [ngClass]="{'container-card-manuali': riferimento.offsetWidth > 1306, 'container-card-manuali-responsive': riferimento.offsetWidth <= 1305}">

  <div #cardPortale class="container-responsive-first-second-card" [ngClass]="{'container-first-second-card': riferimento.offsetWidth > 858, 'container-responsive-first-second-card-responsive': riferimento.offsetWidth <= 857}">

    <!-- MANUALE PORTALE -->
    <mat-card class="card-manuale">
      <mat-card-title>Manuale portale</mat-card-title>
      <mat-card-subtitle style="margin-bottom: 0 !important;">Configurazione e utilizzo</mat-card-subtitle>
      <img mat-card-image src="../../../assets/img/Instruction-manual.png">
      <mat-card-content>
        <p>
          Nel documento sono presenti diverse sezioni esplicative, utili a comprendere il funzionamento e l'utilizzo del portale.
          <br>Esso comprende la descrizione di tutte le sezioni navigabili all'interno dell'applicativo.
          <br>Vengono descritte le tabulazioni per la visualizzazione dei dati e i rispettivi pulsanti per le configurazioni.
          <br>Vengono poi descritte e spiegate tutte le configurazioni necessarie per il corretto funzionamento dell'applicativo.
        </p>
      </mat-card-content>

      <div class="container-action-button" [ngClass]="{'container-action-button': cardPortale.offsetWidth >= 371, 'container-action-button-responsive': cardPortale.offsetWidth <= 370}">

        <button mat-button mat-raised-button color="warn" (click)="DownloadManuale()">
          <div class="format-button-icon-and-label">
            <mat-icon>save_alt</mat-icon>
            <span style="padding-top: 2px;">Scarica</span>
          </div>
        </button>

        <div id="alert-aggiornamento" *ngIf="aggiornamentoMan">
          <div class="container-content-alert-aggiornamento">
            <mat-icon style="margin-top: -7px;">warning</mat-icon>
            <span>In aggiornamento...</span>
          </div>
        </div>

      </div>

    </mat-card>

    <!-- MANUALE DYN@KIT -->
    <mat-card class="card-manuale">
      <mat-card-title>Manuale Dyn@Kit</mat-card-title>
      <mat-card-subtitle style="margin-bottom: 0 !important;">Configurazione e utilizzo</mat-card-subtitle>
      <img mat-card-image src="../../../assets/img/manuale-kit.png">
      <mat-card-content>
        <p>
          Nel documento sono presenti diverse sezioni esplicative, utili a comprendere il funzionamento e l’utilizzo del palmare.
          <br>Esso comprende la descrizione di tutte le sezioni navigabili all'interno dell'app.
          <br>Vengono descritte le azioni per la visualizzazione dei dati e i rispettivi pulsanti per la gestione delle attività.
          <br>Vengono poi descritte e spiegate tutte le configurazioni necessarie per il corretto funzionamento dell’app.
        </p>
      </mat-card-content>

      <div [ngClass]="{'container-action-button': cardPortale.offsetWidth >= 371, 'container-action-button-responsive': cardPortale.offsetWidth <= 370}">

        <button mat-button mat-raised-button color="warn" (click)="DownloadManualeDynakit()">
          <div class="format-button-icon-and-label">
            <mat-icon>save_alt</mat-icon>
            <span style="padding-top: 2px;">Scarica</span>
          </div>
        </button>

        <div id="alert-aggiornamento" *ngIf="aggiornamentoKit">
          <div class="container-content-alert-aggiornamento">
            <mat-icon style="margin-top: -7px;">warning</mat-icon>
            <span>In aggiornamento...</span>
          </div>
        </div>

      </div>

    </mat-card>

  </div>

  <div>

    <!-- CARD CONTATTI -->
    <mat-card class="card-manuale" style="height: fit-content;">
      <mat-card-title>Contattaci</mat-card-title>
      <mat-card-content>
        <span>Di seguito abbiamo riportato le informazioni di contatto del nostro reparto di assistenza.</span>
        <div class="container-info-contatti">
          <span class="bold">Telefono: </span>+39 02 92108836 <br>
          <span class="bold">Orari: </span>Lun - Ven 09:00 - 18:00
        </div>
      </mat-card-content>
    </mat-card>

    <!-- CARD FORM INVIO EMAIL  -->
    <mat-card class="card-segnalazione">
      <mat-card-title>Inviaci una segnalazione</mat-card-title>
      <mat-card-content style="margin-top: 18px;">
        <span>
          Invitiamo gli utenti che hanno incontrato difficoltà,
          barriere nella navigazione o nell’uso dei contenuti a scriverci commenti, domande, suggerimenti,
          segnalazioni di errori o difficoltà rispetto all’accessibilità e all’usabilità del portale.
        </span>
        <div class="container-form-segnalazione">
          <div class="container-tipologia-textBox">
            <span style="font-size: 20px; font-weight: 500;">Messaggio:</span>

            <!-- SELECT TIPOLOGIA - MOTIVAZIONE -->
            <mat-form-field appearance="fill" class="select-tipologia">
              <mat-label>Motivazione</mat-label>
              <mat-select [(ngModel)]="tipologia">
                <mat-option *ngFor="let item of tipologieSegnalazioni; index as i" [value]="item.name">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- TEXTAREA MESSAGGIO -->
            <mat-form-field appearance="fill" style="width: auto;">
              <mat-label>Messaggio</mat-label>
              <textarea placeholder="Scrivici un messaggio..." matInput cdkTextareaAutosize cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="50000000" [(ngModel)]="body" (ngModelChange)="textChange($event)"></textarea>
            </mat-form-field>

            <!-- BUTTON INVIA SEGNALAZIONE -->
            <button
              mat-button
              mat-raised-button
              color="warn"
              style="width: fit-content;"
              (click)="sendSegnalazione(body, tipologia)"
              [disabled]="tipologia == undefined || body == ''"
            >
              <div class="format-button-icon-and-label">
                <span style="padding-top: 2px;">Invia segnalazione</span>
              </div>
            </button>

          </div>
        </div>
      </mat-card-content>
    </mat-card>

  </div>



</div>

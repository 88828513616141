<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div #riferimento class="rif"></div>


<!-- BOX ANAGRAFICA -->

<mat-card class="box_anagrafica" [ngClass]="{'box_fullSize':  riferimento.offsetWidth < 1100}">

  <mat-card-title class="title-box"><mat-icon>account_box</mat-icon><span>Profilo</span></mat-card-title>

  <mat-divider></mat-divider>

  <div #riferimento2>
    <mat-card-content>

      <!-- FORM DEFAULT START -->


      <div style="margin-top: 25px">
        <mat-form-field appearance="fill" class="label-full" *ngIf="!isChangePassword && !isChangeName">
          <mat-label>Email</mat-label>
          <input matInput value={{email}} readonly>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill" class="label-half" style="margin-right: 9.2px;" *ngIf="!isChangePassword && !isChangeName">
        <mat-label>Username</mat-label>
        <input matInput value={{name}} readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" class="label-half" *ngIf="!isChangePassword && !isChangeName">
        <mat-label>Ruolo</mat-label>
        <input matInput value={{role}} readonly>
      </mat-form-field>



      <!-- FORM CHANGE PASSWORD -->

      <form [formGroup]="passwordForm" *ngIf="isChangePassword">

        <mat-form-field appearance="fill" class="label-full">
          <mat-label>Vecchia password</mat-label>
          <input matInput formControlName="oldpassword" maxlength="100" [type]="hide ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <!-- INFO PASSWORD -->

        <div *ngIf="isChangePassword"
          style="width: 170px; margin-top: -5px; margin-bottom: 8px; margin-left: -10px;"
          matTooltip="{{'registration_passwordmustcontain' | translate}}
                      {{'registration_passwordlength' | translate}}
                      {{'registration_passwordonelower' | translate}}
                      {{'registration_passwordonenumber' | translate}}
                      {{'registration_passwordonespecial' | translate}}
                      {{'registration_passwordoneupper' | translate}}"
          matTooltipPosition="right">
          <span>
            <mat-icon style="cursor: pointer;transform: scale(0.9) translateY(7px)">info</mat-icon>
            {{'registration_rulesforpasswordcrea' | translate}}
          </span>
        </div>

        <mat-form-field appearance="fill" class="label-full">
          <mat-label>Nuova password</mat-label>
          <input matInput formControlName="password" maxlength="100" [type]="hide1 ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="hide1 = !hide1">
            <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="fill" class="label-full">
          <mat-label>Conferma password</mat-label>
          <input matInput formControlName="confirmpassword" maxlength="100" [type]="hide2 ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="hide2 = !hide2">
            <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

      </form>



      <!-- FORM CHANGE NOME AZIENDA -->

      <form [formGroup]="nameForm" *ngIf="isChangeName">
        <mat-form-field appearance="fill" class="label-full">
          <mat-label>Nuovo nome azienda</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </form>


      <mat-divider [inset]="true"></mat-divider>


      <!-- ACTION -->

      <mat-card-actions style="text-align: right; margin-top: 8px;">

        <!-- BOTTONI DEFAULT START -->

        <button
          *ngIf="!isChangePassword && !isChangeName"
          mat-stroked-button
          color="primary"
          class="btn-block margin"
          (click)="ChangePassword()"
        >
          Cambia Password
        </button>
        <button
          *ngIf="!isChangePassword && !isChangeName"
          mat-stroked-button
          color="primary"
          class="btn-block margin"
          (click)="ChangeName()"
        >
          Cambia Nome
        </button>


        <!-- BOTTONI CHANGE PASSWORD -->

        <button
          *ngIf="isChangePassword"
          mat-stroked-button
          [disabled]="!passwordForm.valid"
          color="primary"
          class="btn-block margin"
          (click)="SalvaPassword()"
        >
          Salva
        </button>


        <!-- BOTTONI CHANGE NOME AZIENDA -->

        <button
          *ngIf="isChangeName"
          mat-stroked-button
          [disabled]="!nameForm.valid"
          color="primary"
          class="btn-block margin"
          (click)="SalvaName()"
        >
          Salva
        </button>


        <button
          *ngIf="isChangePassword || isChangeName"
          mat-stroked-button
          color="primary"
          class="btn-block margin"
          (click)="Annulla()"
        >
          Annulla
        </button>

      </mat-card-actions>
    </mat-card-content>
  </div>

</mat-card>


<!--<mat-card *ngIf="isChangeName">
  <mat-card-title>Cambia Nome</mat-card-title>
  <mat-card-content>

  </mat-card-content>

  <mat-card-actions>
    <div fxFlex></div>
    <button
      mat-stroked-button
      [disabled]="!nameForm.valid"
      color="primary"
      class="btn-block margin"
      (click)="SalvaName()"
    >
      Salva
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="btn-block margin"
      (click)="Annulla()"
    >
      Annulla
    </button>
  </mat-card-actions>
</mat-card> -->

export class WorkSiteModel {

  constructor(
    public id: number,
    public description: string,
    public isactive: boolean,
    public datestart: Date,
    public dateend: Date,
    public descriptionextended: string,
    public client: string,
    public bossid?: string,
    public commessa?: string,
    public address?: string,
    public lat?: string,
    public lon?: string

  ) {  }
  }



import { Component, OnInit } from '@angular/core';
import { DialogConfermaComponent } from './../Dialog-conferma/Dialog-conferma.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterOperatorModel } from 'src/app/model/FilterOperatorModel';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateAdapter } from '@angular/material/core';
import { OperatorModel } from 'src/app/model/OperatorModel';
import { FilterWorkSiteOperatorModel } from 'src/app/model/FilterWorkSiteOperatorModel';
import { FilterWorkSiteModel } from 'src/app/model/FilterWorkSiteModel';
import { Router } from '@angular/router';
import { FilterDynakitModel } from 'src/app/model/FilterDynakitModel';


@Component({
  selector: 'app-AnagraficaDynaKit',
  templateUrl: './AnagraficaDynaKit.component.html',
  styleUrls: ['./AnagraficaDynaKit.component.scss']
})
export class AnagraficaDynaKitComponent implements OnInit {

  constructor(
    private dateAdapter: DateAdapter<any>,
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 15;
  length = 0;
  displayedColumns: string[] = [
    //'id',
    'machineName',
    'nomeDynakit',
    'dateActivation',
    'dateEnd',
    'isvalid',
  ];


  dataSource = [];
  showActions = false;

  idOperator = '';
  sortField: string = 'name';
  sortOrder: string = 'asc';
  filtername = '';
  activeFilterId = '0';
  dataSourceCantieri = []
  bossFilterId = '0'
  isTableOperatori = true;
  filtercustomer: string = 'bb';

  operatorForm = this.fb.group({
    date: ['', Validators.required],
    name: ['', Validators.required],
    pin : ['']
  });

  ngOnInit(): void {

    this.dateAdapter.setLocale('it-IT');

    this.GetDynakitList()

  }

  ngAfterViewInit() {
    /*this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;
      this.sortField = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.GetOperator();
    });*/

    this.GetDynakitList();
  }

  handlePageEvent(event: PageEvent) {
    console.log('index: ', this.pageIndex)

    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.GetDynakitList();
  }

  GetDynakitList(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterDynakitModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder,
      this.filtercustomer,
    );

    this.apiService.GetDynakitList(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
        console.log(this.dataSource);
      },
      (error) => {
        //console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }


  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

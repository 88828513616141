import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FilterModel } from 'src/app/model/FilterModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParameterModel } from 'src/app/model/ParameterModel';


interface selectGruppo {
  Id: string;
  Description: string;
}

@Component({
  selector: 'app-parameter-page',
  templateUrl: './parameter-page.component.html',
  styleUrls: ['./parameter-page.component.scss']
})
export class ParameterPageComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar) {}

  pageEvent!: PageEvent;
  @ViewChild(MatSort) sort!: MatSort;

  gruppoSource: selectGruppo[] = [];

  isLoadingResults = true;
  pageIndex: number = 0;
  pageSize: number = 50;
  length = 0;
  displayedColumns: string[] = [
    'chiave',
    'model',
    'gruppo',
    'dynalink',
    'actions',
  ];
  dataSource = [];
  isEdit = false;
  isNew = false;
  idParameter = 0;
  sortField: string = 'chiave';
  sortOrder: string = 'asc';
  dynalinkDataSource = ['', 'Basic', 'Advanced'];

  showFinitrice = false;
  showBanco = false;
  showCompattatore = false;

  parameterForm = this.fb.group({
    chiave: ['', Validators.required],
    model : ['', Validators.required],
    gruppo: ['', Validators.required],
    ampiezzahilo : [''],
    banco: [''],
    forzacentrifugahilo : [''],
    frequenzahilo: [''],
    dynalink : ['' ],
    larghezzastesa : [''],
    spessorestesamax : [''],
    velocitastesa : [''],
    vibrazione : [''],
    fueltank : [''],
    tampering: [''],
    tamperinghilo: [''],
  });

  ngOnInit(): void {

    this.gruppoSource = [];

    var itemc: selectGruppo = { Id: 'COMPATTATORE', Description: 'COMPATTATORE' };
    this.gruppoSource.push(itemc);

    var itemf: selectGruppo = { Id: 'FINITRICE', Description: 'FINITRICE' };
    this.gruppoSource.push(itemf);

    var itemf: selectGruppo = { Id: 'ESCAVATORE', Description: 'ESCAVATORE' };
    this.gruppoSource.push(itemf);

    var itemb: selectGruppo = { Id: 'BANCO', Description: 'BANCO' };
    this.gruppoSource.push(itemb);

    this.GetParameters();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((data: any) => {
      this.pageIndex = 0;
      this.sortField = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.GetParameters();
    });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.GetParameters();
  }

  GetParameters(): void {
    this.isLoadingResults = true;

    let dataFilter = new FilterModel(
      this.pageIndex,
      this.pageSize,
      this.sortField,
      this.sortOrder
    );

    this.apiService.GetMachineParameters(dataFilter).subscribe(
      (data) => {
        this.dataSource = data.Data;
        this.length = data.Count;
        this.isLoadingResults = false;
      },
      (error) => {
        ////console.log(error);
        this.openSnackBarError(error);
        this.isLoadingResults = false;
      }
    );
  }

  Add() : void {

    this.isEdit = true;
    this.isNew = true;

    this.idParameter = 0;

    this.showBanco = false;
    this.showFinitrice = false;
    this.showCompattatore = false;

    this.parameterForm.setValue({
      chiave: '',
      model: '',
      gruppo: 'COMPATTATORE',
      ampiezzahilo : '',
      banco: '',
      forzacentrifugahilo : '',
      frequenzahilo: '',
      dynalink : '',
      larghezzastesa : '',
      spessorestesamax : '',
      velocitastesa : '',
      vibrazione: '',
      fueltank: '',
      tampering: '',
      tamperinghilo: '',
    });

  }

  Annulla() : void {
    this.isEdit = false;
    this.isNew = false;
  }

  Salva() : void {
    var fueltank = this.parameterForm.get('fueltank')?.value;

    var ft = 0;

    if(!isNaN(Number(fueltank))){
      ft = Number(fueltank);
    }

    let dataParameter = new ParameterModel(
      this.idParameter,
      this.parameterForm.get('chiave')?.value,
      this.parameterForm.get('model')?.value,
      this.parameterForm.get('gruppo')?.value,
      this.parameterForm.get('dynalink')?.value,
      this.parameterForm.get('vibrazione')?.value,
      this.parameterForm.get('frequenzahilo')?.value,
      this.parameterForm.get('ampiezzahilo')?.value,
      this.parameterForm.get('forzacentrifugahilo')?.value,
      this.parameterForm.get('velocitastesa')?.value,
      this.parameterForm.get('spessorestesamax')?.value,
      this.parameterForm.get('larghezzastesa')?.value,
      this.parameterForm.get('banco')?.value,
      this.parameterForm.get('tampering')?.value,
      this.parameterForm.get('tamperinghilo')?.value,
      ft,
    );

    if (this.isNew) {
      this.apiService.AddParameter(dataParameter).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idParameter = 0;
          this.GetParameters();
          this.openSnackBarOk('Parametri aggiunti');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error.error.Message);
        }
      );
    } else {
      this.apiService.UpdateParameter(dataParameter).subscribe(
        (data) => {
          this.isEdit = false;
          this.isNew = false;
          this.idParameter = 0;
          this.GetParameters();
          this.openSnackBarOk('Parametri modificati');
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error.error.Message);
        }
      );
    }

  }

  Edit(element: any): void {
    this.isEdit = true;
    this.isNew = false;

    this.idParameter = element.Id;

    this.parameterForm.setValue({
      chiave: element.Chiave,
      model: element.Model,
      gruppo: element.Gruppo,
      ampiezzahilo : element.AmpiezzaHiLo,
      banco: element.Banco,
      forzacentrifugahilo : element.ForzaCentrifugaHiLo,
      frequenzahilo: element.FrequenzaHiLo,
      dynalink : element.Dynalink,
      larghezzastesa : element.LarghezzaStesa,
      spessorestesamax : element.SpessoreStesaMax,
      velocitastesa : element.VelocitaStesa,
      vibrazione: element.Vibrazione,
      fueltank: element.FuelTank,
      tampering: element.Tampering,
      tamperinghilo: element.TamperingHiLo,
    });

    this.onChangeGruppo();
  }

  Elimina(element: any): void {

    if(confirm("Confermi eliminazione ?" )) {

      this.apiService.DeleteParameter(element.Id).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.openSnackBarOk('Parametro Eliminato');
          this.GetParameters();
        },
        (error) => {
          ////console.log(error);
          this.openSnackBarError(error);
          this.isLoadingResults = false;
          this.openSnackBarError(error.error.Message);
        }
      );

    }

  }


  onChangeGruppo() {
    let gruppo = this.parameterForm.get('gruppo')?.value;

    if (gruppo == "COMPATTATORE") {
      this.showBanco = false;
      this.showFinitrice = false;
      this.showCompattatore = true;
    }

    if (gruppo == "FINITRICE") {
      this.showBanco = false;
      this.showFinitrice = true;
      this.showCompattatore = false;
    }

    if (gruppo == "BANCO") {
      this.showBanco = true;
      this.showFinitrice = false;
      this.showCompattatore = true;
    }

  }


  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}

import { templateJitUrl } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

interface IMachineMonthWork {
  Year?: number;
  Month?: number;
  OperatingHours: number;
}

interface IMachine {
  Id?: number;
  MachineDescription: string;
  OperatingHours: number;
  MachineMonthWork: Array<IMachineMonthWork>;
}

interface IDashboard {
  MachineTotal: number;
  MachineValidTotal: number;
  Machines?: Array<IMachine>;
}

interface ISeries {
  name: string,
  type: string,
  stack : string,
  data: Array<number>;
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  constructor(private apiService: ApiService) {}

  optionsTotal: any;
  optionsYear: any;
  isLoadingResults = true;
  macchineTotale = 0;
  macchineValideTotale = 0;
  xAxisData: any = [];
  data1: any = [];

  first = true;
  dataYear: any = [];
  dataYearx: any = [];
  dataYeary: any = [];
  dataLegend: any = [];

  dashboard: IDashboard = {
    MachineTotal: 0,
    MachineValidTotal: 0,
  };

  series : Array<ISeries> = [];

  ngOnInit(): void {
    this.GetDashboard();
  }

  GetDashboard(): void {
    this.isLoadingResults = true;

    this.apiService.Dashboard().subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.dashboard = data;

        this.xAxisData = [];
        this.data1 = [];

        this.dataYear = [];
        this.dataYearx = [];
        this.dataYeary = [];
        this.dataLegend = [];

        this.series = [];

        this.dashboard.Machines?.forEach((item) => {
          this.xAxisData.push(item.MachineDescription);
          this.data1.push(item.OperatingHours);
        });

        this.dashboard.Machines?.forEach((item) => {

          this.dataLegend.push(item.MachineDescription);

          const dataY : Array<number> = [];

          for (let entry of item.MachineMonthWork) {
            if (this.first == true) {
              this.dataYearx.push(entry.Month + "-" + entry.Year);
            }

            dataY.push(entry.OperatingHours);
          }

          this.first = false;

          const s : ISeries = {
              name : item.MachineDescription,
              data : dataY,
              type : 'line',
              stack: 'counts'
          }

          this.series.push(s);

        });


        this.optionsTotal = {
          legend: {
            data: ['ore'],
            align: 'left',
          },
          tooltip: {},
          xAxis: {
            data: this.xAxisData,
            silent: false,
            splitLine: {
              show: false,
            },
          },
          yAxis: {},
          series: [
            {
              name: 'ore',
              type: 'bar',
              data: this.data1,
            },
          ],
          animationEasing: 'elasticOut',
        };

        this.optionsYear = {
          legend: {
            data: this.dataLegend,
            align: 'left',
          },
          tooltip: {},
          xAxis: {
            data: this.dataYearx,
            silent: false,
            splitLine: {
              show: false,
            },
          },
          yAxis: {},
          series: this.series,
          animationEasing: 'elasticOut',
        };

        ////console.log(this.dashboard);
      },
      (error) => {
        ////console.log(error);
        this.isLoadingResults = false;
      }
    );
  }
}

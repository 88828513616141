<div class="registerPage">
<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>{{'register' | translate}}</mat-card-title>
      </mat-card-header>

      <form [formGroup]="registrationForm">

        <mat-card-content>
          <mat-form-field class="edit-full-width">
            <input matInput placeholder="Email" formControlName="email" >
            <mat-error *ngIf="registrationForm.get('email')">{{emailError}}</mat-error>
          </mat-form-field>


          <mat-form-field class="edit-full-width">
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password">
            <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="registrationForm.get('password')">{{'validPassword' | translate}}</mat-error>
          </mat-form-field>
          <div matTooltip="{{'registration_passwordmustcontain' | translate}}
                           {{'registration_passwordlength' | translate}}
                           {{'registration_passwordonelower' | translate}}
                           {{'registration_passwordonenumber' | translate}}
                           {{'registration_passwordonespecial' | translate}}
                           {{'registration_passwordoneupper' | translate}}"
                matTooltipPosition="above" style="transform: translatey(-5px);"
                matTooltipClass="my-tooltip">
                <span>
                  <mat-icon style="cursor: pointer;transform: scale(0.9) translateY(7px)">info</mat-icon>
                  <b>{{'registration_rulesforpasswordcrea' | translate}}</b>
                </span>
          </div>

          <mat-form-field class="edit-full-width">
            <input matInput [placeholder]="'confirmPassword' | translate" [type]="hide2 ? 'password' : 'text'" formControlName="confirm_password">
            <mat-icon style="cursor: pointer;" matSuffix (click)="hide2 = !hide2">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="registrationForm.get('confirm_password')">{{'passwordNotMatch' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="edit-full-width">
            <input matInput [placeholder]="'Nome azienda'" formControlName="name">
          </mat-form-field>

        </mat-card-content>

        <button (click)="Register()" mat-stroked-button color="accent" [disabled]="!registrationForm.valid" class="btn-block">{{'register' | translate | uppercase }}</button>

      </form>
    </mat-card>
  </div>
  <div style="text-align: center; font-size: 16px; margin-top: 50px;">
    {{'alreadyRegistered' | translate}}
  <span [routerLink]="['/login']" routerLinkActive="router-link-active" style="cursor: pointer;">
    <u><b>{{'login' | translate}}</b></u>
  </span>
  </div>

</div>

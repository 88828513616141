<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div #riferimento
style="min-width: 620px;">
  <mat-card>
    <mat-card-title *ngIf="!isEdit">Cantieri</mat-card-title>
    <mat-card-title *ngIf="isEdit">Cantiere</mat-card-title>
    <mat-card-content>
      <div class="edit-container" *ngIf="!isEdit">
        <mat-form-field appearance="fill">
          <mat-label>Filtra Attivi</mat-label>
          <mat-select
            [(ngModel)]="activeFilterId"
            (selectionChange)="GetWorkSite()"
          >
            <mat-option *ngFor="let ws of activeFilterSource" [value]="ws.Id">
              {{ ws.Description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Filtra Capo Cantieri</mat-label>
          <mat-select
            [(ngModel)]="bossFilterId"
            (selectionChange)="GetWorkSite()"
          >
            <mat-option *ngFor="let ws of bossFilterSource" [value]="ws.UserId">
              {{ ws.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div fxFlex></div>
      <div>
        <button
          mat-stroked-button
          color="primary"
          (click)="Add()"
          *ngIf="!isEdit"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<!-- TABELLA ELENCO CANTIERI -->
<div class="table-container mat-elevation-z8"
style="min-width: 620px;">
  <table
    mat-table
    *ngIf="!isEdit"
    [dataSource]="dataSource"
    matSort
    matSortActive="datestart"
    matSortDirection="asc"
    matSortDisableClear
  >

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
    </ng-container>

    <!-- DateStart Column -->
    <ng-container matColumnDef="datestart">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Inizio</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DateStart | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- DateEnd Column -->
    <ng-container matColumnDef="dateend">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Data Fine</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DateEnd | date: "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Descrizione</th>
      <td mat-cell *matCellDef="let element">
        <span class="truncate-text">{{ element.Description }}</span>
      </td>
    </ng-container>

    <!-- Client Column -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Committente</th>
      <td mat-cell *matCellDef="let element">{{ element.Client }}</td>
    </ng-container>

    <!-- Indirizzo -->
    <ng-container matColumnDef="Indirizzo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Indirizzo</th>
      <td mat-cell *matCellDef="let element">{{ element.Indirizzo }}</td>
    </ng-container>

    <!-- Boss id -->
    <ng-container matColumnDef="bossid">
      <th mat-header-cell *matHeaderCellDef>Capocantiere Id</th>
      <td mat-cell *matCellDef="let element">{{ element.BossId }}</td>
    </ng-container>

    <!-- Boss Name Column -->
    <ng-container matColumnDef="bossname">
      <th mat-header-cell *matHeaderCellDef class="columns-title">Capocantiere</th>
      <td mat-cell *matCellDef="let element">{{ element.BossName }}</td>
    </ng-container>

    <!-- IsActive Column -->
    <ng-container matColumnDef="isactive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columns-title">Attivo</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.IsActive" class="ok-icon">done</mat-icon>
        <mat-icon *ngIf="!element.IsActive" class="error-icon">close</mat-icon>
      </td>
    </ng-container>

    <!-- IsActive Column -->
    <ng-container matColumnDef="commessa" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Commessa</th>
      <td mat-cell *matCellDef="let element">
        {{ element.CodiceCommessa }}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions"  stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="columns-title">Azioni</th>
      <ng-container *matCellDef="let element">
        <td mat-cell    >
          <button
            (click)="Edit(element)"

            mat-stroked-button
            color="primary"
            matTooltip="Modifica"
            style="margin-right: 5px; margin-top: 2px;"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <!-- DENTRO MAT-CELL [matTooltip]="'Sblocca il Capocantiere prima di poter modificare il cantiere'" [matTooltipDisabled]="isActive(element.BossId)" [matTooltipShowDelay]="1000" -->
          <!-- DENTRO BUTTON EDIT [disabled]="!isActive(element.BossId)" -->

          <button
            (click)="Dashboard(element.Id)"
            mat-stroked-button
            color="primary"
            matTooltip="Visualizza dashboard"
          >
            <mat-icon>timeline</mat-icon>
          </button>

        </td>
      </ng-container>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="report">
      <th mat-header-cell *matHeaderCellDef>Report</th>
      <ng-container *matCellDef="let element">
        <td mat-cell>

        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<mat-paginator
  style="min-width: 620px;"
  *ngIf="!isEdit"
  [pageSizeOptions]="[10]"
  (page)="handlePageEvent($event)"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  showFirstLastButtons
></mat-paginator>

<!-- BOX ANAGRAFICA EDIT CANTIERE-->
<mat-card *ngIf="isEdit" class="box_edit_cantiere"  [style.width.px]="riferimento.offsetWidth - 30">
  <mat-card-title class="title-box">
    <mat-icon>library_books</mat-icon><span>Anagrafica</span>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <form [formGroup]="worksiteForm" class="edit-box" *ngIf="isEdit">
      <div class="edit-container">

        <div class="flex">

          <mat-form-field>
            <mat-label>Data Inizio</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="datestart"
            />

            
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            

            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Data Fine</mat-label>
            <input
              matInput
              [matDatepicker]="pickerEnd"
              formControlName="dateend"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerEnd"
            ></mat-datepicker-toggle>
            
            
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>

        </div>

        <div *ngIf="submitted && wk['dateend'].errors" class="text-danger">
          <div *ngIf="wk['dateend'].errors['required']">

            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Date del cantiere obbligatorie</p>
            </mat-card>

          </div>

          <div *ngIf="wk['dateend'].errors['dateValidator']">
            <mat-card id="alert-card-filterWorksite-long">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">La data di fine deve essere maggiore della data di inizio</p>
            </mat-card>
          </div>

        </div>

        <div *ngIf="submitted && wk['datestart'].errors" class="text-danger">
          <div *ngIf="wk['datestart'].errors['required'] && !wk['dateend'].errors">
            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Date del cantiere obbligatorie</p>
            </mat-card>
          </div>
        </div>

        <mat-checkbox class="check-margin" formControlName="isactive" *ngIf="!notEdit"
          >Attivo
        </mat-checkbox>

      </div>

      <div class="Flex">
        <mat-form-field class="commessa-form-width" appearance="fill">
          <mat-label>Commessa</mat-label>
          <textarea
            matInput (keypress)="keyPressAlphaNumeric($event)"
            rows="1"
            maxlength="10"
            placeholder="Codice identificativo del cantiere"
            formControlName="commessa"
          ></textarea>
        </mat-form-field>

        <div *ngIf="submitted && wk['commessa'].errors" class="text-danger">

          <div *ngIf="wk['commessa'].errors['required']">
            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Codice commessa obbligatorio</p>
            </mat-card>
          </div>

          <div *ngIf="wk['commessa'].errors?.minlength">
            <mat-card id="alert-card-filterWorksite-long">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Il codice commessa deve avere minimo 3 caratteri</p>
            </mat-card>
          </div>

        </div>

      </div>

      <div class="Flex">
        <mat-form-field class="edit-full-width" appearance="fill">
          <mat-label>Nome</mat-label>
          <textarea
            matInput
            rows="1"
            maxlength="100"
            placeholder="Nome visualizzato"
            formControlName="description"
          ></textarea>
        </mat-form-field>

        <div *ngIf="submitted && wk['description'].errors" class="text-danger">
          <div *ngIf="wk['description'].errors['required']">
            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Nome del cantiere obbligatorio</p>
            </mat-card>
          </div>
        </div>

      </div>

      <div>
        <mat-form-field class="edit-full-width" appearance="fill">
          <mat-label>Note</mat-label>
          <textarea
            matInput
            rows="2"
            maxlength="500"
            placeholder="Note del cantiere"
            formControlName="descriptionextended"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="Flex">
        <mat-form-field class="edit-full-width" appearance="fill">
          <mat-label>Committente</mat-label>
          <textarea
            matInput
            rows="1"
            maxlength="100"
            placeholder="Nome committente"
            formControlName="client"
          ></textarea>
        </mat-form-field>

        <div *ngIf="submitted && wk['client'].errors" class="text-danger">
          <div *ngIf="wk['client'].errors['required']">
            <mat-card id="alert-card-filterWorksite-long">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Nome del committente obbligatorio</p>
            </mat-card>
          </div>
        </div>

      </div>

      <div class="Flex">
        <mat-form-field class="edit-full-width" appearance="fill">
          <mat-label>Indirizzo</mat-label>
          <textarea
            matInput
            rows="1"
            placeholder="Via Paolo Sarpi, 50 Milano 20154"
            formControlName="indirizzo"
          ></textarea>
        </mat-form-field>

        <div *ngIf="submitted && wk['indirizzo'].errors" class="text-danger">
          <div *ngIf="wk['indirizzo'].errors['required']">
            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Indirizzo obbligatorio</p>
            </mat-card>
          </div>
          <div *ngIf="mapError">
            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Indirizzo non valido</p>
            </mat-card>
          </div>
        </div>

      </div>

      <div class="Flex">
        <mat-form-field appearance="fill">
          <mat-label>Capocantiere</mat-label>
          <mat-select formControlName="bossid">
            <mat-option *ngFor="let ws of bossSource" [value]="ws.UserId">
              {{ ws.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="submitted && wk['bossid'].errors" class="text-danger">
          <div *ngIf="wk['bossid'].errors['required']">
            <mat-card id="alert-card-filterWorksite">
              <mat-icon style="font-size: 25px; margin-top: -5px;">warning</mat-icon>
              <p id="alert-text">Capocantiere obbligatorio</p>
            </mat-card>
          </div>
        </div>

      </div>
    </form>
  </mat-card-content>

  <mat-divider inset style="margin-top: -10px;"></mat-divider>

  <mat-card-actions>
    <div fxFlex></div>
    <!-- [disabled]="!worksiteForm.valid" -->
      <button

        mat-stroked-button
        color="primary"
        class="btn-block margin"
        (click)="Salva()"
      >
      <mat-icon>check</mat-icon>
      </button>
      <button
        mat-stroked-button
        color="warn"
        class="btn-block margin"
        (click)="Annulla()"
      >
        <mat-icon>close</mat-icon>
      </button>
  </mat-card-actions>

</mat-card>


<div class="loginPage">
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="box" style="max-width: 400px;">
            <mat-card-header>
                <mat-card-title>
                    <img mat-card-image style="padding-top: 1rem;" src="/assets/img/Dynapac_Logo.png" alt="">
                    <p style="display: flex; justify-content:center; align-items:center;">Reset password</p>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content #carta>
                <form [formGroup]="resetForm">
                    <mat-form-field>
                        <input matInput placeholder="Password" type="password" formControlName="password">

                        <mat-error *ngIf="resetForm.get('password')">{{'validPassword' | translate}}</mat-error>

                    </mat-form-field>
                    <div matTooltip="{{'registration_passwordmustcontain' | translate}}
                    {{'registration_passwordlength' | translate}}
                    {{'registration_passwordonelower' | translate}}
                    {{'registration_passwordonenumber' | translate}}
                    {{'registration_passwordonespecial' | translate}}
                    {{'registration_passwordoneupper' | translate}}" matTooltipPosition="above"
                        style="transform: translatey(-5px);" matTooltipClass="my-tooltip">
                        <span>
                            <mat-icon style="cursor: pointer;transform: scale(0.9) translateY(7px)">info</mat-icon>
                            <b>{{'registration_rulesforpasswordcrea' | translate}}</b>
                        </span>
                    </div>
                    <mat-form-field>
                        <input matInput placeholder="Conferma password" type="password"
                            formControlName="confirmPassword">
                        <mat-error *ngIf="resetForm.get('confirmPassword')">{{'passwordNotMatch' | translate}}</mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-stroked-button color="primary" class="btn-block" [disabled]="!resetForm.valid" (click)="resetPassword()"
                        style="width: 100%; max-width: 200px;">{{'confirm' | translate}}
                    </button>
                </div>
            </mat-card-actions>

        </mat-card>
        <br>

    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <img [ngStyle]="{'width.px': carta.offsetWidth }" src="../assets/img/splash.png">
    </div>

    <br>

</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordValidator } from 'src/app/services/PasswordValidator';
import { ChangePasswordModel } from 'src/app/model/ChangePasswordModel';
import { ChangeNameModel } from 'src/app/model/ChangeNameModel';
import { AuthService } from '../../auth/auth.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    public globals: Globals,
  ) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.GetProfile();
  }

  isLoadingResults = true;
  email = '';
  role = '';
  name = '';
  isChange = false;
  isChangePassword = false;
  isChangeName = false;
  hide = true;
  hide1 = true;
  hide2 = true;

  passwordForm = this.fb.group({
    oldpassword: ['', Validators.required],
    password: ['', [Validators.required ,Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
    confirmpassword: [''],
  }, { validators: PasswordValidator.confirmed("password", "confirmpassword") });

  nameForm = this.fb.group({
    name: ['', Validators.required ],
  });

  GetProfile() {
    this.isLoadingResults = true;

    this.apiService.GetProfile().subscribe(
      (data) => {
        this.isLoadingResults = false;

        this.email = data.Email;
        this.name = data.Name;
        this.role = data.Role;

      },
      (error) => {
        ////console.log(error);
        this.isLoadingResults = false;
      }
    );

  }

  ChangePassword() {

    this.isChange = true;
    this.isChangePassword = true;

    this.passwordForm.setValue({
      oldpassword: '',
      password: '',
      confirmpassword: '',
    });

  }

  SalvaPassword() {

    this.isLoadingResults = true;

    let dataPassword = new ChangePasswordModel(
      this.passwordForm.get('oldpassword')?.value,
      this.passwordForm.get('password')?.value,
      this.passwordForm.get('confirmpassword')?.value,
    );

    this.apiService.ChangePassword(dataPassword).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.isChange = false;
        this.isChangePassword = false;

        this.openSnackBarOk('Password cambiata');
      },
      (error) => {
        ////console.log(error);
        this.isLoadingResults = false;
      }
    );

  }

  ChangeName() {
    this.isChangePassword = false
    this.isChangeName = true;

    this.nameForm.setValue({
      name: '',
    });

  }

  SalvaName() {

    this.isLoadingResults = true;

    let dataName = new ChangeNameModel(
      this.nameForm.get('name')?.value,
    );

    this.apiService.ChangeName(dataName).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.isChange = false;
        this.isChangeName = false;

        this.GetProfile();

        this.globals.name = dataName.name;

        this.openSnackBarOk('Nome cambiato');
      },
      (error) => {
        ////console.log(error);
        this.isLoadingResults = false;
      }
    );

  }

  Annulla() {
    this.isChange = false;
    this.isChangePassword = false;
    this.isChangeName = false;

  }

  openSnackBarOk(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition:'bottom',
      panelClass: ['snackClassSuccess'],
      duration: 5000,
    });
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['snackClassError'],
      duration: 5000,
    });
  }

}
